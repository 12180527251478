import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M11.4364 12.9H14C14.4971 12.9 14.9 12.4971 14.9 12C14.9 11.5029 14.4971 11.1 14 11.1H11.4364C11.77 11.2621 12 11.6042 12 12C12 12.3958 11.77 12.7379 11.4364 12.9Z" }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 11C2 10.4477 2.44772 10 3 10H21C21.5523 10 22 10.4477 22 11V13C22 13.5523 21.5523 14 21 14H3C2.44772 14 2 13.5523 2 13V11ZM8 12C8 11.4477 8.44772 11 9 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H9C8.44772 13 8 12.5523 8 12Z",
      "clip-rule": "evenodd"
    })
  ]))
}