import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M4 11V5H20V10.85"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M4 17.8512C4.66667 17.8512 5.66667 16.5345 7.33333 16.3882C9.16667 16.1687 9.58333 17.9243 11.5833 17.9975C13.5 18.0706 14 16.5345 16.0833 16.3882C17.6667 16.2419 19 17.7049 20.0833 17.778C20.75 15.803 21.3333 13.9011 22 11.926L12 9L2 11.926L4 17.8512Z"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M2 21.778C4.08333 22.3009 5.91667 21.5688 7.66667 20C8.33333 20.5229 9.83333 21.6734 12.0833 21.778C14.4167 21.8825 16.0833 20.7321 16.6667 20.2092C18.3333 21.5688 20.1667 22.3009 22 21.8826"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M9 5C9 4.90909 9 2 9 2H15V5"
    })
  ]))
}