const restrictKeyPress = (event: KeyboardEvent) => {
  // restrict to enter `e` character or up/down arrow keys
  if (event.code === 'KeyE') {
    event.preventDefault()
  }

  const target = event.target as HTMLInputElement

  // check if has decimal and prevent entering another decimal
  if (event.code === 'Period' && target.value.indexOf('.') != -1) {
    event.preventDefault()
  }

  // check if already has 2 decimal places
  if (target.value.indexOf('.') >= 0 && target.value.split('.')[1].length >= 2) {
    event.preventDefault()
  }

  // check if already has length of 12
  if (target.value.length >= 12) {
    event.preventDefault()
  }
}

export { restrictKeyPress as restrictToNumbers }
