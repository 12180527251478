import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M7.19999 2.40002C6.56347 2.40002 5.95302 2.65288 5.50293 3.10297C5.05284 3.55306 4.79999 4.1635 4.79999 4.80002V19.2C4.79999 19.8365 5.05284 20.447 5.50293 20.8971C5.95302 21.3472 6.56347 21.6 7.19999 21.6H16.8C17.4365 21.6 18.047 21.3472 18.497 20.8971C18.9471 20.447 19.2 19.8365 19.2 19.2V8.89682C19.1999 8.26036 18.9469 7.65001 18.4968 7.20002L14.4 3.10322C13.95 2.65311 13.3397 2.40016 12.7032 2.40002H7.19999ZM13.2 9.60002C13.2 9.28176 13.0736 8.97654 12.8485 8.7515C12.6235 8.52645 12.3182 8.40002 12 8.40002C11.6817 8.40002 11.3765 8.52645 11.1515 8.7515C10.9264 8.97654 10.8 9.28176 10.8 9.60002V12H8.39999C8.08173 12 7.7765 12.1265 7.55146 12.3515C7.32642 12.5765 7.19999 12.8818 7.19999 13.2C7.19999 13.5183 7.32642 13.8235 7.55146 14.0486C7.7765 14.2736 8.08173 14.4 8.39999 14.4H10.8V16.8C10.8 17.1183 10.9264 17.4235 11.1515 17.6486C11.3765 17.8736 11.6817 18 12 18C12.3182 18 12.6235 17.8736 12.8485 17.6486C13.0736 17.4235 13.2 17.1183 13.2 16.8V14.4H15.6C15.9182 14.4 16.2235 14.2736 16.4485 14.0486C16.6736 13.8235 16.8 13.5183 16.8 13.2C16.8 12.8818 16.6736 12.5765 16.4485 12.3515C16.2235 12.1265 15.9182 12 15.6 12H13.2V9.60002Z",
      "clip-rule": "evenodd"
    })
  ]))
}