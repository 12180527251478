<script lang="ts" setup>
import { Ref, ref } from 'vue'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import SpecificationItem from './SpecificationItem.vue'
import { KeyValueSpecification, VesselSpecificationsSectionData } from './types/types'

type Props = {
  proposalUri: string
  data: VesselSpecificationsSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const filledSpecifications: KeyValueSpecification[] = props.data.specifications.filter(
  (specification) => specification.key && specification.value,
)
const isClamped: Ref<boolean> = ref(true)
</script>
<template>
  <div
    v-if="props.data?.specifications?.length"
    id="vessel-specifications-section"
    class="mx-auto max-w-screen-xl p-4 @sm:p-8"
  >
    <h1 class="font-bold text-3xl text-center mb-4">
      <SingleLineTextEditor value="Specifications" :is-editable="false" />
    </h1>
    <div class="mb-4 grid gap-2 grid-cols-2 @sm:grid-cols-4 @md:grid-cols-5 @lg:grid-cols-6 text-left">
      <SpecificationItem
        v-for="(specification, index) in filledSpecifications.slice(0, isClamped ? 12 : undefined)"
        :specification="specification"
        :key="`${index}-vessel-specification-item`"
      />
    </div>
    <span
      v-if="filledSpecifications.length > 12"
      class="cursor-pointer text-theme-primary"
      @click="isClamped = !isClamped"
    >
      {{ isClamped ? 'Show more' : 'Show less' }}
    </span>
  </div>
</template>
