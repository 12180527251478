import { Indexable } from '@ankor-io/common/lang/functional.types'
import { ThemeTemplate, FontItem } from '@ankor-io/common/proposal/Proposal'

export const loadTheme = (theme: ThemeTemplate): void => {
  // load all the colors into css vars
  Object.keys(CSS_VARS.color).forEach((key: string) => {
    document.documentElement.style.setProperty((CSS_VARS.color as Indexable)[key], (theme.color as Indexable)[key])
  })
  // load all the fonts into css vars
  Object.keys(CSS_VARS.font).forEach((key: string) => {
    const font: FontItem = (theme.font as Indexable)[key].type as FontItem
    const fontId = `${font.family.replace(' ', '+')}_style`
    // console.log('about to load >>> ', font.family)
    /**
     * Need to load the font via css
     */
    const fontStyle = document.getElementById(fontId)
    // if this font style has not been added before, then add it now
    if (!fontStyle) {
      // create a link
      const link = document.createElement('link')
      link.setAttribute('id', fontId)
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', fontItemToLink(font))
      document.head.appendChild(link)
    }

    // load the font color
    document.documentElement.style.setProperty(
      (CSS_VARS.font as Indexable)[key].color,
      (theme.font as Indexable)[key].color,
    )
    // load the font family
    document.documentElement.style.setProperty(
      (CSS_VARS.font as Indexable)[key].type,
      (theme.font as Indexable)[key].type.family,
    )
  })
}

export const fontItemToLink = (font: FontItem): string => {
  const url: string = `https://fonts.googleapis.com/css2?family=${font.family.replace(' ', '+')}`

  // some fonts have them all: regular, italic, 700, 700italic
  if (font.variants.includes('italic') && font.variants.includes('700') && font.variants.includes('700italic')) {
    return `${url}:ital,wght@0,400;0,700;1,400;1,700&display=swap`
  }
  // some fonts don't have 700s but have italic
  if (font.variants.includes('italic')) {
    return `${url}:ital@0;1&display=swap`
  }
  // some fonts have 700 but not italic
  if (font.variants.includes('700')) {
    return `${url}:wght@400;700&display=swap`
  }
  // any other case just return regular for now
  return `${url}&display=swap`
}

export type ThemeVariables = {
  color: {
    primary: string
    background: string
    shading: string
    accent: string
  }
  font: {
    heading: {
      type: string
      color: string
    }
    subheading: {
      type: string
      color: string
    }
    primary: {
      type: string
      color: string
    }
  }
}

export const CSS_VARS: ThemeVariables = {
  color: {
    primary: '--theme-color-primary',
    background: '--theme-color-background',
    shading: '--theme-color-shading',
    accent: '--theme-color-accent',
  },
  font: {
    heading: {
      type: '--theme-font-heading',
      color: '--theme-font-color-heading',
    },
    subheading: {
      type: '--theme-font-subheading',
      color: '--theme-font-color-subheading',
    },
    primary: {
      type: '--theme-font-primary',
      color: '--theme-font-color-primary',
    },
  },
}

export interface ThemeService {
  loadTheme: (theme: ThemeTemplate) => void
  getVars: () => ThemeVariables
}

export const useThemeService = (): ThemeService => {
  return {
    loadTheme: loadTheme,
    getVars: () => CSS_VARS,
  }
}
