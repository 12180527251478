<script setup lang="ts">
import { Ref, ref } from 'vue'
import { Disclaimer } from './types/types'
import { LayoutTemplate } from '@/sections/types'
import { DisclaimersSectionData } from './types/types'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Tabs from '@/components/Tabs.vue'
import StyleTab from './StyleTab.vue'
import SamplesTab from './SamplesTab.vue'

type Props = {
  id: string
  data: DisclaimersSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<DisclaimersSectionData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const tabs: Ref<string[]> = ref(['Style', 'Samples'])
const activeTab: Ref<string> = ref('Style')

const update = (disclaimer: Disclaimer) => {
  const data: DisclaimersSectionData = ObjectUtil.deepCopy(props.data)
  data.disclaimers.push(disclaimer)

  emit('update:value', { sectionId: props.id, data })
}
</script>
<template>
  <Tabs :tabs="tabs" @update:activeTab="activeTab = $event">
    <StyleTab
      v-show="activeTab === 'Style'"
      :layout="props.layout"
      @update:layout="
        emit('update:layout', {
          sectionId: props.id,
          layout: $event,
        })
      "
    />
    <SamplesTab v-show="activeTab === 'Samples'" :layout="props.layout" @add:disclaimer="update" />
  </Tabs>
</template>
