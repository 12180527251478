import gql from 'graphql-tag'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { VesselOverviewData } from '@/sections/vessel/overview/types'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { Component, ComputedOptions, MethodOptions } from 'vue'
import VesselOverviewOptions from '@/sections/vessel/overview/VesselOverviewOptions.vue'
import VesselOverviewSectionEditorVue from '@/sections/vessel/overview/VesselOverviewSectionEditor.vue'

const QUERY = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      description
    }
  }
`

export class VesselOverviewSectionEditor extends ProposalAbstractEditableSection<VesselOverviewData, EditableProposal> {
  async fetchData(): Promise<VesselOverviewData> {
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: this.getSlideUri() },
      })
      .then(({ data }) => {
        return Promise.resolve({
          heading: 'Overview',
          description: data.vessel.description,
        })
      })
      .catch(() => {
        console.error('Error fetching vessel description')
        return Promise.resolve({
          heading: 'Overview',
          description: '',
        })
      })
  }

  getType(): SectionType {
    return SectionType.VESSEL_OVERVIEW
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselOverviewSectionEditorVue
  }

  getConfigurationComponent(): Component<any, any, any, ComputedOptions, MethodOptions> | null {
    return VesselOverviewOptions
  }
}
