<script setup lang="ts">
import { Ref, computed, inject, ref, watch } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ViewMode } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { Identity, JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { SlideType } from '@ankor-io/common/proposal/SlideType'

import Spinner from '@/components/Spinner.vue'
import NavBarModalContentEditor from '@/components/modal-content/NavBarModalContentEditor.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { AuthenticationContext } from '@/iam/types'
import { useModal } from '@/modal/useModal'
import NavTabGroup from '@/sections/navbar/NavTabGroup.vue'
import { NavBarLayout, TabSelection, TabSelections } from '@/sections/navbar/types'
import { linkMedia } from '@/services/MediaService'

type NavBarProps = {
  templateUri: string
  proposalUri: string
  layout: NavBarLayout
  document: JsonProposal
  /**
   * The lifecycle hook
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<NavBarProps>()
const emit = defineEmits<{
  (e: 'change:slide', value: { slideUri: string; viewMode: ViewMode }): void
  (e: 'update:templateIdentity', value: Identity): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const linkingAsset: Ref<boolean> = ref(false)

const { isOpen, updateModalState } = useModal()
const isItineraryModalOpen: Ref<boolean> = ref(false)
const isVesselModalOpen: Ref<boolean> = ref(false)
/**
 * to determine the tab selected
 */
const selectedTab: Ref<TabSelection | null> = ref(null)

props.lifecycle({
  onHydrated: async () => {
    if (props.document.template.identity?.companyImage) {
      /*
      This gets run every time a new section is created. For newly created sections, we want to:
        1. convert relative image paths into media uris
        2. Save the media uris into the proposal json
      */

      const mainImage =
        (props.document.template.identity?.companyImage &&
          replacePathToMediaUris(props.proposalUri, props.document.template.identity?.companyImage)[0]) ||
        ''
      await linkMedia({ authenticationContext }, props.proposalUri, mainImage)
    }
  },
} as unknown as EditableLifecycleHooks)

const imageMediaUri = computed(
  () =>
    (props.document.template.identity.companyImage &&
      replacePathToMediaUris(props.proposalUri, props.document.template.identity.companyImage)[0]) ||
    '',
)

const numSlides = computed(() => props.document.document.slides.length || 0)
const itinerarySlides = computed(() =>
  props.document.document.slides.filter((item) => item.type === SlideType.ITINERARY),
)
const vesselSlides = computed(() => props.document.document.slides.filter((item) => item.type === SlideType.VESSEL))

const proposalItems = computed(() => props.document.proposalItems)

/**
 * @param updatedSelection can be 'Vessel / Itinerary'
 * or null in case nothing should be selected
 */
const selectTab = (updatedSelection: TabSelection | null): void => {
  selectedTab.value = updatedSelection

  if ((isVesselModalOpen.value || isItineraryModalOpen.value) && updatedSelection !== null) {
    return
  }

  if (updatedSelection === TabSelections.VESSELS) {
    isItineraryModalOpen.value = false
    isVesselModalOpen.value = true
  } else if (updatedSelection === TabSelections.ITINERARIES) {
    isItineraryModalOpen.value = true
    isVesselModalOpen.value = false
  }
  updateModalState(true)
}

const changeSlide = (value: { slideUri: string; viewMode: ViewMode }) => {
  updateModalState(false)
  emit('change:slide', { slideUri: value.slideUri, viewMode: value.viewMode })
}

const setNavFlexAlignment = (): string => {
  if (props.layout.type === 'default' || props.layout.options === 'center') {
    return 'justify-center'
  }

  if (props.layout.options === 'right') {
    return numSlides.value > 1 ? 'hidden @lg:flex justify-end' : 'justify-end'
  }

  // left
  return numSlides.value > 1 ? 'hidden @lg:flex justify-start' : 'justify-start'
}

watch(isOpen, (value) => {
  if (!value) {
    isItineraryModalOpen.value = false
    isVesselModalOpen.value = false
    selectedTab.value = null
  }
})
</script>
<template>
  <div>
    <div v-if="linkingAsset" class="overflow-y-hidden">
      <p class="flex justify-center w-full">Your company logo is being copied, hold on</p>
      <Spinner class="h-6" />
    </div>
    <template v-else>
      <div class="flex flex-col border-b border-gray-200 dark:border-gray-900 py-4">
        <!-- Center image -->
        <!-- Appears for left and right alignments on mobile -->
        <div
          v-if="props.layout.type === 'logo' && imageMediaUri"
          class="w-full relative h-16 px-8 flex items-center justify-center"
          :class="
            numSlides <= 1
              ? { hidden: props.layout.options !== 'center' }
              : { '@lg:hidden': props.layout.options !== 'center' }
          "
        >
          <div class="h-full max-w-[17rem] max-h-14">
            <AssetViewerPlaceholder class="w-full h-full object-contain" :url="`/media/${imageMediaUri}`" />
          </div>
        </div>

        <div
          class="w-full relative h-16 px-8 flex items-center"
          :class="
            props.layout.options === 'right'
              ? numSlides > 1
                ? 'lg:justify-end'
                : 'justify-center sm:justify-end'
              : props.layout.options === 'left' || !props.layout.options
              ? numSlides > 1
                ? 'lg:justify-start'
                : 'justify-center sm:justify-start'
              : { hidden: numSlides <= 1 }
          "
        >
          <!-- Left image (hidden on mobile) -->
          <div
            v-if="
              props.layout.type === 'logo' &&
              (props.layout.options === 'left' || !props.layout.options) &&
              imageMediaUri
            "
            class="h-full max-w-[17rem] max-h-14"
            :class="numSlides > 1 ? 'hidden @lg:flex' : 'flex'"
          >
            <AssetViewerPlaceholder class="w-full h-full object-contain" :url="`/media/${imageMediaUri}`" />
          </div>

          <!-- Navbar -->
          <div
            v-if="numSlides > 1"
            class="flex justify-center absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
          >
            <NavTabGroup
              :selected-tab="selectedTab"
              :vessels-length="vesselSlides.length"
              :itineraries-length="itinerarySlides.length"
              @select-tab="selectTab"
            />
          </div>

          <!-- Right image (hidden on mobile) -->
          <div
            v-if="props.layout.type === 'logo' && props.layout.options === 'right' && imageMediaUri"
            class="h-full max-w-[17rem] max-h-14"
            :class="setNavFlexAlignment()"
          >
            <AssetViewerPlaceholder class="w-full h-full object-contain" :url="`/media/${imageMediaUri}`" />
          </div>
        </div>
      </div>
    </template>

    <ModalContentWrapper v-if="isItineraryModalOpen">
      <NavBarModalContentEditor
        title="Routes"
        :itinerarySlides="itinerarySlides"
        :proposalItems="proposalItems"
        @close="updateModalState(false)"
        @change:slide="changeSlide($event)"
      />
    </ModalContentWrapper>

    <ModalContentWrapper v-if="isVesselModalOpen">
      <NavBarModalContentEditor
        title="Yachts"
        :vesselSlides="vesselSlides"
        :proposalItems="proposalItems"
        @close="updateModalState(false)"
        @change:slide="changeSlide($event)"
      />
    </ModalContentWrapper>
  </div>
</template>
