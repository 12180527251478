<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'

import { SolidMoon, SolidSun } from '@ankor-io/icons/solid'

const mode = ref('light')

onBeforeMount(() => {
  // read from browser setting of dark theme and move to session storage.
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue) {
    // use previously set pref.
    toggle(storageValue)
  } else {
    // read from the browser.
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      toggle('dark')
    } else {
      toggle('light')
    }
  }
})

/**
 * Toggle between light and dark mode
 * @param toMode 'dark' or 'light'
 */
const toggle = (toMode: string): void => {
  if (toMode === 'dark') {
    mode.value = 'dark'
    sessionStorage.setItem('prefers-color-scheme', 'dark')
    document.documentElement.classList.add('dark')
  } else {
    mode.value = 'light'
    sessionStorage.setItem('prefers-color-scheme', 'light')
    document.documentElement.classList.remove('dark')
  }
}
</script>
<template>
  <button v-if="mode === 'dark'" @click="toggle('light')" class="text-black dark:text-white">
    <SolidMoon class="w-6 h-6" />
  </button>
  <button v-else @click="toggle('dark')" class="text-black dark:text-white">
    <SolidSun class="w-6 h-6" />
  </button>
</template>
