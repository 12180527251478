import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M8.40002 3.59998C8.08176 3.59998 7.77654 3.7264 7.5515 3.95145C7.32645 4.17649 7.20002 4.48172 7.20002 4.79998C7.20002 5.11824 7.32645 5.42346 7.5515 5.6485C7.77654 5.87355 8.08176 5.99998 8.40002 5.99998H15.6C15.9183 5.99998 16.2235 5.87355 16.4486 5.6485C16.6736 5.42346 16.8 5.11824 16.8 4.79998C16.8 4.48172 16.6736 4.17649 16.4486 3.95145C16.2235 3.7264 15.9183 3.59998 15.6 3.59998H8.40002ZM4.80002 8.39998C4.80002 8.08172 4.92645 7.77649 5.1515 7.55145C5.37654 7.3264 5.68176 7.19998 6.00002 7.19998H18C18.3183 7.19998 18.6235 7.3264 18.8486 7.55145C19.0736 7.77649 19.2 8.08172 19.2 8.39998C19.2 8.71824 19.0736 9.02346 18.8486 9.2485C18.6235 9.47355 18.3183 9.59998 18 9.59998H6.00002C5.68176 9.59998 5.37654 9.47355 5.1515 9.2485C4.92645 9.02346 4.80002 8.71824 4.80002 8.39998ZM2.40002 13.2C2.40002 12.5635 2.65288 11.953 3.10297 11.5029C3.55306 11.0528 4.1635 10.8 4.80002 10.8H19.2C19.8365 10.8 20.447 11.0528 20.8971 11.5029C21.3472 11.953 21.6 12.5635 21.6 13.2V18C21.6 18.6365 21.3472 19.2469 20.8971 19.697C20.447 20.1471 19.8365 20.4 19.2 20.4H4.80002C4.1635 20.4 3.55306 20.1471 3.10297 19.697C2.65288 19.2469 2.40002 18.6365 2.40002 18V13.2Z" })
  ]))
}