<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'

import AssetDropOnly from '@/components/asset-uploader/AssetDropOnly.vue'
import { AuthenticationContext } from '@/iam/types'
import { ImageSectionEditorData } from '@/sections/image/ImageSectionEditor'
import { LayoutTemplate } from '@/sections/types'
import { linkMedia } from '@/services/MediaService'
import { removeDragCursor } from '@/utils/dragcursor'
import { WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE } from '@/utils/growthbook/constants'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The section's data
   */
  data: ImageSectionEditorData
  /**
   * The section's layout
   */
  layout: LayoutTemplate
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The lifecycle hook
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

props.lifecycle({
  onHydrated: async () => {
    if (!['simple', 'full'].includes(props.layout?.type)) {
      updateLayout('simple')
    }

    if (props.data.images?.length) {
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.images)
    }
  },
} as unknown as EditableLifecycleHooks)

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<any>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()
const uploadState: Ref<string | null> = ref(null)
const draggedImage: Ref<string | null> = ref(null)
const dragoverElement: Ref<string | null> = ref(null)

const imageMediaUri = computed(() => {
  if (!props.data.image && !props.data.images?.length) {
    return ''
  }
  return replacePathToMediaUris(props.proposalUri, props.data.image || props.data.images[0])[0]
})

const updateLayout = (layoutType: string) => {
  emit('update:layout', { sectionId: props.id, layout: { type: layoutType } })
}

const drag = (event: DragEvent): void => {
  if (draggedImage.value !== null) return
  const target = event.target as HTMLElement
  draggedImage.value = target.id
}

const dragover = (event: DragEvent): void => {
  const target = event.target as HTMLElement
  // dropzoneLabel is the incorrect target, so ignore it
  if (dragoverElement.value === target.id || target.id.includes('dropzoneLabel')) {
    return
  }

  if (target.tagName === 'svg') {
    dragoverElement.value = target.parentElement!.id
  } else {
    dragoverElement.value = target.id
  }
}

const drop = (id?: string): void => {
  removeDragCursor()
  if (id) {
    const element = document.getElementById(id) as HTMLImageElement

    const selectedImage = element.dataset.imagePath || element.src
    const url = new URL(selectedImage, window.location.origin)
    const mediaUri = url.pathname.split('/')[2]
    emit('update:value', { sectionId: props.id, data: { ...props.data, image: mediaUri } })
    draggedImage.value = null
    dragoverElement.value = null
  }
}
</script>
<template>
  <!-- Full width layout -->
  <div
    v-if="props.layout.type === 'full'"
    class="w-full mx-auto"
    :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? 'py-4 @sm:py-8' : 'p-4 sm:p-8'"
  >
    <!-- Image / placeholder -->
    <AssetDropOnly :image-index="0" :upload-state="uploadState" @file:dropped="drop" @image:dragover="dragover">
      <AssetViewerPlaceholder class="w-full h-full" :url="`/media/${imageMediaUri}`" @drag="drag" />
    </AssetDropOnly>
  </div>

  <!-- Layout with max width -->
  <div
    v-else
    class="w-full mx-auto max-w-360 p-4"
    :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-8' : 'sm:p-8'"
  >
    <!-- Image / placeholder -->
    <AssetDropOnly :image-index="0" :upload-state="uploadState" @file:dropped="drop" @image:dragover="dragover">
      <AssetViewerPlaceholder class="w-full h-full" :url="`/media/${imageMediaUri}`" @drag="drag" />
    </AssetDropOnly>
  </div>
</template>
