<script setup lang="ts">
import { onMounted } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import HelpTip from '@/components/HelpTip.vue'

import { NavBarLayout, NavBarOptions } from './types'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's layout
   */
  layout: NavBarLayout
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:layout', layout: NavBarLayout): void
}>()

onMounted(() => {
  if (!props.layout.type) {
    updateLogoType('type', false)
  }
})

const updateLogoType = (field: string, value: boolean): void => {
  const layout: NavBarLayout = ObjectUtil.deepCopy(props.layout)
  if (field === 'type') {
    layout.type = value ? 'logo' : 'default'
  }

  emit('update:layout', layout)
}

const updateLogo = (field: string, value: NavBarOptions): void => {
  const layout: NavBarLayout = ObjectUtil.deepCopy(props.layout)
  if (field === 'options') {
    layout.options = value
  }

  emit('update:layout', layout)
}
</script>
<template>
  <HelpTip tip="Changes will only be visible in this proposal." />
  <p class="text-sm font-bold text-gray-900 dark:text-white">Top navigation</p>
  <div class="flex flex-col gap-y-5">
    <div class="flex gap-x-2">
      <input
        id="top-navigation-checkbox"
        type="checkbox"
        value=""
        class="w-4 h-4 cursor-pointer text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        :checked="props.layout?.type === 'logo'"
        @change="updateLogoType('type', ($event.target as HTMLInputElement).checked)"
      />
      <label for="top-navigation-checkbox" class="text-xs text-gray-600 dark:text-gray-300 cursor-pointer">
        Include Logo in top navigation bar
      </label>
    </div>
    <select
      v-if="props.layout?.type === 'logo'"
      id="nav-bar-logo-alignment"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      @change="updateLogo('options', ($event.target as HTMLInputElement).value as NavBarOptions)"
    >
      <option value="left" :selected="props.layout.options === 'left'">Left aligned Logo</option>
      <option value="center" :selected="props.layout.options === 'center'">Center aligned Logo</option>
      <option value="right" :selected="props.layout.options === 'right'">Right aligned Logo</option>
    </select>
  </div>
</template>
