import { AbstractSection } from '@/services/proposal/Section'
import { VesselOverviewData } from '@/sections/vessel/overview/types'
import { Component, ComputedOptions, MethodOptions } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import VesselOverviewSectionVue from '@/sections/vessel/overview/VesselOverviewSection.vue'

export class VesselOverviewSection extends AbstractSection<VesselOverviewData> {
  getType(): SectionType {
    return SectionType.VESSEL_OVERVIEW
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselOverviewSectionVue
  }
}
