import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("rect", {
      width: "4",
      height: "5",
      x: "2",
      y: "7",
      rx: "1"
    }),
    _createElementVNode("rect", {
      width: "4",
      height: "5",
      x: "12",
      y: "7",
      rx: "1"
    }),
    _createElementVNode("rect", {
      width: "4",
      height: "5",
      x: "5",
      y: "13",
      rx: "1"
    }),
    _createElementVNode("rect", {
      width: "4",
      height: "5",
      x: "7",
      y: "7",
      rx: "1"
    }),
    _createElementVNode("rect", {
      width: "4",
      height: "5",
      x: "17",
      y: "7",
      rx: "1"
    }),
    _createElementVNode("rect", {
      width: "4",
      height: "5",
      x: "10",
      y: "13",
      rx: "1"
    }),
    _createElementVNode("rect", {
      width: "4",
      height: "5",
      x: "15",
      y: "13",
      rx: "1"
    })
  ]))
}