import { Component } from 'vue'

import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import ProfileCompanyConfigurationVue from '@/sections/profile-company/ProfileCompanyConfiguration.vue'
import ProfileCompanySectionEditorVue from '@/sections/profile-company/ProfileCompanySectionEditor.vue'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

export type ProfileCompanySectionEditorData = {
  givenName: string | null
  surname: string | null
  image: string | null
  role: string | null
  email: string | null
  phone: string | null
  website: string | null
  companyImage: string | null
  companyName: string | null
  companyAddress: string | null
  companyPhone: string | null
}

export class ProfileCompanySectionEditor extends ProposalAbstractEditableSection<
  ProfileCompanySectionEditorData,
  EditableProposal
> {
  async fetchData(): Promise<ProfileCompanySectionEditorData> {
    return this.data
  }

  getType(): SectionType {
    return SectionType.PROFILE_COMPANY
  }

  getComponent(): Component {
    return ProfileCompanySectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return ProfileCompanyConfigurationVue
  }
}
