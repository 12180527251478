import { Component } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { IUri, URIEntity } from '@ankor-io/common/uri/Uri'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'
import { Vessel } from '@ankor-io/common/vessel/types'

import { ToysAmenitiesSectionData } from '@/sections/vessel/toys-amenities/types/types'
import { AbstractSection } from '@/services/proposal/Section'

import ToysAmenitiesLiteSectionVue from './ToysAmenitiesLiteSection.vue'
import { vesselToysAmenitiesLiteSectionMapper } from '@/sections/vessel/mapper'

export class ToysAmenitiesLiteSection extends AbstractSection<ToysAmenitiesSectionData> {
  getType(): SectionType {
    return SectionType.VESSEL_TOYS_AMENITIES
  }

  getComponent(): Component {
    return ToysAmenitiesLiteSectionVue
  }

  /**
   * Implement this method to allow this section to work in memory in the Lite Editor
   * @param _source
   */
  dataFrom<A extends { uri: string; [key: string]: any }>(_source: A): ToysAmenitiesSectionData {
    const _uri: IUri = new GenericUriParser().parse(_source.uri)
    if (_uri.getEntity() === URIEntity.VESSEL) {
      return vesselToysAmenitiesLiteSectionMapper.from(_source as unknown as Vessel)
    }
    throw new Error(`Invalid source not supported for ToysAmenitiesSection: ${_source.uri}`)
  }
}
