import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M5 6V8.61257C5 9.29513 5.6687 9.7771 6.31623 9.56126L12.1838 7.60541C12.389 7.53699 12.611 7.53699 12.8162 7.60541L18.6838 9.56126C19.3313 9.7771 20 9.29513 20 8.61257V6C20 5.44772 19.5523 5 19 5H6C5.44772 5 5 5.44772 5 6Z" }),
    _createElementVNode("path", { d: "M4.67727 17.1565C4.80999 17.5704 5.19835 17.8055 5.5605 17.5652C6.15717 17.1692 6.95614 16.4908 8.06669 16.3882C9.80835 16.1687 10.2042 17.9243 12.1042 17.9975C13.925 18.0706 14.4 16.5345 16.3792 16.3882C17.5387 16.2754 18.5571 17.119 19.4344 17.5449C19.849 17.7463 20.2785 17.4692 20.4167 17.0295C20.8536 15.6398 21.2608 14.2775 21.6947 12.8905C21.861 12.3591 21.5653 11.7921 21.0332 11.6282L12.7944 9.09069C12.6026 9.03162 12.3974 9.03162 12.2056 9.09069L3.9668 11.6282C3.43467 11.7921 3.13889 12.3591 3.30891 12.8893L4.67727 17.1565Z" }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M7.39455 18.2915C7.76647 17.921 8.36154 17.9012 8.75729 18.2461C9.33584 18.7504 10.4629 19.693 12.1259 19.7792C13.834 19.864 15.075 18.9185 15.4945 18.5007C15.8718 18.1249 16.4773 18.1107 16.8718 18.4682C18.2633 19.7295 19.5771 20.2117 20.7545 19.9132C21.2898 19.7775 21.8339 20.1014 21.9696 20.6368C22.1053 21.1721 21.7814 21.7161 21.246 21.8519C19.4096 22.3175 17.6752 21.6452 16.1992 20.5113C15.319 21.1466 13.8739 21.8688 12.0255 21.7767L12.0237 21.7766C10.2727 21.6862 8.95984 20.9311 8.1394 20.3215C6.57951 21.637 4.79003 22.3153 2.73161 21.7412C2.19963 21.5928 1.88865 21.0413 2.03701 20.5093C2.18538 19.9773 2.73692 19.6664 3.2689 19.8147C4.66091 20.203 5.95584 19.7245 7.39455 18.2915Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", { d: "M15 2H10C9.44772 2 9 2.44772 9 3C9 3.55228 9.44772 4 10 4H15C15.5523 4 16 3.55228 16 3C16 2.44772 15.5523 2 15 2Z" })
  ]))
}