import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      d: "M19.1278 13.2496H2.51715M2.51715 13.2496L1.10383 9.31926C0.771684 8.01677 1.27184 7.1987 2.10017 6.75996C2.9285 6.32123 9.40697 3.80753 9.40697 3.80753C12.3961 2.73083 12.064 2.73079 14.3888 3.8075L21.5291 6.75996C22.3599 7.03124 23.3227 8.01677 22.8949 9.31926C22.3486 10.9828 21.7567 12.6326 21.5291 13.2496M2.51715 13.2496V15.2376M21.5291 13.2496H20.9342M21.5291 13.2496V15.2376M21.5291 15.2376C22.0278 15.6282 22.3599 15.9864 22.0278 17.061C21.4354 18.9775 17.3702 21 12.0561 21C7.07425 21 2.84927 19.2102 1.76806 17.061C1.43594 15.9864 2.05892 15.3839 2.51715 15.2376M21.5291 15.2376H2.51715M12.8492 8.01677H11.2631M12.0561 6.75997V11.9471M12.0561 11.9471C10.4033 11.3297 10.0712 10.6133 10.0712 9.89689L9.40697 9.53869M12.0561 11.9471C13.7246 11.3297 13.9287 10.6133 13.9287 9.89053L14.721 9.53869"
    })
  ]))
}