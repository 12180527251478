import gql from 'graphql-tag'
import { Component } from 'vue'

import { Itinerary, Segment, Stop } from '@ankor-io/common/itinerary/Itinerary'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import ConfigurationComponent from './OverviewConfiguration.vue'
import OverviewSectionEditorVue from './OverviewSectionEditor.vue'

const QUERY = gql`
  query Itinerary($URI: String!) {
    itinerary(uri: $URI) {
      uri
      overviewHeading
      externalName
      description
      images
      segments {
        stops {
          place {
            uri
            images
          }
        }
      }
    }
  }
`

/**
 * Get all the images from the itinerary
 * @param itinerary
 */
const getAllImages = (itinerary: Itinerary): string[] => {
  const itineraryImages = (itinerary.images && replacePathToMediaUris(itinerary.uri, ...itinerary.images)) || []
  return itineraryImages.concat(
    itinerary.segments.flatMap(
      (segment: Segment) =>
        segment.stops.flatMap(
          (stop: Stop) => stop.place && replacePathToMediaUris(stop.place.uri, ...stop.place.images),
        ) || [],
    ),
  )
}

export interface OverviewSectionEditorData {
  heading: string
  subheading: string
  narrative: string
  images: string[]
  selectedImage: string
  /**
   *  Backward compatable fields
   */
  // heading alternatives
  name?: string
  overviewHeading?: string
  // subheading alternatives
  internalName?: string
  externalName?: string
  // narrative alternatives
  description?: string
  // selectedImage alternative
  image?: string
}

export class OverviewSectionEditor extends ProposalAbstractEditableSection<
  OverviewSectionEditorData,
  EditableProposal
> {
  async fetchData(): Promise<OverviewSectionEditorData> {
    const itineraryUri = this.getSlideUri()
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: itineraryUri },
      })
      .then(({ data }) => {
        const images = getAllImages(data?.itinerary)
        return Promise.resolve({
          heading: data.itinerary?.overviewHeading || 'Overview',
          subheading: data.itinerary?.externalName || '',
          narrative: data.itinerary?.description || '',
          images,
          selectedImage: images[0] || '',
        })
      })
  }

  getType(): SectionType {
    return SectionType.ITINERARY_OVERVIEW
  }

  getComponent(): Component {
    return OverviewSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return ConfigurationComponent
  }
}
