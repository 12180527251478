import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M21.6 12C21.6 14.5461 20.5886 16.9879 18.7883 18.7882C16.9879 20.5886 14.5461 21.6 12 21.6C9.45395 21.6 7.01215 20.5886 5.2118 18.7882C3.41145 16.9879 2.40002 14.5461 2.40002 12C2.40002 9.45392 3.41145 7.01212 5.2118 5.21177C7.01215 3.41142 9.45395 2.39999 12 2.39999C14.5461 2.39999 16.9879 3.41142 18.7883 5.21177C20.5886 7.01212 21.6 9.45392 21.6 12V12ZM12 8.39999C11.7892 8.39979 11.582 8.45514 11.3994 8.56047C11.2167 8.6658 11.065 8.8174 10.9596 8.99999C10.8835 9.14145 10.7798 9.26617 10.6545 9.36679C10.5293 9.4674 10.3852 9.54185 10.2306 9.58572C10.0761 9.62959 9.91436 9.64198 9.75495 9.62218C9.59554 9.60237 9.44174 9.55076 9.30265 9.47041C9.16356 9.39005 9.04201 9.28259 8.94522 9.1544C8.84843 9.0262 8.77837 8.87987 8.73918 8.72409C8.69999 8.56831 8.69248 8.40625 8.71708 8.24752C8.74169 8.08878 8.79791 7.9366 8.88242 7.79999C9.27869 7.11373 9.89031 6.57739 10.6225 6.27415C11.3546 5.97091 12.1663 5.91772 12.9318 6.12282C13.6972 6.32793 14.3736 6.77987 14.8561 7.40855C15.3385 8.03724 15.6 8.80754 15.6 9.59999C15.6002 10.3447 15.3696 11.0712 14.9397 11.6794C14.5099 12.2876 13.9021 12.7476 13.2 12.996V13.2C13.2 13.5183 13.0736 13.8235 12.8486 14.0485C12.6235 14.2736 12.3183 14.4 12 14.4C11.6818 14.4 11.3765 14.2736 11.1515 14.0485C10.9265 13.8235 10.8 13.5183 10.8 13.2V12C10.8 11.6817 10.9265 11.3765 11.1515 11.1515C11.3765 10.9264 11.6818 10.8 12 10.8C12.3183 10.8 12.6235 10.6736 12.8486 10.4485C13.0736 10.2235 13.2 9.91825 13.2 9.59999C13.2 9.28173 13.0736 8.97651 12.8486 8.75147C12.6235 8.52642 12.3183 8.39999 12 8.39999ZM12 18C12.3183 18 12.6235 17.8736 12.8486 17.6485C13.0736 17.4235 13.2 17.1183 13.2 16.8C13.2 16.4817 13.0736 16.1765 12.8486 15.9515C12.6235 15.7264 12.3183 15.6 12 15.6C11.6818 15.6 11.3765 15.7264 11.1515 15.9515C10.9265 16.1765 10.8 16.4817 10.8 16.8C10.8 17.1183 10.9265 17.4235 11.1515 17.6485C11.3765 17.8736 11.6818 18 12 18V18Z",
    })
  ]))
}