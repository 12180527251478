<script lang="ts" setup>
import { watch } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { LifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { HiOutlineGlobeAlt } from '@ankor-io/icons/hi_outline'
import { OutlineMail, OutlinePhone } from '@ankor-io/icons/outline'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { ProfileCompanySectionEditorData } from '@/sections/profile-company/ProfileCompanySectionEditor'
import { LayoutTemplate } from '@/sections/types'
import { AbstractSection } from '@/services/proposal/Section'

type Props = {
  section: AbstractSection<any>
  id: string
  proposalUri: string
  document: JsonProposal
  data: ProfileCompanySectionEditorData
  layout: LayoutTemplate
  lifecycle: Runnable<LifecycleHooks>
  source?: { uri: string; [key: string]: any }
}

const props = defineProps<Props>()

props.lifecycle({
  onBeforeAttach() {
    // if the source is provided and the data is null, use that to hydrate
    if (props.source && (!props.data || Object.keys(props.data).length === 0)) {
      //@ts-ignore this works because of the lifecycle bound to the section class
      this.setData(this.dataFrom<JsonProposal>(props.document))
    }
  },
} as LifecycleHooks)
// only watch changes in the identity
watch(
  () => props.document.template.identity,
  (newVal, oldValue) => {
    if (props.document.template.identity && JSON.stringify(newVal) !== JSON.stringify(oldValue)) {
      props.section.setData(props.section.dataFrom<JsonProposal>(props.document))
    }
  },
)
</script>
<template>
  <div
    class="profile-company w-full h-full flex items-center p-4 sm:p-8 flex-col justify-between sm:flex-row gap-8"
    :class="props.layout.type === 'taller' ? 'bg-gray-700' : 'bg-gray-50'"
  >
    <!-- Company details -->
    <div class="company flex flex-col justify-center sm:max-w-[33%] gap-y-1 text-center sm:text-left">
      <h1
        v-if="props.data.companyName"
        class="text-lg sm:text-[2rem] font-semibold"
        :class="{ '!text-white': props.layout.type === 'taller' }"
      >
        <SingleLineTextEditor :value="props.data.companyName" :is-editable="false" />
      </h1>
      <div class="flex flex-col gap-y-4 self-center sm:self-auto">
        <div
          v-if="props.data.companyAddress || props.data.companyPhone"
          :class="{ '!text-white': props.layout.type === 'taller' }"
        >
          <SingleLineTextEditor
            v-if="props.data.companyAddress"
            :value="props.data.companyAddress"
            :is-editable="false"
          />
          <SingleLineTextEditor v-if="props.data.companyPhone" :value="props.data.companyPhone" :is-editable="false" />
        </div>
        <AssetViewer
          v-if="props.data.companyImage"
          class="max-w-[11.5rem] max-h-[11.5rem] object-cover self-center sm:self-start"
          :url="`/media/${props.data.companyImage}`"
        />
      </div>
    </div>

    <!-- Profile details -->
    <div
      class="profile min-w-[25rem] w-fit flex flex-col sm:flex-row rounded-lg overflow-hidden shadow-md h-full my-auto"
      :class="{ 'bg-gray-500': props.layout.type === 'taller' }"
    >
      <!-- Profile image -->
      <div class="w-full sm:w-56 h-full sm:min-h-56">
        <AssetViewer
          v-if="props.data.image"
          class="flex object-cover aspect-square sm:aspect-auto"
          :url="`/media/${props.data.image}`"
        />
      </div>

      <div class="flex flex-col justify-between gap-y-2 py-8 px-6">
        <div v-if="props.data.givenName || props.data.surname || props.data.role">
          <div
            v-if="props.data.givenName || props.data.surname"
            class="flex flex-row gap-1 flex-wrap text-lg font-bold"
          >
            <h1 v-if="props.data.givenName" :class="{ '!text-white': props.layout.type === 'taller' }">
              <SingleLineTextEditor :value="props.data.givenName" :is-editable="false" />
            </h1>
            <h1 v-if="props.data.surname" :class="{ '!text-white': props.layout.type === 'taller' }">
              <SingleLineTextEditor :value="props.data.surname" :is-editable="false" />
            </h1>
          </div>
          <h3 v-if="props.data.role">
            <SingleLineTextEditor
              class="italic font-light text-left"
              :class="props.layout.type === 'taller' ? '!text-white' : 'text-gray-500'"
              :value="props.data.role"
              :is-editable="false"
            />
          </h3>
        </div>

        <div class="flex flex-col gap-y-2 !font-light" :class="{ 'text-white': props.layout.type === 'taller' }">
          <div v-if="props.data.phone" class="flex gap-x-2">
            <div class="self-center">
              <OutlinePhone class="w-4 stroke-theme-primary" />
            </div>
            <SingleLineTextEditor :value="props.data.phone" :is-editable="false" />
          </div>
          <div v-if="props.data.email" class="flex gap-x-2">
            <div class="self-center">
              <OutlineMail class="w-4 stroke-theme-primary" />
            </div>
            <SingleLineTextEditor class="break-all" :value="props.data.email" :is-editable="false" />
          </div>
          <div v-if="props.data.website" class="flex gap-x-2">
            <div class="self-center">
              <HiOutlineGlobeAlt class="w-4 stroke-theme-primary" />
            </div>
            <SingleLineTextEditor class="break-all" :value="props.data.website" :is-editable="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Primary colour block for taller layout/layout 2 at the bottom -->
  <div v-if="props.layout.type === 'taller'" class="h-16 bg-theme-primary"></div>
</template>
@/sections/profile-company/ProfileCompanySectionEditor
