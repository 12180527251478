import { Component } from 'vue'

import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { AllDayCardSegment } from '@/sections/allDayCards/Type'
import AllDayCardsSectionVue from './AllDayCardsSection.vue'

export interface AllDayCardsSectionData {
  segments: AllDayCardSegment[]
}

export class AllDayCardsSection extends AbstractSection<AllDayCardsSectionData> {
  public onAttached(): void {
    const proxy = this.getProxy<AllDayCardsSectionData>()
    this.data.segments = proxy.segments
  }

  getType(): SectionType {
    return SectionType.ALL_DAY_CARDS
  }

  getComponent(): Component {
    return AllDayCardsSectionVue
  }
}
