import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M20 10.3077L17.5294 8M16.7059 13L13 9.53846"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M14 16.3077L11.5294 14M10.7059 19L7 15.5385"
    }),
    _createElementVNode("path", {
      "stroke-linejoin": "round",
      d: "M16.3214 1L23 7.67857L7.67857 23L1 16.3214L16.3214 1Z"
    })
  ]))
}