import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M10 2C10.5523 2 11 2.43173 11 2.96429C11 3.49685 10.5523 3.92857 10 3.92857C9.44772 3.92857 9 3.49685 9 2.96429C9 2.43173 9.44772 2 10 2Z" }),
    _createElementVNode("path", { d: "M10 5.53571C10.5523 5.53571 11 5.96744 11 6.5C11 7.03256 10.5523 7.46429 10 7.46429C9.44772 7.46429 9 7.03256 9 6.5C9 5.96744 9.44772 5.53571 10 5.53571Z" }),
    _createElementVNode("path", { d: "M11 10.0357C11 9.50315 10.5523 9.07143 10 9.07143C9.44772 9.07143 9 9.50315 9 10.0357C9 10.5683 9.44772 11 10 11C10.5523 11 11 10.5683 11 10.0357Z" }),
    _createElementVNode("path", { d: "M14 2C14.5523 2 15 2.43173 15 2.96429C15 3.49685 14.5523 3.92857 14 3.92857C13.4477 3.92857 13 3.49685 13 2.96429C13 2.43173 13.4477 2 14 2Z" }),
    _createElementVNode("path", { d: "M14 5.53571C14.5523 5.53571 15 5.96744 15 6.5C15 7.03256 14.5523 7.46429 14 7.46429C13.4477 7.46429 13 7.03256 13 6.5C13 5.96744 13.4477 5.53571 14 5.53571Z" }),
    _createElementVNode("path", { d: "M15 10.0357C15 9.50315 14.5523 9.07143 14 9.07143C13.4477 9.07143 13 9.50315 13 10.0357C13 10.5683 13.4477 11 14 11C14.5523 11 15 10.5683 15 10.0357Z" }),
    _createElementVNode("path", { d: "M10 22C9.44771 22 9 21.5683 9 21.0357C9 20.5032 9.44771 20.0714 10 20.0714C10.5523 20.0714 11 20.5032 11 21.0357C11 21.5683 10.5523 22 10 22Z" }),
    _createElementVNode("path", { d: "M10 18.4643C9.44771 18.4643 9 18.0326 9 17.5C9 16.9674 9.44772 16.5357 10 16.5357C10.5523 16.5357 11 16.9674 11 17.5C11 18.0326 10.5523 18.4643 10 18.4643Z" }),
    _createElementVNode("path", { d: "M9 13.9643C9 14.4968 9.44772 14.9286 10 14.9286C10.5523 14.9286 11 14.4968 11 13.9643C11 13.4317 10.5523 13 10 13C9.44772 13 9 13.4317 9 13.9643Z" }),
    _createElementVNode("path", { d: "M14 22C13.4477 22 13 21.5683 13 21.0357C13 20.5032 13.4477 20.0714 14 20.0714C14.5523 20.0714 15 20.5032 15 21.0357C15 21.5683 14.5523 22 14 22Z" }),
    _createElementVNode("path", { d: "M14 18.4643C13.4477 18.4643 13 18.0326 13 17.5C13 16.9674 13.4477 16.5357 14 16.5357C14.5523 16.5357 15 16.9674 15 17.5C15 18.0326 14.5523 18.4643 14 18.4643Z" }),
    _createElementVNode("path", { d: "M13 13.9643C13 14.4968 13.4477 14.9286 14 14.9286C14.5523 14.9286 15 14.4968 15 13.9643C15 13.4317 14.5523 13 14 13C13.4477 13 13 13.4317 13 13.9643Z" })
  ]))
}