import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01213 18.7882 5.21178C16.9879 3.41143 14.5461 2.4 12 2.4C9.45395 2.4 7.01215 3.41143 5.2118 5.21178C3.41145 7.01213 2.40002 9.45392 2.40002 12C2.40002 14.5461 3.41145 16.9879 5.2118 18.7882C7.01215 20.5886 9.45395 21.6 12 21.6ZM10.4484 8.7516C10.2221 8.53301 9.91898 8.41206 9.60434 8.41479C9.28971 8.41753 8.98873 8.54373 8.76624 8.76622C8.54375 8.98871 8.41755 9.28968 8.41482 9.60432C8.41208 9.91896 8.53304 10.2221 8.75163 10.4484L10.3032 12L8.75163 13.5516C8.63701 13.6623 8.54559 13.7947 8.4827 13.9411C8.41981 14.0875 8.38671 14.245 8.38532 14.4043C8.38394 14.5637 8.4143 14.7217 8.47464 14.8691C8.53498 15.0166 8.62408 15.1506 8.73675 15.2633C8.84942 15.3759 8.9834 15.4651 9.13088 15.5254C9.27836 15.5857 9.43637 15.6161 9.59571 15.6147C9.75504 15.6133 9.91251 15.5802 10.0589 15.5173C10.2053 15.4544 10.3377 15.363 10.4484 15.2484L12 13.6968L13.5516 15.2484C13.7779 15.467 14.0811 15.5879 14.3957 15.5852C14.7103 15.5825 15.0113 15.4563 15.2338 15.2338C15.4563 15.0113 15.5825 14.7103 15.5852 14.3957C15.588 14.081 15.467 13.7779 15.2484 13.5516L13.6968 12L15.2484 10.4484C15.467 10.2221 15.588 9.91896 15.5852 9.60432C15.5825 9.28968 15.4563 8.98871 15.2338 8.76622C15.0113 8.54373 14.7103 8.41753 14.3957 8.41479C14.0811 8.41206 13.7779 8.53301 13.5516 8.7516L12 10.3032L10.4484 8.7516Z",
      "clip-rule": "evenodd"
    })
  ]))
}