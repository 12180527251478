import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M5.15158 5.1516C5.37661 4.92664 5.68178 4.80026 5.99998 4.80026C6.31817 4.80026 6.62334 4.92664 6.84838 5.1516L12 10.3032L17.1516 5.1516C17.2623 5.03699 17.3947 4.94557 17.5411 4.88268C17.6875 4.81979 17.845 4.78669 18.0043 4.7853C18.1636 4.78392 18.3216 4.81428 18.4691 4.87462C18.6166 4.93495 18.7506 5.02406 18.8632 5.13673C18.9759 5.2494 19.065 5.38338 19.1254 5.53086C19.1857 5.67833 19.2161 5.83635 19.2147 5.99568C19.2133 6.15502 19.1802 6.31248 19.1173 6.45889C19.0544 6.60529 18.963 6.7377 18.8484 6.8484L13.6968 12L18.8484 17.1516C19.067 17.3779 19.1879 17.681 19.1852 17.9957C19.1825 18.3103 19.0562 18.6113 18.8338 18.8338C18.6113 19.0563 18.3103 19.1825 17.9957 19.1852C17.681 19.1879 17.3779 19.067 17.1516 18.8484L12 13.6968L6.84838 18.8484C6.62205 19.067 6.31893 19.1879 6.00429 19.1852C5.68966 19.1825 5.38868 19.0563 5.16619 18.8338C4.9437 18.6113 4.8175 18.3103 4.81477 17.9957C4.81203 17.681 4.93299 17.3779 5.15158 17.1516L10.3032 12L5.15158 6.8484C4.92661 6.62337 4.80023 6.3182 4.80023 6C4.80023 5.6818 4.92661 5.37663 5.15158 5.1516V5.1516Z",
      "clip-rule": "evenodd"
    })
  ]))
}