import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { AbstractSection } from '@/services/proposal/Section'
import ProfileCompanySectionVue from '@/sections/profile-company/ProfileCompanySection.vue'

export type ProfileCompanySectionData = {
  givenName: string | null
  surname: string | null
  image: string | null
  role: string | null
  email: string | null
  phone: string | null
  website: string | null
  companyImage: string | null
  companyName: string | null
  companyAddress: string | null
  companyPhone: string | null
}

export class ProfileCompanySection extends AbstractSection<ProfileCompanySectionData> {
  public onAttached(): void {
    const proxy = this.getProxy<ProfileCompanySectionData>()
    this.data = proxy
  }

  getType(): SectionType {
    return SectionType.PROFILE_COMPANY
  }

  getComponent(): Component {
    return ProfileCompanySectionVue
  }
}
