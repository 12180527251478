/**
 * Represents an on change event that contains the data
 */
export declare type ChangeEvent<T> = {
  /**
   * The section id
   */
  sectionId: string
  /**
   * the changed data
   */
  data: T
}

/**
 * The different states and editor can be in
 */
export enum State {
  EDIT = 'edit',
  READ = 'read',
}

/**
 * The different view mode and editor can be in
 */
export enum ViewMode {
  SLIDE = 'slide',
  STACKED = 'stacked',
}
