import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#clip0_2638_87399)" }, [
      _createElementVNode("path", {
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        d: "M13.089 6.0361L16.339 2.786C19.5889 -0.463702 24.4638 4.41119 21.2139 7.66123L17.9639 10.911M13.089 6.0361L3.3392 15.7859L1.71423 22.2857L8.32046 20.6299L17.9639 10.911M13.089 6.0361L17.9639 10.911"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "clip0_2638_87399" }, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          fill: "white"
        })
      ])
    ])
  ]))
}