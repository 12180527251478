<script setup lang="ts">
import { Ref, ref } from 'vue'

export interface TabsProps {
  tabs: string[]
}

const props = defineProps<TabsProps>()
const emit = defineEmits<{
  (e: 'update:activeTab', value: string): void
}>()

const activeTab: Ref<string> = ref(props.tabs[0])
const setActiveTab = (tab: string) => {
  activeTab.value = tab
  emit('update:activeTab', tab)
}
</script>
<template>
  <div class="relative">
    <ul class="flex text-sm font-medium text-center" id="tabs">
      <li
        v-for="(tab, index) in tabs"
        class="border-b border-transparent"
        :key="index"
        :class="
          activeTab === tab
            ? 'border-b-primary-600 text-primary-600 dark:border-primary-500 dark:text-primary-500'
            : 'text-gray-900 dark:text-white'
        "
        @click="setActiveTab(tab)"
      >
        <button
          class="pb-3 w-24 transition-all duration-200 hover:text-primary-700 hover:border-primary-700 dark:hover:text-primary-600 dark:hover:border-primary-600"
          type="button"
          :id="`${tab}-tab`"
        >
          {{ tab }}
        </button>
      </li>
    </ul>
    <!-- Below div completes the full width underline of the tabs -->
    <div class="-mt-px border-t border-gray-200"></div>
  </div>
  <!-- tab content -->
  <div>
    <slot></slot>
  </div>
</template>
