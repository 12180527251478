import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 95 95",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M3.95825 77.1156C3.95825 76.0623 4.84436 75.2085 5.93742 75.2085H89.0624C90.1553 75.2085 91.0416 76.0623 91.0416 77.1156V85.1764C91.0416 86.2297 90.1553 87.0835 89.0624 87.0835H5.93742C4.84432 87.0835 3.95825 86.2297 3.95825 85.1764V77.1156Z" }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M40.573 55.019C41.1707 54.6109 41.5626 53.9281 41.5626 53.155V39.7462H51.4584V73.2293H40.573V55.019ZM28.698 55.4168H39.2789C39.3822 55.4168 39.4838 55.4101 39.5834 55.397V73.2293H28.698V55.4168ZM16.823 55.4168H27.7084V73.2293H16.823V55.4168ZM11.8751 39.5835V53.155C11.8751 54.4043 12.8975 55.4168 14.1587 55.4168H15.8334V73.2293H7.91675V39.5835H11.8751ZM52.448 73.2293V39.7462H63.3334V73.2293H52.448ZM64.323 73.2293V39.7462H75.2084V73.2293H64.323ZM76.198 73.2293V39.7462H87.0834V73.2293H76.198Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M41.5624 37.604V26.0117C41.5624 25.916 41.5565 25.8216 41.5446 25.729H89.0624C90.1557 25.729 91.0416 26.5827 91.0416 27.6359V35.6971C91.0416 36.7503 90.1557 37.604 89.0624 37.604H41.5624ZM11.8749 37.604V26.0117C11.8749 25.916 11.8805 25.8216 11.8914 25.729H5.93742C4.84436 25.729 3.95825 26.5827 3.95825 27.6359V35.6971C3.95825 36.7503 4.84436 37.604 5.93742 37.604H11.8749Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", { d: "M38.4414 23.75H14.9959C13.8188 23.75 12.8645 24.6656 12.8645 25.7952V52.7879C12.8645 53.9176 13.2491 54.4271 14.4262 54.4271H39.0798C40.257 54.4271 40.5728 53.7621 40.5728 52.6324V25.7952C40.5728 24.6656 39.6185 23.75 38.4414 23.75Z" }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M51.089 4.31522C51.5814 4.79085 51.5814 5.56202 51.089 6.03765C50.6009 6.50917 50.0436 7.34635 49.599 8.23417C49.3825 8.66638 49.2084 9.07963 49.0912 9.4259C49.0326 9.59876 48.9918 9.74479 48.9665 9.86089C48.9538 9.91836 48.9463 9.96384 48.942 9.998L48.9396 10.0164C48.9522 10.0441 48.9875 10.1131 49.0789 10.231C49.2511 10.4527 49.4969 10.6935 49.8286 11.0139C49.8381 11.0233 49.848 11.0327 49.8579 11.0422C50.152 11.3263 50.5213 11.6831 50.8123 12.0578C51.1028 12.4323 51.4583 13.0002 51.4583 13.7021C51.4583 14.6067 51.0292 15.7321 50.5372 16.6827C50.0253 17.6717 49.3227 18.7056 48.5679 19.4351C48.0755 19.9107 47.2775 19.9107 46.7851 19.4351C46.293 18.9595 46.293 18.1883 46.7851 17.7127C47.2909 17.2242 47.8487 16.4312 48.2825 15.5933C48.7195 14.7487 48.9222 14.0665 48.9364 13.7377C48.9253 13.7128 48.8913 13.6429 48.7951 13.5194C48.6233 13.2977 48.3775 13.0568 48.0458 12.7364C48.0359 12.727 48.0264 12.7176 48.0165 12.7081C47.7224 12.424 47.3531 12.0672 47.0621 11.6925C46.7712 11.318 46.4161 10.7501 46.4161 10.0482C46.4161 9.6066 46.5464 9.10742 46.6952 8.6686C46.8535 8.20033 47.0732 7.68491 47.3297 7.17322C47.8305 6.17322 48.5339 5.06168 49.3065 4.31522C49.7985 3.83959 50.5969 3.83959 51.089 4.31522ZM42.9308 8.53405C43.3765 9.05077 43.3041 9.81877 42.7693 10.2494C42.2674 10.6536 42.1289 11.0624 42.1063 11.3972C42.0861 11.6939 42.1574 11.9644 42.2318 12.1397C43.3655 13.2228 43.73 14.4348 43.5008 15.575C43.2811 16.6664 42.5584 17.4832 41.9804 17.9202C41.4322 18.3348 40.6397 18.2415 40.2107 17.7118C39.7816 17.1821 39.8782 16.4166 40.4264 16.002C40.6797 15.8105 40.9544 15.4644 41.0257 15.1101C41.0756 14.8625 41.0748 14.4112 40.3536 13.775L40.2099 13.6483L40.1125 13.4858C39.5361 12.5239 38.9493 10.1545 41.1551 8.37813C41.6903 7.94751 42.4851 8.01729 42.9308 8.53405Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", { d: "M58.235 8.82447C58.6807 9.34123 58.6083 10.1092 58.0735 10.5399C57.5716 10.944 57.433 11.3529 57.4105 11.6876C57.3903 11.9843 57.4615 12.2548 57.536 12.4302C58.6696 13.5132 59.0342 14.7252 58.805 15.8655C58.5853 16.9568 57.8625 17.7736 57.2846 18.2107C56.7364 18.6253 55.9439 18.532 55.5148 18.0023C55.0858 17.4726 55.1823 16.7071 55.7306 16.2925C55.9839 16.1009 56.2586 15.7549 56.3299 15.4005C56.3797 15.1529 56.3789 14.7016 55.6577 14.0654L55.514 13.9388L55.4167 13.7762C54.8403 12.8143 54.2533 10.4449 56.4597 8.66859C56.9945 8.23792 57.7893 8.30775 58.235 8.82447Z" })
  ]))
}