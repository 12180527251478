<script lang="ts" setup>
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { CheckListItem } from '@ankor-io/common/itinerary/Itinerary'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import { ChecklistEditorData } from './ChecklistSectionEditor'

type Props = {
  id: string
  data: ChecklistEditorData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<ChecklistEditorData>): void
}>()

const deleteItem = (index: number): void => {
  const checklist = ObjectUtil.deepCopy(props.data.checklist)
  checklist.splice(index, 1)
  emit('update:value', { sectionId: props.id, data: { ...props.data, checklist } })
}

const edit = (event: { field: string; value: string; index: number }): void => {
  if (!props.data.checklist) return

  if (event.field === 'title') {
    // Delete item if both title and description are blank
    const description = props.data.checklist[event.index].description
    if (!event.value && !description) return deleteItem(event.index)
    // Update checklist item title
    const checklist: CheckListItem[] = ObjectUtil.deepCopy(props.data.checklist)
    checklist[event.index].title = event.value
    emit('update:value', { sectionId: props.id, data: { ...props.data, checklist } })
  }

  if (event.field === 'description') {
    // Delete item if both title and description are blank
    const title = props.data.checklist[event.index].title
    if (!event.value && !title) return deleteItem(event.index)
    // Update checklist item description
    const checklist: CheckListItem[] = ObjectUtil.deepCopy(props.data.checklist)
    checklist[event.index].description = event.value
    emit('update:value', { sectionId: props.id, data: { ...props.data, checklist } })
  }
}

const addItem = (value: string): void => {
  if (!value) return
  const checklist = ObjectUtil.deepCopy(props.data.checklist)
  checklist.push({ title: value, description: '' })
  emit('update:value', { sectionId: props.id, data: { ...props.data, checklist } })
  editor.value?.clearContent && editor.value.clearContent()
}

const editor: Ref<typeof SingleLineTextEditor | null> = ref(null)
</script>
<template>
  <div v-if="props.data?.checklist" class="flex flex-col w-full max-w-7xl mx-auto p-4 @sm:p-8">
    <!-- Section heading -->
    <h1 class="text-5xl mb-2.5 leading-normal">
      <SingleLineTextEditor
        :value="props.data.heading"
        placeholder="Enter a heading"
        @update:value="emit('update:value', { sectionId: props.id, data: { ...props.data, heading: $event } })"
      />
    </h1>

    <!-- Section subheading -->
    <h3 class="text-xl leading-normal mb-8">
      <SingleLineTextEditor
        :value="props.data.subheading"
        placeholder="Enter a subheading"
        @update:value="emit('update:value', { sectionId: props.id, data: { ...props.data, subheading: $event } })"
      />
    </h3>

    <!-- Before you board -->
    <div
      class="bg-white relative grid gap-x-10 gap-y-6 p-10 rounded-2xl border border-gray-200 shadow-sm shadow-[rgba(0,0,0,0.05)]"
      :class="{
        '@sm:grid-cols-2': props.layout.type === 'two_column_layout',
      }"
    >
      <div v-for="(item, index) in props.data.checklist" :key="index" class="checklist-item flex gap-8 leading-8">
        <span class="text-3xl text-gray-300" :class="index + 1 < 100 ? 'w-4' : 'w-8'">
          {{ index + 1 }}
        </span>

        <div class="flex flex-col basis-full">
          <!-- Title -->
          <SingleLineTextEditor
            class="title w-full font-bold leading-normal"
            :value="item.title"
            placeholder="Enter a title"
            @update:value="edit({ field: 'title', value: $event, index })"
          />
          <!-- Description -->
          <MultiLineTextEditor
            class="w-full text-sm leading-normal"
            placeholder="Enter a description"
            :value="item.description"
            @update:value="edit({ field: 'description', value: $event, index })"
          />
        </div>
      </div>

      <!-- Placeholder item -->
      <div class="flex grow gap-8 placeholder leading-8 font-light">
        <span class="text-3xl text-gray-300" :class="props.data.checklist?.length + 1 < 100 ? 'w-4' : 'w-8'">
          {{ props.data.checklist?.length + 1 }}
        </span>
        <SingleLineTextEditor
          value=""
          ref="editor"
          class="title-placeholder w-full font-bold leading-normal"
          placeholder="Enter a title"
          @update:value="addItem"
        />
      </div>
    </div>
  </div>
</template>
