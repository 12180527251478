import { Component } from 'vue'

import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { CheckListItem } from '@ankor-io/common/itinerary/Itinerary'

import ChecklistSectionnVue from './ChecklistSection.vue'

export interface ChecklistSectionData {
  heading: string
  subheading: string
  checklist: CheckListItem[]
}

export class ChecklistSection extends AbstractSection<ChecklistSectionData> {
  getType(): SectionType {
    return SectionType.ITINERARY_CHECKLIST
  }

  getComponent(): Component {
    return ChecklistSectionnVue
  }
}
