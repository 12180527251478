<script setup lang="ts">
import { Ref, onMounted, ref, watch } from 'vue'

import { SolidChevronDown } from '@ankor-io/icons/solid'

type Props = {
  id: string
  value: string
  options: string[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'select:value', value: string): void
}>()

const isOpen: Ref<boolean> = ref(false)
const selectedValue: Ref<string | null> = ref(null)

const select = (value: string): void => {
  emit('select:value', value)
}

onMounted(() => {
  selectedValue.value = props.value
})

watch(
  () => props.value,
  (newValue: string) => {
    selectedValue.value = newValue
  },
)
</script>

<template>
  <button
    type="button"
    data-dropdown-toggle="dropdown"
    class="w-full h-full border focus:ring-2 focus:outline-none font-medium rounded-lg text-sm p-3 text-center inline-flex items-center justify-between bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
    :id="`${props.id}Button`"
    @blur="isOpen = false"
    @click="isOpen = !isOpen"
  >
    <span>{{ selectedValue }}</span>
    <SolidChevronDown class="w-2.5 fill-gray-500 dark:fill-gray-400" />
  </button>
  <!-- Dropdown menu -->
  <div
    v-show="isOpen"
    class="dropdown absolute w-full mt-2 p-4 z-10 border rounded-lg shadow bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600"
    :id="`${props.id}Options`"
  >
    <ul class="flex flex-col gap-y-3 text-sm text-gray-700 dark:text-gray-200" :aria-labelledby="`${props.id}Button`">
      <li
        v-for="(option, index) of props.options"
        class="transition-colors cursor-pointer hover:text-primary-600 dark:hover:text-primary-500"
        :key="index"
        :class="{ 'text-primary-600 dark:text-primary-500': option === selectedValue }"
        @mousedown="select(option)"
      >
        <span>{{ option }}</span>
      </li>
    </ul>
  </div>
</template>
