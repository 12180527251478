import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.09091",
      d: "M5.19272 5.00018C4.29518 9.45473 6.05435 17.7275 20.2713 15.182M20.2713 15.182L15.9625 10.0911M20.2713 15.182L15.9625 20.2729"
    })
  ]))
}