import { ref } from 'vue'

/**
 * Helper to use modals.
 *
 * It is designed to ease the usage of a modal component. It is not a requirement to use
 * this helper while using the modal components.
 *
 * ```
 * const modalState = useModal()
 *
 * * show/hide the modal
 * v-if="modalState.isVisible()"
 *
 * * pass the model in the modal to use in the slots
 * <Modal :model="modalState.model" ... />
 *
 * * open the modal
 * <button @click="modalState.show({ uri: 'abc123-...-fff000' })" ... />
 *
 * * decide when to close the modal
 * <Modal
 *   @cancel="two.hide()"
 *   @confirm="(model) => [two.hide(), performAction(model.uri)]" ... />
 *
 * 
 * ```
 */
export const modalHelper = () => {
  let model = ref({})
  const _visible = ref(false)

  //
  const show = (modelValue: any) => {
    _visible.value = true
    model.value = modelValue
  }

  const hide = () => {
    _visible.value = false
  }

  const isVisible = () => _visible.value

  return {
    show,
    hide,
    isVisible,
    model,
  }
}
