import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { Component, ComputedOptions, MethodOptions } from 'vue'
import { VesselCrewData } from './types'
import { IUri, URIEntity } from '@ankor-io/common/uri/Uri'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'
import { Vessel } from '@ankor-io/common/vessel/types'
import VesselCrewLiteSectionVue from '@/sections/vessel/crew/VesselCrewLiteSection.vue'
import { vesselCrewLiteSectionMapper } from '@/sections/vessel/mapper'

export class VesselCrewLiteSection extends AbstractSection<VesselCrewData> {
  getType(): SectionType {
    return SectionType.VESSEL_CREW
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselCrewLiteSectionVue
  }

  /**
   * Implement this method to allow this section to work in memory in the Lite Editor
   * @param _source
   */
  dataFrom<A extends { uri: string; [key: string]: any }>(_source: A): VesselCrewData {
    const _uri: IUri = new GenericUriParser().parse(_source.uri)
    if (_uri.getEntity() === URIEntity.VESSEL) {
      return vesselCrewLiteSectionMapper.from(_source as unknown as Vessel)
    }
    throw new Error(`Invalid source not supported for VesselCrewSection: ${_source.uri}`)
  }
}
