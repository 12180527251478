import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M2.40002 7.2C2.40002 6.56348 2.65288 5.95303 3.10297 5.50295C3.55306 5.05286 4.1635 4.8 4.80002 4.8H12C12.6365 4.8 13.247 5.05286 13.6971 5.50295C14.1472 5.95303 14.4 6.56348 14.4 7.2V16.8C14.4 17.4365 14.1472 18.047 13.6971 18.4971C13.247 18.9471 12.6365 19.2 12 19.2H4.80002C4.1635 19.2 3.55306 18.9471 3.10297 18.4971C2.65288 18.047 2.40002 17.4365 2.40002 16.8V7.2ZM17.4636 8.5272C17.2643 8.62679 17.0967 8.7799 16.9795 8.96937C16.8623 9.15885 16.8001 9.37721 16.8 9.6V14.4C16.8001 14.6228 16.8623 14.8412 16.9795 15.0306C17.0967 15.2201 17.2643 15.3732 17.4636 15.4728L19.8636 16.6728C20.0465 16.7642 20.2498 16.8073 20.454 16.7981C20.6583 16.7889 20.8568 16.7277 21.0308 16.6202C21.2047 16.5128 21.3483 16.3626 21.4479 16.1841C21.5476 16.0055 21.5999 15.8045 21.6 15.6V8.4C21.5999 8.19553 21.5476 7.99448 21.4479 7.81593C21.3483 7.63737 21.2047 7.48724 21.0308 7.37977C20.8568 7.2723 20.6583 7.21106 20.454 7.20186C20.2498 7.19266 20.0465 7.23581 19.8636 7.3272L17.4636 8.5272Z" })
  ]))
}