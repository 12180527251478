<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import Button from '@/components/Button.vue'

export type TaxRateProps = {
  taxRateName: string
  taxRateValue: string
}

const props = defineProps<TaxRateProps>()
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'confirm:modal', value: TaxRateProps): void
}>()

const taxRateName: Ref<string> = ref('')
const taxRateValue: Ref<string> = ref('')

onMounted(() => {
  taxRateName.value = props.taxRateName
  taxRateValue.value = (Number(props.taxRateValue) * 100).toString()
})
</script>
<template>
  <div class="h-auto w-auto flex flex-col print:hidden">
    <!-- Modal header -->
    <h3 class="text-lg text-left font-semibold text-gray-900 dark:text-white">Configure Tax Rate:</h3>
    <!-- Modal body -->
    <div class="flex flex-col space-y-4 mt-4">
      <div class="w-80 relative">
        <input
          type="text"
          id="floating_outlined_tax_rate_name"
          class="block w-full h-full rounded-lg border-1 border-gray-300 dark:border-gray-600 appearance-none focus:outline-none focus:ring-0 peer focus:border-primary-600 focus:text-primary-600 dark:focus:border-primary-500 dark:focus:text-primary-500 text-gray-900 dark:text-white bg-white dark:bg-gray-900"
          placeholder=" "
          v-model="taxRateName"
        />
        <label
          for="floating_outlined_tax_rate_name"
          class="absolute text-xs font-medium rounded-t text-gray-500 dark:text-gray-400 cursor-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-primary-600 dark:peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 bg-white dark:bg-gray-900"
        >
          Tax Rate Name
        </label>
      </div>
      <div class="w-80 relative">
        <input
          type="text"
          id="floating_outlined_tax_rate_value"
          class="block w-full h-full rounded-lg border-1 border-gray-300 dark:border-gray-600 appearance-none focus:outline-none focus:ring-0 peer focus:border-primary-600 focus:text-primary-600 dark:focus:border-primary-500 dark:focus:text-primary-500 text-gray-900 dark:text-white bg-white dark:bg-gray-900"
          placeholder=" "
          v-model="taxRateValue"
        />
        <label
          for="floating_outlined_tax_rate_name"
          class="absolute text-xs font-medium rounded-t text-gray-500 dark:text-gray-400 cursor-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-primary-600 dark:peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 bg-white dark:bg-gray-900"
        >
          Tax Rate Value (%)
        </label>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="flex justify-end items-center space-x-2 rounded-b pt-5">
      <Button name="Cancel" :isPrimary="false" @click="emit('close:modal')"></Button>
      <Button
        name="Save"
        isPrimary
        @click="emit('confirm:modal', { taxRateName: taxRateName, taxRateValue: taxRateValue })"
        :disabled="taxRateName === '' || taxRateValue === ''"
      ></Button>
    </div>
  </div>
</template>
