import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { YachtSummaryCardLayoutTemplate, YachtSummaryCardSectionData } from './types/types'

import YachtSummaryCardSectionOptions from '@/sections/yacht-summary-card/YachtSummaryCardConfiguration.vue'
import YachtSummaryCardSectionEditorVue from '@/sections/yacht-summary-card/YachtSummaryCardSectionEditor.vue'

export class YachtSummaryCardSectionEditor extends ProposalAbstractEditableSection<
  YachtSummaryCardSectionData,
  EditableProposal
> {
  async fetchData(): Promise<YachtSummaryCardSectionData> {
    //set the default layout on hydration
    const defaultLayoutOptionsForYachtSummaryCard: YachtSummaryCardLayoutTemplate = {
      type: 'default',
      options: {
        costAndFees: true,
        yachtType: true,
        numCabins: true,
        numPassengers: true,
        maxCrew: true,
        buildYearRefitYear: true,
        length: true,
        numDecks: true,
        cruiseSpeed: true,
        topSpeed: true,
        flag: true,
        descriptionAndNotes: true,
        galleryCarousel: true,
        yachtButton: true,
      },
    }

    this.setLayout(defaultLayoutOptionsForYachtSummaryCard)
    return Promise.resolve({
      name: '',
      uri: '',
      images: [],
      yachtType: '',
      length: '',
      buildYear: '',
      cruiseSpeed: '',
      numDecks: '',
      topSpeed: '',
      refitYear: '',
      flag: '',
      numPassengers: '',
      maxCrew: '',
      numCabins: '',
      yachtButton: {
        title: '',
        link: '',
      },
      description: '',
      vat: 0,
      apa: 0,
      price: '',
      priceSubtext: '',
    })
  }

  getType(): SectionType {
    return SectionType.YACHT_SUMMARY_CARD
  }

  getComponent(): Component {
    return YachtSummaryCardSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return YachtSummaryCardSectionOptions
  }
}
