import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    "aria-hidden": "true",
    version: "1.1",
    viewBox: "0 0 21 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { id: "surface1" }, [
      _createElementVNode("path", {
        d: "M 19.957031 15.96875 C 19.089844 17.320312 17.871094 18.410156 16.425781 19.121094 C 15.433594 19.621094 14.375 19.972656 13.277344 20.164062 C 13.15625 20.1875 13.03125 20.207031 12.910156 20.222656 L 13.015625 22.796875 C 13.136719 22.777344 13.261719 22.753906 13.382812 22.726562 C 14.71875 22.445312 15.988281 21.921875 17.128906 21.175781 C 18.878906 20.027344 20.285156 18.421875 21.191406 16.535156 Z M 19.957031 15.96875 ",
        style: {"fill-opacity":"1"}
      }),
      _createElementVNode("path", {
        d: "M 11.457031 10.628906 L 9.742188 10.628906 L 9.339844 20.328125 C 8.863281 20.300781 8.390625 20.246094 7.921875 20.160156 C 6.824219 19.972656 5.761719 19.621094 4.769531 19.121094 C 3.328125 18.410156 2.105469 17.320312 1.242188 15.96875 L 0 16.535156 C 0.90625 18.421875 2.3125 20.027344 4.070312 21.171875 C 5.207031 21.921875 6.476562 22.449219 7.8125 22.730469 C 8.28125 22.828125 8.753906 22.894531 9.230469 22.929688 L 10.597656 24 L 11.964844 22.929688 L 11.457031 10.636719 Z M 11.457031 10.628906 ",
        style: {"fill-opacity":"1"}
      }),
      _createElementVNode("path", {
        d: "M 16.722656 9.574219 L 17.707031 9.574219 C 18.851562 9.566406 19.933594 9.066406 20.683594 8.207031 L 17.9375 8.207031 C 17.902344 8.265625 17.867188 8.320312 17.824219 8.382812 C 17.675781 8.609375 17.503906 8.824219 17.320312 9.023438 C 17.136719 9.222656 16.933594 9.40625 16.722656 9.574219 Z M 16.722656 9.574219 ",
        style: {"fill-opacity":"1"}
      }),
      _createElementVNode("path", {
        d: "M 3.914062 7.164062 L 5.777344 7.164062 C 5.523438 6.769531 5.386719 6.3125 5.382812 5.839844 C 5.382812 4.53125 6.222656 3.472656 7.53125 3.472656 L 7.652344 3.472656 C 8.296875 3.5 8.917969 3.691406 9.464844 4.027344 L 10.261719 3.28125 C 9.601562 2.574219 8.691406 2.152344 7.726562 2.101562 L 7.535156 2.101562 C 5.441406 2.097656 3.726562 3.757812 3.671875 5.84375 C 3.671875 6.109375 3.699219 6.375 3.753906 6.632812 C 3.792969 6.816406 3.847656 6.992188 3.914062 7.164062 Z M 3.914062 7.164062 ",
        style: {"fill-opacity":"1"}
      }),
      _createElementVNode("path", {
        d: "M 3.5 9.578125 L 13.664062 9.578125 C 15.457031 9.578125 17.015625 8.359375 17.445312 6.625 C 17.46875 6.511719 17.492188 6.398438 17.503906 6.289062 C 17.519531 6.136719 17.53125 5.988281 17.53125 5.835938 C 17.511719 4.5625 16.847656 3.386719 15.761719 2.710938 L 15.75 2.703125 L 15.691406 2.667969 C 15.203125 2.371094 14.660156 2.183594 14.089844 2.117188 C 13.894531 1.71875 13.621094 1.359375 13.292969 1.054688 C 12.558594 0.378906 11.597656 0.00390625 10.597656 0 C 9.617188 0 8.675781 0.378906 7.976562 1.066406 C 8.214844 1.085938 8.449219 1.121094 8.683594 1.175781 C 8.941406 1.242188 9.191406 1.324219 9.433594 1.429688 C 9.773438 1.320312 10.128906 1.273438 10.484375 1.296875 C 11.269531 1.328125 11.992188 1.726562 12.4375 2.371094 C 12.566406 2.566406 12.671875 2.773438 12.746094 2.996094 C 12.894531 3.5 12.921875 4.03125 12.832031 4.550781 C 12.773438 4.898438 12.671875 5.234375 12.527344 5.554688 L 13.324219 6.304688 C 13.761719 5.855469 14.09375 5.3125 14.300781 4.71875 C 14.40625 4.402344 14.460938 4.074219 14.460938 3.742188 L 14.460938 3.609375 C 15.332031 4 15.867188 4.886719 15.816406 5.835938 C 15.816406 5.945312 15.808594 6.050781 15.792969 6.160156 C 15.785156 6.21875 15.777344 6.277344 15.765625 6.335938 C 15.714844 6.566406 15.632812 6.785156 15.523438 6.992188 L 15.507812 7.019531 L 15.507812 7.027344 C 15.101562 7.757812 14.335938 8.207031 13.5 8.207031 L 0.519531 8.207031 C 1.269531 9.070312 2.355469 9.570312 3.5 9.578125 Z M 3.5 9.578125 ",
        style: {"fill-opacity":"1"}
      })
    ])
  ]))
}