import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M18.7648 7.82817C19.099 7.48985 19.2857 7.03225 19.2857 6.55635C19.2857 6.08044 19.099 5.62286 18.7649 5.28453C18.4306 4.94597 17.9756 4.7546 17.5 4.7546C17.0244 4.7546 16.5696 4.94597 16.2353 5.28452C15.9012 5.62285 15.7144 6.08044 15.7144 6.55635C15.7144 7.03225 15.9011 7.48984 16.2352 7.82816M18.7648 7.82817C18.4305 8.16675 17.9756 8.3581 17.5 8.3581C17.0244 8.3581 16.5696 8.16673 16.2352 7.82816M14.6738 3.68589C15.4245 2.92586 16.4412 2.5 17.5 2.5C18.5589 2.5 19.5756 2.92583 20.3263 3.68586C21.0771 4.44612 21.5 5.47854 21.5 6.55635C21.5 7.63416 21.0771 8.66658 20.3263 9.42682L17.5 12.2884L14.6739 9.42683C14.3022 9.05045 14.0068 8.60327 13.8054 8.11073C13.6038 7.61814 13.5 7.0899 13.5 6.55635C13.5 6.0228 13.6038 5.49463 13.8054 5.00204C14.0068 4.5095 14.3021 4.06226 14.6738 3.68589Z" }),
    _createElementVNode("path", { d: "M6.5 9.5C5.44123 9.5 4.42444 9.92583 3.67382 10.6859C3.30206 11.0623 3.00681 11.5094 2.80536 12.002C2.6038 12.4946 2.5 13.0228 2.5 13.5564C2.5 14.0899 2.60383 14.6181 2.80539 15.1107C3.00684 15.6033 3.30209 16.0504 3.67385 16.4268L6.50001 19.2884L9.32625 16.4268C10.0771 15.6666 10.5 14.6342 10.5 13.5564C10.5 12.4785 10.0771 11.4461 9.32625 10.6859C8.57563 9.92583 7.55887 9.5 6.5 9.5Z" }),
    _createElementVNode("path", { d: "M18 12H15C12.5 12 12.5 16 15 16H19C21 16 21 19 19 19H7" })
  ]))
}