import { Component, ComputedOptions, MethodOptions } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { IUri, URIEntity } from '@ankor-io/common/uri/Uri'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'

import { VesselHeroData } from '@/sections/vessel/hero/types'
import { AbstractSection } from '@/services/proposal/Section'

import VesselHeroLiteSectionVue from './VesselHeroLiteSection.vue'
import { vesselHeroLiteSectionMapper } from '@/sections/vessel/mapper'
import { Vessel } from '@ankor-io/common/vessel/types'

export class VesselHeroLiteSection extends AbstractSection<VesselHeroData> {
  getType(): SectionType {
    return SectionType.VESSEL_HERO
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselHeroLiteSectionVue
  }

  /**
   * Implement this method to allow this section to work in memory in the Lite Editor
   * @param _source
   */
  dataFrom<A extends { uri: string; [key: string]: any }>(_source: A): VesselHeroData {
    const _uri: IUri = new GenericUriParser().parse(_source.uri)
    if (_uri.getEntity() === URIEntity.VESSEL) {
      return vesselHeroLiteSectionMapper.from(_source as unknown as Vessel)
    }
    throw new Error(`Invalid source not supported for VesselHeroSection: ${_source.uri}`)
  }
}
