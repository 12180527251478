import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M8.68396 13.342C8.88596 12.938 8.99996 12.482 8.99996 12C8.99996 11.518 8.88596 11.062 8.68396 10.658M8.68396 13.342C8.38166 13.9464 7.88416 14.431 7.27205 14.7174C6.65994 15.0037 5.96909 15.075 5.3114 14.9197C4.65371 14.7644 4.06773 14.3916 3.64837 13.8617C3.22901 13.3317 3.00085 12.6758 3.00085 12C3.00085 11.3242 3.22901 10.6682 3.64837 10.1383C4.06773 9.60841 4.65371 9.2356 5.3114 9.08029C5.96909 8.92499 6.65994 8.99628 7.27205 9.28263C7.88416 9.56898 8.38166 10.0536 8.68396 10.658M8.68396 13.342L15.316 16.658M8.68396 10.658L15.316 7.34199M15.316 16.658C14.96 17.3698 14.9015 18.1939 15.1531 18.9489C15.4048 19.704 15.9461 20.3281 16.658 20.684C17.3698 21.0399 18.1939 21.0985 18.9489 20.8468C19.7039 20.5951 20.328 20.0538 20.684 19.342C21.0399 18.6302 21.0984 17.8061 20.8468 17.0511C20.5951 16.296 20.0538 15.6719 19.342 15.316C18.9895 15.1398 18.6058 15.0347 18.2127 15.0067C17.8196 14.9788 17.4249 15.0286 17.051 15.1532C16.296 15.4049 15.6719 15.9462 15.316 16.658ZM15.316 7.34199C15.4922 7.69439 15.7361 8.00863 16.0338 8.26677C16.3315 8.5249 16.6771 8.72188 17.0509 8.84645C17.4247 8.97102 17.8194 9.02074 18.2124 8.99278C18.6054 8.96482 18.9891 8.85973 19.3415 8.68349C19.6939 8.50726 20.0081 8.26334 20.2662 7.96566C20.5244 7.66798 20.7213 7.32238 20.8459 6.94858C20.9705 6.57477 21.0202 6.1801 20.9922 5.78708C20.9643 5.39406 20.8592 5.01039 20.683 4.65799C20.327 3.94628 19.703 3.40511 18.948 3.15353C18.1931 2.90195 17.3692 2.96057 16.6575 3.31649C15.9457 3.67241 15.4046 4.29648 15.153 5.05141C14.9014 5.80634 14.96 6.63028 15.316 7.34199Z"
    })
  ]))
}