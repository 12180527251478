<script setup lang="ts">
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Tabs from '@/components/Tabs.vue'
import GalleryTab from '@/sections/hero/GalleryTab.vue'
import HeroLayoutTab from '@/sections/hero/HeroLayoutTab.vue'
import { HeroSectionEditorData } from '@/sections/hero/HeroSectionEditor'
import { LayoutTemplate } from '@/sections/types'

type HeroConfigurationProps = {
  id: string
  uri: string
  proposalUri: string
  data: HeroSectionEditorData
  layout: LayoutTemplate
}

const props = defineProps<HeroConfigurationProps>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<HeroSectionEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const tabs: Ref<string[]> = ref(['Layout', 'Gallery'])
const activeTab: Ref<string> = ref('Layout')

/**
 * @param layout receives a newly selected layout
 * Update to diff sync the newly selected layout
 */
const updateLayout = (event: { layout: LayoutTemplate }): void => {
  emit('update:layout', { sectionId: props.id, layout: event.layout })
}

const update = (event: { field: string; value: string; layout?: LayoutTemplate }): void => {
  // deep copy the data
  const data: HeroSectionEditorData = ObjectUtil.deepCopy(props.data)

  if (event.field === 'images') {
    if (event.layout) {
      updateLayout({ layout: event.layout })
    }

    const images = data.images || []
    data.images = [...images, event.value]
  }

  if (event.field === 'heroImages') {
    if (event.layout) {
      updateLayout({ layout: event.layout })
      data.heroImages[event.layout.options?.addCycle] = event.value
    } else {
      const images = data.heroImages || []
      data.heroImages = [...images, event.value]
    }
  }

  emit('update:value', { sectionId: props.id, data: data })
}

const deleteImage = (event: { value: string; index: number; layout?: LayoutTemplate }): void => {
  // deep copy the data
  const data: HeroSectionEditorData = ObjectUtil.deepCopy(props.data)

  if (event.layout) {
    updateLayout({ layout: event.layout })
  }

  data.images.splice(event.index, 1)

  // get all the indexes to remove (convert them into a placeholder position)
  const heroImageToDelete = data.heroImages
    .map((image, index) => (image.includes(event.value) ? index : ''))
    .filter(String)
  heroImageToDelete.forEach((index) => {
    if (typeof index === 'number') {
      data.heroImages[index] = ''
    }
  })

  emit('update:value', { sectionId: props.id, data: data })
}

const updateByReorder = (event: { from: number; to: number; images: string[] }): void => {
  const data: any = ObjectUtil.deepCopy(props.data)
  if (data.images.length === 1 || event.from === event.to) {
    return
  }

  data.images = event.images
  emit('update:value', { sectionId: props.id, data: data })
}
</script>
<template>
  <Tabs :tabs="tabs" @update:activeTab="activeTab = $event">
    <HeroLayoutTab v-show="activeTab === 'Layout'" :layout="layout" @update:layout="updateLayout" />
    <GalleryTab
      v-show="activeTab === 'Gallery'"
      :proposalUri="props.proposalUri"
      :images="props.data.images || []"
      :heroImages="props.data.heroImages || []"
      :layout="layout"
      @update:value="update"
      @delete:value="deleteImage"
      @update:reorder="updateByReorder"
    />
  </Tabs>
</template>
@/sections/hero/HeroSectionEditor
