import { Component } from 'vue'
import { MapSectionData } from '@/sections/map/types/types'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import MapSectionVue from '@/sections/map/MapSection.vue'

export class MapSection extends AbstractSection<MapSectionData> {
  getType(): SectionType {
    return SectionType.ITINERARY_MAP
  }

  getComponent(): Component {
    return MapSectionVue
  }
}
