import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "5.3333",
      "stroke-width": "2",
      d: "M3 3V13.5M3 21V13.5M3 16.5H12M21 16.5H12M21 21V10.125C20.5 10.125 20.625 10.125 19.5 10.125H15H12M3 13.5H8V10.5V10.125H12M12 10.125V16.5"
    })
  ]))
}