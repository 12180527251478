import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M4.80002 3.60001C4.1635 3.60001 3.55306 3.85286 3.10297 4.30295C2.65288 4.75304 2.40002 5.36349 2.40002 6.00001V18C2.40002 18.6365 2.65288 19.247 3.10297 19.6971C3.55306 20.1471 4.1635 20.4 4.80002 20.4H19.2C19.8365 20.4 20.447 20.1471 20.8971 19.6971C21.3472 19.247 21.6 18.6365 21.6 18V6.00001C21.6 5.36349 21.3472 4.75304 20.8971 4.30295C20.447 3.85286 19.8365 3.60001 19.2 3.60001H4.80002ZM19.2 18H4.80002L9.60002 8.40001L13.2 15.6L15.6 10.8L19.2 18Z",
      "clip-rule": "evenodd"
    })
  ]))
}