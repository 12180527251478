import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { AbstractSection } from '@/services/proposal/Section'

import AdditionalNotesVue from '@/sections/additionalNotes/AdditionalNotes.vue'
import { AdditionalNotesEditorData } from '@/sections/additionalNotes/types'
import AdditionalNotesConfigurationVue from '@/sections/additionalNotes/AdditionalNotesConfiguration.vue'

export class AdditionalNotes extends AbstractSection<AdditionalNotesEditorData> {
  public onAttached(): void {
    const proxy = this.getProxy<AdditionalNotesEditorData>()
    this.data.images = proxy.images
    this.data.textNote = proxy.textNote
  }

  getType(): SectionType {
    return SectionType.ADDITIONAL_NOTES
  }

  getComponent(): Component {
    return AdditionalNotesVue
  }

  getConfigurationComponent(): Component | null {
    return AdditionalNotesConfigurationVue
  }
}
