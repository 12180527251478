import { v1 as _v1, v4 as _v4 } from 'uuid'

import v1 from './uuid.v1'

export const UUID = {
  /**
   * Cloudflare worker compatible v1 UUID
   * @returns a v1 UUID
   */
  v1: (): string => v1() as string,

  parseV1: (uuid: string): Date => getDate(uuid),

  /**
   * Parse a v1 UUID to epoch seconds
   * @param uuid
   */
  parseV1EpochSeconds: (uuid: string): number => getEpochSeconds(uuid),

  /**
   * A node:crypto based v1 UUID
   * @returns a v1 UUID
   */
  timeBased: (): string => _v1(),

  /**
   * A node:crypto based v4 UUID
   * @returns a v4 UUID
   */
  randomUUID: (): string => _v4(),
}

const getTime = (uuid_str: string) => {
  const uuidComponents = uuid_str.split('-')
  const timeString = [uuidComponents[2].substring(1), uuidComponents[1], uuidComponents[0]].join('')
  return parseInt(timeString, 16)
}

const getDate = (uuid_str: string) => {
  const time = getTime(uuid_str) - 122192928000000000
  const epoch = Math.floor(time / 10000)
  return new Date(epoch)
}

/**
 * Get the epoch seconds from a UUID
 * @param uuid_str
 */
const getEpochSeconds = (uuid_str: string) => {
  const time = getTime(uuid_str) - 122192928000000000
  return Math.floor(time / 10000000)
}
