import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.09091",
      d: "M4.36363 12.4492L5.15909 10.2032M5.15909 10.2032L7.22727 4.36364L9.29545 10.2032M5.15909 10.2032H9.29545M10.3636 13.0909L15.2727 8.72728L19.6364 13.3476L12.9545 19.6364M10.3636 13.0909L8.18181 15.2727M10.3636 13.0909L9.29545 10.2032M12.9545 19.6364H8.18181L6 17.4545L8.18181 15.2727M12.9545 19.6364L8.18181 15.2727"
    })
  ]))
}