<script setup lang="ts">
import { computed } from 'vue'

import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { isValidURL } from '@ankor-io/common/url/isValidURL'

import { LayoutTemplate } from '@/sections/types'
import { WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE } from '@/utils/growthbook/constants'

import { ButtonData, ButtonLayout } from './types'

type ButtonProps = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's data
   */
  data: ButtonData
  /**
   * The section's layout
   */
  layout: ButtonLayout
  /**
   * The lifecycle hook
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<ButtonProps>()
const emit = defineEmits<{
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

props.lifecycle({
  onHydrated: () => {
    if (!props.layout.type) {
      updateLayout({ type: 'rounded', options: 'left' })
    }
  },
} as unknown as EditableLifecycleHooks)

const url = computed(() => {
  if (!isValidURL(props.data.link)) {
    return 'http://' + props.data.link
  } else {
    return props.data.link
  }
})

const updateLayout = (selectedLayout: LayoutTemplate): void => {
  const layout: LayoutTemplate = ObjectUtil.deepCopy(props.layout)

  layout.type = selectedLayout.type || props.layout.type
  layout.options = selectedLayout.options || props.layout.options

  emit('update:layout', { sectionId: props.id, layout: layout })
}
</script>
<template>
  <!--Right Aligned Button-->
  <div
    v-if="props.layout.options === 'right'"
    class="buttonSection w-full text-right p-4"
    :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-8' : 'sm:p-8'"
  >
    <!--Right Rounded Button-->
    <a
      v-if="props.layout.type === 'rounded'"
      class="right-rounded-button !border-none rounded-lg py-3 px-5"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
    <!--Right Transparent Button-->
    <a
      v-else-if="props.layout.type === 'transparent'"
      class="right-transparent-button !bg-transparent rounded-lg py-3 px-5"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
    <!--Right Link Button-->
    <a
      v-else-if="props.layout.type === 'link'"
      class="right-link-button !bg-transparent !border-none"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
  </div>
  <!--Center Aligned Button-->
  <div
    v-else-if="props.layout.options === 'center'"
    class="buttonSection w-full text-center p-4"
    :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-8' : 'sm:p-8'"
  >
    <!--Center Rounded Button-->
    <a
      v-if="props.layout.type === 'rounded'"
      class="center-rounded-button !border-none rounded-lg py-3 px-5"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
    <!--Center Transparent Button-->
    <a
      v-else-if="props.layout.type === 'transparent'"
      class="center-transparent-button !bg-transparent rounded-lg py-3 px-5"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
    <!--Center Link Button-->
    <a
      v-else-if="props.layout.type === 'link'"
      class="center-link-button !bg-transparent !border-none"
      :class="{ 'cursor-not-allowed': !props.data.link }"
      target="_blank"
      :disabled="!props.data.link"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
  </div>
  <!--Left Aligned Button-->
  <div
    v-else
    class="buttonSection w-full text-left p-4"
    :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-8' : 'sm:p-8'"
  >
    <!--Left Transparent Button-->
    <a
      v-if="props.layout.type === 'transparent'"
      class="left-transparent-button !bg-transparent rounded-lg py-3 px-5"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
    <!--Left Link Button-->
    <a
      v-else-if="props.layout.type === 'link'"
      class="left-link-button !bg-transparent !border-none"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
    <!--Left Rounded Button-->
    <a
      v-else
      class="left-rounded-button !border-none rounded-lg py-3 px-5"
      target="_blank"
      :class="{ 'pointer-events-none': !props.data.link }"
      :href="url"
    >
      {{ props.data.title || 'Enter title' }}
    </a>
  </div>
</template>
