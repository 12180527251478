import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    "aria-hidden": "true",
    viewBox: "0 0 11 10"
  }, [
    _createElementVNode("path", {
      fill: "#057A55",
      "fill-rule": "evenodd",
      d: "M10.1219 0.127888C10.3573 0.33708 10.4027 0.729242 10.2234 1.00381L4.50912 9.75378C4.41543 9.89724 4.27319 9.98649 4.11895 9.99859C3.96471 10.0107 3.8135 9.94447 3.70419 9.81694L0.489914 6.06695C0.280706 5.82288 0.280706 5.42715 0.489914 5.18307C0.699123 4.939 1.03832 4.939 1.24753 5.18307L4.02836 8.42738L9.37114 0.246255C9.55045 -0.0283092 9.88659 -0.0813041 10.1219 0.127888Z",
      "clip-rule": "evenodd"
    })
  ]))
}