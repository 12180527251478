import { Component, ComputedOptions, MethodOptions } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { IUri, URIEntity } from '@ankor-io/common/uri/Uri'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'

import { VesselOverviewData } from '@/sections/vessel/overview/types'
import { AbstractSection } from '@/services/proposal/Section'

import VesselOverviewLiteSectionVue from './VesselOverviewLiteSection.vue'
import { vesselOverviewLiteSectionMapper } from '@/sections/vessel/mapper'
import { Vessel } from '@ankor-io/common/vessel/types'

export class VesselOverviewLiteSection extends AbstractSection<VesselOverviewData> {
  getType(): SectionType {
    return SectionType.VESSEL_OVERVIEW
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselOverviewLiteSectionVue
  }

  /**
   * Implement this method to allow this section to work in memory in the Lite Editor
   * @param _source
   */
  dataFrom<A extends { uri: string; [key: string]: any }>(_source: A): VesselOverviewData {
    const _uri: IUri = new GenericUriParser().parse(_source.uri)
    if (_uri.getEntity() === URIEntity.VESSEL) {
      return vesselOverviewLiteSectionMapper.from(_source as unknown as Vessel)
    }
    throw new Error(`Invalid source not supported for VesselOverviewSection: ${_source.uri}`)
  }
}
