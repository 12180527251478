import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M16.2864 5.67033C16.2864 7.85519 14.3673 9.62638 12 9.62638C9.63272 9.62638 7.71365 7.85519 7.71365 5.67033C7.71365 3.48547 9.63272 1.71429 12 1.71429C14.3673 1.71429 16.2864 3.48547 16.2864 5.67033Z"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M3.42859 20.5643C3.50895 16.2587 7.31573 12.7912 12 12.7912C16.6844 12.7912 20.4913 16.2589 20.5714 20.5646C17.9621 21.6696 15.0592 22.2857 12.0004 22.2857C8.94126 22.2857 6.03808 21.6695 3.42859 20.5643Z"
    })
  ]))
}