import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { Component } from 'vue'
import ButtonVue from '@/sections/button/Button.vue'
import { ButtonData } from './types'

/**
 * Button section
 */
export class Button extends AbstractSection<ButtonData> {
  getType(): SectionType {
    return SectionType.BUTTON
  }

  getComponent(): Component {
    return ButtonVue
  }
}
