import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M6.05997 4.86C7.63536 3.28461 9.77204 2.39957 12 2.39957C14.2279 2.39957 16.3646 3.28461 17.94 4.86C19.5154 6.43538 20.4004 8.57206 20.4004 10.8C20.4004 13.0279 19.5154 15.1646 17.94 16.74L12 22.68L6.05997 16.74C5.27987 15.96 4.66105 15.0339 4.23886 14.0147C3.81667 12.9955 3.59937 11.9032 3.59937 10.8C3.59937 9.69682 3.81667 8.60445 4.23886 7.58526C4.66105 6.56607 5.27987 5.64002 6.05997 4.86V4.86ZM12 13.2C12.6365 13.2 13.2469 12.9471 13.697 12.4971C14.1471 12.047 14.4 11.4365 14.4 10.8C14.4 10.1635 14.1471 9.55303 13.697 9.10294C13.2469 8.65285 12.6365 8.4 12 8.4C11.3635 8.4 10.753 8.65285 10.3029 9.10294C9.85283 9.55303 9.59997 10.1635 9.59997 10.8C9.59997 11.4365 9.85283 12.047 10.3029 12.4971C10.753 12.9471 11.3635 13.2 12 13.2Z",
      "clip-rule": "evenodd"
    })
  ]))
}