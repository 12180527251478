<script lang="ts" setup>
import { computed, useAttrs } from 'vue'

import Badge from '@ankor-io/blocks/components/Badge.vue'

defineOptions({ inheritAttrs: false })

type Props = {
  tags: string[]
  only?: string[]
  exclude?: string[]
  labels?: Record<string, string>
}

const props = withDefaults(defineProps<Props>(), {
  tags: () => [],
  only: () => [],
  exclude: () => ['custom', 'website_api', 'test-ra'],
  labels: () => ({}),
})

const styleForTag = (tag: string) => {
  switch (tag.toLowerCase()) {
    case 'ankor':
      return 'blue'
    case 'claimed':
    case 'claimed_ca':
    case 'ca verified':
      return 'green'
    case 'design studio':
      return 'purple'
  }
  //
  return undefined
}

const label = (tag: string) => {
  const _tag = tag.toLowerCase()
  const staticLabels = {
    'ankor': 'Ankor',
    'ca verified': 'CA Managed',
    'claimed': 'CA Managed',
    'claimed_ca': 'CA Managed',
    'design studio': 'Design Studio',
  }

  // merge the static labels with the passed in labels; return the label or the tag.
  return {
    ...staticLabels,
    ...props.labels,
  }[_tag] ?? tag
}

// filter out the excluded tags.
const _tags = computed(() => {
  let __tags = props.tags
    // remove excluded
    .filter((tag) => !props.exclude.includes(tag))
    // keep the "only"
    .filter((tag) => props.only.length == 0 || props.only.includes(tag))

  // remove "duplicates"
  if (__tags.includes('claimed') || __tags.includes('claimed_ca')) {
    __tags = __tags.filter((tag) => tag !== 'claimed' && tag !== 'claimed_ca')
    __tags.push('CA Verified')
  }

  return __tags
})
</script>
<template>
  <ul :class="useAttrs().class ?? 'flex flex-wrap gap-2'">
    <template v-for="tag in _tags" :key="tag">
      <li>
        <Badge :tone="styleForTag(tag)">{{ label(tag) }}</Badge>
      </li>
    </template>
  </ul>
</template>
