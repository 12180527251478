import { GenericURI, URIContext, UserURI } from './Uri'

/**
 * Defines the uri builder interface as the common contract for all uri builders.
 *
 * @param T the type of the arguments to build the URI from
 */
export interface URIBuilder<T> {
  /**
   * Build a URI from the given arguments
   *
   * @param args the arguments to build the URI from
   */
  build(args: T, opts: { assumeValid: boolean }): string
}

/**
 * A Generic URI Builder that builds a uri from a string array.
 */
export class GenericUriBuilder implements URIBuilder<string[]> {
  build(parts: string[], opts = { assumeValid: false }): string {
    return new GenericURI(parts, opts).toString()
  }
}

/**
 * A User URI Builder that builds a uri from a user id.
 */
export class UserUriBuilder implements URIBuilder<string> {
  //@ts-ignore
  build(userId: string, opts?: { assumeValid: boolean }): string {
    return new UserURI([URIContext.U, userId]).toString()
  }
}

export class MediaUriBuilder implements URIBuilder<string[]> {
  /**
   * Build a media uri
   * For example:
   *    media::a::itinerary::f19f3aa0-2a3d-11ee-bb81-2b0206f821f2::assets::original::8878c4a0-24e9-11ee-96bd-07f1a2380a31
   * @param uri the uri of where the media file is stored for example: a::itinerary::{id} or u::kp::{userId}::proposal::{proposalId}
   * @param relativePath Optional - the path of the media relative to the uri its stored against. For example /assets/original/{filename}
   */
  //@ts-ignore
  build(uri: string, relativePath?): string {
    if (relativePath) {
      return new GenericURI([URIContext.MEDIA, URIContext.IMAGE, uri, relativePath]).toString().replace(/\//g, '::')
    }

    return new GenericURI([URIContext.MEDIA, URIContext.IMAGE, uri]).toString()
  }
}
