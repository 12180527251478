import { capitalCase } from 'change-case'
import gql from 'graphql-tag'
import { Component } from 'vue'

import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionInit } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import {
  Amenity,
  Blueprint,
  CabinLayout,
  KVPair,
  LineItem,
  Pricing,
  Toy,
  Variant,
  Vessel,
} from '@ankor-io/common/vessel/types'

import VesselShowcaseConfiguration from '@/sections/vessel-showcase/VesselShowcaseConfiguration.vue'
import VesselShowcaseSectionEditorVue from '@/sections/vessel-showcase/VesselShowcaseSectionEditor.vue'
import {
  KeyValueFeatured,
  VesselPricing,
  VesselShowcaseLayoutTemplate,
  VesselShowcaseSectionData,
} from '@/sections/vessel-showcase/types/types'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

const QUERY = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      yachtType
      uri
      description
      additionalInfo {
        label
        value
      }
      blueprint {
        name
        hero
        images
        length
        sleeps
        cabins
        maxCrew
        builtYear
        refitYear
        make
        model
        bathrooms
        flag
        registryPort
        mainsailTypes
        genoaTypes
        stabilizers
        decks
        architect
        interiorDesigner
        beam
        draft
        hullType
        hullConstruction
        superStructure
        tonnage
        engines
        fuelCapacity
        fuelPerHour
        topSpeed
        cruiseSpeed
        mmsi
        cabinLayout {
          label
          value
        }
        amenities {
          label
          quantity
        }
        entertainment
        toys {
          label
          quantity
        }
        tenders
      }
      crew {
        name
        role
        avatar
        bio
        email
        phone
      }
      variants {
        label
        active
        description
        pricing {
          currency
          note
          unit
          inputAmountTaxed
          lineItems {
            item
            conditions
            quantity
            unitPrice
            taxRate {
              label
              value
            }
            discount {
              type
              value
            }
            amount
          }
          subTotal
          totalTax
          total
        }
        maxPassengers
        amenities {
          label
          quantity
        }
        entertainment
        toys {
          label
          quantity
        }
        tenders
        petsAllowed
      }
      tags
    }
  }
`

export class VesselShowcaseSectionEditor extends ProposalAbstractEditableSection<
  VesselShowcaseSectionData,
  EditableProposal
> {
  private vesselUri: string | null

  constructor(init: SectionInit) {
    super(init)
    this.vesselUri = null
  }

  private getLength(length: number): string {
    const roundedLength: number = Math.round(length * 10) / 10
    return `${roundedLength}m (${Number(length * 3.281).toFixed(0)}ft)`
  }

  private getValue(
    key: string,
    value: number | Amenity[] | string[] | string | boolean | Toy[] | CabinLayout[] | Pricing,
  ) {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No'
    }
    if (value && typeof value === 'number' && key === 'length') {
      return this.getLength(value)
    }
    return value?.toString()
  }

  /**
   * Transforms a key from Backend name to frontend name for the purpose of UI display
   * @param key BE key name
   * @returns FE key name
   */
  private renameKeyForDisplay(key: string): string {
    switch (key) {
      case 'maxCrew':
        return 'crew'
      case 'make':
        return 'builder'
      default:
        return key
    }
  }

  /**
   * Get the key value pairs from the blueprint or variant and update labels if needed
   * @param obj Object to be iterated over
   * @param keysToBeFeatured keys to be featured
   * @param keysToExclude keys to be excluded
   * @returns Array of KeyValueFeatured
   */
  private getKeyValues(
    obj: Blueprint | Variant,
    keysToBeFeatured: string[],
    keysToExclude: string[],
  ): KeyValueFeatured[] {
    return Object.entries(obj)
      .map(([key, value]) => ({
        key: capitalCase(this.renameKeyForDisplay(key)),
        value: this.getValue(key, value),
        featured: keysToBeFeatured.includes(key),
      }))
      .filter((obj: KeyValueFeatured) => !keysToExclude.includes(obj.key) && obj.value)
  }

  public setVesselUri(uri: string | null): void {
    this.vesselUri = uri
  }

  public getVesselUri(): string | null {
    return this.vesselUri
  }

  async fetchData(): Promise<VesselShowcaseSectionData> {
    const client = await this.client()
    //set the default layout
    const defaultLayoutOptions: VesselShowcaseLayoutTemplate = {
      type: 'default',
      options: {
        size: 'medium',
        imageHeight: 'medium',
        alignment: 'center',
        highlightsBackground: 'rgb(255,255,255)',
        showHighlightsBorder: true,
        highlightsPlacement: 'top',
        featuresBackground: 'rgb(255,255,255)',
        showDescription: true,
        showFeatures: true,
        showPricing: true,
        showButton: true,
        enabledPricingColumns: ['item', 'conditions', 'quantity', 'unitPrice', 'discount', 'taxRate', 'amount'],
        pricingHeaderBackground: 'rgb(243, 244, 246)',
        pricingBodyBackground: 'rgb(255,255,255)',
        pricingLabel: 'Pricing',
      },
    }

    this.setLayout(defaultLayoutOptions)
    console.debug('VesselShowcaseSectionEditor fetchData: ', this.getVesselUri())

    if (this.getVesselUri() === null || typeof this.getVesselUri() === 'undefined') {
      return Promise.resolve({
        uri: this.getVesselUri(),
        type: '',
        name: '',
        description: '',
        images: [],
        offering: '',
        features: [],
        pricing: {} as VesselPricing,
        shouldCopyAssets: false,
        shouldRehydrate: false,
        buttonLink: '',
        buttonText: '',
      })
    }

    const { data, errors } = await client.query({
      query: QUERY,
      variables: { URI: this.getVesselUri() },
      errorPolicy: 'all',
    })

    console.debug('VesselShowcaseSectionEditor errors: ', errors)

    const { uri, additionalInfo, blueprint, variants, description, yachtType = [] }: Vessel = data.vessel
    const variant = variants[0]

    const keyValueFeaturedFromBluePrint: KeyValueFeatured[] = blueprint
      ? this.getKeyValues(
          blueprint,
          ['length', 'cabins', 'sleeps', 'maxCrew', 'refitYear', 'builtYear', 'make'],
          [
            'Name',
            'Mmsi',
            'Images',
            'Hero',
            'Cabin Layout',
            'Typename',
            'Amenities',
            'Toys',
            'Entertainment',
            'Beam',
            'Draft',
            'Hull Type',
            'Hull Construction',
            'Super Structure',
            'Tonnage',
            'Engines',
            'Fuel Capacity',
            'Fuel Per Hour',
            'Top Speed',
            'Cruise Speed',
            'Tenders',
            'Architect',
            'Interior Designer',
            'Bathrooms',
            'Flag',
            'Decks',
            'Stabilizers',
          ],
        )
      : []

    const keyValueFeaturedFromVariant: KeyValueFeatured[] = variant
      ? this.getKeyValues(
          variant,
          [],
          [
            'Label',
            'Active',
            'Description',
            'Pricing',
            'Availability',
            'Typename',
            'Amenities',
            'Toys',
            'Entertainment',
            'Tenders',
            'Pets Allowed',
            'Max Passengers',
          ],
        )
      : []

    const pricing: any = variant?.pricing ?? {}
    const pricingLineItems = (pricing.lineItems ?? []).map((lineItem: LineItem) => ({
      ...lineItem,
      displayQuantityAsPercent: Number(lineItem.quantity) < 1,
    }))

    const keyValueAdditionalInfo =
      additionalInfo
        ?.filter((kv: KVPair) => kv.label && kv.label !== 'class' && kv.label !== 'formerName')
        .map((kv: KVPair) => ({
          key: capitalCase(kv.label),
          value: kv.value,
          featured: false,
        })) || []

    const vesselData: VesselShowcaseSectionData = {
      uri,
      type: yachtType?.join(', ').toUpperCase(),
      name: blueprint?.name ?? '',
      description: description ?? '',
      images: replacePathToMediaUris(uri, ...(blueprint?.images || [])),
      offering: variant?.label ?? '',
      features: [...keyValueFeaturedFromBluePrint, ...keyValueFeaturedFromVariant, ...keyValueAdditionalInfo],
      pricing: { ...pricing, lineItems: pricingLineItems },
      shouldCopyAssets: !!blueprint?.images?.length,
      shouldRehydrate: false,
      buttonLink: '',
      buttonText: 'Yacht Brochure',
    }

    return Promise.resolve(vesselData)
  }

  getType(): SectionType {
    return SectionType.VESSEL_SHOWCASE
  }

  getComponent(): Component {
    return VesselShowcaseSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return VesselShowcaseConfiguration
  }

  async onAttached(): Promise<void> {
    this.setVesselUri(this.data.uri)
  }
}
