import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import SummarySectionVue from '@/sections/summary/SummarySection.vue'

export type SummarySectionData = {
  summaryTitle: string
}

export class SummarySection extends AbstractSection<SummarySectionData> {
  // TODO: Get data - eg HelloWorld.ts

  getType(): SectionType {
    return SectionType.ITINERARY_SUMMARY
  }

  getComponent(): Component {
    return SummarySectionVue
  }
}
