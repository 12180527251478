import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { Events } from '@ankor-io/common/proposal/Events'
import {
  EditableSection,
  EditableSectionState,
  JsonSection,
  Section,
  SectionTemplate,
} from '@ankor-io/common/proposal/Section'
import {
  EditableSlideState,
  GenericSlide,
  JsonSlide,
  ProposalEditableSlideInterface,
  SlideInit,
  SlideTemplate,
} from '@ankor-io/common/proposal/Slide'
import { SlideType } from '@ankor-io/common/proposal/SlideType'
import { IUri, URI_SEPARATOR } from '@ankor-io/common/uri/Uri'
import { GenericUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'

import { useStowageService } from '../stowage/stowage.service'

/**
 * Represents the abstract generic implementation of a Slide
 */
export abstract class AbstractGenericSlide<T extends Section<any> | EditableSection<any>, D>
  implements GenericSlide<T, D>
{
  private id: string
  private uri: string
  private proposalUri: string
  protected sections: T[]
  protected state: EditableSlideState

  /**
   * Create a new Slide with an id as a new value and an empty section array
   *
   * @param id the id to set
   */
  constructor(init: SlideInit) {
    this.id = new String(init.id).toString()
    this.uri = init.uri
    this.sections = []
    this.proposalUri = init.proposalUri
    this.state = init.state || EditableSlideState.NEEDS_HYDRATION
  }

  getState(): EditableSlideState {
    return this.state
  }

  getProposalUri(): string {
    return this.proposalUri
  }

  getSubdocumentUri(): string {
    const uri: IUri = new GenericUriParser().parse(this.uri)
    return new GenericUriBuilder().build([...this.proposalUri.split(URI_SEPARATOR), uri.getEntity(), uri.getEntityId()])
  }

  getId(): string {
    return this.id
  }

  getUri(): string {
    return this.uri
  }

  abstract getType(): SlideType

  addSection<S extends GenericSlide<T, D>>(section: T, index?: number): S {
    section.onBeforeAttach()
    // Index can be 0 which is possible when adding a section below the header
    if (index !== undefined) {
      this.sections.splice(index, 0, section)
    } else {
      this.sections.push(section)
    }

    section.onAttached()
    return this as unknown as S
  }

  pushSection(section: T): void {
    this.sections.push(section)
  }

  deleteSection(section: T): T {
    this.sections = this.sections.filter((current: T) => current !== section)
    return section
  }

  getSections(): T[] {
    return this.sections
  }

  asTemplate(): SlideTemplate {
    const sectionsTemplates: SectionTemplate[] = this.sections.map((section: T) => {
      return section.asTemplate()
    })

    return {
      type: JSON.parse(JSON.stringify(this.getType())),
      sections: sectionsTemplates,
    }
  }

  abstract toJson(): D
}

/**
 * The abstract slide implementation
 */
export abstract class AbstractSlide extends AbstractGenericSlide<Section<any>, JsonSlide> {
  /**
   * Common implementation to serialize an abstract slide
   *
   * @returns a slide template
   */
  toJson(): JsonSlide {
    const sectionJsons: JsonSection<any>[] = this.sections.map((section: Section<any>) => {
      return section.toJson()
    })

    return {
      uri: this.getUri(),
      type: JSON.parse(JSON.stringify(this.getType())),
      sections: sectionJsons,
      state: this.getState(),
    }
  }
}

/**
 * The abstract editable slide implementation
 */
export abstract class AbstractEditableSlide<T> extends AbstractGenericSlide<EditableSection<any>, T> {}

export abstract class AbstractProposalEditableSlide
  extends AbstractGenericSlide<EditableSection<any>, JsonSlide>
  implements ProposalEditableSlideInterface
{
  private hydrating: boolean

  constructor(init: SlideInit) {
    super(init)
    this.hydrating = false
  }

  isHydrating(): boolean {
    return this.hydrating
  }

  hydrate(): Promise<void> {
    const dispatcher = useAppDispatcher().get()
    dispatcher.dispatchEvent(Events.PAUSE, this.getId())
    this.hydrating = true
    // get the service
    const stowageService = useStowageService()
    // prepare the promises
    const getDocumentPromise = stowageService.getDocument<{ uri: string; [key: string]: any }>(this.getUri())
    const setDocumentPromise = (document: { uri: string }) =>
      stowageService.setDocument(this.getSubdocumentUri(), document)

    return getDocumentPromise
      .then((document) => {
        if (!document) {
          // do not throw so existing proposals don't break, 
          // figure out if and how to pass callback to the slide constructor
          return
          // throw new Error('Document not found')
        }
        // let's change the uri to be the subdoco uri
        document.uri = this.getSubdocumentUri()
        return setDocumentPromise(document).then(() => {
          this.setState(EditableSlideState.HYDRATED)
          dispatcher.dispatchEvent(Events.UNPAUSE, this.getId())
        })
      })
      .finally(() => {
        this.hydrating = false
      })
  }

  deHydrate(): Promise<void> {
    const stowageService = useStowageService()
    return stowageService.deleteDocument(this.getSubdocumentUri()).then(() => {})
  }

  setState(state: EditableSlideState): void {
    this.state = state
  }

  markDelete(section: EditableSection<any>): void {
    section.setState(EditableSectionState.NEEDS_TO_GO)
    this.deleteSection(section)
  }

  /**
   * Common implementation to serialize an abstract slide
   *
   * @returns a slide template
   */
  toJson(): JsonSlide {
    const sectionJsons: JsonSection<any>[] = this.sections.map((section: EditableSection<any>) => {
      return section.toJson()
    })

    return {
      uri: this.getUri(),
      type: JSON.parse(JSON.stringify(this.getType())),
      sections: sectionJsons,
      state: this.getState(),
    }
  }
}
