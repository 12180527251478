<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { useAttrs } from 'vue'

import { OutlineXMark } from '@ankor-io/icons/outline'

defineEmits<{
  (e: 'close'): void
}>()

// Turn off the inheritAttrs as we apply them to the class using twMerge
defineOptions({
  inheritAttrs: false,
})

const withClassAttrs = (classes: any) => {
  const inAttrs = useAttrs().class as string
  return twMerge(classes, inAttrs)
}
</script>
<template>
  <div
    :class="withClassAttrs(`h-11 flex items-center gap-x-6 bg-states-info-fill px-6 py-2.5 sm:px-3.5 sm:before:flex-1`)"
  >
    <slot></slot>

    <div class="flex flex-1 justify-end">
      <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
        <span class="sr-only">Dismiss</span>

        <OutlineXMark class="size-3 stroke-white" aria-hidden="true" @click="$emit('close')" />
      </button>
    </div>
  </div>
</template>
