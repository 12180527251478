import { Function } from '@ankor-io/common/lang/functional.types'
import { EditableSection, Section, SectionInit } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { ProposalEditableSlide, Slide, SlideInit } from '@ankor-io/common/proposal/Slide'
import { SlideType } from '@ankor-io/common/proposal/SlideType'

import { AdditionalNotes } from '@/sections/additionalNotes/AdditionalNotes'
import { AdditionalNotesEditor } from '@/sections/additionalNotes/AdditionalNotesEditor'
import { AllDayCardsSection } from '@/sections/allDayCards/AllDayCardsSection'
import { AllDayCardsSectionEditor } from '@/sections/allDayCards/AllDayCardsSectionEditor'
import { Button } from '@/sections/button/Button'
import { ButtonEditor } from '@/sections/button/ButtonEditor'
import { ChecklistSection } from '@/sections/checklist/ChecklistSection'
import { ChecklistSectionEditor } from '@/sections/checklist/ChecklistSectionEditor'
import { DisclaimersSection } from '@/sections/disclaimers/DisclaimersSection'
import { DisclaimersSectionEditor } from '@/sections/disclaimers/DisclaimersSectionEditor'
import { EmbedSection } from '@/sections/embed/EmbedSection'
import { EmbedSectionEditor } from '@/sections/embed/EmbedSectionEditor'
import { HeadingSection } from '@/sections/heading/HeadingSection'
import { HeadingSectionEditor } from '@/sections/heading/HeadingSectionEditor'
import { HeroSection } from '@/sections/hero/HeroSection'
import { HeroSectionEditor } from '@/sections/hero/HeroSectionEditor'
import { ImageSection } from '@/sections/image/ImageSection'
import { ImageSectionEditor } from '@/sections/image/ImageSectionEditor'
import { MapSection } from '@/sections/map/MapSection'
import { MapSectionEditor } from '@/sections/map/MapSectionEditor'
import { NavBar } from '@/sections/navbar/NavBar'
import { NavBarEditor } from '@/sections/navbar/NavBarEditor'
import { NavBarLiteSection } from '@/sections/navbar/NavBarLiteSection'
import { OverviewSection } from '@/sections/overview/OverviewSection'
import { OverviewSectionEditor } from '@/sections/overview/OverviewSectionEditor'
import { ProfileCompanyLiteSection } from '@/sections/profile-company/ProfileCompanyLiteSection'
import { ProfileCompanySectionEditor } from '@/sections/profile-company/ProfileCompanySectionEditor'
import { SpacerSection } from '@/sections/spacer/Spacer'
import { SpacerSectionEditor } from '@/sections/spacer/SpacerEditor'
import { SummarySection } from '@/sections/summary/SummarySection'
import { SummarySectionEditor } from '@/sections/summary/SummarySectionEditor'
import { VesselShowcaseSection } from '@/sections/vessel-showcase/VesselShowcaseSection'
import { VesselShowcaseSectionEditor } from '@/sections/vessel-showcase/VesselShowcaseSectionEditor'
import { VesselCrewLiteSection } from '@/sections/vessel/crew/VesselCrewLiteSection'
import { VesselCrewSection } from '@/sections/vessel/crew/VesselCrewSection'
import { VesselCrewSectionEditor } from '@/sections/vessel/crew/VesselCrewSectionEditor'
import { VesselHeroLiteSection } from '@/sections/vessel/hero/VesselHeroLiteSection'
import { VesselHeroSection } from '@/sections/vessel/hero/VesselHeroSection'
import { VesselHeroSectionEditor } from '@/sections/vessel/hero/VesselHeroSectionEditor'
import { VesselOverviewLiteSection } from '@/sections/vessel/overview/VesselOverviewLiteSection'
import { VesselOverviewSection } from '@/sections/vessel/overview/VesselOverviewSection'
import { VesselOverviewSectionEditor } from '@/sections/vessel/overview/VesselOverviewSectionEditor'
import { VesselSpecificationsLiteSection } from '@/sections/vessel/specifications/VesselSpecificationsLiteSection'
import { VesselSpecificationsSection } from '@/sections/vessel/specifications/VesselSpecificationsSection'
import { VesselSpecificationsSectionEditor } from '@/sections/vessel/specifications/VesselSpecificationsSectionEditor'
import { ToysAmenitiesLiteSection } from '@/sections/vessel/toys-amenities/ToysAmenitiesLiteSection'
import { ToysAmenitiesSection } from '@/sections/vessel/toys-amenities/ToysAmenitiesSection'
import { ToysAmenitiesSectionEditor } from '@/sections/vessel/toys-amenities/ToysAmenitiesSectionEditor'
import { YachtSummaryCardSection } from '@/sections/yacht-summary-card/YachtSummaryCardSection'
import { YachtSummaryCardSectionEditor } from '@/sections/yacht-summary-card/YachtSummaryCardSectionEditor'
import { ItineraryProposalEditableSlide } from '@/services/proposal/slides/ItineraryProposalEditableSlide'
import { ItineraryProposalSlide } from '@/services/proposal/slides/ItineraryProposalSlide'
import { ItinerarySlide } from '@/services/proposal/slides/ItinerarySlide'
import { VesselSlide } from '@/services/proposal/slides/VesselSlide'
import { YachtSlide } from '@/services/proposal/slides/YachtSlide'

import { ProfileCompanySection } from '../../sections/profile-company/ProfileCompanySection'
import { VesselProposalEditableSlide } from './slides/VesselProposalEditableSlide'
import { VesselProposalSlide } from './slides/VesselProposalSlide'
import { YachtProposalEditableSlide } from './slides/YachtProposalEditableSlide'
import { YachtProposalSlide } from './slides/YachtProposalSlide'

/**
 * Bind all the Slides implementations
 */
const slideBindings: Map<SlideType, Function<SlideInit, Slide>> = new Map<SlideType, Function<SlideInit, Slide>>()

slideBindings.set(SlideType.YACHT, (slideInit: SlideInit) => new YachtSlide(slideInit))
slideBindings.set(SlideType.ITINERARY, (slideInit: SlideInit) => new ItinerarySlide(slideInit))
slideBindings.set(SlideType.VESSEL, (slideInit: SlideInit) => new VesselSlide(slideInit))
/**
 * END Slide binding
 */

/**
 * START Proposal editable slides binding
 */
const proposalEditableSlidesBinding: Map<SlideType, Function<SlideInit, ProposalEditableSlide>> = new Map<
  SlideType,
  Function<SlideInit, ProposalEditableSlide>
>()

proposalEditableSlidesBinding.set(
  SlideType.ITINERARY,
  (slideInit: SlideInit) => new ItineraryProposalEditableSlide(slideInit),
)
proposalEditableSlidesBinding.set(SlideType.YACHT, (slideInit: SlideInit) => new YachtProposalEditableSlide(slideInit))

proposalEditableSlidesBinding.set(
  SlideType.VESSEL,
  (slideInit: SlideInit) => new VesselProposalEditableSlide(slideInit),
)
/**
 * END Proposal editable slides binding
 */

/**
 * START Proposal editable slides binding
 */
const proposalEditableSectionsBinding: Map<SectionType, Function<SectionInit, EditableSection<any>>> = new Map<
  SectionType,
  Function<SectionInit, EditableSection<any>>
>()

proposalEditableSectionsBinding.set(SectionType.NAV_BAR, (sectionInit: SectionInit) => new NavBarEditor(sectionInit))

proposalEditableSectionsBinding.set(
  SectionType.VESSEL_HERO,
  (sectionInit: SectionInit) => new VesselHeroSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.VESSEL_OVERVIEW,
  (sectionInit: SectionInit) => new VesselOverviewSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.VESSEL_SPECIFICATIONS,
  (sectionInit: SectionInit) => new VesselSpecificationsSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.VESSEL_CREW,
  (sectionInit: SectionInit) => new VesselCrewSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.VESSEL_TOYS_AMENITIES,
  (sectionInit: SectionInit) => new ToysAmenitiesSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.ITINERARY_SUMMARY,
  (sectionInit: SectionInit) => new SummarySectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.ITINERARY_OVERVIEW,
  (sectionInit: SectionInit) => new OverviewSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.ITINERARY_HERO,
  (sectionInit: SectionInit) => new HeroSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.ITINERARY_CHECKLIST,
  (sectionInit: SectionInit) => new ChecklistSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.ITINERARY_MAP,
  (sectionInit: SectionInit) => new MapSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.IMAGE,
  (sectionInit: SectionInit) => new ImageSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.HEADING,
  (sectionInit: SectionInit) => new HeadingSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.PROFILE_COMPANY,
  (sectionInit: SectionInit) => new ProfileCompanySectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.ALL_DAY_CARDS,
  (sectionInit: SectionInit) => new AllDayCardsSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.ADDITIONAL_NOTES,
  (sectionInit: SectionInit) => new AdditionalNotesEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.SPACER,
  (sectionInit: SectionInit) => new SpacerSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.DISCLAIMERS,
  (sectionInit: SectionInit) => new DisclaimersSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(SectionType.BUTTON, (sectionInit: SectionInit) => new ButtonEditor(sectionInit))

proposalEditableSectionsBinding.set(
  SectionType.YACHT_SUMMARY_CARD,
  (sectionInit: SectionInit) => new YachtSummaryCardSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.VESSEL_SHOWCASE,
  (sectionInit: SectionInit) => new VesselShowcaseSectionEditor(sectionInit),
)

proposalEditableSectionsBinding.set(
  SectionType.EMBED,
  (sectionInit: SectionInit) => new EmbedSectionEditor(sectionInit),
)
/**
 * END Proposal editable slides binding
 */

/**
 * Bind al the Proposal Slides implementations
 */
const proposalSlides: Map<SlideType, Function<SlideInit, Slide>> = new Map<SlideType, Function<SlideInit, Slide>>()
proposalSlides.set(SlideType.ITINERARY, (slideInit: SlideInit) => new ItineraryProposalSlide(slideInit))
proposalSlides.set(SlideType.YACHT, (slideInit: SlideInit) => new YachtProposalSlide(slideInit))
proposalSlides.set(SlideType.VESSEL, (slideInit: SlideInit) => new VesselProposalSlide(slideInit))
/**
 * END proposal Slide binding
 */

/**
 * Bind all the sections implementations for a proposal
 */
const proposalSections: Map<SectionType, Function<SectionInit, Section<any>>> = new Map<
  SectionType,
  Function<SectionInit, Section<any>>
>()

proposalSections.set(SectionType.NAV_BAR, (sectionInit: SectionInit) => new NavBar(sectionInit))
proposalSections.set(SectionType.VESSEL_HERO, (sectionInit: SectionInit) => new VesselHeroSection(sectionInit))
proposalSections.set(SectionType.VESSEL_OVERVIEW, (sectionInit: SectionInit) => new VesselOverviewSection(sectionInit))
proposalSections.set(
  SectionType.VESSEL_SPECIFICATIONS,
  (sectionInit: SectionInit) => new VesselSpecificationsSection(sectionInit),
)
proposalSections.set(SectionType.VESSEL_CREW, (sectionInit: SectionInit) => new VesselCrewSection(sectionInit))
proposalSections.set(
  SectionType.VESSEL_TOYS_AMENITIES,
  (sectionInit: SectionInit) => new ToysAmenitiesSection(sectionInit),
)

proposalSections.set(SectionType.ITINERARY_HERO, (sectionInit: SectionInit) => new HeroSection(sectionInit))
proposalSections.set(SectionType.ITINERARY_SUMMARY, (sectionInit: SectionInit) => new SummarySection(sectionInit))
proposalSections.set(SectionType.ITINERARY_MAP, (sectionInit: SectionInit) => new MapSection(sectionInit))
proposalSections.set(SectionType.IMAGE, (sectionInit: SectionInit) => new ImageSection(sectionInit))
proposalSections.set(SectionType.HEADING, (sectionInit: SectionInit) => new HeadingSection(sectionInit))
proposalSections.set(SectionType.ALL_DAY_CARDS, (sectionInit: SectionInit) => new AllDayCardsSection(sectionInit))
proposalSections.set(SectionType.SPACER, (sectionInit: SectionInit) => new SpacerSection(sectionInit))
proposalSections.set(SectionType.ADDITIONAL_NOTES, (sectionInit: SectionInit) => new AdditionalNotes(sectionInit))
proposalSections.set(SectionType.PROFILE_COMPANY, (sectionInit: SectionInit) => new ProfileCompanySection(sectionInit))
proposalSections.set(SectionType.ITINERARY_CHECKLIST, (sectionInit: SectionInit) => new ChecklistSection(sectionInit))
proposalSections.set(SectionType.ITINERARY_OVERVIEW, (sectionInit: SectionInit) => new OverviewSection(sectionInit))
proposalSections.set(SectionType.DISCLAIMERS, (sectionInit: SectionInit) => new DisclaimersSection(sectionInit))
proposalSections.set(SectionType.BUTTON, (sectionInit: SectionInit) => new Button(sectionInit))
proposalSections.set(
  SectionType.YACHT_SUMMARY_CARD,
  (sectionInit: SectionInit) => new YachtSummaryCardSection(sectionInit),
)
proposalSections.set(SectionType.VESSEL_SHOWCASE, (sectionInit: SectionInit) => new VesselShowcaseSection(sectionInit))
proposalSections.set(SectionType.EMBED, (sectionInit: SectionInit) => new EmbedSection(sectionInit))

/**
 * END proposal sections binding
 */

/**
 * START lite sections binding
 */
const liteSectionsBinding: Map<SectionType, Function<SectionInit, Section<any>>> = new Map<
  SectionType,
  Function<SectionInit, Section<any>>
>()

liteSectionsBinding.set(SectionType.NAV_BAR, (sectionInit: SectionInit) => new NavBarLiteSection(sectionInit))
liteSectionsBinding.set(SectionType.VESSEL_HERO, (sectionInit: SectionInit) => new VesselHeroLiteSection(sectionInit))
liteSectionsBinding.set(
  SectionType.VESSEL_OVERVIEW,
  (sectionInit: SectionInit) => new VesselOverviewLiteSection(sectionInit),
)
liteSectionsBinding.set(
  SectionType.VESSEL_SPECIFICATIONS,
  (sectionInit: SectionInit) => new VesselSpecificationsLiteSection(sectionInit),
)
liteSectionsBinding.set(SectionType.VESSEL_CREW, (sectionInit: SectionInit) => new VesselCrewLiteSection(sectionInit))
liteSectionsBinding.set(
  SectionType.VESSEL_TOYS_AMENITIES,
  (sectionInit: SectionInit) => new ToysAmenitiesLiteSection(sectionInit),
)
liteSectionsBinding.set(
  SectionType.PROFILE_COMPANY,
  (sectionInit: SectionInit) => new ProfileCompanyLiteSection(sectionInit),
)

/**
 * END lite sections binding
 */

/**
 * Return the bound implementations
 */
export const bindings = {
  slides: slideBindings,
  liteSections: liteSectionsBinding,
  proposalEditableSlides: proposalEditableSlidesBinding,
  proposalsEditableSections: proposalEditableSectionsBinding,
  proposalSlides: proposalSlides,
  proposalSections: proposalSections,
}
