import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M1 5C1 4.44772 1.44772 4 2 4H22C22.5523 4 23 4.44772 23 5V18C23 18.5523 22.5523 19 22 19H2C1.44772 19 1 18.5523 1 18V5ZM8.59541 8.32321L3.34746 15.5233C3.20295 15.7215 3.34456 16 3.58989 16H20.4001C20.6473 16 20.7885 15.7178 20.6402 15.52L16.6749 10.2309C16.5555 10.0716 16.3167 10.0707 16.1961 10.2293L13.6314 13.6014C13.5119 13.7585 13.2758 13.7594 13.1551 13.6031L9.07534 8.31663C8.95316 8.15831 8.7132 8.1616 8.59541 8.32321ZM14 10C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10Z",
      "clip-rule": "evenodd"
    })
  ]))
}