<script lang="ts" setup>
import { Ref, ref } from 'vue'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { ToysAmenitiesSectionData } from '@/sections/vessel/toys-amenities/types/types'

type Props = {
  id: string
  proposalUri: string
  data: ToysAmenitiesSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const isClamped: Ref<boolean> = ref(true)
</script>
<template>
  <div
    v-if="props.data && Object.keys(props.data).length"
    id="toys-amenities-section"
    class="mx-auto max-w-screen-xl p-4 @sm:p-8"
  >
    <!-- Heading -->
    <h1
      v-if="props.data.heading"
      class="mx-auto max-w-screen-sm text-center mb-4 @sm:mb-8 text-2xl @sm:text-3xl tracking-tight"
    >
      <SingleLineTextEditor class="mb-4 text-center font-bold" :value="props.data.heading" :is-editable="false" />
    </h1>

    <div
      id="toys-amenities-container"
      class="flex flex-col p-8 @sm:p-16 bg-white drop-shadow-md border border-gray-200 rounded-lg"
    >
      <!-- Toys -->
      <div v-if="props.data.toys?.length" class="py-8 first:pt-0 last:pb-0">
        <h1 class="mb-4 text-xl @sm:text-2xl font-bold">Toys</h1>
        <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
          <SingleLineTextEditor
            v-for="toy in props.data.toys.slice(0, isClamped ? 8 : undefined)"
            :key="toy"
            :value="toy"
            :is-editable="false"
          ></SingleLineTextEditor>
        </div>
      </div>

      <hr v-if="!isClamped" />

      <template v-if="!isClamped">
        <!-- Amenities -->
        <div v-if="props.data.amenities?.length" class="py-8 first:pt-0 last:pb-0">
          <h1 class="mb-4 text-xl @sm:text-2xl font-bold">Amenities</h1>
          <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
            <SingleLineTextEditor
              v-for="amenity in props.data.amenities"
              :key="amenity"
              :value="amenity"
              :is-editable="false"
            ></SingleLineTextEditor>
          </div>
        </div>

        <hr />

        <!-- Entertainment -->
        <div v-if="props.data.entertainment?.length" class="py-8 first:pt-0 last:pb-0">
          <h1 class="mb-4 text-xl @sm:text-2xl font-bold">Entertainment</h1>
          <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
            <SingleLineTextEditor
              v-for="entertainment in props.data.entertainment"
              :key="entertainment"
              :value="entertainment"
              :is-editable="false"
            ></SingleLineTextEditor>
          </div>
        </div>

        <hr />

        <!-- Tenders -->
        <div v-if="props.data.tenders?.length" class="font-normal py-8 first:pt-0 last:pb-0">
          <h1 class="mb-4 text-xl @sm:text-2xl font-bold">Tenders</h1>
          <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
            <SingleLineTextEditor
              v-for="tender in props.data.tenders"
              :key="tender"
              :value="tender"
              :is-editable="false"
            ></SingleLineTextEditor>
          </div>
        </div>
      </template>

      <span
        v-if="
          props.data.amenities?.length ||
          props.data.entertainment?.length ||
          props.data.tenders?.length ||
          props.data.toys?.length > 8
        "
        class="cursor-pointer text-theme-primary"
        @click="isClamped = !isClamped"
      >
        {{ isClamped ? 'Show more' : 'Show less' }}
      </span>
    </div>
  </div>
</template>
