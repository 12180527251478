export const URI_SEPARATOR = '::' // Path separator
export const PATH_SEPARATOR = '/'

/**
 * URI interface definition. This structure is used
 * to drive the storing of data
 * @deprecated this is being replaced by uri parsers and builders
 */
export class URI {
  readonly parts: string[]

  constructor(parts: string[]) {
    // If any item in parts is null or undefined or empty string, throw an error
    if (parts.some((part) => !part)) {
      throw new Error('Invalid URI parts')
    }
    // As a basic support for min and max parts, we allow only between 3 and 8 (inclusive)
    if (parts.length < 3 || parts.length > 8) {
      throw new Error('Unsupported length for URI format')
    }
    this.parts = parts.map((part) => encodeURIComponent(part))
  }

  /**
   * The URI context
   *  a::itinerary::frdtgyhui78
   *  u::123::itinerary::34567y
   *  t::123::itinerary::4567
   *  @deprecated
   */
  get context(): URIContext {
    return this.parts[0] as URIContext
  }

  /**
   * A nullable id that acts as the unique identifier of the context.
   * Different {@link URIContext} can have a different
   * @deprecated
   */
  get contextId(): string | undefined {
    return this.parts[0] === URIContext.A ? undefined : decodeURIComponent(this.parts[1])
  }

  /**
   * A string describing the name of the entity the entity id refers to
   * @deprecated
   */
  get entity(): string {
    return getEntityFromParts(this.parts)
  }

  /**
   * The entity unique identifier
   * @deprecated
   */
  get entityId(): string {
    return this.parts[0] === URIContext.A ? decodeURIComponent(this.parts[2]) : decodeURIComponent(this.parts[3])
  }

  /**
   * Turns this {@link URI} into a string of `namespace:entity:id`
   */
  toString(): string {
    return this.parts.map((part: string) => decodeURIComponent(part)).join(URI_SEPARATOR)
  }

  /**
   * Turns a uri into a key that points to the entity folder in r2
   */
  toKey(): string {
    return this.parts.map((part: string) => decodeURIComponent(part)).join(PATH_SEPARATOR)
  }

  /**
   * Turns a uri into a key that points to the entity document in r2
   */
  toDocumentKey(): string {
    return [this.toKey(), 'document'].join(PATH_SEPARATOR)
  }

  /**
   * Turns an uri into a /etc/index key
   */
  toDocumentSidecar(sidecarName: string): string {
    return [`${this.toDocumentKey()}.${sidecarName}`].join(PATH_SEPARATOR)
  }
}

/**
 * Get the entity from the parts
 * @param parts - array of parts
 */
const getEntityFromParts = (parts: string[]) => {
  const entity: string = parts[0] === URIContext.A ? parts[1] : parts[2]
  // Get the values of URIEntity
  const entityIndexMatch = Object.values(URIEntity).findIndex((value) => value === entity)
  if (entityIndexMatch === -1) {
    throw new Error(`Invalid URI entity: ${entity}`)
  }
  return entity
}

/**
 * The supported uri context types
 */
export enum URIContext {
  /**
   * The `user` uri context
   */
  U = 'u',
  /**
   * The `ankor` uri context
   */
  A = 'a',
  /**
   * The `team` uri context.
   * Teams are not currently implement but this is just
   * to show how the context can be extended for
   * shared permissions
   * @deprecated this context has been moved to be an entity
   */
  T = 't',
  /**
   * The 'Company' uri context.
   * any document belonging to a company should be stored under this context
   */
  C = 'c',
  /**
   * The federated system identity for hubspot
   */
  HUBSPOT = 'hubspot',
  /**
   * The federated system identity for kinde
   */
  KINDE = 'kinde',
  /**
   * Used for generic records stored in kv that belongs to the platform
   */
  KV = 'kv',
  MEDIA = 'media',
  IMAGE = 'image',
}

/**
 * The supported uri entity types
 */
export enum URIEntity {
  CART = 'cart',
  ITINERARY = 'itinerary',
  NOTES = 'notes',
  PLACE = 'place',
  PROPOSAL = 'proposal',
  TEMPLATE = 'template',
  TRIP = 'trip',
  VESSEL = 'vessel',
  YACHT = 'yacht',
  TEAM = 'team',
  GEO = 'geo',
  COMPANY = 'company',
}

export const URIBuilder = {
  /**
   *
   * @param key
   * @deprecated replaced by the iamFederatedKV
   */
  federatedUriFrom: (key: string): URI => {
    const parts = key.split('::')
    if (parts.length !== 4) {
      throw new Error('Invalid federated uri key')
    }

    const source = parts[0]
    const sourceId = parts[1]
    const target = parts[2]
    const targetId = parts[3]

    return URIBuilder.federatedUri(source as URIContext.HUBSPOT | URIContext.KINDE, sourceId, target, targetId)
  },
  /**
   * Build a uri for a federated system identity
   *
   * @param source The federated system identity source
   * @param sourceId the federated system identity source id
   * @param target the federated system identity target
   * @param targetId the federated system identity target id
   * @returns a uri representing a federated system identity
   * @deprecated replaced by the iamFederatedKV
   */
  federatedUri: (
    source: URIContext.HUBSPOT | URIContext.KINDE,
    sourceId: string,
    target: string,
    targetId?: string,
  ): URI => {
    return new URI(targetId ? [source, sourceId, target, targetId] : [source, sourceId, target])
  },
  /**
   @deprecated
   **/
  contextToEnum: (context: string): URIContext | null => {
    if (
      Object.values(URIContext)
        .map((c) => c.toString())
        .includes(context)
    ) {
      return context as URIContext
    }
    return null
  },
  /**
   * @param s
   * @deprecated
   */
  fromString: (s: string): URI => {
    // make sure the uri is valid
    // regex that checks a string group only contains alphanumeric chars and colons
    // This also matches uris separated by a single : like u:123:itineraries:123
    // it's not a 100% proof safe validation however we are managing uris
    // so we have control over how we create them
    const regexp = new RegExp(/^[a-zA-Z0-9%_:-]+$/)

    if (!regexp.test(s)) {
      throw new Error('Invalid URI string')
    }

    const parts: string[] = s.split(URI_SEPARATOR).map((part) => encodeURIComponent(part))

    // a uri is minimum 3 and up to 8 parts anything outside of that is not supported, throw
    if (parts.length < 3 || parts.length > 8) {
      throw new Error('Invalid URI string')
    }

    // parse the first part to a uri context
    const context: URIContext | null = URIBuilder.contextToEnum(parts[0])

    // make sure this context type is supported
    if (!context) {
      throw new Error(`Unsupported URI context ${parts[0]}`)
    }

    if (context === URIContext.A) {
      // it must be 3 parts, throw otherwise
      if (parts.length !== 3) {
        throw new Error(`Invalid URI string with context ${context}`)
      }

      const entity = decodeURIComponent(parts[1])
      const entityId = decodeURIComponent(parts[2])

      return new URI([context, entity, entityId])
    }

    const contextId = decodeURIComponent(parts[1])
    const entity = decodeURIComponent(parts[2])
    const entityId = decodeURIComponent(parts[3])

    return new URI([context, contextId, entity, entityId, ...parts.slice(4, parts.length)])
  },
  /**
   * Build a uri string given the parts
   * @deprecated
   */
  from: (context: URIContext, contextId: string | null, entity: string, entityId: string): string => {
    switch (context) {
      case URIContext.A:
        return `${context}::${entity}::${entityId}`
      default:
        if (contextId === null) {
          throw new Error(`contextId is required for uri of type ${context}`)
        }
        return `${context}::${contextId}::${entity}::${entityId}`
    }
  },
  fromParts: (parts: string[]): URI => {
    return new URI(parts)
  },
}

/**
 * Build a user itinerary uri
 *
 */
export const UserItineraryURIBuilder = {
  fromParts: (contextId: string, itineraryId: string): URI => {
    return URIBuilder.fromParts([URIContext.U, contextId, URIEntity.ITINERARY, itineraryId])
  },
}

/**
 * Build a proposal URI
 * There is no concept of an Ankor proposal always build a user proposal
 */
export const ProposalURIBuilder = {
  fromParts: (contextId: string, proposalId: string): URI => {
    return URIBuilder.fromParts([URIContext.U, contextId, URIEntity.PROPOSAL, proposalId])
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    if (parts.length !== 4) {
      throw new Error('Invalid proposal uri key')
    }
    return ProposalURIBuilder.fromParts(parts[1], parts[3])
  },
}

export const AnkorYachtURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.A || parts[1] !== URIEntity.YACHT) {
      throw new Error('Invalid ankor yacht uri key')
    }
  },
  fromParts: (yachtId: string): URI => {
    AnkorYachtURIBuilder.validate([URIContext.A, URIEntity.YACHT, yachtId])
    return URIBuilder.fromParts([URIContext.A, URIEntity.YACHT, yachtId])
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    if (parts.length !== 3) {
      throw new Error('Invalid ankor yacht uri key')
    }
    AnkorYachtURIBuilder.validate(parts)
    return URIBuilder.fromParts([URIContext.A, URIEntity.YACHT, parts[2]])
  },
}

export const CustomYachtURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.U || (parts[2] !== URIEntity.YACHT && parts[2] !== URIEntity.VESSEL)) {
      throw new Error('Invalid custom yacht uri key')
    }
  },
  fromParts: (contextId: string, yachtId: string, entity: URIEntity = URIEntity.VESSEL): URI => {
    CustomYachtURIBuilder.validate([URIContext.U, contextId, entity, yachtId])
    return URIBuilder.fromParts([URIContext.U, contextId, entity, yachtId])
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    if (parts.length !== 4) {
      throw new Error('Invalid custom yacht uri key')
    }
    CustomYachtURIBuilder.validate(parts)
    return CustomYachtURIBuilder.fromParts(parts[1], parts[3], parts[2] as URIEntity)
  },
}

export const AnkorVesselURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.A || parts[1] !== URIEntity.VESSEL) {
      throw new Error('Invalid ankor vessel uri key')
    }
  },
  fromParts: (vesselId: string): URI => {
    AnkorVesselURIBuilder.validate([URIContext.A, URIEntity.VESSEL, vesselId])
    return URIBuilder.fromParts([URIContext.A, URIEntity.VESSEL, vesselId])
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    if (parts.length !== 3) {
      throw new Error('Invalid ankor vessel uri key')
    }
    AnkorVesselURIBuilder.validate(parts)
    return URIBuilder.fromParts([URIContext.A, URIEntity.VESSEL, parts[2]])
  },
}

export const CustomVesselURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.U || parts[2] !== URIEntity.VESSEL) {
      throw new Error('Invalid custom vessel uri key')
    }
  },
  fromParts: (contextId: string, vesselId: string): URI => {
    CustomVesselURIBuilder.validate([URIContext.U, contextId, URIEntity.VESSEL, vesselId])
    return URIBuilder.fromParts([URIContext.U, contextId, URIEntity.VESSEL, vesselId])
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    if (parts.length !== 4) {
      throw new Error('Invalid custom vessel uri key')
    }
    CustomVesselURIBuilder.validate(parts)
    return CustomVesselURIBuilder.fromParts(parts[1], parts[3])
  },
}

export const ClaimedVesselCalendarURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.C || parts[2] !== URIEntity.VESSEL || parts[4] !== 'calendar') {
      throw new Error('Invalid claimed vessel uri key')
    }
  },
  fromParts: (contextId: string, vesselId: string): URI => {
    ClaimedVesselCalendarURIBuilder.validate([URIContext.C, contextId, URIEntity.VESSEL, vesselId, 'calendar'])
    return URIBuilder.fromParts([URIContext.C, contextId, URIEntity.VESSEL, vesselId, 'calendar'])
  },
  fromString: (s: string): URI => {
    const parts: string[] = s.split(URI_SEPARATOR)
    if (parts.length !== 5) {
      throw new Error('Invalid claimed vessel uri key')
    }
    ClaimedVesselCalendarURIBuilder.validate(parts)
    return ClaimedVesselCalendarURIBuilder.fromParts(parts[1], parts[3])
  },
}

export const ClaimedVesselURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.C || parts[2] !== URIEntity.VESSEL) {
      throw new Error('Invalid claimed vessel uri key')
    }
  },
  fromParts: (contextId: string, vesselId: string): URI => {
    ClaimedVesselURIBuilder.validate([URIContext.C, contextId, URIEntity.VESSEL, vesselId])
    return URIBuilder.fromParts([URIContext.C, contextId, URIEntity.VESSEL, vesselId])
  },
  fromString: (s: string): URI => {
    const parts: string[] = s.split(URI_SEPARATOR)
    if (parts.length !== 4) {
      throw new Error('Invalid claimed vessel uri key')
    }
    ClaimedVesselURIBuilder.validate(parts)
    return ClaimedVesselURIBuilder.fromParts(parts[1], parts[3])
  },
}

export const NoteCategoriesURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.U || parts[2] !== URIEntity.NOTES || parts[3] !== 'categories') {
      throw new Error('Invalid note categories uri key')
    }
  },
  fromParts: (contextId: string): URI => {
    const parts: string[] = [URIContext.U, contextId, URIEntity.NOTES, 'categories']
    NoteCategoriesURIBuilder.validate(parts)
    return URIBuilder.fromParts(parts)
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    if (parts.length !== 4) {
      throw new Error('Invalid note categories uri key')
    }
    NoteCategoriesURIBuilder.validate(parts)
    return URIBuilder.fromParts([URIContext.U, parts[1], URIEntity.NOTES, 'categories'])
  },
}

export const NoteYachtListURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts[0] !== URIContext.U || parts[2] !== URIEntity.NOTES || parts[3] !== 'yachts') {
      throw new Error('Invalid note yacht list uri key')
    }
  },
  fromParts: (contextId: string): URI => {
    const parts: string[] = [URIContext.U, contextId, URIEntity.NOTES, 'yachts']
    NoteYachtListURIBuilder.validate(parts)
    return URIBuilder.fromParts(parts)
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    if (parts.length !== 4) {
      throw new Error('Invalid note yacht list uri key')
    }
    NoteYachtListURIBuilder.validate(parts)
    return URIBuilder.fromParts([URIContext.U, parts[1], URIEntity.NOTES, 'yachts'])
  },
}

// u::kp:123::notes::<encoded_yacht_uri>::noteId
export const YachtNoteURIBuilder = {
  validate: (parts: string[]): void => {
    if (parts.length !== 5) {
      throw new Error('Invalid yacht note uri key')
    }
    if (parts[0] !== URIContext.U || parts[2] !== URIEntity.NOTES) {
      throw new Error('Invalid yacht note uri key')
    }
    const targetUri: URI = URIBuilder.fromString(decodeURIComponent(parts[3]))
    // Should either be a yacht or a vessel
    if (targetUri.entity !== URIEntity.YACHT && targetUri.entity !== URIEntity.VESSEL) {
      throw new Error('Invalid yacht note uri key')
    }
  },
  fromParts: (contextId: string, yachtUri: string, noteId: string): URI => {
    const parts: string[] = [URIContext.U, contextId, URIEntity.NOTES, yachtUri, noteId]
    YachtNoteURIBuilder.validate(parts)
    return URIBuilder.fromParts(parts)
  },
  fromString: (s: string): URI => {
    const parts = s.split(URI_SEPARATOR)
    YachtNoteURIBuilder.validate(parts)
    return URIBuilder.fromParts([URIContext.U, parts[1], URIEntity.NOTES, parts[3], parts[4]])
  },
}

/**
 * The Uri interface. This is the third and most current iteration of the URI implementation.
 * An uri is defined to always have a context, a contextId, an entity and a target.
 * The entity describes the uri entity type and each entity type can have a specific URI builder and parser
 * implementations.
 * When the business logic does not require the uri type to be known then the {@link GenericUriBuilder} and
 * {@link GenericUriParser} are used to build and parse the uri.
 */
export interface IUri {
  /**
   * The URI context identifiable in the first part of the uri
   */
  getContext(): URIContext

  /**
   * The URI contextId identifiable in the second part of the uri
   */
  getContextId(): string

  /**
   * The URI entity identifiable in the third part of the uri
   */
  getEntity(): URIEntity

  /**
   * The URI entityId identifiable in the fourth part of the uri
   */
  getEntityId(): string

  /**
   * The end part of this uri, also known as the uri target
   */
  getTarget(): string[]

  /**
   * Returns a string representation of this uri object
   */
  toString(): string

  /**
   * Returns the key that points to the entity folder in r2
   */
  toKey(): string

  /**
   * Returns the document key for this uri
   */
  toDocumentKey(): string

  /**
   * Returns the sidecar key for this uri
   *
   * @param sidecarName the name of the sidecar
   */
  toDocumentSidecar(sidecarName: string): string
}

abstract class AbstractURI implements IUri {
  /**
   * The parts of this uri
   */
  protected readonly parts: string[]

  /**
   * Ensures the parts supplied can form a valid uri.
   * The validation rules have been copied from the v2 implementation, with the addition of ensuring the first part is
   * always a {@link URIContext}.
   *
   * @param parts the string array to validate
   * @throws Error if the parts cannot form a valid uri
   */
  protected constructor(parts: string[]) {
    this.parts = parts.map((part) => encodeURIComponent(part))
  }

  abstract getContext(): URIContext

  getContextId(): string {
    return this.parts[0] === URIContext.A ? '' : decodeURIComponent(this.parts[1])
  }

  getEntity(): URIEntity {
    const entity: string = this.parts[0] === URIContext.A ? this.parts[1] : this.parts[2]
    // Get the values of URIEntity
    const entityIndexMatch = Object.values(URIEntity).findIndex((value) => value === entity)
    if (entityIndexMatch === -1) {
      throw new Error(`Invalid URI entity: ${entity}`)
    }
    return entity as URIEntity
  }

  getEntityId(): string {
    const entityId: string = this.parts[0] === URIContext.A ? this.parts[2] : this.parts[3]
    if (!entityId) {
      throw new Error('Invalid URI entity id')
    }
    return entityId
  }

  getTarget(): string[] {
    return this.parts[0] === URIContext.A ? this.parts.slice(2) : this.parts.slice(3)
  }

  toKey(): string {
    return this.parts.map((part: string) => decodeURIComponent(part)).join(PATH_SEPARATOR)
  }

  toDocumentKey(): string {
    return [this.toKey(), 'document'].join(PATH_SEPARATOR)
  }

  toString(): string {
    return this.parts.map((part: string) => decodeURIComponent(part)).join(URI_SEPARATOR)
  }

  toDocumentSidecar(sidecarName: string): string {
    return [`${this.toDocumentKey()}.${sidecarName}`].join(PATH_SEPARATOR)
  }
}

/**
 * The generic implementation of an uri object
 */
export class GenericURI extends AbstractURI {
  /**
   * Ensures the parts supplied can form a valid uri.
   * The validation rules have been copied from the v2 implementation, with the addition of ensuring the first part is
   * always a {@link URIContext}.
   *
   * @param parts the string array to validate
   * @throws Error if the parts cannot form a valid uri
   */
  constructor(parts: string[], opts = { assumeValid: false }) {
    super(parts)
    //
    if (!opts.assumeValid) {
      // If any item in parts is null or undefined or empty string, throw an error
      if (parts.some((part) => !part)) {
        throw new Error('Invalid URI parts')
      }
      // As a basic support for min and max parts, we allow only between 3 and 8 (inclusive)
      if (parts.length < 3 || parts.length > 8) {
        throw new Error('Unsupported length for URI format')
      }
      // The first part must be one of the URIContext values
      if (!Object.values(URIContext).includes(parts[0] as URIContext)) {
        throw new Error('Unsupported URI context')
      }
    }
  }

  getContext(): URIContext {
    return this.parts[0] as URIContext
  }
}

export class UserURI extends AbstractURI {
  constructor(parts: string[]) {
    super(parts)
  }

  getContext(): URIContext {
    return URIContext.U
  }
}
