import { inject } from 'vue'

import { URIBuilder, URIContext } from '@ankor-io/common/uri/Uri'

import { Config, ConfigKey } from '@/config/types'
import { AuthenticationContext } from '@/iam/types'

// inject the config and the authentication context which are required
let config: Config
let authenticationContext: AuthenticationContext

/**
 * Get a document for the current user, so the uri is contexted as:
 * u::<user-id>::
 *
 * @param entity the entity name of the document
 * @param entityId the unique identifier of the document
 * @returns a promise with either the document or null when the document is not found
 */
export const getDocument = async <T>(entity: string, entityId: string): Promise<T | null> => {
  const uri: string = URIBuilder.from(URIContext.U, authenticationContext.getUser().id, entity, entityId)
  return getURIDocument(uri)
}

/**
 * Get a document by uri
 *
 * @param uri the uri of the document to get
 * @returns a promise with either the document or null when the document is not found
 */
export const getURIDocument = async <T>(uri: string): Promise<T | null> => {
  const token = await authenticationContext.getToken()
  const res: Response = await fetch(`${config.STOWAGE_URL}/document/${encodeURIComponent(uri)}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status === 200) {
    const json: any = await res.json()
    return Promise.resolve(json.data as T)
  }

  return Promise.resolve(null)
}

/**
 * Set a document for the current user, so the uri is contexted as:
 * u::<user-id>::
 *
 * @param entity the entity name of the document to set
 * @param entityId the document unique identifier
 * @param document the document content
 * @returns a promise with the document content or null when failing to save
 */
export const setDocument = async <T>(entity: string, entityId: string, document: T): Promise<T | null> => {
  const token = await authenticationContext.getToken()
  const uri: string = URIBuilder.from(URIContext.U, authenticationContext.getUser().id, entity, entityId)

  const res: Response = await fetch(`${config.STOWAGE_URL}/document`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ uri: uri.toString(), document: document }),
  })

  if (res.status === 200) {
    return Promise.resolve(document as T)
  }

  return Promise.resolve(null)
}

/**
 * Set a document by uri
 *
 * @param uri the document uri
 * @returns a promise with the document content or null when failing to save
 */
export const setURIDocument = async <T>(uri: string, document: T): Promise<T | null> => {
  const token = await authenticationContext.getToken()

  const res: Response = await fetch(`${config.STOWAGE_URL}/document`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ uri: uri.toString(), document: document }),
  })

  if (res.status === 200) {
    return Promise.resolve(document as T)
  }

  return Promise.resolve(null)
}

/**
 * Copy files from one uri to another, retaining the same name
 *
 * @param fromUri the uri to copy the files from
 * @param toURI the uri to copy the files to
 * @param suffix any suffix acting as a filter to which files to include in the copy
 * @returns true when the files were copied successfully, false otherwise
 */
export const copyFiles = async (fromUri: string, toUri: string, suffix?: string[]): Promise<boolean> => {
  const token = await authenticationContext.getToken()

  const res: Response = await fetch(`${config.STOWAGE_URL}/files/copy`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      source: fromUri,
      destination: toUri,
      suffix: suffix,
    }),
  })

  if (res.status === 200) {
    return Promise.resolve(true)
  }

  return Promise.resolve(false)
}

/**
 * Delete document.
 * Back-end will delete document that match the uri.
 */
export const deleteDocument = async (uri: string): Promise<boolean> => {
  const token = await authenticationContext.getToken()
  const uriEncoded = encodeURIComponent(uri)
  const res: Response = await fetch(`${config.STOWAGE_URL}/document/${uriEncoded}`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status === 200) {
    return Promise.resolve(true)
  }

  return Promise.resolve(false)
}

/**
 * Stowage service definition
 */
export type StowageService = {
  /**
   * Get a document for the current user, so the uri is contexted as:
   * u::<user-id>::
   *
   * @param entity the entity name of the document
   * @param entityId the unique identifier of the document
   * @returns a promise with either the document or null when the document is not found
   */
  getDocument: <T>(entity: string, entityId: string) => Promise<T | null>
  /**
   * Get a document by uri
   *
   * @param uri the uri of the document to get
   * @returns a promise with either the document or null when the document is not found
   */
  getURIDocument: <T>(uri: string) => Promise<T | null>
  /**
   * Set a document for the current user, so the uri is contexted as:
   * u::<user-id>::
   *
   * @param entity the entity name of the document to set
   * @param entityId the document unique identifier
   * @param document the document content
   * @returns a promise with the document content or null when failing to save
   */
  setDocument: <T>(entity: string, entityId: string, document: T) => Promise<T | null>
  /**
   * Set a document by uri
   *
   * @param uri the document uri
   * @returns a promise with the document content or null when failing to save
   */
  setURIDocument: <T>(uri: string, document: T) => Promise<T | null>
  /**
   * Copy files from one uri to another, retaining the same name
   *
   * @param fromUri the uri to copy the files from
   * @param toURI the uri to copy the files to
   * @param suffix any suffix acting as a filter to which files to include in the copy
   * @returns true when the files were copied successfully, false otherwise
   */
  copyFiles: (fromUri: string, toURI: string, suffix?: string[]) => Promise<boolean>
  /**
   * Delete document.
   * Back-end will delete document that match the uri.
   */
  deleteDocument: (uri: string) => Promise<boolean>
}

/**
 * This is required since inject only works in vue components
 */
export const useStowageService = (): StowageService => {
  config = inject(ConfigKey)!
  authenticationContext = inject('authenticationContext')!
  return {
    getDocument: getDocument,
    getURIDocument: getURIDocument,
    setDocument: setDocument,
    setURIDocument: setURIDocument,
    copyFiles: copyFiles,
    deleteDocument: deleteDocument,
  } as StowageService
}
