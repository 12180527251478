import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("circle", {
      cx: "16",
      cy: "11",
      r: "1"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M23 8C23 7.44772 22.5523 7 22 7H2C1.44772 7 1 7.44772 1 8V15C1 15.5523 1.44772 16 2 16H16H22C22.5523 16 23 15.5523 23 15V8ZM17.7555 13.041C18.0207 13.5255 18.4477 14 19 14H20.1667C20.7189 14 21.1667 13.5523 21.1667 13V10C21.1667 9.44772 20.7189 9 20.1667 9H13.9167C13.3644 9 12.9167 9.44772 12.9167 10V13.4583C12.9167 13.7575 13.1592 14 13.4583 14C13.7575 14 13.9925 13.753 14.0724 13.4647C14.3065 12.6201 15.0808 12 16 12C16.7571 12 17.4159 12.4207 17.7555 13.041ZM2.81669 8.80005C2.31964 8.80005 1.91669 9.20299 1.91669 9.70005C1.91669 10.1971 2.31964 10.6 2.8167 10.6H4.68336C5.18042 10.6 5.58336 10.1971 5.58336 9.70005C5.58336 9.20299 5.18042 8.80005 4.68336 8.80005H2.81669ZM1.91669 11.95C1.91669 11.7015 2.11817 11.5 2.36669 11.5H8.80003C9.04856 11.5 9.25003 11.7015 9.25003 11.95C9.25003 12.1985 9.04856 12.4 8.80003 12.4H2.36669C2.11817 12.4 1.91669 12.1985 1.91669 11.95ZM2.36669 13.3C2.11817 13.3 1.91669 13.5015 1.91669 13.75C1.91669 13.9986 2.11817 14.2 2.36669 14.2H8.80003C9.04856 14.2 9.25003 13.9986 9.25003 13.75C9.25003 13.5015 9.04856 13.3 8.80003 13.3H2.36669Z",
      "clip-rule": "evenodd"
    })
  ]))
}