<script setup lang="ts">
import { Pane, Splitpanes } from 'splitpanes'
import { Ref, computed, ref } from 'vue'

import { ProposalItemType } from '@ankor-io/common/proposal/Proposal'
import { SolidSearch } from '@ankor-io/icons/solid'

import Button from '@/components/Button.vue'
import VisibleObserver from '@/components/VisibleObserver.vue'
import SearchHitsList from '@/components/search-results/SearchHitsList.vue'
import { IndexItemType, PreviewOpenEvent } from '@/components/search-results/types'
import PreviewTemplateVessel from '@/sections/vessel-showcase/PreviewTemplateVessel.vue'

const emit = defineEmits<{
  (e: 'confirm:modal', value: string | null): void
}>()

const previewType: Ref<
  ProposalItemType.ITINERARY | ProposalItemType.PROPOSAL | ProposalItemType.YACHT | ProposalItemType.VESSEL | null
> = ref(null)
const previewUri: Ref<string | null> = ref(null)
const previewItem: Ref<IndexItemType | null> = ref(null)

// the data in the search results -- the dirty one has "null" values for tombstoned items.
let dirtyRiver: Ref<any[]> = ref([])
const river = computed(() => dirtyRiver.value.filter((item) => !!item))

// During the "transform items" step of the Aloglia search API,
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922
  //
  // reset river to empty array if it's the first page
  if (results.page === 0) {
    dirtyRiver.value = []
  }

  items.forEach((hit, i) => {
    let offset = results.page * results.hitsPerPage + i
    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = hit
  })

  // return the clean river (used by algolia lib)
  return river
}

/////// preview methods start
const openPreviewPanel = async (event: PreviewOpenEvent) => {
  // without awaiting after an input in the algolia search,
  // the first click doesn't take affect
  previewUri.value = event.uri
  previewType.value = event.type
  previewItem.value = event.item
}

const closePreviewPanel = () => {
  previewUri.value = null
  previewType.value = null
  previewItem.value = null
}
</script>
<template>
  <div class="h-[85vh] w-[60vw] flex flex-col print:hidden">
    <!-- nunununununununun -->
    <!--   Search Box      -->
    <!-- nunununununununun -->
    <div class="grow-0 flex justify-center items-center my-4">
      <div class="w-100 h-10">
        <ais-search-box
          placeholder="Search for yacht..."
          submit-title="Submit"
          reset-title="clear"
          :autofocus="true"
          :show-loading-indicator="false"
          :class-names="{
            'ais-SearchBox': 'h-full',
            'ais-SearchBox-form':
              'px-4 h-full flex gap-x-2 relative border border-gray-100 rounded-lg focus-within:border-primary-600 dark:bg-gray-700 dark:focus-within:border-primary-500 dark:border-gray-600',
            'ais-SearchBox-input':
              'order-2 p-0 h-full w-full z-20 text-sm placeholder-gray-500 text-gray-900 outline-none border-none focus:ring-0 focus:border-0 focus:outline-0 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700',
            'ais-SearchBox-reset':
              'self-center order-last z-20 bg-gray-400 p-1 rounded-full text-xxxs fill-white dark:bg-gray-700',
            'ais-SearchBox-loadingIcon': 'hidden',
          }"
          @input="closePreviewPanel"
        >
          <template v-slot:submit-icon>
            <SolidSearch
              class="order-first solid-search z-20 pointer-events-none w-5 h-5 stroke-2 fill-gray-500 group-hover:fill-white"
            />
          </template>
        </ais-search-box>
      </div>
    </div>

    <!-- Search hits container -->
    <div class="grow grid grid-cols-[1fr_max-content] overflow-x-hidden rounded-lg bg-gray-100 dark:bg-gray-700">
      <section class="flex flex-col grow p-4 overflow-hidden">
        <!-- Search Hits -->
        <ais-configure :hitsPerPage="20" facetFilters='[["type:vessel"]]' filters="NOT tags:tombstone" page="0" />
        <!-- Setup results ("hits") -->
        <ais-infinite-hits class="flex-grow overflow-hidden" :transform-items="accumulateSearchResults">
          <template v-slot="{ refineNext, isLastPage, results }">
            <div class="text-sm font-normal mb-5 text-gray-500 dark:text-gray-400">
              {{ results.nbHits }}
              <span v-if="results.nbHits === 1">result</span>
              <span v-else>results</span>
            </div>

            <div v-if="results.nbHits" class="flex h-[calc(100%-2.5rem)]">
              <Splitpanes class="default-theme">
                <Pane v-if="previewUri !== null && previewType !== null" min-size="40" max-size="70">
                  <!-- Preview Panel -->
                  <PreviewTemplateVessel
                    :uri="previewUri"
                    :type="previewType"
                    :is-selected="false"
                    @preview:panel:close="closePreviewPanel"
                  />
                </Pane>
                <Pane class="h-full overflow-hidden !overflow-y-auto">
                  <!-- Search Hits list -->
                  <SearchHitsList
                    :hits="river"
                    :preview-uri="previewUri"
                    :added-items="[]"
                    :allowed-actions="[]"
                    :tombstones="[]"
                    @preview:panel:open="openPreviewPanel"
                  ></SearchHitsList>
                  <template v-if="!isLastPage">
                    <VisibleObserver @visible="refineNext" />
                  </template>
                  <div v-else="isLastPage" class="mt-8 text-center text-sm">
                    You have reached the end of the results!
                  </div>
                </Pane>
              </Splitpanes>
            </div>

            <div v-if="results.nbHits === 0" class="flex mt-5 justify-center h-full rounded-lg bg-gray-100">
              <div class="text-center text-gray-500">
                <h5 class="text-sm font-semibold">0 results</h5>
                <p class="text-xs">No results for your search keyword.</p>
              </div>
            </div>
          </template>
        </ais-infinite-hits>
      </section>
    </div>

    <!-- Modal actions -->
    <div class="flex justify-end items-center space-x-2 rounded-b pt-5">
      <Button
        name="Create Blank"
        :isPrimary="false"
        :has-border="true"
        @click.stop="emit('confirm:modal', null)"
      ></Button>
      <Button
        name="Use Selected"
        isPrimary
        :disabled="!previewUri"
        @click.stop="emit('confirm:modal', previewUri)"
      ></Button>
    </div>
  </div>
</template>
<style lang="scss">
.splitpanes.default-theme {
  .splitpanes__splitter {
    background: none;
    margin: 0 1rem;
    border-left: none;
  }
  .splitpanes__pane {
    background-color: #ffffff;
    border-radius: 0.25rem;
  }
}
</style>
