<script setup lang="ts">
import { LayoutTemplate } from '@/sections/types'
import { OutlineCalendar } from '@ankor-io/icons/outline'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { SummarySectionEditorData } from '@/sections/summary/SummarySectionEditor'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE } from '@/utils/growthbook/constants'

type Props = {
  id: string
  data: SummarySectionEditorData
  layout: LayoutTemplate
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<SummarySectionEditorData>): void
}>()

const update = (event: string): void => {
  emit('update:value', { sectionId: props.id, data: { summaryTitle: event } })
}

const setTextAlignment = (): string => {
  if (props.layout?.options?.alignment === 'right') {
    return 'text-right'
  }

  if (props.layout?.options?.alignment === 'left') {
    return 'text-left'
  }

  return 'text-center'
}
</script>
<template>
  <div
    class="itinerary-summary w-full flex justify-center p-4"
    :class="[
      setTextAlignment,
      $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-8' : 'sm:p-8',
    ]"
  >
    <div class="max-w-screen-xl w-full border-b border-b-gray-200 flex justify-center pb-9 gap-x-2.5">
      <OutlineCalendar class="w-6 h-6" />
      <h2 class="text-xl">
        <SingleLineTextEditor
          placeholder="Enter a summary title"
          :value="props.data.summaryTitle"
          @update:value="update"
        />
      </h2>
    </div>
  </div>
</template>
