import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M6.35159 9.24841C6.12662 9.02338 6.00024 8.71821 6.00024 8.40001C6.00024 8.08181 6.12662 7.77665 6.35159 7.55161L11.1516 2.75161C11.3766 2.52665 11.6818 2.40027 12 2.40027C12.3182 2.40027 12.6234 2.52665 12.8484 2.75161L17.6484 7.55161C17.867 7.77793 17.9879 8.08106 17.9852 8.39569C17.9825 8.71033 17.8563 9.0113 17.6338 9.23379C17.4113 9.45628 17.1103 9.58249 16.7957 9.58522C16.481 9.58795 16.1779 9.467 15.9516 9.24841L13.2 6.49681V20.4C13.2 20.7183 13.0736 21.0235 12.8485 21.2485C12.6235 21.4736 12.3182 21.6 12 21.6C11.6817 21.6 11.3765 21.4736 11.1515 21.2485C10.9264 21.0235 10.8 20.7183 10.8 20.4V6.49681L8.04839 9.24841C7.82335 9.47338 7.51818 9.59976 7.19999 9.59976C6.88179 9.59976 6.57662 9.47338 6.35159 9.24841V9.24841Z",
      "clip-rule": "evenodd"
    })
  ]))
}