/**
 * Get a presigned url to perform a file upload
 *
 * @param filePath the relative file path
 * @param uri the uri to store this image in
 * @param token the authenticated user token
 * @returns a promise with a signed url and uri or an empty strings when the backend failed
 */
export const requestSignedUrl = async (
  filePath: string,
  uri: string,
  token: string,
): Promise<{ uri: string; url: string }> => {
  const response = await fetch('/api/assets/upload', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ path: filePath, uri: uri }),
  })

  if (response.status === 200) {
    const json: {
      url: string
      uri: string
    } = await response.json()
    return Promise.resolve({ uri: json.uri, url: json.url })
  }

  // something went wrong, console the error
  // and return an empty string
  console.error(response.json())
  return Promise.resolve({ uri: '', url: '' })
}

/**
 * Get a presigned url to perform a file upload
 *
 * @param filePath the relative file path
 * @param uri the uri to store this image in
 * @param token the authenticated user token
 * @returns a promise with a signed url and the new assets's mediaUri or an empty strings when the backend failed
 */
export const _requestSignedUrl = async (
  uri: string,
  token: string,
): Promise<{ mediaUri: string; signedUrl: string }> => {
  const response = await fetch(`/media/upload/${uri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    const json: {
      signedUrl: string
      mediaUri: string
    } = await response.json()
    return Promise.resolve(json)
  }

  // something went wrong, console the error
  // and return an empty string
  console.error(response.json())
  return Promise.resolve({ mediaUri: '', signedUrl: '' })
}

/**
 * Saves a file using a presigned url
 *
 * @param preSignedUrl the presigned url to store the file at
 * @param file the file to store
 * @param token an optional parameter only required in localhost
 * @returns a promise with true when suceesfull or false when a failure happens
 */
export const uploadFile = async (preSignedUrl: string, file: File): Promise<boolean> => {
  const response = await fetch(preSignedUrl, {
    method: 'PUT',
    headers: {
      'Content-type': file.type,
    },
    body: file,
  })
    .then((data) => {
      if (data.status === 200) {
        return true
      }

      return false
    })
    .catch((error) => {
      console.log('uploadFile error:', error)
      return false
    })

  return Promise.resolve(response)
}
