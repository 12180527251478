<script setup lang="ts">
import { computed } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { URIContext } from '@ankor-io/common/uri/Uri'
import { MediaUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { formatLength } from '@ankor-io/common/vessel/Formatter'
import { OutlineAdd, OutlineCalendar, OutlinePerson, OutlineSleeping } from '@ankor-io/icons/outline'

import Badge from '@/components/Badge.vue'
import CustomBanner from '@/components/card/CustomBanner.vue'
import { WHEELHOUSE_20240228_VESSEL_SLIDE } from '@/utils/growthbook/constants'

type VesselCardProps = {
  /**
   * Defines if the card has been added to the cart or not
   */
  isAdded: boolean
  /**
   * indexed item of type vessel from the hits
   */
  indexItem: VesselIndexItem
  /**
   * allowed actions in card
   */
  allowedActions: string[]
}

const props = defineProps<VesselCardProps>()

defineEmits<{
  (e: 'proposal:item:add'): void
}>()

// Remove the check for Array when types get updated to be always string[] for line_3 and line_4
const bedsPeopleCount = computed(() => {
  const { line_3 = [], line_4 = [] } = props.indexItem
  const beds = Array.isArray(line_3) ? line_3.join(', ') : line_3
  const people = Array.isArray(line_4) ? line_4.join(', ') : line_4
  return {
    beds,
    people,
  }
})

const mediaUri = computed(() => {
  if (!props.indexItem.hero) {
    return ''
  }
  return props.indexItem.hero.startsWith(`${URIContext.MEDIA}::`)
    ? props.indexItem.hero
    : new MediaUriBuilder().build(props.indexItem.uri, props.indexItem.hero)
})
</script>
<template>
  <figure class="hide-on-transit relative w-full h-48">
    <!-- custom banner -->
    <CustomBanner v-if="props.indexItem.tags?.includes('custom')" />

    <div class="h-full grid grid-rows-[minmax(0,1fr)_3.2rem]">
      <!-- card content -->
      <div class="relative flex">
        <AssetViewerPlaceholder
          class="w-full h-full object-cover"
          :url="`/media/${mediaUri}`"
          :widthDescriptors="['320w']"
        />
      </div>
      <figcaption class="flex px-2 py-1 items-start bg-white dark:bg-gray-800">
        <div class="w-full font-medium">
          <h3
            class="text-left text-lg mb-1 leading-tight line-clamp-1 text-gray-900 dark:text-gray-50"
            v-html="props.indexItem.line_1"
          ></h3>
          <div class="flex flex-row gap-x-2.5 text-xs text-gray-500 dark:text-gray-400">
            <span v-if="props.indexItem.line_2">{{ formatLength(props.indexItem.line_2!) }}</span>
            <div v-if="bedsPeopleCount.beds" class="flex self-start gap-x-1.5">
              <OutlineSleeping class="w-4" />
              <span>{{ bedsPeopleCount.beds }}</span>
            </div>
            <div v-if="bedsPeopleCount.people" class="flex self-start gap-x-1.5">
              <OutlinePerson class="w-4" />
              <span>{{ bedsPeopleCount.people }}</span>
            </div>
            <div v-if="props.indexItem?.vessel?.blueprint?.builtYear" class="flex self-start gap-x-1.5">
              <OutlineCalendar class="w-4" />
              <span>{{ props.indexItem?.vessel?.blueprint?.builtYear }}</span>
            </div>
          </div>
        </div>

        <button
          v-if="props.allowedActions.includes('add') && $growthbook.isOn(WHEELHOUSE_20240228_VESSEL_SLIDE)"
          name="add"
          type="button"
          class="relative group rounded-lg p-1 border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-700 active:bg-gray-100 focus:bg-gray-100 dark:focus:border-gray-600 dark:active:border-gray-600"
          :class="{ 'opacity-0': props.isAdded }"
          :disabled="props.isAdded"
          @click.stop="$emit('proposal:item:add')"
        >
          <OutlineAdd class="w-3.5 h-3.5 text-gray-900 dark:text-white" />
        </button>
      </figcaption>
    </div>
    <!-- added badge -->
    <Badge text="Added" :show="props.isAdded" />
  </figure>
</template>
