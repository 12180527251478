<script lang="ts" setup>
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { SolidPlus } from '@ankor-io/icons/solid'

// import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import SpecificationItemEditor from './SpecificationItemEditor.vue'
import { KeyValueSpecification, VesselSpecificationsSectionData } from './types/types'

type Props = {
  id: string
  proposalUri: string
  data: VesselSpecificationsSectionData
  layout: LayoutTemplate
  isHydrating: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselSpecificationsSectionData>): void
}>()

const addNewSpecItem = () => {
  const data: VesselSpecificationsSectionData = ObjectUtil.deepCopy(props.data)
  data.specifications.push({
    key: '',
    value: '',
  })
  emit('update:value', { sectionId: props.id, data })
}

const updateSpecItem = (
  index: number,
  event: { key: keyof KeyValueSpecification; value: string; arrIndex?: number },
): void => {
  const data: VesselSpecificationsSectionData = ObjectUtil.deepCopy(props.data)
  const specification = data.specifications[index]
  // For values that are string[]
  if (event.key === 'value' && typeof specification.value === 'object' && event.arrIndex !== undefined) {
    // Text input does not exist as part of the current data
    if (event.arrIndex === specification.value.length && !event.value) {
      return
    }

    // Remove the value if the value is empty
    if (!event.value) {
      specification.value.splice(event.arrIndex, 1)
    } else {
      if (specification.value[event.arrIndex] === event.value) {
        return
      }

      specification.value[event.arrIndex] = event.value
    }
  } else {
    if (specification[event.key] === event.value) {
      return
    }

    specification[event.key] = event.value
  }

  emit('update:value', { sectionId: props.id, data })
}

const deleteSpecItem = (index: number): void => {
  const data: VesselSpecificationsSectionData = ObjectUtil.deepCopy(props.data)
  data.specifications.splice(index, 1)
  emit('update:value', { sectionId: props.id, data })
}
</script>
<template>
  <div v-if="props.isHydrating" class="h-44 flex items-center justify-center">
    <Spinner />
  </div>
  <div v-else id="vessel-specifications-section" class="mx-auto max-w-screen-xl p-4 @sm:p-8">
    <h1 class="font-bold text-3xl text-center mb-4">
      <SingleLineTextEditor value="Specifications" :is-editable="false" />
    </h1>
    <div class="mb-4 grid gap-2 grid-cols-2 @sm:grid-cols-4 @md:grid-cols-5 @lg:grid-cols-6 text-left">
      <SpecificationItemEditor
        v-for="(specification, index) in props.data.specifications"
        :specification="specification"
        :key="`${index}-specification-item-editor`"
        @delete:specification="deleteSpecItem(index)"
        @update:value="updateSpecItem(index, $event)"
      />
    </div>
    <button
      id="add-new-specification"
      class="px-3 py-2 flex flex-nowrap gap-x-2 h-full border text-primary-700 border-primary-600 hover:bg-primary-300 rounded-lg"
      @click="addNewSpecItem()"
    >
      <SolidPlus class="w-4 h-4 shrink-0 self-center fill-primary-600" />
      <span class="font-medium text-xs">Add feature</span>
    </button>
  </div>
</template>
