import { Component } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { IUri, URIEntity } from '@ankor-io/common/uri/Uri'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'

import ProfileCompanyLiteSectionVue from '@/sections/profile-company/ProfileCompanyLiteSection.vue'
import { AbstractSection } from '@/services/proposal/Section'
import { profileCompanySectionLiteMapper } from '@/sections/profile-company/mapper'
import { Template } from '@ankor-io/common/proposal/Proposal'

export type ProfileCompanySectionData = {
  givenName: string | null
  surname: string | null
  image: string | null
  role: string | null
  email: string | null
  phone: string | null
  website: string | null
  companyImage: string | null
  companyName: string | null
  companyAddress: string | null
  companyPhone: string | null
}

export class ProfileCompanyLiteSection extends AbstractSection<ProfileCompanySectionData> {
  public onAttached(): void {
    this.data = this.getProxy<ProfileCompanySectionData>()
  }

  getType(): SectionType {
    return SectionType.PROFILE_COMPANY
  }

  getComponent(): Component {
    return ProfileCompanyLiteSectionVue
  }

  dataFrom<A extends { uri: string; [key: string]: any }>(_source: A): ProfileCompanySectionData {
    const _uri: IUri = new GenericUriParser().parse(_source.uri)
    if (_uri.getEntity() === URIEntity.PROPOSAL) {
      return profileCompanySectionLiteMapper.from(_source.template as unknown as Template)
    }
    throw new Error(`Invalid source not supported for ProfileCompanyLiteSection: ${_source.uri}`)
  }
}
