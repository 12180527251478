export const applyDragCursor = () => {
  if (document.getElementsByTagName('body')[0].classList.contains('grabbable')) {
    removeDragCursor()
  }

  // Safety against race condition of datatransfer cursor taking over
  setTimeout(() => {
    document.getElementsByTagName('body')[0].classList.add('grabbable')
  }, 20)
}

export const removeDragCursor = () => {
  document.getElementsByTagName('body')[0].classList.remove('grabbable')
}
