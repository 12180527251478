import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.09091",
      d: "M8.72727 5.45454H19.6364"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.09091",
      d: "M8.72727 12H19.6364"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.09091",
      d: "M8.72727 18.5455H19.6364"
    }),
    _createElementVNode("path", { d: "M5.45455 13.0909C6.05704 13.0909 6.54546 12.6025 6.54546 12C6.54546 11.3975 6.05704 10.9091 5.45455 10.9091C4.85206 10.9091 4.36364 11.3975 4.36364 12C4.36364 12.6025 4.85206 13.0909 5.45455 13.0909Z" }),
    _createElementVNode("path", { d: "M5.45455 19.6364C6.05704 19.6364 6.54546 19.1479 6.54546 18.5455C6.54546 17.943 6.05704 17.4545 5.45455 17.4545C4.85206 17.4545 4.36364 17.943 4.36364 18.5455C4.36364 19.1479 4.85206 19.6364 5.45455 19.6364Z" }),
    _createElementVNode("path", { d: "M5.45455 6.54545C6.05704 6.54545 6.54546 6.05704 6.54546 5.45455C6.54546 4.85205 6.05704 4.36364 5.45455 4.36364C4.85206 4.36364 4.36364 4.85205 4.36364 5.45455C4.36364 6.05704 4.85206 6.54545 5.45455 6.54545Z" })
  ]))
}