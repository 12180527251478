import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import SpacerVue from '@/sections/spacer/Spacer.vue'
import SpacerConfigurationVue from '@/sections/spacer/SpacerConfiguration.vue'

export class SpacerSectionEditor extends ProposalAbstractEditableSection<any, EditableProposal> {
  async fetchData(): Promise<any> {
    return this.data
  }

  getType(): SectionType {
    return SectionType.SPACER
  }

  getComponent(): Component {
    return SpacerVue
  }

  getConfigurationComponent(): Component | null {
    return SpacerConfigurationVue
  }
}
