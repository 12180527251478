<script setup lang="ts">
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Tabs from '@/components/Tabs.vue'
import OverviewGalleryTab from '@/sections/overview/OverviewGalleryTab.vue'
import { OverviewSectionEditorData } from '@/sections/overview/OverviewSectionEditor'
import OverviewStyleTab from '@/sections/overview/OverviewStyleTab.vue'
import { LayoutTemplate } from '@/sections/types'

type Props = {
  id: string
  uri: string
  proposalUri: string
  data: OverviewSectionEditorData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<OverviewSectionEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const tabs: Ref<string[]> = ref(['Style', 'Gallery'])
const activeTab: Ref<string> = ref('Style')

const update = (event: { field: string; value: string }): void => {
  // deep copy the data
  const data: OverviewSectionEditorData = ObjectUtil.deepCopy(props.data)
  if (event.field === 'images') {
    const images = data.images || []
    data.images = [...images, event.value]
  }

  if (event.field === 'selectedImage') {
    data.selectedImage = event.value
  }

  emit('update:value', { sectionId: props.id, data: data })
}

const deleteImage = (event: { value: string; index: number }): void => {
  // deep copy the data
  const data: OverviewSectionEditorData = ObjectUtil.deepCopy(props.data)
  data.images.splice(event.index, 1)
  emit('update:value', { sectionId: props.id, data: data })
}

const updateLayout = (event: { layout: LayoutTemplate }): void => {
  emit('update:layout', { sectionId: props.id, layout: event.layout })
  // move init selectedImage from OverviewStyleTab to here
  if (event.layout.type === 'image_and_text' && !props.data.selectedImage && props.data.images.length) {
    const data: OverviewSectionEditorData = ObjectUtil.deepCopy(props.data)
    data.selectedImage = data.images[0]
    emit('update:value', { sectionId: props.id, data: data })
  }
}

const updateByReorder = (event: { from: number; to: number; images: string[] }): void => {
  const data: any = ObjectUtil.deepCopy(props.data)
  if (data.images.length === 1 || event.from === event.to) {
    return
  }

  data.images = event.images
  emit('update:value', { sectionId: props.id, data: data })
}
</script>
<template>
  <Tabs :tabs="tabs" @update:activeTab="activeTab = $event">
    <OverviewStyleTab v-show="activeTab === 'Style'" :layout="props.layout" @update:layout="updateLayout" />
    <OverviewGalleryTab
      v-show="activeTab === 'Gallery'"
      :proposalUri="props.proposalUri"
      :images="props.data.images"
      :layout="props.layout"
      @update:value="update"
      @delete:value="deleteImage"
      @update:reorder="updateByReorder"
    />
  </Tabs>
</template>
