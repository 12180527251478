import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M1 26.5L10.7453 16.7547C11.5422 15.958 12.6231 15.5104 13.75 15.5104C14.8769 15.5104 15.9578 15.958 16.7547 16.7547L26.5 26.5M22.25 22.25L25.6203 18.8797C26.4172 18.083 27.4981 17.6354 28.625 17.6354C29.7519 17.6354 30.8328 18.083 31.6297 18.8797L35 22.25M22.25 9.5H22.2713M5.25 35H30.75C31.8772 35 32.9582 34.5522 33.7552 33.7552C34.5522 32.9582 35 31.8772 35 30.75V5.25C35 4.12283 34.5522 3.04183 33.7552 2.2448C32.9582 1.44777 31.8772 1 30.75 1H5.25C4.12283 1 3.04183 1.44777 2.2448 2.2448C1.44777 3.04183 1 4.12283 1 5.25V30.75C1 31.8772 1.44777 32.9582 2.2448 33.7552C3.04183 34.5522 4.12283 35 5.25 35Z"
    })
  ]))
}