<script setup lang="ts">
import { Ref, ref } from 'vue'

type Props = {
  title: string
  value?: string
  readonly?: boolean
  disabled?: boolean
  inputSize?: 'small' | 'medium' | 'large'
  labelSize?: 'small' | 'large'
  backgroundColor: 'bg-gray-100' | 'bg-white'
  textColor?: string
  name?: string
  type?: string
  isValid?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
  disabled: false,
  inputSize: 'medium',
  labelSize: 'small',
  backgroundColor: 'bg-gray-100',
  type: 'text',
  isValid: true,
})
const emit = defineEmits<{
  (e: 'update:value', value: string): void
}>()

const isFocused: Ref<boolean> = ref(false)

const setTextSize = () => {
  if (props.inputSize === 'small') {
    return 'text-sm'
  } else if (props.inputSize === 'medium') {
    return 'text-lg'
  } else {
    return 'text-4xl pt-4'
  }
}

const setFocus = () => {
  isFocused.value = !isFocused.value
}

const handleBlur = (newValue: Event) => {
  const target = newValue.target as HTMLInputElement
  emit('update:value', target.value)
  setFocus()
}
</script>
<template>
  <div class="relative w-full">
    <input
      :type="props.type"
      class="block w-full h-full rounded-lg border-1 appearance-none focus:outline-none focus:ring-0 peer focus:text-primary-600 dark:focus:text-primary-500"
      placeholder=" "
      :name="props.name"
      :disabled="props.disabled"
      :readonly="props.readonly"
      :class="[
        setTextSize(),
        { 'bg-gray-50 dark:bg-gray-800 active:bg-transparent focus:bg-transparent': !props.value },
        props.textColor ? `text-gray-900 dark:text-white border-primary-600` : 'text-gray-900 dark:text-white',
        props.backgroundColor === 'bg-gray-100' ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-900',
        props.isValid
          ? 'border-gray-300 dark:border-gray-600 focus:border-primary-600 dark:focus:border-primary-500'
          : 'border-red-500 dark:border-red-400 bg-red-600 bg-opacity-10',
      ]"
      :value="props.value"
      :id="props.title"
      @blur="handleBlur($event)"
      @focus="setFocus"
      @click.stop="null"
    />
    <label
      class="absolute peer-focus:text-xs font-medium rounded-t text-gray-500 dark:text-gray-400 cursor-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-primary-600 dark:peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      :class="[
        props.value !== '' || isFocused ? props.backgroundColor : 'bg-transparent',
        props.textColor ? props.textColor : '',
        props.backgroundColor === 'bg-gray-100' ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-900',
        props.labelSize === 'small' || props.value ? 'text-xs' : 'text-4xl',
      ]"
      :for="props.title"
    >
      {{ props.title }}
    </label>
  </div>
</template>
