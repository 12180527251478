import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M8.625 11H6.375C6.1679 11 6 11.2238 6 11.5001C6 11.7762 6.1679 12 6.375 12H8.625C8.83211 12 9 11.7762 9 11.5001C9 11.2238 8.83211 11 8.625 11Z" }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M6 2.72728C6 2.32561 6.35818 2 6.8 2H21.1999C21.6419 2 22 2.32562 22 2.72728V9.27269C22 9.67437 21.6419 10 21.1999 10H6.8C6.35818 10 6 9.67437 6 9.27269V2.72728ZM12.1142 4.45642L9.44006 8.20862C9.33658 8.35379 9.45119 8.54542 9.64151 8.54542H18.3517C18.5434 8.54542 18.6577 8.35125 18.5514 8.20622L16.5644 5.49577C16.4698 5.36671 16.2615 5.36608 16.1659 5.49457L14.941 7.14168C14.8462 7.26914 14.64 7.26976 14.5443 7.1429L12.5135 4.45164C12.4166 4.32328 12.2073 4.32578 12.1142 4.45642ZM15.6 4.54544C15.6 4.74627 15.4208 4.90908 15.2 4.90908C14.9791 4.90908 14.7999 4.74627 14.7999 4.54544C14.7999 4.34462 14.9791 4.18181 15.2 4.18181C15.4208 4.18181 15.6 4.34462 15.6 4.54544ZM7.2 2.72728C6.97909 2.72728 6.8 2.89008 6.8 3.09091C6.8 3.29174 6.97909 3.45454 7.2 3.45454H9.6C9.82092 3.45454 9.99998 3.29174 9.99998 3.09091C9.99998 2.89008 9.82092 2.72728 9.6 2.72728H7.2Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M6.9 13C6.40295 13 6 13.3731 6 13.8334V17.1666C6 17.6269 6.40295 18 6.9 18H14.1C14.5971 18 15 17.6269 15 17.1666V13.8334C15 13.3731 14.5971 13 14.1 13H6.9ZM8.04214 16.7133L9.32363 14.7154C9.42673 14.5547 9.67688 14.5518 9.78435 14.7101L10.688 16.0405C10.7943 16.197 11.0408 16.1963 11.1461 16.0391L11.5997 15.3613C11.7057 15.203 11.9548 15.2037 12.0596 15.3626L12.949 16.7107C13.0589 16.8772 12.9294 17.091 12.7186 17.091H8.27428C8.06495 17.091 7.93525 16.88 8.04214 16.7133ZM11.175 14.8183C11.2992 14.8183 11.3999 14.7165 11.3999 14.591C11.3999 14.4655 11.2992 14.3638 11.175 14.3638C11.0507 14.3638 10.95 14.4655 10.95 14.591C10.95 14.7165 11.0507 14.8183 11.175 14.8183Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", { d: "M18.625 13H16.375C16.1679 13 16 13.2239 16 13.5C16 13.7761 16.1679 14 16.375 14H18.625C18.8321 14 19 13.7761 19 13.5C19 13.2239 18.8321 13 18.625 13Z" }),
    _createElementVNode("path", { d: "M21.6667 15H16.3333C16.1492 15 16 15.2238 16 15.4999C16 15.7761 16.1492 16 16.3333 16H21.6667C21.8508 16 22 15.7761 22 15.4999C22 15.2238 21.8508 15 21.6667 15Z" }),
    _createElementVNode("path", { d: "M21.6667 17H16.3333C16.1492 17 16 17.2239 16 17.5C16 17.7761 16.1492 18 16.3333 18H21.6667C21.8508 18 22 17.7761 22 17.5C22 17.2239 21.8508 17 21.6667 17Z" }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M5.89189 1H22.1082C22.6008 1 23 1.39932 23 1.89189V18.1081C23 18.6006 22.6008 19 22.1082 19H5.89189C5.39932 19 5 18.6006 5 18.1081V1.89189C5 1.39932 5.39932 1 5.89189 1ZM5.89189 1.08108C5.4441 1.08108 5.08108 1.44409 5.08108 1.89189V18.1081C5.08108 18.556 5.4441 18.9189 5.89189 18.9189H22.1082C22.556 18.9189 22.9189 18.556 22.9189 18.1081V1.89189C22.9189 1.44409 22.556 1.08108 22.1082 1.08108H5.89189Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M3 3.04451C3 2.46763 3.44303 2 3.98953 2V2.09948C3.49508 2.09948 3.09424 2.52258 3.09424 3.04451V19.9554C3.09424 20.4774 3.49508 20.9005 3.98953 20.9005H20.0105C20.5049 20.9005 20.9057 20.4774 20.9057 19.9554H21C21 20.5324 20.557 21 20.0105 21H3.98953C3.44303 21 3 20.5324 3 19.9554V3.04451Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M1 5.04451C1 4.46763 1.44303 4 1.98953 4V4.09948C1.49508 4.09948 1.09424 4.52258 1.09424 5.04451V21.9554C1.09424 22.4774 1.49508 22.9005 1.98953 22.9005H18.0105C18.5049 22.9005 18.9057 22.4774 18.9057 21.9554H19C19 22.5324 18.557 23 18.0105 23H1.98953C1.44303 23 1 22.5324 1 21.9554V5.04451Z",
      "clip-rule": "evenodd"
    })
  ]))
}