/**
 * Define the layout type for the NavBar section
 */
export type NavBarLayout = {
  type: NavBarTypes
  options?: NavBarOptions
}

export type NavBarTypes = 'default' | 'logo'
export type NavBarOptions = 'left' | 'center' | 'right'

/**
 * Defines the type of tab selection
 */
export type TabSelection = TabSelections.VESSELS | TabSelections.ITINERARIES

export enum TabSelections {
  VESSELS = 'Yachts',
  ITINERARIES = 'Routes',
}
