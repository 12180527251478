<script lang="ts" setup>
import { Ref, onBeforeUpdate, onMounted, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { formatLength } from '@ankor-io/common/vessel/Formatter'
import type { Pricing, Variant, Vessel } from '@ankor-io/common/vessel/types'
import { OutlineBed, OutlineCrew, OutlinePerson } from '@ankor-io/icons/outline/index'
import { SolidArrowNarrowUp } from '@ankor-io/icons/solid'

import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import { useStowageService } from '@/services/stowage/StowageService'

type Props = {
  uri: string
}

const props = defineProps<Props>()

const isLoading: Ref<boolean> = ref(true)
const doc: Ref<Vessel | null> = ref(null)
const uri: Ref<string> = ref('')
const stowageService = useStowageService()
const showAllImages: Ref<boolean> = ref(false)

onMounted(async () => {
  uri.value = props.uri
  isLoading.value = true
  doc.value = (await stowageService.getURIDocument<Vessel>(uri.value)) as Vessel
  isLoading.value = false
})

onBeforeUpdate(async () => {
  if (props.uri !== uri.value) {
    showAllImages.value = false
    uri.value = props.uri
    isLoading.value = true
    doc.value = (await stowageService.getURIDocument<Vessel>(uri.value)) as Vessel
    isLoading.value = false
  }
})

const _formatPrice = (vessel: Vessel | null): string => {
  if (!vessel) {
    return 'Pricing not available'
  }
  const variantWithPrice: Variant | undefined = vessel?.variants
    .filter((variant: Variant) => variant.active)
    .find((v: Variant) => v.pricing && v.pricing.lineItems.length)

  if (!variantWithPrice) {
    return 'Pricing not available'
  }

  const pricing: Pricing | undefined = variantWithPrice.pricing

  if (!pricing) {
    return 'Pricing not available'
  }

  return `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: pricing.currency,
    maximumFractionDigits: 0,
  }).format(
    // the price is in cents, so we divide by 100 to get the price in dollars
    Number(pricing.total) / 100,
  )}`
}
</script>
<template>
  <div v-if="isLoading" class="flex items-center justify-center h-full">
    <Spinner />
  </div>
  <div v-else-if="doc && doc.blueprint" :class="$attrs.class" class="@container px-3 pb-3">
    <h3 id="preview-vessel-name" class="font-bold text-2xl pt-4 pb-2 text-gray-900 dark:text-white">
      {{ doc.blueprint.name }}
    </h3>
    <div class="flex flex-row items-center gap-x-2.5 pb-4 text-gray-900 dark:text-white">
      <span v-if="doc.blueprint.length">{{ formatLength(doc.blueprint.length) }}</span>
      <div v-if="doc.blueprint.cabins" class="flex self-start gap-x-1.5">
        <OutlineBed class="w-4 stroke-[1.5px] stroke-gray-900 dark:stroke-white" />
        <span>{{ doc.blueprint.cabins }}</span>
      </div>
      <div v-if="doc.blueprint.maxCrew" class="flex self-start gap-x-1.5">
        <OutlineCrew class="w-4 stroke-[1.5px]" />
        <span>{{ doc.blueprint.maxCrew }}</span>
      </div>
      <div v-if="doc.blueprint.sleeps" class="flex self-start gap-x-1.5">
        <OutlinePerson class="w-4 stroke-[1.5px]" />
        <span>{{ doc.blueprint.sleeps }}</span>
      </div>
    </div>

    <!-- Vessel images -->
    <div v-if="doc.blueprint.images">
      <p class="text-sm font-semibold pb-3 text-gray-900 dark:text-white">All Photos</p>
      <!-- First image is large -->
      <AssetViewer
        v-if="doc.blueprint.images?.[0]"
        class="w-full h-full rounded"
        :url="`/media/${doc.blueprint.images?.[0]}`"
      />

      <!-- Remaining images are flexed -->
      <div
        v-show="doc.blueprint.images.length >= 10 && !showAllImages"
        class="grid grid-cols-2 @sm:grid-cols-4 my-2 gap-2 mx-auto"
      >
        <div v-for="f in doc.blueprint.images.slice(1, 9)" class="h-28" :key="f">
          <AssetViewer v-if="f" class="w-full rounded object-cover" :url="`/media/${f}`" />
        </div>
      </div>
      <div v-show="showAllImages" class="grid grid-cols-2 @sm:grid-cols-4 my-2 gap-2 mx-auto">
        <div v-for="f in doc.blueprint.images.slice(1)" class="h-28" :key="f">
          <AssetViewer v-if="f" class="w-full rounded object-cover" :url="`/media/${f}`" />
        </div>
      </div>
      <div class="flex justify-center items-center">
        <Button
          position="prefix"
          :name="showAllImages ? `Close Images` : `See All Images`"
          :hasBorder="true"
          :isPrimary="false"
          @click="showAllImages = !showAllImages"
        >
          <SolidArrowNarrowUp
            class="w-5 h-5 mr-2 self-center transition-all duration-300 fill-primary-600 dark:fill-white"
            :class="{ 'rotate-180': !showAllImages }"
          />
        </Button>
      </div>
    </div>

    <!-- Vessel desc -->
    <div v-if="doc.description">
      <!-- Overview -->
      <p class="text-sm font-semibold pb-3 text-gray-900 dark:text-white">Boat Overview</p>
      <p class="text-sm font-normal text-gray-500 dark:text-gray-400" v-html="doc.description"></p>
      <hr class="h-px my-4 border-0 bg-gray-200" />

      <!-- Cost and fees -->
      <section class="flex flex-col gap-y-3 text-sm">
        <p class="font-semibold text-gray-900 dark:text-white">Cost and Fees</p>
        <div class="flex gap-x-1">
          <span class="font-semibold text-gray-500 dark:text-gray-400">Price:</span>
          <span class="text-gray-900 dark:text-white">{{ _formatPrice(doc) }}</span>
        </div>
      </section>
      <hr class="h-px my-4 border-0 bg-gray-200" />

      <!-- Specifications -->
      <div class="text-sm font-semibold pb-3 text-gray-900 dark:text-white">Specifications</div>
      <div class="grid grid-cols-2 @sm:grid-cols-4 gap-x-4 mx-auto">
        <div class="text-sm grid grid-rows-4 grid-flow-col gap-y-5 mx-0">
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Boat type:</span>
            <span class="text-gray-900 dark:text-white">{{ doc.type?.join(', ') || `-` }}</span>
          </div>
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Build year:</span>
            <span class="text-gray-900 dark:text-white">{{ doc.blueprint.builtYear || `-` }}</span>
          </div>
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Decks:</span>
            <span class="text-gray-900 dark:text-white">
              {{ doc.blueprint.decks !== undefined ? doc.blueprint.decks : `-` }}
            </span>
          </div>
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Refit year:</span>
            <span class="text-gray-900 dark:text-white">{{ doc.blueprint.refitYear || `-` }}</span>
          </div>
        </div>
        <div class="text-sm grid grid-rows-4 grid-flow-col gap-y-5 mx-0">
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Length:</span>
            <span class="text-gray-900 dark:text-white">{{ formatLength(doc.blueprint.length) }}</span>
          </div>
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Cruising speed:</span>
            <span class="text-gray-900 dark:text-white">
              {{ doc.blueprint.cruiseSpeed ? doc.blueprint.cruiseSpeed + 'kn' : `-` }}
            </span>
          </div>
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Top speed:</span>
            <span class="text-gray-900 dark:text-white">
              {{ doc.blueprint.topSpeed ? doc.blueprint.topSpeed + 'kn' : '-' }}
            </span>
          </div>
          <div class="flex gap-x-1">
            <span class="font-semibold text-gray-500 dark:text-gray-400">Flag:</span>
            <span class="text-gray-900 dark:text-white">{{ doc.blueprint.flag || `-` }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-gray-900 dark:text-white">Vessel not available</div>
</template>
