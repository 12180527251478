<script setup lang="ts">
import { GrowthBook } from '@growthbook/growthbook'
import ls from 'localstorage-slim'
import { inject, onMounted } from 'vue'

import { ProposalItemType } from '@ankor-io/common/proposal/Proposal'

import ItineraryCard from '@/components/search-results/ItineraryCard.vue'
import ProposalCard from '@/components/search-results/ProposalCard.vue'
import VesselCard from '@/components/search-results/VesselCard.vue'
import {
  IndexItemType,
  OpenDuplicateModalEvent,
  PreviewOpenEvent,
  ProposalItemAddEvent,
} from '@/components/search-results/types'
import { WHEELHOUSE_20240228_VESSEL_SLIDE } from '@/utils/growthbook/constants'

type SearchHitsListProps = {
  hits: Array<IndexItemType>
  addedItems: string[]
  previewUri: string | null
  allowedActions: string[]
  tombstones: string[]
}

const props = defineProps<SearchHitsListProps>()
const emit = defineEmits<{
  (e: 'preview:panel:open', value: PreviewOpenEvent): void
  (e: 'open:duplicate:modal', value: OpenDuplicateModalEvent): void
  (e: 'proposal:item:add', value: ProposalItemAddEvent): void
  (e: 'hits:mounted'): void
  (e: 'item:delete', value: string): void
}>()

const growthbook: GrowthBook = inject('growthbook')!

onMounted(() => {
  emit('hits:mounted')
})

const openPreviewPanel = (item: IndexItemType): void => {
  emit('preview:panel:open', { uri: item.uri, type: item.type, item })
}

const openDuplicateModal = (
  entity: ProposalItemType.ITINERARY | ProposalItemType.PROPOSAL | ProposalItemType.YACHT | ProposalItemType.VESSEL,
  uri: string,
  internalName: string = '',
  externalName: string = '',
) => {
  emit('open:duplicate:modal', { entity, uri, internalName, externalName })
}

const addProposalItem = (
  entity: ProposalItemType.ITINERARY | ProposalItemType.PROPOSAL | ProposalItemType.YACHT | ProposalItemType.VESSEL,
  item: IndexItemType,
) => {
  emit('proposal:item:add', { type: entity, uri: item.uri, item: item })
}

const isProposalItemDraggable = (type: ProposalItemType): boolean => {
  if (type === ProposalItemType.VESSEL) {
    return growthbook.isOn(WHEELHOUSE_20240228_VESSEL_SLIDE)
  }
  return true
}

const mergeWithLocalStorage = (item: IndexItemType): IndexItemType => {
  const partial: Partial<IndexItemType> | null = ls.get<IndexItemType>(item.uri)
  if (partial) {
    return Object.assign({}, item, partial)
  }
  return item
}
</script>
<template>
  <div
    id="hits-list"
    class="grid grid-cols-[repeat(auto-fit,minmax(18.75rem,1fr))] grid-rows-[repeat(auto-fill,minmax(min-content,12rem))] gap-x-5 gap-y-[3.75rem] bg-gray-100 dark:bg-gray-900"
  >
    <div
      v-for="item of props.hits.map(mergeWithLocalStorage)"
      :key="item.objectID"
      class="hits-list-card h-48 w-[clamp(18.75rem,100%,26.25rem)] rounded shadow-md overflow-hidden bg-white dark:bg-gray-500"
      :class="[
        {
          'border-2 border-primary-300 dark:border-primary-400': previewUri === item.uri,
          hidden: ls.get<string>(`tombstone::${item.uri}`) !== null || props.tombstones.includes(item.uri),
        },
        { 'cursor-pointer': item.type !== ProposalItemType.VESSEL },
      ]"
      :data-uri="item.uri"
      :draggable="isProposalItemDraggable(item.type)"
    >
      <VesselCard
        v-if="item.type === ProposalItemType.VESSEL"
        :indexItem="item"
        :isAdded="!!props.addedItems && props.addedItems.includes(item.uri)"
        :allowed-actions="props.allowedActions"
        @open:duplicate:modal="openDuplicateModal(ProposalItemType.VESSEL, item.uri, item.line_1, item.line_2)"
        @proposal:item:add="addProposalItem(ProposalItemType.VESSEL, item)"
        @item:delete="$emit('item:delete', item.uri)"
        @click.stop="openPreviewPanel(item)"
      />
      <ItineraryCard
        v-if="item.type === ProposalItemType.ITINERARY"
        :indexItem="item"
        :isAdded="!!props.addedItems && props.addedItems.includes(item.uri)"
        :allowed-actions="props.allowedActions"
        @open:duplicate:modal="openDuplicateModal(ProposalItemType.ITINERARY, item.uri, item.line_1, item.line_2)"
        @proposal:item:add="addProposalItem(ProposalItemType.ITINERARY, item)"
        @item:delete="$emit('item:delete', item.uri)"
        @click.stop="openPreviewPanel(item)"
      />
      <ProposalCard
        v-if="item.type === ProposalItemType.PROPOSAL"
        :indexItem="item"
        :isAdded="!!props.addedItems && props.addedItems.includes(item.uri)"
        :allowed-actions="props.allowedActions"
        @open:duplicate:modal="openDuplicateModal(ProposalItemType.PROPOSAL, item.uri, item.line_1, item.line_2)"
        @proposal:item:add="addProposalItem(ProposalItemType.PROPOSAL, item)"
        @item:delete="$emit('item:delete', item.uri)"
        @click.stop="openPreviewPanel(item)"
      />
    </div>
  </div>
</template>
