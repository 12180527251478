import { Component } from 'vue'

import { UUID } from '@ankor-io/common/lang/uuid'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import DisclaimersConfigurationVue from '@/sections/disclaimers/DisclaimersConfiguration.vue'
import DisclaimersSectionEditorVue from '@/sections/disclaimers/DisclaimersSectionEditor.vue'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import { DisclaimersSectionData } from './types/types'

export class DisclaimersSectionEditor extends ProposalAbstractEditableSection<
  DisclaimersSectionData,
  EditableProposal
> {
  async fetchData(): Promise<DisclaimersSectionData> {
    return {
      disclaimers: [
        {
          id: UUID.timeBased(),
          type: 'VAT (Value Added Tax)',
          disclaimer:
            'Please be aware that the prices quoted for the yacht charter may not include Value Added Tax (VAT). VAT rates and applicability vary depending on the jurisdiction in which the charter takes place, which may include multiple locations should your charter include different countries. If applicable, the VAT will be added to the charter fee and any other taxable services or goods provided during the charter. Details of the applicable VAT will be confirmed in the charter contract, however, may be subject to change without notice should your route change.',
        },
      ],
    }
  }

  getType(): SectionType {
    return SectionType.DISCLAIMERS
  }

  getComponent(): Component {
    return DisclaimersSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return DisclaimersConfigurationVue
  }
}
