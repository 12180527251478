import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M12 21.6C14.5461 21.6 16.9879 20.5886 18.7883 18.7883C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45395 20.5886 7.01215 18.7883 5.2118C16.9879 3.41145 14.5461 2.40002 12 2.40002C9.45395 2.40002 7.01215 3.41145 5.2118 5.2118C3.41145 7.01215 2.40002 9.45395 2.40002 12C2.40002 14.5461 3.41145 16.9879 5.2118 18.7883C7.01215 20.5886 9.45395 21.6 12 21.6V21.6ZM16.4484 10.4484C16.667 10.2221 16.788 9.91898 16.7852 9.60434C16.7825 9.28971 16.6563 8.98873 16.4338 8.76624C16.2113 8.54375 15.9103 8.41755 15.5957 8.41482C15.2811 8.41208 14.9779 8.53304 14.7516 8.75162L10.8 12.7032L9.24842 11.1516C9.0221 10.933 8.71898 10.8121 8.40434 10.8148C8.08971 10.8176 7.78873 10.9438 7.56624 11.1662C7.34375 11.3887 7.21755 11.6897 7.21482 12.0043C7.21208 12.319 7.33304 12.6221 7.55162 12.8484L9.95162 15.2484C10.1767 15.4734 10.4818 15.5998 10.8 15.5998C11.1182 15.5998 11.4234 15.4734 11.6484 15.2484L16.4484 10.4484V10.4484Z",
      "clip-rule": "evenodd"
    })
  ]))
}