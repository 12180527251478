import { Component } from 'vue'

import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import ImageSectionVue from './ImageSection.vue'

export interface ImageSectionData {
  images: string[]
  image: string | null
}

export class ImageSection extends AbstractSection<ImageSectionData> {
  public onAttached(): void {
    const proxy = this.getProxy<ImageSectionData>()
    this.data.images = proxy?.images || []
    this.data.image = proxy?.image || null
  }

  getType(): SectionType {
    return SectionType.IMAGE
  }

  getComponent(): Component {
    return ImageSectionVue
  }
}
