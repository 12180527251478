import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import HeroSectionVue from '@/sections/hero/HeroSection.vue'

export interface HeroSectionData {
  heading: string
  subheading: string
  images: string[]
  heroImages: string[]
}

// TODO: Implement the readonly ProposalAbstractSection when readonly proposal is merged
export class HeroSection extends AbstractSection<HeroSectionData> {
  getType(): SectionType {
    return SectionType.ITINERARY_HERO
  }

  getComponent(): Component {
    return HeroSectionVue
  }
}
