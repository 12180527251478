import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue'

export default function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        fill: 'currentColor',
        'aria-hidden': 'true',
        viewBox: '0 0 24 24',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          'fill-rule': 'evenodd',
          d: 'M12 10.8C12.9548 10.8 13.8704 10.4207 14.5456 9.74559C15.2207 9.07046 15.6 8.15479 15.6 7.20001C15.6 6.24523 15.2207 5.32955 14.5456 4.65442C13.8704 3.97929 12.9548 3.60001 12 3.60001C11.0452 3.60001 10.1295 3.97929 9.45439 4.65442C8.77926 5.32955 8.39998 6.24523 8.39998 7.20001C8.39998 8.15479 8.77926 9.07046 9.45439 9.74559C10.1295 10.4207 11.0452 10.8 12 10.8V10.8ZM3.59998 21.6C3.59998 20.4969 3.81725 19.4046 4.23939 18.3855C4.66153 17.3663 5.28027 16.4403 6.06028 15.6603C6.84029 14.8803 7.7663 14.2616 8.78543 13.8394C9.80457 13.4173 10.8969 13.2 12 13.2C13.1031 13.2 14.1954 13.4173 15.2145 13.8394C16.2337 14.2616 17.1597 14.8803 17.9397 15.6603C18.7197 16.4403 19.3384 17.3663 19.7606 18.3855C20.1827 19.4046 20.4 20.4969 20.4 21.6H3.59998Z',
          'clip-rule': 'evenodd',
        }),
      ],
    )
  )
}
