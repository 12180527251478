import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M12.5384 15.75C12.5384 14.2312 13.7696 13 15.2884 13H17C17.2761 13 17.5 13.2239 17.5 13.5V14.5C17.5 14.7761 17.2761 15 17 15H15.2884C14.8742 15 14.5384 15.3358 14.5384 15.75C14.5384 16.1642 14.8742 16.5 15.2884 16.5H18.25C19.7688 16.5 21 17.7312 21 19.25C21 20.7688 19.7688 22 18.25 22H7C6.72386 22 6.5 21.7761 6.5 21.5V20.5C6.5 20.2239 6.72386 20 7 20H18.25C18.6642 20 19 19.6642 19 19.25C19 18.8358 18.6642 18.5 18.25 18.5H15.2884C13.7697 18.5 12.5384 17.2688 12.5384 15.75Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M14.3181 3.33452C15.162 2.48004 16.3066 2 17.5 2C18.6935 2 19.8381 2.48004 20.682 3.33452C21.5259 4.18901 22 5.34793 22 6.55635C22 7.76477 21.5259 8.92369 20.682 9.77817L17.5 13L14.3181 9.77817C13.9002 9.35509 13.5687 8.85281 13.3426 8.30001C13.1164 7.7472 13 7.15471 13 6.55635C13 5.95799 13.1164 5.3655 13.3426 4.81269C13.5687 4.25989 13.9002 3.7576 14.3181 3.33452ZM17.5 7.8581C17.841 7.8581 18.168 7.72095 18.4091 7.47682C18.6502 7.2327 18.7857 6.90159 18.7857 6.55635C18.7857 6.2111 18.6502 5.88 18.4091 5.63587C18.168 5.39175 17.841 5.2546 17.5 5.2546C17.1591 5.2546 16.8321 5.39175 16.591 5.63587C16.3499 5.88 16.2144 6.2111 16.2144 6.55635C16.2144 6.90159 16.3499 7.2327 16.591 7.47682C16.8321 7.72095 17.1591 7.8581 17.5 7.8581Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M3.3181 11.2132C4.16201 10.4364 5.30659 10 6.50005 10C7.69351 10 8.83809 10.4364 9.68199 11.2132C10.5259 11.99 11 13.0436 11 14.1421C11 15.2407 10.5259 16.2943 9.68199 17.0711L6.50005 20L3.3181 17.0711C2.90021 16.6864 2.56873 16.2298 2.34256 15.7273C2.1164 15.2247 2 14.6861 2 14.1421C2 13.5982 2.1164 13.0595 2.34256 12.557C2.56873 12.0544 2.90021 11.5978 3.3181 11.2132ZM6.50005 15.3255C6.84102 15.3255 7.16803 15.2009 7.40913 14.9789C7.65023 14.757 7.78568 14.456 7.78568 14.1421C7.78568 13.8283 7.65023 13.5273 7.40913 13.3053C7.16803 13.0834 6.84102 12.9587 6.50005 12.9587C6.15908 12.9587 5.83207 13.0834 5.59097 13.3053C5.34986 13.5273 5.21441 13.8283 5.21441 14.1421C5.21441 14.456 5.34986 14.757 5.59097 14.9789C5.83207 15.2009 6.15908 15.3255 6.50005 15.3255Z",
      "clip-rule": "evenodd"
    })
  ]))
}