import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M18 9.6C18.731 9.60002 19.4447 9.37752 20.0461 8.96208C20.6476 8.54663 21.1083 7.95794 21.3671 7.27428C21.6258 6.59063 21.6703 5.8444 21.4946 5.13484C21.319 4.42528 20.9314 3.78601 20.3836 3.30204C19.8358 2.81807 19.1536 2.51233 18.4278 2.42549C17.702 2.33865 16.967 2.47482 16.3204 2.81588C15.6739 3.15695 15.1465 3.68676 14.8084 4.33485C14.4703 4.98293 14.3375 5.71859 14.4276 6.444L8.49963 9.408C7.9911 8.91762 7.34954 8.5877 6.65486 8.45933C5.96017 8.33096 5.24307 8.40982 4.59289 8.68608C3.9427 8.96234 3.38816 9.42379 2.99835 10.013C2.60855 10.6021 2.4007 11.293 2.4007 11.9994C2.4007 12.7058 2.60855 13.3967 2.99835 13.9858C3.38816 14.575 3.9427 15.0365 4.59289 15.3127C5.24307 15.589 5.96017 15.6678 6.65486 15.5395C7.34954 15.4111 7.9911 15.0812 8.49963 14.5908L14.4276 17.5548C14.3226 18.3975 14.5189 19.2502 14.9818 19.9621C15.4446 20.6741 16.1443 21.1995 16.9571 21.4456C17.7698 21.6916 18.6434 21.6423 19.4234 21.3066C20.2034 20.9708 20.8396 20.3701 21.2196 19.6106C21.5995 18.8512 21.6988 17.9818 21.4997 17.1563C21.3007 16.3307 20.8163 15.6021 20.132 15.0992C19.4478 14.5962 18.6077 14.3514 17.7604 14.4079C16.9131 14.4644 16.113 14.8187 15.5016 15.408L9.57362 12.444C9.60994 12.1491 9.60994 11.8509 9.57362 11.556L15.5016 8.592C16.1472 9.216 17.028 9.6 18 9.6Z" })
  ]))
}