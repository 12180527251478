import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M6.00002 4.8C5.04525 4.8 4.12957 5.17929 3.45444 5.85442C2.77931 6.52955 2.40002 7.44522 2.40002 8.4V15.6C2.40002 16.5548 2.77931 17.4705 3.45444 18.1456C4.12957 18.8207 5.04525 19.2 6.00002 19.2H18C18.9548 19.2 19.8705 18.8207 20.5456 18.1456C21.2207 17.4705 21.6 16.5548 21.6 15.6V8.4C21.6 7.44522 21.2207 6.52955 20.5456 5.85442C19.8705 5.17929 18.9548 4.8 18 4.8H6.00002ZM4.80002 15.6V14.4H10.8V16.8H6.00002C5.68176 16.8 5.37654 16.6736 5.1515 16.4485C4.92645 16.2235 4.80002 15.9183 4.80002 15.6ZM13.2 16.8H18C18.3183 16.8 18.6235 16.6736 18.8486 16.4485C19.0736 16.2235 19.2 15.9183 19.2 15.6V14.4H13.2V16.8ZM13.2 12H19.2V9.6H13.2V12ZM10.8 9.6H4.80002V12H10.8V9.6Z",
      "clip-rule": "evenodd"
    })
  ]))
}