<script setup lang="ts">
import { Ref, ref } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Tabs from '@/components/Tabs.vue'
import { MapSectionData, MapStatisticData } from '@/sections/map/types/types'
import { LayoutTemplate } from '@/sections/types'

import MapSettingsTab from './MapSettingsTab.vue'
import StyleTab from './StyleTab.vue'

type Props = {
  id: string
  uri: string
  data: MapSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: { sectionId: string; data: MapSectionData }): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const tabs: Ref<string[]> = ref(['Style', 'Settings'])
const activeTab: Ref<string> = ref('Style')

/**
 * @param statistics is the updated stat data within the settings tab
 * Update to diff sync the updated statistic data
 * This won't trigger unless the feature toggle is enabled - check MapSettingsTab.vue
 */
const updateValue = (statistics: MapStatisticData): void => {
  const data = ObjectUtil.deepCopy(props.data) as MapSectionData
  data.statistics = statistics
  emit('update:value', { sectionId: props.id, data })
}

/**
 * @param layout receives a newly selected layout
 * Update to diff sync the newly selected layout
 */
const updateLayout = (layout: LayoutTemplate): void => {
  emit('update:layout', { sectionId: props.id, layout: layout })
}
</script>
<template>
  <Tabs :tabs="tabs" @update:activeTab="activeTab = $event">
    <StyleTab v-show="activeTab === 'Style'" :uri="props.uri" :layout="props.layout" @update:layout="updateLayout" />
    <MapSettingsTab
      v-show="activeTab === 'Settings'"
      :layout="props.layout"
      :statistics="props.data.statistics || {}"
      @update:layout="updateLayout"
      @update:value="updateValue"
    />
  </Tabs>
</template>
