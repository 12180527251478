import gql from 'graphql-tag'
import { Component } from 'vue'

import { Itinerary, Segment, Stop } from '@ankor-io/common/itinerary/Itinerary'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import AllDayCardsSectionConfigurationVue from '@/sections/allDayCards/AllDayCardsConfiguration.vue'
import AllDayCardsSectionEditorVue from '@/sections/allDayCards/AllDayCardsSectionEditor.vue'
import { AllDayCardSegment, AllDayCardStop } from '@/sections/allDayCards/Type'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

const QUERY = gql`
  query Itinerary($URI: String!) {
    itinerary(uri: $URI) {
      segments {
        label
        description
        stops {
          place {
            uri
            name
            description
            images
          }
          pathFromPrevious {
            distance {
              value
              unit
            }
          }
        }
      }
    }
  }
`

export interface AllDayCardsSectionEditorData {
  segments: AllDayCardSegment[]
}

export class AllDayCardsSectionEditor extends ProposalAbstractEditableSection<
  AllDayCardsSectionEditorData,
  EditableProposal
> {
  async fetchData(): Promise<AllDayCardsSectionEditorData> {
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: this.getSlideUri() },
        errorPolicy: 'all',
      })
      .then(({ data, errors }) => {
        if (errors) {
          // Log the errors for debugging
          console.debug(errors)
        }
        const _data: { itinerary: Itinerary } = data
        return Promise.resolve({
          segments: _data.itinerary?.segments.map((segment: Segment) => {
            const images: string[] = segment.stops
              .map((stop: Stop) => {
                return stop.place?.images && replacePathToMediaUris(stop.place.uri, ...stop.place.images) || []
              })
              .reduce((acc: string[], comb: string[]) => {
                return acc.concat(comb)
              }, [])

            const dayCardSegment: AllDayCardSegment = {
              images: images,
              heroImage: images.length > 0 ? images[0] : '',
              label: segment.label,
              description: segment.description,
              stops: segment.stops.map((stop: Stop) => {
                const placeImagePaths = stop.place?.images && replacePathToMediaUris(stop.place.uri, ...stop.place.images) || []
                return {
                  ...stop,
                  heroImage: placeImagePaths.length > 0 ? placeImagePaths[1] || placeImagePaths[0] : '',
                  distance:
                    stop.pathFromPrevious && stop.pathFromPrevious.distance
                      ? `${stop.pathFromPrevious.distance.value} ${stop.pathFromPrevious.distance.unit}`
                      : '',
                  place: {
                    ...(stop.place || {}),
                    images: placeImagePaths,
                  },
                } as AllDayCardStop
              }),
            }
            return dayCardSegment
          }),
        })
      })
  }

  getType(): SectionType {
    return SectionType.ALL_DAY_CARDS
  }

  getComponent(): Component {
    return AllDayCardsSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return AllDayCardsSectionConfigurationVue
  }
}
