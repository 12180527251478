import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import NavBarEditorVue from './NavBarEditor.vue'
import NavBarConfiguration from './NavBarConfiguration.vue'

export class NavBarEditor extends ProposalAbstractEditableSection<void, null> {
  async fetchData(): Promise<void> {}

  getType(): SectionType {
    return SectionType.NAV_BAR
  }

  getComponent(): Component {
    return NavBarEditorVue
  }

  getConfigurationComponent(): Component | null {
    return NavBarConfiguration
  }
}
