import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { Component } from 'vue'
import ButtonVue from '@/sections/button/Button.vue'
import ButtonConfiguration from '@/sections/button/ButtonConfiguration.vue'
import { ButtonData } from './types'

export class ButtonEditor extends ProposalAbstractEditableSection<ButtonData, EditableProposal> {
  fetchData(): Promise<ButtonData> {
    return Promise.resolve({ link: '', title: '' })
  }
  getType(): SectionType {
    return SectionType.BUTTON
  }

  getComponent(): Component {
    return ButtonVue
  }

  getConfigurationComponent(): Component | null {
    return ButtonConfiguration
  }
}
