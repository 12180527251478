import { Component } from 'vue'
import { DisclaimersSectionData } from './types/types'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import DisclaimersSectionVue from '@/sections/disclaimers/DisclaimersSection.vue'

export class DisclaimersSection extends AbstractSection<DisclaimersSectionData> {
  getType(): SectionType {
    return SectionType.DISCLAIMERS
  }

  getComponent(): Component {
    return DisclaimersSectionVue
  }
}
