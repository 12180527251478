<script lang="ts" setup>
//
// How to use this layout component;
//  1. Pass in your columns
//  2. Pass in your row data
//  3. For standard text rendering (most tables), there is nothing else to do.
//  4. If you need to customize the rendering of a cell, you can use the slot named after the field name.
//
import { twMerge } from 'tailwind-merge'
import { Ref, ref, useAttrs } from 'vue'

import { SolidChevronDown } from '@ankor-io/icons/solid'

type ColumnDef = {
  label: string
  field: string
  type?: string
  classes?: any
  default?: string // a default value if the field is not present in the row.
}

const props = defineProps<{
  columns: ColumnDef[] // the definitions of the columns
  rows: any[] // the row data
  rowKey: string // the reactive key for the row
}>()

// Turn off the inheritAttrs as we apply them to the class using twMerge
defineOptions({
  inheritAttrs: false,
})

type Emits = {
  (e: 'rowClick', payload: { row: any; field: string }): void
  (e: 'rowDblClick', payload: { row: any; field: string }): void
}
const emits = defineEmits<Emits>()

// track the opened rows
const rowsOpened: Ref<any> = ref({})
// watch(
//   () => rowsOpened.value,
//   () => {
//     console.debug('rowsOpened', JSON.stringify(rowsOpened.value, null, 2))
//   },
//   { deep: true },
// )

// numbers go right, everything else goes left.
const textAlignment = (dataType: string | undefined): string[] => {
  return dataType === 'number' ? ['text-right'] : ['text-left']
}

// helper to clean up the usage of twMerge and classes below.
const classes = (attr: string) => {
  return (useAttrs().classes as any)?.[attr] || []
}
</script>
<template>
  <slot name="before"></slot>

  <div :class="[twMerge(`relative`, classes('table'), useAttrs().class as string)]">
    <div
      :class="[
        twMerge(
          `z-20 rounded-t-lg border overflow-hidden w-full flex sticky border-gray-200 dark:border-gray-700 top-0`,
          classes('thead'),
        ),
      ]"
    >
      <!-- Header -->
      <div
        v-for="col of columns"
        :key="col.label"
        scope="col"
        :class="[twMerge('bg-gray-100 uppercase text-xs p-3 md:p-4', textAlignment(col.type), col.classes?.th)]"
      >
        {{ col.label }}
      </div>
    </div>
    <!-- Rows -->
    <dl
      v-for="(row, rowIndex) of rows"
      :key="`${rowIndex}:${row[props.rowKey]}`"
      :class="[
        twMerge(
          'w-full flex flex-col select-none',
          'border-b border-gray-200 dark:border-gray-700',
          'hover:bg-gray-50 dark:hover:bg-gray-600',
          classes('tr'),
        ),
      ]"
    >
      <!-- Row -->
      <dt
        class="flex items-center border-x pl-3 transition-colors"
        :class="rowsOpened[row[props.rowKey]] ? 'bg-gray-200 dark:bg-gray-700' : 'bg-white dark:bg-gray-800'"
      >
        <!-- Columns -->
        <SolidChevronDown
          class="cursor-pointer size-6 shrink-0 transition-all"
          :class="{ 'rotate-180 mb-2': rowsOpened[row[props.rowKey]] }"
          @click="rowsOpened[row[props.rowKey]] = !rowsOpened[row[props.rowKey]]"
        />
        <div
          v-for="(col, colIndex) of props.columns"
          :key="`${rowIndex}:${colIndex}:${row[props.rowKey]}`"
          :class="[twMerge('text-sm p-3 md:p-4', textAlignment(col.type), col.classes?.td || [])]"
          @click="emits('rowClick', { row, field: col.field })"
          @dblclick="emits('rowDblClick', { row, field: col.field })"
        >
          <!-- if a slot was supplied, use that -->
          <template v-if="$slots[col.field]">
            <slot :name="col.field" :row="row" :rowIndex="rowIndex" :col="col" :colIndex="colIndex">
              {{ row[col.field] }}
            </slot>
          </template>
          <template v-else>
            <!-- is the value blank? -->
            <span v-if="!row[col.field]" class="empty-cell"></span>
            <template v-else>
              <!-- show the value -->
              {{ row[col.field] }}
            </template>
          </template>
        </div>
      </dt>
      <!-- Accordion row -->
      <dd
        class="cursor-auto grid grid-cols-2 gap-4 overflow-x-auto w-full p-4 border-x border-gray-200 dark:border-gray-700"
        :class="{ hidden: !rowsOpened[row[props.rowKey]] }"
      >
        <slot name="accordion-content" :row="row" :rowIndex="rowIndex"></slot>
      </dd>
    </dl>
  </div>

  <slot name="after"></slot>
</template>
