import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 95 95",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M59.375 3.95898H39.5833C37.3972 3.95898 35.625 5.73119 35.625 7.91732C35.625 10.1034 37.3972 11.8757 39.5833 11.8757H59.375C61.5611 11.8757 63.3333 10.1034 63.3333 7.91732C63.3333 5.73119 61.5611 3.95898 59.375 3.95898Z" }),
    _createElementVNode("path", { d: "M65.3126 13.8535H33.6459C32.5529 13.8535 31.6667 14.7396 31.6667 15.8327C31.6667 16.9257 32.5529 17.8118 33.6459 17.8118H65.3126C66.4056 17.8118 67.2917 16.9257 67.2917 15.8327C67.2917 14.7396 66.4056 13.8535 65.3126 13.8535Z" }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M7.91659 19.791C5.73048 19.791 3.95825 21.5632 3.95825 23.7493V87.0827C3.95825 89.2689 5.73048 91.041 7.91659 91.041H87.0833C89.2694 91.041 91.0416 89.2689 91.0416 87.0827V23.7493C91.0416 21.5632 89.2694 19.791 87.0833 19.791H7.91659ZM37.6041 23.7493C37.0576 23.7493 36.6145 24.192 36.6145 24.7389V77.8835C30.3912 78.4412 24.4468 78.8771 19.7916 79.166C19.7916 83.916 25.0694 85.1035 27.7083 85.1035H63.3333C69.6666 85.1035 72.5692 75.8675 73.2291 71.2493C72.1667 73.8943 54.8046 76.1953 38.5937 77.703V24.7389C38.5937 24.192 38.1506 23.7493 37.6041 23.7493ZM75.2083 69.2702C75.2083 42.3535 53.4374 29.6868 41.5624 25.7285C49.4791 49.4785 44.8609 66.6312 41.5624 73.2285C51.0624 66.8948 67.2916 67.9509 75.2083 69.2702ZM35.6249 75.2077V33.6452L21.7708 75.2077H35.6249Z",
      "clip-rule": "evenodd"
    })
  ]))
}