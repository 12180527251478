import { Predicate } from './functional.types'

/**
 * The fault interface definition
 */
export declare type Fault = {
  /**
   * The status code to represent this fault with
   */
  status: number
  /**
   * The reason for this fault to be thrown
   */
  reason: string
}

//
// A Fault that can be type checked.
export class BadArgumentFault extends Error {
  constructor(message = 'Bad Argument') {
    super(message)
    this.name = 'BadArgumentFault'
  }
}

export class InternalFault extends Error {
  constructor(message = 'Internal Error') {
    super(message)
    this.name = 'InternalFault'
  }
}

/**
 * Build a fault given status code and a reason
 *
 * @param status the status code that maps to this fault
 * @param reason the reason for this fault to happen
 * @returns a fault object
 */
const buildFault = (status: number, reason: string): Fault => {
  return {
    status: status,
    reason: reason,
  }
}

/**
 *
 * @param message the reason that justifies this fault
 * @returns a response containing a fault as body
 */
export const UnauthorizedFault = (message: string = 'Unauthorized'): Response => {
  return new Response(JSON.stringify(buildFault(401, message)), {
    status: 401,
  })
}

/**
 *
 * @param message the reason that justifies this fault
 * @returns a response containing a fault as body
 */
export const ArgumentFault = (message: string = 'Bad Request'): Response => {
  return new Response(JSON.stringify(buildFault(400, message)), {
    status: 400,
  })
}

/**
 *
 * @param message the reason that justifies this fault
 * @returns a response containing a fault as body
 */
export const ForbiddenFault = (message: string = 'Forbidden'): Response => {
  return new Response(JSON.stringify(buildFault(403, message)), {
    status: 403,
  })
}

/**
 *
 * @param message the reason that justifies this fault
 * @returns a response containing a fault as body
 */
export const NotFoundFault = (message: string = 'Not Found'): Response => {
  return new Response(JSON.stringify(buildFault(404, message)), {
    status: 404,
  })
}

/**
 * 422 Unprocessable Entity response status code indicates that the server understands the content type of the request entity,
 * and the syntax of the request entity is correct, but it was unable to process the contained instructions.
 * @param message the reason that justifies this fault
 *
 */
export const UnprocessableEntityFault = (message: string = 'Unprocessable Entity'): Response => {
  return new Response(JSON.stringify(buildFault(422, message)), {
    status: 422,
  })
}

/**
 *
 * @param message the reason that justifies this fault
 * @returns a response containing a fault as body
 */
export const ServerFault = (message: string = 'Internal Server Error'): Response => {
  return new Response(JSON.stringify(buildFault(500, message)), {
    status: 500,
  })
}

/**
 * Method not allowed response code
 *
 * @param message the reason that justifies this fault
 * @returns a response containing a fault as body
 */
export const NotAllowedFault = (message: string = 'Method Not Allowed'): Response => {
  return new Response(JSON.stringify(buildFault(405, message)), {
    status: 405,
  })
}

/**
 * Checks if an argument is defined and throws if it isn't
 *
 * @param predicate the predicate to check the result for
 * @param errorMessage the message for the error to throw
 * @param errorType the type of Error to raise
 * @throws Error when the predicate evaluates to false
 */
export const affirmArgument = (predicate: Predicate, errorMessage?: string, errorType = BadArgumentFault): void => {
  if (!predicate()) {
    throw new errorType(errorMessage)
  }
}

/**
 * Build a response for a given status code and message
 * @param code the code to build the response for
 * @param message the message to build the response for
 */
export const ResponseFor = (code: number, message: string): Response => {
  switch (code) {
    case 400:
      return ArgumentFault(message)
    case 401:
      return UnauthorizedFault(message)
    case 403:
      return ForbiddenFault(message)
    case 404:
      return NotFoundFault(message)
    case 405:
      return NotAllowedFault(message)
    case 422:
      return UnprocessableEntityFault(message)
    default:
      return ServerFault(message)
  }
}
