import { Runnable } from './functional.types'

/**
 * This is an application event that flies outside of the DOM
 * It allows parts of the application that lives outside the DOM
 * to communicate between each other.
 * It loosely follows the EventTarget implementation
 */
export interface AppEvent<T> {
  /**
   * The event name
   */
  readonly name: string
  /**
   * The listeners attached to this event
   */
  readonly listeners: Runnable<T>[]
  /**
   * Allows to register a listener to an event
   * 
   * @param listener the listener to register to this event
   */
  registerListener(listener: Runnable<T>): void
}

/**
 * The application event dispatcher
 */
export interface AppEventDispatcher {
  /**
   * Allows to add listener to a particular event
   * 
   * @param name the name of the event to listen to
   * @param listener the listener to trigger when the event is dispatched
   */
  addEventListener<T>(name: string, listener: Runnable<T>): void
  /**
   * Allows to remove a listener for a particular event
   * 
   * @param name the name of the event to remove the listener for
   * @param listener the listener to remove from the event listener
   */
  removeEventListener<T>(name: string, listener: Runnable<T>): void
  /**
   * Allows to dispatch an event with some args if required
   * 
   * @param name the name of the event to dispatch
   * @param args the arguments dispatched with this event
   */
  dispatchEvent(name: string, args?: any): void
}

/**
 * The app event dispatcher provider is responsible for returning
 * a singleton instance of {@link AppEventDispatcher}.
 */
export interface AppEventDispatcherProvider {
  /**
   * Returns a singleton instance of the {@link AppEventDispatcher}
   */
  get(): AppEventDispatcher
}

class AppEventImpl<T> implements AppEvent<T> {
  readonly name: string
  readonly listeners: Runnable<T>[]

  constructor(name: string, listeners?: Runnable<T>[]) {
    this.name = name
    this.listeners = listeners || []
  }

  registerListener(listener: Runnable<T>): void {
    this.listeners.push(listener)
  }
}

class AppEventDispatcherImpl implements AppEventDispatcher {
  private events: Record<string, AppEvent<any>>

  constructor() {
    this.events = {}
  }

  removeEventListener<T>(name: string, listener: Runnable<T>): void {
    if (this.events[name]) {
      const filtered = this.events[name].listeners.filter((_listener: Runnable<T>) => listener !== _listener)

      if (filtered.length === 0) {
        delete this.events[name]
      } else {
        this.events[name] = new AppEventImpl(name, filtered)
      }
    }
  }

  addEventListener<T>(name: string, listener: Runnable<any>): void {
    if (!this.events[name]) {
      this.events[name] = new AppEventImpl<T>(name)
    }
    this.events[name].registerListener(listener)

  }

  dispatchEvent(name: string, args: any): void {
    if (this.events[name]) {
      this.events[name].listeners.forEach((listener: Runnable<any>) => listener(args))
    }
  }
}

 class AppEventDispatcherProviderImpl {
  private static dispatcher: AppEventDispatcher
   static get(): AppEventDispatcher {
     if (!AppEventDispatcherProviderImpl.dispatcher) {
      AppEventDispatcherProviderImpl.dispatcher = new AppEventDispatcherImpl()
     }
     return AppEventDispatcherProviderImpl.dispatcher
   }
}

export const useAppDispatcher = (): AppEventDispatcherProvider => {
  return {
    get: AppEventDispatcherProviderImpl.get
  }
}