import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M16.4754 1.74195C15.5296 0.752685 13.996 0.752685 13.0501 1.74195L10.2573 4.49569L6.97721 2.29316C6.79403 2.17015 6.55239 2.2056 6.40863 2.37656L1.32911 8.41691C0.939564 8.88014 0.890818 9.55732 1.20965 10.0763L3.59438 13.9582L11.0505 21.7564C11.361 22.0812 11.8646 22.0812 12.1751 21.7564C12.4857 21.4316 12.4857 20.9049 12.1751 20.5801L10.1681 18.481C9.91014 18.2112 9.91014 17.7738 10.1681 17.504C10.4261 17.2342 10.8443 17.2342 11.1023 17.504L13.2914 19.7936C13.7162 20.2378 14.4048 20.2378 14.8295 19.7936C15.2542 19.3494 15.2542 18.6292 14.8295 18.185L12.6398 15.8948C12.3766 15.6196 12.3766 15.1733 12.6398 14.898C12.903 14.6228 13.3297 14.6228 13.5928 14.898L15.9288 17.3411C16.2727 17.7009 16.8304 17.7009 17.1743 17.3411L17.4685 17.0335C17.8125 16.6737 17.8125 16.0905 17.4685 15.7308L15.1317 13.2868C14.869 13.012 14.869 12.5665 15.1317 12.2917C15.3945 12.017 15.8204 12.017 16.0831 12.2917L18.2181 14.5247C18.6412 14.9672 19.3271 14.9672 19.7501 14.5247C20.1732 14.0822 20.1732 13.3649 19.7501 12.9224L14.6446 7.58263C14.4856 7.41633 14.2323 7.40203 14.0571 7.54946L11.6128 9.60635C10.8788 9.99235 9.19076 10.417 8.31003 9.02734L11.4599 5.30331L13.9843 2.71897C14.4142 2.26931 15.1113 2.26931 15.5413 2.71897L21.3564 8.80097C21.8009 9.26584 21.7836 10.0249 21.3185 10.4672L20.2634 11.4703C19.9935 11.727 19.9736 12.1639 20.219 12.4463C20.4644 12.7286 20.8822 12.7494 21.1521 12.4927L22.2072 11.4895C23.2304 10.5166 23.2684 8.84666 22.2906 7.82395L16.4754 1.74195Z" })
  ]))
}