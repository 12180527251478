<script setup lang="ts">
import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ViewMode } from '@ankor-io/common/events/Editor'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { ProposalItem } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { JsonSlide } from '@ankor-io/common/proposal/Slide'

import { removeHtmlTagsFromString } from '@/utils/stringManipulations'

type NavBarProps = {
  title: string
  vesselSlides?: JsonSlide[]
  itinerarySlides?: JsonSlide[]
  proposalItems: ProposalItem[]
}

const props = defineProps<NavBarProps>()
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'change:slide', value: { slideUri: string; viewMode: ViewMode }): void
}>()

const getProposalItemForSlideUri = (slideUri: string) =>
  props.proposalItems?.find((proposal) => proposal.uri === slideUri)

const getItineraryThumbnail = (slide: JsonSlide): string => {
  const heroSectionImage = slide.sections.find((section) => section.type === SectionType.ITINERARY_HERO)?.data
    .heroImages?.[0]
  if (heroSectionImage) {
    return replacePathToMediaUris(slide.uri, heroSectionImage)[0]
  }

  const proposalItemHeroUri = getProposalItemForSlideUri(slide.uri)?.item?.hero
  return (proposalItemHeroUri && replacePathToMediaUris(slide.uri, proposalItemHeroUri)[0]) || ''
}

const getItineraryLine1 = (slide: JsonSlide): string | null => {
  const heroSection = slide.sections.find((section) => section.type === SectionType.ITINERARY_HERO)
  if (heroSection?.data.heading) {
    return removeHtmlTagsFromString(heroSection.data.heading)
  }

  const proposalItemLine1 = getProposalItemForSlideUri(slide.uri)?.item?.line_1
  return removeHtmlTagsFromString(proposalItemLine1!)
}

const getVesselThumbnail = (slide: JsonSlide): string => {
  const proposalItemHeroUri = getProposalItemForSlideUri(slide.uri)?.item?.hero
  return (proposalItemHeroUri && replacePathToMediaUris(slide.uri, proposalItemHeroUri)[0]) || ''
}

const getVesselName = (slide: JsonSlide): string => {
  const proposalItemLine1 = getProposalItemForSlideUri(slide.uri)?.item?.line_1
  return proposalItemLine1 ?? ''
}
</script>

<template>
  <div class="flex items-center justify-between border-b pb-1 rounded-t border-gray-300">
    <h3 class="text-sm font-semibold text-gray-900 dark:text-white">{{ props.title }}</h3>
  </div>

  <!-- Itinerary -->
  <div v-if="props.itinerarySlides" class="flex flex-wrap gap-2.5 overflow-y-auto p-4 sm:p-8">
    <div
      v-for="itinerarySlide in props.itinerarySlides"
      :key="itinerarySlide.uri"
      class="w-48 sm:w-72 h-72 max-h-full bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md cursor-pointer flex-grow sm:flex-none"
      @click="emit('change:slide', { slideUri: itinerarySlide.uri, viewMode: ViewMode.SLIDE })"
    >
      <!-- itinerary hero image -->
      <div class="w-full h-56">
        <AssetViewerPlaceholder
          class="w-full h-full rounded-t-lg nav-itinerary-image object-cover"
          :url="`/media/${getItineraryThumbnail(itinerarySlide)}`"
        />
      </div>

      <div class="p-2.5">
        <div class="text-sm font-bold tracking-tight truncate text-gray-900 dark:text-white">
          {{ getItineraryLine1(itinerarySlide) }}
        </div>
      </div>
    </div>
  </div>

  <!-- Vessel -->
  <div v-if="props.vesselSlides" class="flex flex-wrap gap-2.5 overflow-y-auto p-4 sm:p-8">
    <div
      v-for="vesselSlide in props.vesselSlides"
      :key="vesselSlide.uri"
      class="w-48 sm:w-72 h-72 max-h-full bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md cursor-pointer flex-grow sm:flex-none"
      @click="emit('change:slide', { slideUri: vesselSlide.uri, viewMode: ViewMode.SLIDE })"
    >
      <!-- itinerary hero image -->
      <div class="w-full h-60">
        <AssetViewerPlaceholder
          class="w-full h-full rounded-t-lg nav-itinerary-image object-cover"
          :url="`/media/${getVesselThumbnail(vesselSlide)}`"
        />
      </div>

      <div
        class="p-3 text-sm font-bold tracking-tight truncate text-gray-900 dark:text-white"
        v-html="getVesselName(vesselSlide)"
      ></div>
    </div>
  </div>
</template>
