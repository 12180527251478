import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import OverviewSectionVue from '@/sections/overview/OverviewSection.vue'

export type OverviewSectionData = {
  heading: string
  subheading: string
  narrative: string
  images: string[]
  selectedImage: string
  /**
   *  Backward compatable fields
   */
  // heading alternatives
  name?: string
  overviewHeading?: string
  // subheading alternatives
  internalName?: string
  externalName?: string
  // narrative alternatives
  description?: string
  // selectedImage alternative
  image?: string
}

export class OverviewSection extends AbstractSection<OverviewSectionData> {
  getType(): SectionType {
    return SectionType.ITINERARY_OVERVIEW
  }

  getComponent(): Component {
    return OverviewSectionVue
  }
}
