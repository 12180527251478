import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M21.6 12C21.6 14.5461 20.5886 16.9879 18.7883 18.7883C16.9879 20.5886 14.5461 21.6 12 21.6C9.45395 21.6 7.01215 20.5886 5.2118 18.7883C3.41145 16.9879 2.40002 14.5461 2.40002 12C2.40002 9.45395 3.41145 7.01215 5.2118 5.2118C7.01215 3.41145 9.45395 2.40002 12 2.40002C14.5461 2.40002 16.9879 3.41145 18.7883 5.2118C20.5886 7.01215 21.6 9.45395 21.6 12V12ZM13.2 16.8C13.2 17.1183 13.0736 17.4235 12.8486 17.6486C12.6235 17.8736 12.3183 18 12 18C11.6818 18 11.3765 17.8736 11.1515 17.6486C10.9265 17.4235 10.8 17.1183 10.8 16.8C10.8 16.4818 10.9265 16.1765 11.1515 15.9515C11.3765 15.7265 11.6818 15.6 12 15.6C12.3183 15.6 12.6235 15.7265 12.8486 15.9515C13.0736 16.1765 13.2 16.4818 13.2 16.8V16.8ZM12 6.00002C11.6818 6.00002 11.3765 6.12645 11.1515 6.3515C10.9265 6.57654 10.8 6.88176 10.8 7.20002V12C10.8 12.3183 10.9265 12.6235 11.1515 12.8486C11.3765 13.0736 11.6818 13.2 12 13.2C12.3183 13.2 12.6235 13.0736 12.8486 12.8486C13.0736 12.6235 13.2 12.3183 13.2 12V7.20002C13.2 6.88176 13.0736 6.57654 12.8486 6.3515C12.6235 6.12645 12.3183 6.00002 12 6.00002Z",
      "clip-rule": "evenodd"
    })
  ]))
}