import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import SpacerVue from '@/sections/spacer/Spacer.vue'

export class SpacerSection extends AbstractSection<any> {
  getType(): SectionType {
    return SectionType.SPACER
  }

  getComponent(): Component {
    return SpacerVue
  }
}
