import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M11.0049 6.90004C11.0551 6.39469 11.4815 6 12 6H20.9091C21.4614 6 21.9091 6.44772 21.9091 7V17C21.9091 17.5523 21.4614 18 20.9091 18H12C11.4815 18 11.0551 17.6053 11.0049 17.1C11.0033 17.1 11.0017 17.1 11 17.1H2.00002C1.39251 17.1 0.900024 16.6075 0.900024 16V8.00002C0.900024 7.39251 1.39251 6.90002 2.00002 6.90002H11C11.0017 6.90002 11.0033 6.90003 11.0049 6.90004ZM11.5447 7.16122C11.388 7.05926 11.2009 7.00002 11 7.00002H2.00002C1.44774 7.00002 1.00002 7.44774 1.00002 8.00002V16C1.00002 16.5523 1.44774 17 2.00002 17H11C11 17 11 17 11 17C11.5523 17 12 16.5523 12 16V8C12 7.79289 11.937 7.60049 11.8292 7.44089C11.7541 7.32971 11.6572 7.23445 11.5447 7.16122ZM15.035 11.3077L13.3635 13.5191C13.2141 13.7167 13.3551 14 13.6028 14H19.3868C19.6364 14 19.7768 13.7129 19.6236 13.5159L18.4605 12.0198C18.3409 11.866 18.1087 11.8652 17.9881 12.0182L17.2761 12.9209C17.1565 13.0725 16.927 13.0733 16.8063 12.9226L15.5086 11.3011C15.3864 11.1484 15.153 11.1516 15.035 11.3077ZM18 10.5C18 10.7761 17.7761 11 17.5 11C17.2239 11 17 10.7761 17 10.5C17 10.2239 17.2239 10 17.5 10C17.7761 10 18 10.2239 18 10.5ZM3 8C2.44772 8 2 8.44772 2 9C2 9.55228 2.44772 10 3 10H6C6.55228 10 7 9.55228 7 9C7 8.44772 6.55228 8 6 8H3ZM2.5 11C2.22386 11 2 11.2239 2 11.5C2 11.7761 2.22386 12 2.5 12H10.5C10.7761 12 11 11.7761 11 11.5C11 11.2239 10.7761 11 10.5 11H2.5ZM2.5 13C2.22386 13 2 13.2239 2 13.5C2 13.7761 2.22386 14 2.5 14H7.5C7.77614 14 8 13.7761 8 13.5C8 13.2239 7.77614 13 7.5 13H2.5ZM2 15.5C2 15.2239 2.22386 15 2.5 15H9.5C9.77614 15 10 15.2239 10 15.5C10 15.7761 9.77614 16 9.5 16H2.5C2.22386 16 2 15.7761 2 15.5Z",
      "clip-rule": "evenodd"
    })
  ]))
}