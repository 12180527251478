<script lang="ts" setup>
import { Ref, ref } from 'vue'

import { LifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { Vessel } from '@ankor-io/common/vessel/types'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import SpecificationItem from './SpecificationItem.vue'
import { KeyValueSpecification, VesselSpecificationsSectionData } from './types/types'

type Props = {
  proposalUri: string
  data: VesselSpecificationsSectionData
  layout: LayoutTemplate
  lifecycle: Runnable<LifecycleHooks>
  source?: { uri: string; [key: string]: any }
}

const props = defineProps<Props>()
const filledSpecifications: Ref<KeyValueSpecification[]> = ref([])
props.lifecycle({
  onBeforeAttach() {
    // if the source is provided and the data is null, use that to hydrate. This is necessary for the lite editor
    // to work in memory
    if (props.source && (!props.data || !Object.keys(props.data).length)) {
      //@ts-ignore this works because of the lifecycle bound to the section class
      const data = this.dataFrom<Vessel>(props.source)
      filledSpecifications.value = data?.specifications.filter((specification: KeyValueSpecification) => {
        return (
          (specification.key &&
            specification.value &&
            Array.isArray(specification.value) &&
            specification.value.length > 0) ||
          (specification.value && !Array.isArray(specification.value))
        )
      })
      //@ts-ignore this works because of the lifecycle bound to the section class
      this.setData({ specifications: filledSpecifications.value })
    }
  },
} as LifecycleHooks)
const isClamped: Ref<boolean> = ref(true)
</script>
<template>
  <div
    v-if="props.data && props.data.specifications?.length"
    id="vessel-specifications-section"
    class="mx-auto max-w-screen-xl p-4 @sm:p-8"
  >
    <h1 class="font-bold text-3xl text-center mb-4">
      <SingleLineTextEditor value="Specifications" :is-editable="false" />
    </h1>
    <div class="mb-4 grid gap-2 grid-cols-2 @sm:grid-cols-4 @md:grid-cols-5 @lg:grid-cols-6 text-left">
      <SpecificationItem
        v-for="(specification, index) in filledSpecifications.slice(
          0,
          isClamped ? 12 : filledSpecifications.length - 1,
        )"
        :specification="specification"
        :key="`${index}-${specification.key}-${specification.value}`"
      />
    </div>
    <span
      v-if="filledSpecifications.length > 12"
      class="cursor-pointer text-theme-primary"
      @click="isClamped = !isClamped"
    >
      {{ isClamped ? 'Show more' : 'Show less' }}
    </span>
  </div>
</template>
