import { JsonProposal, Proposal } from '@ankor-io/common/proposal/Proposal'
import { JsonSection, Section, SectionTemplate } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableSlideState, JsonSlide, Slide } from '@ankor-io/common/proposal/Slide'

import { bindings } from '@/services/proposal/Binding'
import { ProposalDocument } from '@/services/proposal/Document'
import { ProposalImpl } from '@/services/proposal/ProposalImpl'

/**
 * Deserialize a json proposal into a proposal accessing bound slides and sections.
 * This method makes sure the proposal is still deserialized even when a section binding is missing.
 * This is safe to do here because we are in read only mode.
 *
 * @param jsonProposal the json proposal to deserialize
 * @returns a deserialized proposal
 */
const deserialize = (jsonProposal: JsonProposal): Proposal => {
  // build the slides
  const slides: Slide[] = jsonProposal.document.slides.map((jsonSlide: JsonSlide) => {
    // create the slide
    const slide: Slide = bindings.proposalSlides.get(jsonSlide.type)!({
      id: jsonSlide.uri,
      uri: jsonSlide.uri,
      proposalUri: jsonProposal.uri,
      state: jsonSlide.state || EditableSlideState.NEEDS_HYDRATION,
    })

    const sections: Section<any>[] = jsonSlide.sections
      .map((jsonSection: JsonSection<any>) => {
        return bindings.proposalSections.get(jsonSection.type)?.({
          template: jsonSection as SectionTemplate,
          slideUri: slide.getUri(),
          source: jsonSection.data,
          id: jsonSection.id
        })
      })
      .filter((s: Section<any> | undefined) => s !== undefined)
      .map((s: Section<any> | undefined) => s!)
    // attach the sections so we don't hydrate
    sections.forEach((section: Section<any>) => slide.addSection(section))
    // return the slide
    return slide
  })

  // build the header section if defined
  const header: Section<any> | null =
    Object.keys(jsonProposal.document.header).length > 0
      ? bindings.proposalSections.has(jsonProposal.document.header.type as SectionType)
        ? bindings.proposalSections.get(jsonProposal.document.header.type as SectionType)!({
            template: jsonProposal.document.header as SectionTemplate,
            slideUri: null,
            source: jsonProposal.document.header.data,
          })
        : null
      : null
  // build the footer section if defined
  const footer: Section<any> | null =
    Object.keys(jsonProposal.document.footer).length > 0
      ? bindings.proposalSections.get(jsonProposal.document.footer.type as SectionType)
        ? bindings.proposalSections.get(jsonProposal.document.footer.type as SectionType)!({
            template: jsonProposal.document.footer as SectionTemplate,
            slideUri: null,
            source: jsonProposal.document.footer.data,
          })
        : null
      : null
  // create the proposal object and return it
  return new ProposalImpl(
    JSON.parse(JSON.stringify(jsonProposal.template)),
    new ProposalDocument(header, footer, slides),
    jsonProposal.uri,
    jsonProposal.proposalItems,
    jsonProposal.flow,
    jsonProposal.indexable,
    jsonProposal.tags,
    jsonProposal.internalName,
    jsonProposal?.externalName || '',
  )
}

export const ProposalDeserializer = {
  deserialize: deserialize,
}

export declare type ProposalDeserializer = {
  deserialize: (jsonProposal: JsonProposal) => Proposal
}
