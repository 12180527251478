/**
 * Toasts!
 *
 * Top Right Corner: Success, Errors, Warnings.
 * Bottom Left: Neutral, Informational, Branded
 *
 */
import { BellIcon } from '@heroicons/vue/24/outline'
import { h } from 'vue'
import { type ToastOptions, toast } from 'vue3-toastify'

// some defaults
const DEFAULTS: ToastOptions = {
  autoClose: 7000,
  // hideProgressBar: true,
  dangerouslyHTMLString: true,
  position: toast.POSITION.TOP_RIGHT,
  theme: 'auto',
} as ToastOptions

/**
 * Show a toast with a spinner and await on a promise
 * @param promise the promise to wait on
 * @param pendingMessage the message to show while waiting
 * @param successMessage the success message
 * @param errorMessage the error message
 * @returns
 */
export const showAwaitToast = async (
  promise: Promise<any>,
  pendingMessage: string,
  successMessage: string,
  errorMessage: string,
) => {
  //
  return await toast.promise(
    promise,
    {
      pending: pendingMessage,
      success: {
        ...DEFAULTS,
        render: successMessage,
        type: 'success',
        icon: ({ theme, type }) => h(BellIcon, { class: 'stroke-green-500 w-8 h-8', theme, type }),
      },
      error: {
        ...DEFAULTS,
        render: errorMessage,
        type: 'error',
        icon: ({ theme, type }) => h(BellIcon, { class: 'stroke-red-500 w-8 h-8', theme, type }),
      },
    },
    {
      dangerouslyHTMLString: true,
      position: toast.POSITION.TOP_RIGHT,
      theme: 'auto',
    },
  )
}

/**
 * Show a success toast with the supplied message
 * @param message the message to show
 */
export const showSuccessToast = (message: string) => {
  toast(message, {
    ...DEFAULTS,
    type: 'success',
    icon: ({ theme, type }) => h(BellIcon, { class: 'stroke-green-500 w-8 h-8', theme, type }),
  })
}

/**
 * Show an error toast with the supplied message
 * @param message the message to show
 */
export const showErrorToast = (message: string) => {
  toast(message, {
    ...DEFAULTS,
    type: 'error',
    icon: ({ theme, type }) => h(BellIcon, { class: 'stroke-red-500 w-8 h-8', theme, type }),
  })
}

/**
 * Show a warning toast with the supplied message
 * @param message the message to show
 */
export const showWarningToast = (message: string) => {
  toast(message, {
    ...DEFAULTS,
    type: 'warning',
    icon: ({ theme, type }) => h(BellIcon, { class: 'stroke-orange-500 w-8 h-8', theme, type }),
  })
}

/**
 * Show a neutral toast with the supplied message
 * @param message the message to show
 */
export const showInfoToast = (message: string) => {
  toast(message, {
    ...DEFAULTS,
    position: toast.POSITION.BOTTOM_LEFT,
    type: 'info',
    icon: false,
  })
}
