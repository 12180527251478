// Create a UUID v1
//
// crypto provided by Webcrypto:
//  https://developers.cloudflare.com/workers/runtime-apis/web-crypto/
//
// Follows the same API as
//   See https://github.com/uuidjs/uuid for API details
//
// Inspired from :
//    - https://github.com/cfworker/cfworker/blob/main/packages/uuid/src/index.ts
//    - https://github.com/uuidjs/uuid
//

//
const rnds8Pool = new Uint8Array(256) // # of random values to pre-allocate
let poolPtr = rnds8Pool.length
const rng = (): Uint8Array => {
  if (typeof crypto !== 'object') {
    throw new Error('no crypto')
  }
  if (poolPtr > rnds8Pool.length - 16) {
    // @ts-ignore
    crypto.getRandomValues(rnds8Pool)
    poolPtr = 0
  }
  return rnds8Pool.slice(poolPtr, (poolPtr += 16))
}

function asString(arr: number[], offset = 0) {
  /**
   * Convert array of 16 byte values to UUID string format of the form:
   * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  const byteToHex: string[] = []
  for (let i = 0; i < 256; ++i) {
    byteToHex.push((i + 0x100).toString(16).slice(1))
  }

  // Note: Be careful editing this code!  It's been tuned for performance
  // and works in ways you may not expect. See https://github.com/uuidjs/uuid/pull/434
  //
  // Note to future-self: No, you can't remove the `toLowerCase()` call.
  // REF: https://github.com/uuidjs/uuid/pull/677#issuecomment-1757351351
  return (
    byteToHex[arr[offset + 0]] +
    byteToHex[arr[offset + 1]] +
    byteToHex[arr[offset + 2]] +
    byteToHex[arr[offset + 3]] +
    '-' +
    byteToHex[arr[offset + 4]] +
    byteToHex[arr[offset + 5]] +
    '-' +
    byteToHex[arr[offset + 6]] +
    byteToHex[arr[offset + 7]] +
    '-' +
    byteToHex[arr[offset + 8]] +
    byteToHex[arr[offset + 9]] +
    '-' +
    byteToHex[arr[offset + 10]] +
    byteToHex[arr[offset + 11]] +
    byteToHex[arr[offset + 12]] +
    byteToHex[arr[offset + 13]] +
    byteToHex[arr[offset + 14]] +
    byteToHex[arr[offset + 15]]
  ).toLowerCase()
}

let _nodeId: any[]
let _clockseq: number

// Previous uuid creation time
let _lastMSecs = 0
let _lastNSecs = 0

// See https://github.com/uuidjs/uuid for API details
export const v1 = (options?: any, buf?: any[], offset?: number) => {
  let i = (buf && offset) || 0
  const b = buf || new Array(16)

  options = options || {}
  let node = options.node || _nodeId
  let clockseq = options.clockseq !== undefined ? options.clockseq : _clockseq

  // node and clockseq need to be initialized to random values if they're not
  // specified.  We do this lazily to minimize issues related to insufficient
  // system entropy.  See #189
  if (node == null || clockseq == null) {
    const seedBytes = options.random || (options.rng || rng)()

    if (node == null) {
      // Per 4.5, create and 48-bit node id, (47 random bits + multicast bit = 1)
      node = _nodeId = [seedBytes[0] | 0x01, seedBytes[1], seedBytes[2], seedBytes[3], seedBytes[4], seedBytes[5]]
    }

    if (clockseq == null) {
      // Per 4.2.2, randomize (14 bit) clockseq
      clockseq = _clockseq = ((seedBytes[6] << 8) | seedBytes[7]) & 0x3fff
    }
  }

  // UUID timestamps are 100 nano-second units since the Gregorian epoch,
  // (1582-10-15 00:00).  JSNumbers aren't precise enough for this, so
  // time is handled internally as 'msecs' (integer milliseconds) and 'nsecs'
  // (100-nanoseconds offset from msecs) since unix epoch, 1970-01-01 00:00.
  let msecs = options.msecs !== undefined ? options.msecs : Date.now()

  // Per 4.2.1.2, use count of uuid's generated during the current clock
  // cycle to simulate higher resolution clock
  let nsecs = options.nsecs !== undefined ? options.nsecs : _lastNSecs + 1

  // Time since last uuid creation (in msecs)
  const dt = msecs - _lastMSecs + (nsecs - _lastNSecs) / 10000

  // Per 4.2.1.2, Bump clockseq on clock regression
  if (dt < 0 && options.clockseq === undefined) {
    clockseq = (clockseq + 1) & 0x3fff
  }

  // Reset nsecs if clock regresses (new clockseq) or we've moved onto a new
  // time interval
  if ((dt < 0 || msecs > _lastMSecs) && options.nsecs === undefined) {
    nsecs = 0
  }

  // Per 4.2.1.2 Throw error if too many uuids are requested
  if (nsecs >= 10000) {
    throw new Error("uuid.v1(): Can't create more than 10M uuids/sec")
  }

  _lastMSecs = msecs
  _lastNSecs = nsecs
  _clockseq = clockseq

  // Per 4.1.4 - Convert from unix epoch to Gregorian epoch
  msecs += 12219292800000

  // `time_low`
  const tl = ((msecs & 0xfffffff) * 10000 + nsecs) % 0x100000000
  b[i++] = (tl >>> 24) & 0xff
  b[i++] = (tl >>> 16) & 0xff
  b[i++] = (tl >>> 8) & 0xff
  b[i++] = tl & 0xff

  // `time_mid`
  const tmh = ((msecs / 0x100000000) * 10000) & 0xfffffff
  b[i++] = (tmh >>> 8) & 0xff
  b[i++] = tmh & 0xff

  // `time_high_and_version`
  b[i++] = ((tmh >>> 24) & 0xf) | 0x10 // include version
  b[i++] = (tmh >>> 16) & 0xff

  // `clock_seq_hi_and_reserved` (Per 4.2.2 - include variant)
  b[i++] = (clockseq >>> 8) | 0x80

  // `clock_seq_low`
  b[i++] = clockseq & 0xff

  // `node`
  for (let n = 0; n < 6; ++n) {
    b[i + n] = node[n]
  }

  return buf || asString(b)
}

export default v1
