import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M9.60002 4.8C8.32698 4.8 7.10609 5.30571 6.20591 6.20589C5.30574 7.10606 4.80002 8.32696 4.80002 9.6C4.80002 10.873 5.30574 12.0939 6.20591 12.9941C7.10609 13.8943 8.32698 14.4 9.60002 14.4C10.8731 14.4 12.094 13.8943 12.9941 12.9941C13.8943 12.0939 14.4 10.873 14.4 9.6C14.4 8.32696 13.8943 7.10606 12.9941 6.20589C12.094 5.30571 10.8731 4.8 9.60002 4.8ZM2.40002 9.6C2.39988 8.46684 2.6672 7.34965 3.18023 6.33929C3.69326 5.32892 4.43753 4.45392 5.3525 3.78543C6.26747 3.11694 7.3273 2.67385 8.4458 2.4922C9.5643 2.31054 10.7099 2.39545 11.7894 2.74002C12.8689 3.08459 13.8518 3.67908 14.6582 4.47515C15.4646 5.27123 16.0718 6.2464 16.4302 7.32136C16.7887 8.39632 16.8884 9.54071 16.7212 10.6615C16.554 11.7822 16.1246 12.8477 15.468 13.7712L21.2484 19.5516C21.467 19.7779 21.588 20.081 21.5852 20.3957C21.5825 20.7103 21.4563 21.0113 21.2338 21.2338C21.0113 21.4563 20.7103 21.5825 20.3957 21.5852C20.0811 21.5879 19.7779 21.467 19.5516 21.2484L13.7724 15.4692C12.6953 16.2351 11.428 16.6898 10.1096 16.7833C8.79123 16.8769 7.47251 16.6057 6.29798 15.9996C5.12346 15.3934 4.13845 14.4756 3.45089 13.3468C2.76334 12.218 2.39977 10.9217 2.40002 9.6V9.6Z",
      "clip-rule": "evenodd"
    })
  ]))
}