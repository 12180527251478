import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M13.828 10.172C13.0778 9.42213 12.0606 9.00087 11 9.00087C9.9393 9.00087 8.92207 9.42213 8.17196 10.172L4.17196 14.172C3.78992 14.541 3.48519 14.9824 3.27555 15.4704C3.06592 15.9584 2.95557 16.4833 2.95096 17.0144C2.94634 17.5455 3.04755 18.0722 3.24867 18.5638C3.4498 19.0554 3.74681 19.502 4.12238 19.8776C4.49795 20.2532 4.94456 20.5502 5.43614 20.7513C5.92773 20.9524 6.45445 21.0536 6.98556 21.049C7.51668 21.0444 8.04156 20.9341 8.52958 20.7244C9.01759 20.5148 9.45897 20.2101 9.82796 19.828L10.93 18.727M10.172 13.828C10.9221 14.5779 11.9393 14.9992 13 14.9992C14.0606 14.9992 15.0778 14.5779 15.828 13.828L19.828 9.82801C20.5566 9.0736 20.9598 8.06319 20.9507 7.01441C20.9415 5.96562 20.5209 4.96237 19.7792 4.22074C19.0376 3.4791 18.0344 3.05843 16.9856 3.04932C15.9368 3.0402 14.9264 3.44338 14.172 4.17201L13.072 5.27201"
    })
  ]))
}