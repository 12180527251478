import { Template } from '@ankor-io/common/proposal/Proposal'

export const profileCompanySectionLiteMapper = {
  from: (source: Template): any => {
    return {
      givenName: source.identity.givenName,
      surname: source.identity.surname,
      image: source.identity.image,
      role: source.identity.role,
      email: source.identity.email,
      phone: source.identity.phone,
      website: source.identity.website,
      companyImage: source.identity.companyImage,
      companyName: source.identity.companyName,
      companyAddress: source.identity.companyAddress,
      companyPhone: source.identity.companyPhone,
    }
  }
}
