import ls from 'localstorage-slim'
import { defineStore } from 'pinia'
import { Ref, ref, watch } from 'vue'

type PreferredViewType = 'table' | 'card'

// define the store
export const usePreferredViewStore = defineStore('preferred-view', () => {
  const preferredView: Ref<PreferredViewType> = ref(ls.get('preferred-view') || 'table')

  watch(preferredView, () => ls.set('preferred-view', preferredView.value))

  return {
    preferredView,
  }
})
