import { Component } from 'vue'

import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { EmbedSectionData } from '@/sections/embed/types'
import EmbedSectionVue from '@/sections/embed/EmbedSection.vue'
import EmbedConfigurationVue from '@/sections/embed/EmbedConfiguration.vue'

export class EmbedSectionEditor extends ProposalAbstractEditableSection<EmbedSectionData, EditableProposal> {
  async fetchData(): Promise<EmbedSectionData> {
    return Promise.resolve({ content: '' })
  }

  getType(): SectionType {
    return SectionType.EMBED
  }

  getComponent(): Component {
    return EmbedSectionVue
  }

  getConfigurationComponent(): Component | null {
    return EmbedConfigurationVue
  }
}
