import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { EmbedSectionData } from '@/sections/embed/types'
import EmbedSectionVue from '@/sections/embed/EmbedSection.vue'

export class EmbedSection extends AbstractSection<EmbedSectionData> {
  getType(): SectionType {
    return SectionType.EMBED
  }

  getComponent(): Component {
    return EmbedSectionVue
  }
}
