<script lang="ts" setup>
/**
 * ###############################################
 * ################### Input #####################
 * id - Proposal ID
 * uri - URI of the vessel entity
 * data - {@VesselCrewData} - Vessel crew data
 * layout - {@LayoutTemplate} - Layout template
 * ################### Emits #####################
 * 1. update:value - Updates the data {@VesselCrewData}
 * ################### Output ####################
 * Renders vessel editable crew section
 * ###############################################
 */
import { inject } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { AuthenticationContext } from '@/iam/types'
import { LayoutTemplate } from '@/sections/types'
import CrewCardEditor from '@/sections/vessel/crew/CrewCardEditor.vue'
import { linkMedia } from '@/services/MediaService'

import { Crew, VesselCrewData } from './types'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's data
   */
  data: VesselCrewData
  /**
   * The section's layout
   */
  layout: LayoutTemplate
  /**
   * The lifecycle hook
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselCrewData>): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
props.lifecycle({
  onHydrated: async () => {
    const crewAvatars = props.data.crewMembers.reduce((accumulator: string[], currentValue: Crew) => {
      return accumulator.concat(currentValue.avatar!)
    }, [])

    if (crewAvatars?.length) {
      await linkMedia({ authenticationContext }, props.proposalUri, crewAvatars)
    }
  },
} as unknown as EditableLifecycleHooks)

const updateCrewMember = (value: { data: Crew; index: number }) => {
  const data = ObjectUtil.deepCopy(props.data)
  data.crewMembers[value.index] = value.data
  emit('update:value', { sectionId: props.id, data })
}

const updateHeading = (value: string) => {
  const data: VesselCrewData = ObjectUtil.deepCopy(props.data)
  data.heading = value
  emit('update:value', { sectionId: props.id, data })
}
</script>
<template>
  <div class="mx-auto max-w-screen-xl p-4 @sm:p-8">
    <h1 class="font-bold text-2xl @sm:text-3xl text-center mb-4">
      <SingleLineTextEditor :value="props.data.heading || 'Crew'" @update:value="updateHeading($event)" />
    </h1>

    <div class="w-full flex flex-row @lg:grid grid-cols-3 gap-x-4 @lg:gap-8 overflow-x-auto">
      <CrewCardEditor
        v-for="(crew, index) of props.data.crewMembers"
        :key="`crew-card-${index}`"
        :crew-member="crew"
        :index="index"
        :uri="props.uri"
        :proposalUri="props.proposalUri"
        @update:value="updateCrewMember"
      />
    </div>
  </div>
</template>
