import { Serializable } from '@ankor-io/common/lang/Serializable'

import { EditableSection, JsonSection, Section, SectionTemplate } from './Section'
import { SlideType } from './SlideType'
import { Templating } from './Templating'

/**
 * The slide interface
 */
export interface GenericSlide<T, D> extends Templating<SlideTemplate>, Serializable<D> {
  /**
   * The id referencing either the entity behind this slide.
   * See {@link SlideType} to see what the available entities are
   */
  getId(): string

  /**
   * The slide uri
   */
  getUri(): string

  /**
   * This slide type
   */
  getType(): SlideType

  /**
   * Add a section to a specific position in the list
   *
   * @param sectionTemplate the section to add to this slide
   * @param index the position the sections should be added in. Adds the section
   * at the end of the list when the index is not defined
   */
  addSection<S extends GenericSlide<T, D>>(section: T, index?: number): S

  /**
   * Remove a section from this slide
   *
   * @param section the section to delete
   */
  deleteSection(section: T): T

  /**
   * Get all the sections belonging to this slide
   */
  getSections(): T[]

  /**
   * Get the proposal uri
   */
  getProposalUri(): string

  /**
   * Get the subdocument uri
   */
  getSubdocumentUri(): string
  /**
   * Get the current slide state
   */
  getState(): EditableSlideState
}

/**
 * Describe a slide template
 */
export type SlideTemplate = {
  /**
   * the slide type
   */
  type: SlideType
  /**
   * all the sections templates belonging to this slide template
   */
  sections: SectionTemplate[]
}

export type JsonSlide = {
  /**
   * The slide uri
   */
  uri: string
  /**
   * the slide type
   */
  type: SlideType
  /**
   * all the sections templates belonging to this slide template
   */
  sections: JsonSection<any>[]
  /**
   * The editable slide state
   */
  state: EditableSlideState
}

/**
 * Used as object initializer for a Slide constructor
 */
export type SlideInit = {
  /**
   * The slide id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal Uri
   */
  proposalUri: string
  /**
   * The editable slide state
   */
  state: EditableSlideState
}

export interface ProposalEditableSlideInterface extends GenericSlide<EditableSection<any>, JsonSlide> {
  /**
   * Marks the section as to be deleted, then deletes the section starting the remove lifecycle
   * @param section the section to mark as being deleted
   */
  markDelete(section: EditableSection<any>): void
  /**
   * Hydrates the slide
   */
  hydrate(): Promise<void>
  /**
   * de-hydrates the slide
   */
  deHydrate(): Promise<void>
  /**
   * Change the state of a slide
   * @param state the state to set this slide to
   */
  setState(state: EditableSlideState): void
  /**
   * Get if the slide is currently hydrating or not
   */
  isHydrating(): boolean
}

/**
 * Declare the Slide type as a generic slide of Sections
 */
export type Slide = GenericSlide<Section<any>, JsonSlide>
/**
 * Declare the EditableSlide type as a generic slide of EditableSections
 */
export type EditableSlide = GenericSlide<EditableSection<any>, any>

export type ProposalEditableSlide = ProposalEditableSlideInterface

export enum EditableSlideState {
  // The slide needs initialization
  NEEDS_HYDRATION = 'needs_hydration',
  // The slide has been initialized
  HYDRATED = 'hydrated',
  // The slide is about to be removed
  NEEDS_TO_GO = 'needs_to_go',
}
