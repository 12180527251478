import { GenericURI, IUri, URI_SEPARATOR, UserURI } from './Uri'

/**
 * Defines the Uri parser interface as the common contract for all uri parsers.
 * @param T the type of URI implementation that the parser is expected to return. It must be
 * an extension of a {@link GenericURI}
 */
export interface URIParser<T extends IUri> {
  /**
   * Parse a URI into the given arguments
   *
   * @param uri the uri to parse
   */
  parse(uri: string): T
}

export class GenericUriParser implements URIParser<IUri> {
  parse(uri: string): IUri {
    return new GenericURI(uri.split(URI_SEPARATOR))
  }
}

export class UserUriParser implements URIParser<IUri> {
  parse(uri: string): IUri {
    return new UserURI(uri.split(URI_SEPARATOR))
  }
}

