<script lang="ts" setup>
//
// How to use this layout component;
//  1. Pass in your columns
//  2. Pass in your row data
//  3. For standard text rendering (most tables), there is nothing else to do.
//  4. If you need to customize the rendering of a cell, you can use the slot named after the field name.
//
import { twMerge } from 'tailwind-merge'
import { useAttrs } from 'vue'

type ColumnDef = {
  label: string
  field: string
  type?: string
  classes?: any
  default?: string // a default value if the field is not present in the row.
}

const props = defineProps<{
  columns: ColumnDef[] // the definitions of the columns
  rows: any[] // the row data
  rowKey: string // the reactive key for the row
}>()

// numbers go right, everything else goes left.
const textAlignment = (dataType: string | undefined): string[] => {
  return dataType === 'number' ? ['text-right'] : ['text-left']
}

// Turn off the inheritAttrs as we apply them to the class using twMerge
defineOptions({
  inheritAttrs: false,
})

type Emits = {
  (e: 'rowClick', payload: { row: any; field: string }): void
  (e: 'rowDblClick', payload: { row: any; field: string }): void
}
const emits = defineEmits<Emits>()

//
// helper to clean up the usage of twMerge and classes below.
const classes = (attr: string) => {
  return (useAttrs().classes as any)?.[attr] || []
}
</script>
<template>
  <slot name="before"></slot>
  <table :class="[twMerge(`table-auto relative`, classes('table'), useAttrs().class as string)]">
    <thead :class="[twMerge(`z-20 sticky top-0`, classes('thead'))]">
      <tr>
        <template v-for="col of props.columns" :key="col.label">
          <th
            scope="col"
            :class="[
              twMerge(
                'uppercase text-xs p-3 md:p-4 bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400',
                textAlignment(col.type),
                col.classes?.th,
              ),
            ]"
          >
            {{ col.label }}
          </th>
        </template>
      </tr>
    </thead>
    <tbody
      :class="[
        twMerge(
          `overflow-y-scroll text-gray-500 dark:text-gray-400 outline-1 outline outline-gray-200 dark:outline-gray-600`,
          classes('tbody'),
        ),
      ]"
    >
      <!-- rows -->
      <template v-for="(row, rowIndex) of props.rows" :key="`${rowIndex}:${row[props.rowKey]}`">
        <tr
          :class="[
            twMerge(
              'bg-white dark:bg-gray-800',
              'border-b border-gray-100 dark:border-gray-700',
              'hover:bg-gray-50 dark:hover:bg-gray-600',
              classes('tr'),
            ),
          ]"
          :id="`row-${rowIndex}:${row[props.rowKey]}`"
        >
          <!-- columns -->
          <template v-for="(col, colIndex) of props.columns" :key="`${rowIndex}:${colIndex}:${row[props.rowKey]}`">
            <td
              :class="[twMerge('p-3 md:p-4', textAlignment(col.type), col.classes?.td || [])]"
              @click="emits('rowClick', { row, field: col.field })"
              @dblclick="emits('rowDblClick', { row, field: col.field })"
            >
              <!-- if a slot was supplied, use that -->
              <template v-if="$slots[col.field]">
                <slot :name="col.field" :row="row" :rowIndex="rowIndex" :col="col" :colIndex="colIndex">
                  {{ row[col.field] }}
                </slot>
              </template>
              <template v-else>
                <!-- is the value blank? -->
                <span v-if="!row[col.field]" class="empty-cell"></span>
                <template v-else>
                  <!-- show the value -->
                  {{ row[col.field] }}
                </template>
              </template>
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
  <slot name="after"></slot>
</template>
<style lang="scss">
/**
 * The table is outlined; to recreate this on the top row, we use a shadow.
 * It is not perfect, but it is close.
 *   > when the header is sticky, the border of the rows can be seen in the top corners
 *   > the (shadow) curve outlines are not perfect.
 */
table th:first-child {
  @apply rounded-ss-lg shadow-[-1px_0px,0px_-1px] shadow-gray-200 dark:shadow-gray-600;
}

table th:last-child {
  @apply rounded-se-lg shadow-[1px_0px,0px_-1px] shadow-gray-200 dark:shadow-gray-600;
}

table thead {
  @apply rounded-t-lg shadow-[-1px_0px,1px_-1px] shadow-gray-200 dark:shadow-gray-600;
}
</style>
