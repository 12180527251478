import { Component } from 'vue'
import gql from 'graphql-tag'

import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { ToysAmenitiesSectionData } from '@/sections/vessel/toys-amenities/types/types'

import ConfigurationComponent from './ToysAmenitiesConfiguration.vue'
import ToysAmenitiesSectionEditorVue from './ToysAmenitiesSectionEditor.vue'
import { Amenity, Toy } from '@ankor-io/common/vessel/types'

const QUERY = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      blueprint {
        toys {
          label
          quantity
        }
        amenities {
          label
          quantity
        }
        entertainment
        tenders
      }
    }
  }
`

export class ToysAmenitiesSectionEditor extends ProposalAbstractEditableSection<
  ToysAmenitiesSectionData,
  EditableProposal
> {
  async fetchData(): Promise<ToysAmenitiesSectionData> {
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: this.getSlideUri() },
      })
      .then(({ data }) => {
        const { blueprint = {} } = data.vessel
        return Promise.resolve({
          heading: 'Toys & Amenities',
          toys: (blueprint.toys || []).map(
            (toy: Toy) => `${toy.label}${toy.quantity && toy.quantity > 1 ? ` x ${toy.quantity}` : ''}`,
          ),
          amenities: (blueprint.amenities || []).map(
            (amenity: Amenity) =>
              `${amenity.label}${amenity.quantity && amenity.quantity > 1 ? ` x ${amenity.quantity}` : ''}`,
          ),
          entertainment: blueprint.entertainment || [],
          tenders: blueprint.tenders || [],
        })
      })
      .catch(() => {
        console.error('Error fetching vessel crew members')
        return Promise.resolve({} as ToysAmenitiesSectionData)
      })
  }

  getType(): SectionType {
    return SectionType.VESSEL_TOYS_AMENITIES
  }

  getComponent(): Component {
    return ToysAmenitiesSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return ConfigurationComponent
  }
}
