import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("line", {
      x1: "5.5",
      x2: "18.5",
      y1: "20.5",
      y2: "20.5",
      "stroke-linecap": "round"
    }),
    _createElementVNode("path", {
      d: "M8.80091 4.75832V11.5745C8.80091 12.2981 8.8361 12.8987 8.90649 13.3763C8.97687 13.8394 9.11765 14.2952 9.32881 14.7438C9.73705 15.6122 10.6099 16.0463 11.9472 16.0463C13.2986 16.0463 14.2348 15.67 14.7557 14.9175C15.2765 14.165 15.537 13.0724 15.537 11.6397V4.75832H14.4178C14.2066 4.75832 14.1011 4.64978 14.1011 4.43271V3.32561C14.1011 3.10854 14.2066 3 14.4178 3H18.6833C18.8944 3 19 3.10854 19 3.32561V4.43271C19 4.64978 18.8944 4.75832 18.6833 4.75832H17.5641V12.0955C17.5641 13.6874 17.1981 15.0333 16.4661 16.1331C16.0719 16.712 15.4947 17.1679 14.7345 17.5007C13.9884 17.8336 13.0804 18 12.0106 18C9.9271 18 8.47712 17.5369 7.66063 16.6107C6.84414 15.67 6.4359 14.2446 6.4359 12.3343V4.75832H5.31674C5.10558 4.75832 5 4.64978 5 4.43271V3.32561C5 3.10854 5.10558 3 5.31674 3H9.92006C10.1312 3 10.2368 3.10854 10.2368 3.32561V4.43271C10.2368 4.64978 10.1312 4.75832 9.92006 4.75832H8.80091Z"
    })
  ]))
}