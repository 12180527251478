import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { AbstractSection } from '@/services/proposal/Section'
import VesselShowcaseSectionVue from '@/sections/vessel-showcase/VesselShowcaseSection.vue'
import { VesselShowcaseSectionData } from '@/sections/vessel-showcase/types/types'

export class VesselShowcaseSection extends AbstractSection<VesselShowcaseSectionData> {
  getType(): SectionType {
    return SectionType.VESSEL_SHOWCASE
  }

  getComponent(): Component {
    return VesselShowcaseSectionVue
  }
}
