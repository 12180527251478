import { Component } from 'vue'

import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { CheckListItem } from '@ankor-io/common/itinerary/Itinerary'

import ChecklistComponent from './ChecklistSectionEditor.vue'
import ConfigurationComponent from '@/sections/checklist/ChecklistConfiguration.vue'

export interface ChecklistEditorData {
  heading: string
  subheading: string
  checklist: CheckListItem[]
}

const defaultChecklistItems: CheckListItem[] = [
  {
    title: 'Passport',
    description:
      'Time to get another stamp in that passport! You’ll not get far without it, so it should be at the top of your “must have” list. Pro tip: email a photo of the key pages to yourself.',
  },
  {
    title: 'Licences and Permits',
    description:
      'Let’s get you out on the water! Whether you’re in a car, on a boat, whizzing around on your jet ski or diving into the deep you might need a permit or licence. Don’t miss out!',
  },
  {
    title: 'Visa / Visitor’s Permit',
    description:
      'You’ll need a visa to enter lots of countries. This is one to get on to straight away as they can take a while to come through.',
  },
  {
    title: 'Camera',
    description:
      'Your phone is great for quick shots, but if you need a long zoom lens, want something particularly cinematic or to shoot in dim light, then a dedicated camera is the way to go.',
  },
  {
    title: 'Personal devices',
    description:
      'Bring all your chargers and an international adapter to keep your electronics juiced. Pro tip: if you bring a power board you can charge everything with one international adapter.',
  },
  {
    title: 'Books or Kindle',
    description:
      'You’ll have as many quiet, secluded moments as you want. Will you feel like a trashy airport novel or will you delve into one of the classics? Why not bring both, or a Kindle full?',
  },
  {
    title: 'Reading & sunglasses',
    description:
      'Don’t forget your shades and your reading glasses. That way you’ll be able to enjoy the incredible views and a local magazine.',
  },
]

export class ChecklistSectionEditor extends ProposalAbstractEditableSection<ChecklistEditorData, EditableProposal> {
  async fetchData(): Promise<ChecklistEditorData> {
    return Promise.resolve({
      heading: 'Before you board',
      subheading: 'Gather what you need for your trip, and start imagining yourself on board.',
      checklist: defaultChecklistItems,
    })
  }

  getType(): SectionType {
    return SectionType.ITINERARY_CHECKLIST
  }

  getComponent(): Component {
    return ChecklistComponent
  }

  getConfigurationComponent(): Component | null {
    return ConfigurationComponent
  }
}
