import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M4.5 21V3H12.2848C13.7152 3 14.9082 3.19629 15.8639 3.58887C16.8196 3.98145 17.538 4.52637 18.019 5.22363C18.5 5.91504 18.7405 6.71191 18.7405 7.61426C18.7405 8.31738 18.5886 8.93555 18.2848 9.46875C17.981 9.99609 17.5633 10.4297 17.0316 10.7695C16.5063 11.1035 15.9051 11.3408 15.2278 11.4814V11.6572C15.9684 11.6865 16.6614 11.8799 17.307 12.2373C17.9589 12.5947 18.4873 13.0957 18.8924 13.7402C19.2975 14.3789 19.5 15.1406 19.5 16.0254C19.5 16.9805 19.2437 17.833 18.731 18.583C18.2247 19.3271 17.4747 19.916 16.481 20.3496C15.4873 20.7832 14.2627 21 12.807 21H4.5ZM8.61076 17.8887H11.962C13.1076 17.8887 13.943 17.6865 14.4684 17.2822C14.9937 16.8721 15.2563 16.3271 15.2563 15.6475C15.2563 15.1494 15.1266 14.71 14.8671 14.3291C14.6076 13.9482 14.2373 13.6494 13.7563 13.4326C13.2816 13.2158 12.7152 13.1074 12.057 13.1074H8.61076V17.8887ZM8.61076 10.5322H11.6582C12.2215 10.5322 12.7215 10.4414 13.1582 10.2598C13.6013 10.0723 13.9494 9.80859 14.2025 9.46875C14.462 9.12891 14.5918 8.72168 14.5918 8.24707C14.5918 7.59668 14.3418 7.07227 13.8418 6.67383C13.3481 6.27539 12.6456 6.07617 11.7342 6.07617H8.61076V10.5322Z"
    })
  ]))
}