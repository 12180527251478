<script setup lang="ts">
import { LayoutTemplate } from '../types'
import { OutlineCalendar } from '@ankor-io/icons/outline'
import { SummarySectionData } from '@/sections/summary/SummarySection'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

type Props = {
  layout: LayoutTemplate
  data: SummarySectionData
}

const setTextAlignment = (): string => {
  if (props.layout?.options?.alignment === 'right') {
    return 'text-right'
  }

  if (props.layout?.options?.alignment === 'left') {
    return 'text-left'
  }

  return 'text-center'
}

const props = defineProps<Props>()
</script>
<template>
  <div class="itinerary-summary w-full flex justify-center p-4 sm:p-8" :class="setTextAlignment">
    <div class="max-w-screen-xl w-full border-b border-b-gray-200 flex justify-center pb-9 gap-x-2.5">
      <OutlineCalendar class="w-6 h-6" />
      <h2 class="text-xl">
        <SingleLineTextEditor :value="props.data.summaryTitle" :is-editable="false" />
      </h2>
    </div>
  </div>
</template>
