<script lang="ts" setup>
/**
 * ###############################################
 * ################### Input #####################
 * id - Proposal ID
 * uri - URI of the vessel entity
 * data - {@VesselCrewData} - Vessel crew data
 * layout - {@LayoutTemplate} - Layout template
 * ################### Output ####################
 * Renders vessel crew section (non-editable)
 * ###############################################
 */
import { LifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { Vessel } from '@ankor-io/common/vessel/types'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import CrewCard from '@/sections/vessel/crew/CrewCard.vue'

import { VesselCrewData } from './types'

type Props = {
  id: string
  proposalUri: string
  data: VesselCrewData
  layout: LayoutTemplate
  lifecycle: Runnable<LifecycleHooks>
  source?: { uri: string; [key: string]: any }
}

const props = defineProps<Props>()

props.lifecycle({
  onBeforeAttach() {
    // if the source is provided and the data is null, use that to hydrate
    if (props.source && !props.data) {
      //@ts-ignore this works because of the lifecycle bound to the section class
      this.setData(this.dataFrom<Vessel>(props.source))
    }
  },
} as LifecycleHooks)
</script>
<template>
  <div v-if="props.data?.crewMembers?.length" id="vessel-crew-section" class="mx-auto max-w-screen-xl p-4 @sm:p-8">
    <h1 class="font-bold text-2xl sm:text-3xl text-center mb-4">
      <SingleLineTextEditor :value="props.data.heading || 'Crew'" :is-editable="false" />
    </h1>

    <div class="w-full flex flex-row @lg:grid grid-cols-3 gap-x-4 @lg:gap-8 overflow-x-auto">
      <CrewCard
        v-for="(crew, index) of props.data.crewMembers"
        :key="`crew-card-${index}-${crew.name}`"
        :proposalUri="props.proposalUri"
        :crew-member="crew"
        :index="index"
      />
    </div>
  </div>
</template>
