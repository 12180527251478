import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 4C2 3.44772 2.44772 3 3 3H10C10.5523 3 11 3.44772 11 4C11 4.55228 10.5523 5 10 5H3C2.44772 5 2 4.55228 2 4Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 6.5C2 6.22386 2.22386 6 2.5 6H21.5C21.7761 6 22 6.22386 22 6.5C22 6.77614 21.7761 7 21.5 7H2.5C2.22386 7 2 6.77614 2 6.5Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 8.5C2 8.22386 2.22386 8 2.5 8H21.5C21.7761 8 22 8.22386 22 8.5C22 8.77614 21.7761 9 21.5 9H2.5C2.22386 9 2 8.77614 2 8.5Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 10.5C2 10.2239 2.22386 10 2.5 10H21.5C21.7761 10 22 10.2239 22 10.5C22 10.7761 21.7761 11 21.5 11H2.5C2.22386 11 2 10.7761 2 10.5Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 12.5C2 12.2239 2.22386 12 2.5 12H21.5C21.7761 12 22 12.2239 22 12.5C22 12.7761 21.7761 13 21.5 13H2.5C2.22386 13 2 12.7761 2 12.5Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 14.5C2 14.2239 2.22386 14 2.5 14H21.5C21.7761 14 22 14.2239 22 14.5C22 14.7761 21.7761 15 21.5 15H2.5C2.22386 15 2 14.7761 2 14.5Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 20.5C2 20.2239 2.22386 20 2.5 20H21.5C21.7761 20 22 20.2239 22 20.5C22 20.7761 21.7761 21 21.5 21H2.5C2.22386 21 2 20.7761 2 20.5Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 18C2 17.4477 2.44772 17 3 17H10C10.5523 17 11 17.4477 11 18C11 18.5523 10.5523 19 10 19H3C2.44772 19 2 18.5523 2 18Z",
      "clip-rule": "evenodd"
    })
  ]))
}