import gql from 'graphql-tag'
import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import SummarySectionEditorVue from '@/sections/summary/SummarySectionEditor.vue'

const QUERY = gql`
  query Itinerary($URI: String!) {
    itinerary(uri: $URI) {
      segments {
        label
      }
      from {
        location {
          region
        }
      }
    }
  }
`

export type SummarySectionEditorData = {
  summaryTitle: string
}

export class SummarySectionEditor extends ProposalAbstractEditableSection<SummarySectionEditorData, EditableProposal> {
  async fetchData(): Promise<SummarySectionEditorData> {
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: this.getSlideUri() },
      })
      .then(({ data }) => {
        const duration = data.itinerary?.segments?.length || null
        const region = data.itinerary?.from.location?.region || ''
        const adventureString = !duration ? 'Adventure in ' : 'day(s) adventure in '
        return Promise.resolve({
          summaryTitle: `${duration}${adventureString}${region}` || '',
        })
      })
  }

  getType(): SectionType {
    return SectionType.ITINERARY_SUMMARY
  }

  getComponent(): Component {
    return SummarySectionEditorVue
  }
}
