<script lang="ts" setup>
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { Ref, onBeforeUpdate, onMounted, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { Itinerary } from '@ankor-io/common/itinerary/Itinerary'
import { OutlineCalendar } from '@ankor-io/icons/outline'
import { SolidArrowRight } from '@ankor-io/icons/solid'

import Loader from '@/components/Loader.vue'
import { useStowageService } from '@/services/stowage/StowageService'
import { durationToDays } from '@/utils/date'

import Tags from './Tags.vue'

export interface Props {
  uri: string
}

const props = defineProps<Props>()

const isLoading: Ref<boolean> = ref(true)
const doc: Ref<Itinerary | null> = ref(null)
const uri: Ref<string> = ref('')
const stowageService = useStowageService()

onMounted(async () => {
  uri.value = props.uri
  isLoading.value = true
  doc.value = (await stowageService.getURIDocument<Itinerary>(uri.value)) as Itinerary
  isLoading.value = false
})

onBeforeUpdate(async () => {
  if (props.uri !== uri.value) {
    uri.value = props.uri
    isLoading.value = true
    doc.value = (await stowageService.getURIDocument<Itinerary>(uri.value)) as Itinerary
    isLoading.value = false
  }
})
</script>
<template>
  <div v-if="isLoading" class="flex items-center justify-center h-full">
    <Loader />
  </div>
  <div v-else-if="doc" class="px-6 pb-6" :class="$attrs.class" :key="props.uri">
    <div
      v-if="doc.from"
      id="route-preview-header"
      class="flex flex-row content-start items-center gap-3 font-bold text-2xl pt-4 pb-8 text-gray-900 dark:text-white"
    >
      <div>{{ doc.from.name }}</div>
      <div><SolidArrowRight class="h-6 w-4" /></div>
      <div v-if="doc.to">{{ doc.to.name }}</div>
    </div>

    <div class="flex gap-x-4">
      <div v-if="doc.duration" class="inline-flex items-center gap-x-2 text-gray-900 dark:text-white">
        <OutlineCalendar class="size-4" />
        <span>{{ durationToDays(doc.duration) }} Days</span>
      </div>
      <!-- FIXME: Put the tags in the doc -->
      <Tags :tags="doc.tags || []" />
    </div>

    <!-- Pills of segments -->
    <div v-if="doc.segments" class="py-8">
      <ul class="flex flex-wrap items-center gap-x-2 gap-y-2">
        <template v-for="(segment, segIdx) in doc.segments" :key="segment.label">
          <template v-for="(stop, stopIdx) in segment.stops" :key="stop.place.uri">
            <li v-if="segIdx + stopIdx !== 0" aria-hidden="true">
              <ChevronRightIcon class="size-4 text-gray-900 dark:text-white" />
            </li>
            <li
              class="inline-flex items-center rounded-full px-3 py-2 text-xs font-medium ring-1 ring-inset ring-gray-200 text-gray-900 dark:text-white"
            >
              {{ stop.place.name }}
            </li>
          </template>
        </template>
      </ul>
    </div>

    <!-- itinerary images -->
    <div v-if="doc.images">
      <p class="text-sm font-semibold pb-3 text-gray-900 dark:text-white">All Photos</p>
      <div v-if="doc.images.length >= 2" class="grid grid-cols-4 my-4 gap-4 mx-auto">
        <AssetViewer
          v-for="f in doc.images.filter((i) => !!i)"
          class="w-full h-full object-cover object-center aspect-[1.44] rounded-sm md:rounded-lg"
          :key="f"
          :url="`/media/${f}`"
        />
      </div>
    </div>

    <!-- itinerary desc -->
    <div v-if="doc.description">
      <p class="text-sm font-semibold pb-3 text-gray-900 dark:text-white">Route Overview</p>
      <p class="text-sm font-normal text-gray-500 dark:text-gray-400" v-html="doc.description"></p>
    </div>

    <!-- map -->

    <!-- days -->
    <template v-if="doc.segments">
      <!-- each segment -->
      <div v-for="(segment, segIdx) in doc.segments" :key="segIdx" class="relative">
        <!-- hr with label -->
        <div class="sticky -top-px inline-flex items-center justify-center w-full bg-white dark:bg-gray-700">
          <hr class="w-full my-8 border border-gray-500 dark:border-gray-400" />
          <span
            class="absolute uppercase font-bold text-3xl px-6 -translate-x-1/2 left-1/2 text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-700"
          >
            {{ segment.label }}
          </span>
        </div>

        <template v-if="segment.description">
          <div class="text-sm font-semibold pb-3 text-gray-900 dark:text-white">Overview</div>
          <div class="text-sm font-normal text-gray-500 dark:text-gray-400" v-html="segment.description"></div>
        </template>

        <!-- for each stop -->
        <template v-for="(stop, stopIdx) in segment.stops" :key="`${segIdx}-${stopIdx}`">
          <hr class="w-full my-8 border border-gray-500 dark:border-gray-400" />

          <div class="text-sm font-semibold text-gray-900 dark:text-white">
            {{ segIdx + 1 }}{{ segment.stops.length > 1 ? String.fromCharCode(97 + stopIdx) : '' }}.
            {{ stop.place.name }}
            <!-- distance, sometimes there is a distance object but no value -->
            <span v-if="stop.pathFromPrevious?.distance?.value" class="text-gray-500 dark:text-gray-400">
              / Approx {{ stop.pathFromPrevious.distance.value }} {{ stop.pathFromPrevious.distance.unit }} from last
              location
            </span>
          </div>

          <!-- images -->
          <div v-if="stop.place.images.length > 0" class="grid grid-cols-4 my-4 gap-4 mx-auto">
            <AssetViewer
              v-for="f in stop.place.images.filter((i) => !!i)"
              class="w-full h-full object-cover object-center aspect-[1.44] rounded-sm md:rounded-lg"
              :key="f"
              :url="`/media/${f}`"
            />
          </div>
          <!-- Place description -->
          <div v-if="stop.place.description">
            <div class="text-sm font-semibold pb-3 text-gray-900 dark:text-white">Overview</div>
            <div class="text-sm font-normal text-gray-500 dark:text-gray-400" v-html="stop.place.description"></div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
