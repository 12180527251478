<script lang="ts" setup>
import mapboxgl, { LngLatBoundsLike, Map, Marker } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Ref, createVNode, onMounted, ref, render } from 'vue'

import { Segment } from '@ankor-io/common/itinerary/Itinerary'
import { UUID } from '@ankor-io/common/lang/uuid'
import { HiOutlineArrowsPointingIn, HiOutlineArrowsPointingOut } from '@ankor-io/icons/hi_outline'
import { OutlineDoubleWavy, OutlineMinusCircle, OutlinePlusCircle } from '@ankor-io/icons/outline'
import { SolidClock, SolidFuel, SolidItinerarySolidFill, SolidLocationMarker } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import { LayoutTemplate } from '@/sections/types'

import CommandButton from './CommandButton.vue'
import MarkerBuilder from './MarkerBuilder.vue'
import ColouredLocationCard from './coloured/LocationCard.vue'
import ColouredStatisticCard from './coloured/StatisticCard.vue'
import MonochromaticLocationCard from './monochromatic/LocationCard.vue'
import MonochromaticStatisticCard from './monochromatic/StatisticCard.vue'
import { MapSectionData, StopWithSegmentLabel } from './types/types'

type Props = {
  uri: string
  data: MapSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()
const COLOURED_MAPBOX_STYLE = 'mapbox://styles/ankor-io/cl7jmtqs9000314qm7uyc31m7'
const MONOCHROMATIC_MAPBOX_STYLE = 'mapbox://styles/ankor-io/clgbqcb09001i01o5fmrzwtzz'

const selectedMapBoxStyle: Ref<string> = ref(COLOURED_MAPBOX_STYLE)

const showLocationCards: Ref<boolean> = ref(true)
// map statistics data set in the MapConfiguration
const showDuration: Ref<boolean> = ref(true)
const showDistance: Ref<boolean> = ref(true)
const showFuelConsumption: Ref<boolean> = ref(true)

/**
 * @deprecated this variable is deprecated after feature toggle, but is required for backward compatability
 */
const tripDuration: Ref<string> = ref('')

/**
 * @deprecated this variable is deprecated after feature toggle, but is required for backward compatability
 */
const distanceTravelled: Ref<string> = ref('')
/**
 * @deprecated this variable is deprecated after feature toggle, but is required for backward compatability
 */
const distanceTravelledUnit: Ref<string> = ref('nm')

/**
 * @deprecated this variable is deprecated after feature toggle, but is required for backward compatability
 */
const cruisingSpeed: Ref<string> = ref('')
/**
 * @deprecated this variable is deprecated after feature toggle, but is required for backward compatability
 */
const cruisingSpeedUnit: Ref<string> = ref('')

/**
 * @deprecated this variable is deprecated after feature toggle, but is required for backward compatability
 */
const fuelConsumption: Ref<string> = ref('')
/**
 * @deprecated this variable is deprecated after feature toggle, but is required for backward compatability
 */
const fuelConsumptionUnit: Ref<string> = ref('')

const setMapBoxStyle = (layoutType: string) => {
  if (layoutType === 'monochromatic') {
    selectedMapBoxStyle.value = MONOCHROMATIC_MAPBOX_STYLE
  } else {
    selectedMapBoxStyle.value = COLOURED_MAPBOX_STYLE
  }
}

const getMapStatistics = (options: any) => {
  // Because value can be false, a simple props.layout.options.field || true will also fallback to true
  showDistance.value = typeof options?.showDistance === 'boolean' ? options?.showDistance : true
  showDuration.value = typeof options?.showDuration === 'boolean' ? options?.showDuration : true
  showFuelConsumption.value = typeof options?.showFuel === 'boolean' ? options?.showFuel : true

  distanceTravelled.value = options?.distanceTravelled?.value || ''
  distanceTravelledUnit.value = options?.distanceTravelled?.unit || 'nm'

  cruisingSpeed.value = options?.cruisingSpeed?.value || ''
  cruisingSpeedUnit.value = options?.cruisingSpeed?.unit || ''

  fuelConsumption.value = options?.fuelConsumption?.value || ''
  fuelConsumptionUnit.value = options?.fuelConsumption?.unit || ''

  calculateTripDuration()
}

const calculateTripDuration = () => {
  if (!cruisingSpeed.value || !cruisingSpeedUnit.value || !distanceTravelled.value) {
    tripDuration.value = ''
    return
  }

  // First convert all values into the same metric (kms)
  const NM_TO_KM = 1.852
  let distance = Number(distanceTravelled.value)
  if (distance && distanceTravelledUnit.value === 'nm') {
    distance *= NM_TO_KM
  }

  let speed = Number(cruisingSpeed.value)
  if (speed && cruisingSpeedUnit.value === 'knots') {
    speed *= NM_TO_KM
  }

  if (distance && speed) {
    const timeInDecimals = distance / speed
    const hours = timeInDecimals.toString().split('.')[0]
    if (hours === '0') {
      tripDuration.value = `${(timeInDecimals * 0.6).toString()} min`
    } else {
      const minutesAsDecimal = timeInDecimals.toFixed(2).toString().split('.')[1]
      const minutes = Math.round(Number(minutesAsDecimal) * 0.6).toString()
      if (minutes === '0') {
        tripDuration.value = `${hours} hr`
      } else {
        tripDuration.value = `${hours} hr ${minutes} min`
      }
    }
  }
}

/**
 * On mounted, create the map. If there are segments, render that.
 */
onMounted(() => {
  setMapBoxStyle(props.layout.type)
  showLocationCards.value =
    typeof props.layout.options?.showLocationCards === 'boolean' ? props.layout.options?.showLocationCards : true

  // await above showLocationCards allows dom the time to hide/show the monochromatic without affecting map width
  setTimeout(() => {
    createMap()
    if (props.data.segments) {
      setSegments(props.data.segments)
    }

    getMapStatistics(props.layout.options)
  }, 1)
})

const mapElementId = `${UUID.timeBased()}-itinerary-map`
const mapCommandsId = `${UUID.timeBased()}-map-commands`
const tripDetailsId = `${UUID.timeBased()}-trip-details`

const isFullScreen: Ref<boolean> = ref(false)

const overlayContainer: Ref<HTMLElement | null> = ref(null)
const selectedMarker: Ref<number> = ref(0)

const defaultZoom = ref(9)
const hasChanged = ref(false)

// setup the mapbox access token. Required for the map to work
// checked on mapbox and this token has public scopes only so it is safe
// to keep on the client
const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

const map: Ref<Map | null> = ref(null)
const markers: Ref<Marker[]> = ref([])
const itineraryStops: Ref<StopWithSegmentLabel[]> = ref([])

// Initialize the mapbox instance with markers
const createMap = () => {
  mapboxgl.accessToken = accessToken
  map.value = new mapboxgl.Map({
    // the container that will hold the map
    container: mapElementId,
    // the map style
    style: selectedMapBoxStyle.value,
    // the center of the map
    center: [0, 0],
    // the initial zoom value
    zoom: 9,
    // projection is not customisable and it is always set to globe as per current requirements
    projection: { name: 'globe' },
    // disable double click zoom
    doubleClickZoom: false,
  })

  defaultZoom.value = map.value.getZoom()
  // disable zoom on scroll
  map.value.scrollZoom.disable()
}

// Initialize the mapbox instance with markers
const setSegments = (segments: Segment[]) => {
  itineraryStops.value = []

  segments.forEach((segment, segmentIndex) =>
    segment.stops.forEach((stop, stopIndex, stops) => {
      const markerLabel = stops.length > 1 ? String.fromCharCode((stopIndex % 26) + 'a'.charCodeAt(0)) : ''

      return itineraryStops.value.push({
        label: segment.label,
        markerLabel: `${segmentIndex + 1}${markerLabel}`,
        stopIndex,
        segmentIndex,
        ...stop,
      })
    }),
  )

  drawMarkers()
}

const drawMarkers = () => {
  // reset the array of markers
  markers.value = []

  for (let i = 0; i < itineraryStops.value.length; i++) {
    const place = itineraryStops.value[i].place

    const virtualMarkerNode = createVNode(
      MarkerBuilder,
      // MarkerBuilder component props definitions
      {
        markerLabel: itineraryStops.value[i].markerLabel,
        markerIndex: i, // This is the index of marker relative to all markers in the itinerary
        placeName: place.name,
        mapLayoutType: props.layout.type,
        latitude: place.location.coordinates.latitude,
        longitude: place.location.coordinates.longitude,
        handleSelect: highlightCard,
        zoomToMarkerRunnable: zoomToMarker,
      },
    )

    const markerWrapper: HTMLDivElement = document.createElement('div')

    // render the virtual marker into the wrapper
    render(virtualMarkerNode, markerWrapper)

    markers.value.push(
      new mapboxgl.Marker({
        element: markerWrapper,
        anchor: 'bottom-left',
        offset: new mapboxgl.Point(-18, -42),
      })
        .setLngLat([place.location.coordinates.longitude, place.location.coordinates.latitude])
        // the map cannot possibly be null here since it has been defined above
        .addTo(map.value!),
    )
  }

  map.value!.resize()
  // once the map and markers have been set and loaded,
  // make sure the markers fit the bounds
  fitMarkers()
}

/**
 * Get the south west and north east bounds from all the markers to fit them in the map.
 * Markers should never be hidden by the overlay itineraryLocationCard, therefore the bounbaries needs to
 * be calculated based on the current viewport size.
 * When the viewport width is less than 1080px the overlay occupies the bottom of the map.
 * It otherwise occupies the left of the map.
 */
const fitMarkers = (): void => {
  if (map.value && markers.value?.length > 0) {
    if (markers.value.length === 1) {
      map.value.setCenter(markers.value[0].getLngLat())
      map.value.setZoom(defaultZoom.value)
      return
    }

    // good to fit
    const maxLat: number = markers.value
      .map((marker) => marker.getLngLat().lat)
      .reduce((prev, next) => (prev >= next ? prev : next))
    const maxLon: number = markers.value
      .map((marker) => marker.getLngLat().lng)
      .reduce((prev, next) => (prev >= next ? prev : next))
    const minLat: number = markers.value
      .map((marker) => marker.getLngLat().lat)
      .reduce((prev, next) => (prev <= next ? prev : next))
    const minLon: number = markers.value
      .map((marker) => marker.getLngLat().lng)
      .reduce((prev, next) => (prev <= next ? prev : next))
    const sw = [minLon, minLat]
    const ne = [maxLon, maxLat]
    const bounds = [sw, ne]

    setBounds(bounds as LngLatBoundsLike)
  }
}

const setBounds = (bounds: LngLatBoundsLike) => {
  if (map.value) {
    /**
     * get the current width so we can determine the bottom and left padding dynamically
     */
    // const windowWidth = window.innerWidth
    // const bottom = windowWidth >= lgBreakpoint ? 50 : 150
    // const left = windowWidth >= lgBreakpoint ? 250 : 50

    let leftPadding = 200
    leftPadding = props.layout.type === 'coloured' && showLocationCards.value ? 250 : 50

    map.value.fitBounds(bounds, {
      padding: {
        top: 50,
        bottom: 50, // bottom, related to locationCard space
        left: leftPadding, // left, related to locationCard space
        right: 50,
      },
    })
  }
}

/**
 * Fit a single marker into the map bounds
 *
 * @param long the marker longitude coordinate
 * @param lat the marker latitude coordinate
 */
const zoomToMarker = (long: number, lat: number): void => {
  if (map.value) {
    const currentZoomLevel = hasChanged.value ? defaultZoom.value : map.value.getZoom() + 2
    map.value.flyTo({
      zoom: currentZoomLevel,
      center: [long, lat],
    })

    hasChanged.value = false
  }
}

/**
 * Mapbox standard zoom in
 */
const zoomIn = () => {
  if (map.value) {
    map.value.zoomIn()
  }
}
/**
 * Mapbox standard zoom out
 */
const zoomOut = () => {
  if (map.value) {
    map.value.zoomOut()
  }
}

/**
 * Remove the map from the dom and re renders the map at full screen.
 * This operation is somwhat expensive but still not putting the browser
 * under pressure for now.
 */
const toggleFullScreen = () => {
  if (map.value) {
    // remove the map, this is necessary so the canvas can be redrawn with the full screen height
    map.value?.remove()
    isFullScreen.value = !isFullScreen.value

    // a symbolic wait for the map to clear before init it again
    setTimeout(() => {
      createMap()
      drawMarkers() // re-add markers and zoom bounds
    }, 1)
  }
}

/**
 * Scrolls to the itinerary step card number associated with the
 * selected marker number
 *
 * Make sure to update this method if the width/height and margin values of the cards
 * have been changed.
 *
 * @param selected the selected step marker number in the itinerary
 */
const highlightCard = (markerIndex: number): void => {
  if (selectedMarker.value !== markerIndex) {
    hasChanged.value = true
  }

  selectedMarker.value = markerIndex

  // since we have a double click zoom event (current zoom + 2)
  // we need to make sure the markers are fitted
  // when a new selection happens. To avoid overzooming
  fitMarkers()

  // vertical scroll
  const cardHeight = props.layout.type === 'coloured' ? 308 : 244
  const cardMarginBottom = 20
  overlayContainer.value?.scrollTo(0, (cardHeight + cardMarginBottom) * markerIndex)
}
</script>
<template>
  <div v-if="!props.data.segments" class="flex items-center justify-center h-[70vh] @sm:h-[calc(100vh-15rem)]">
    <Spinner />
  </div>
  <div
    v-show="props.data.segments.length > 0"
    :class="[
      isFullScreen ? 'fixed w-screen h-screen top-0 z-40' : 'h-[70vh] @sm:h-[calc(100vh-15rem)] relative flex',
      $route.path === '/' ? 'flex-wrap' : 'flex-row', // set flex direction for the preview if it is opened in a new tab or on the home screen
    ]"
  >
    <div class="h-full flex-auto w-full" :id="mapElementId">
      <!-- The map commands -->
      <div
        class="absolute top-5 rounded-lg flex gap-1.5"
        :id="mapCommandsId"
        :class="[
          isFullScreen ? 'z-40' : 'z-20',
          props.layout.type === 'monochromatic'
            ? 'left-1/2 @sm:left-5 -translate-x-1/2 @sm:translate-x-0'
            : 'left-1/2 @sm:left-[27rem] -translate-x-1/2 @sm:translate-x-0',
        ]"
      >
        <CommandButton class="hidden" :callback="toggleFullScreen">
          <HiOutlineArrowsPointingOut
            v-if="!isFullScreen"
            class="stroke-white w-4 group-hover:stroke-theme-primary group-active:stroke-theme-primary group-focus:stroke-theme-primary"
          />
          <HiOutlineArrowsPointingIn
            v-else
            class="stroke-white w-4 group-hover:stroke-theme-primary group-active:stroke-theme-primary group-focus:stroke-theme-primary"
          />
        </CommandButton>
        <CommandButton :callback="zoomIn">
          <OutlinePlusCircle
            class="stroke-white w-4 group-hover:stroke-theme-primary group-active:stroke-theme-primary group-focus:stroke-theme-primary"
          />
        </CommandButton>
        <CommandButton :callback="zoomOut">
          <OutlineMinusCircle
            class="stroke-white w-4 group-hover:stroke-theme-primary group-active:stroke-theme-primary group-focus:stroke-theme-primary"
          />
        </CommandButton>
        <CommandButton :callback="fitMarkers">
          <SolidItinerarySolidFill
            class="stroke-white w-4 group-hover:stroke-theme-primary group-active:stroke-theme-primary group-focus:stroke-theme-primary"
          />
        </CommandButton>
      </div>

      <!-- The monochromatic layout statistics -->
      <div
        v-if="props.layout.type === 'monochromatic'"
        class="absolute flex bottom-2 @sm:bottom-8 left-1/2 @sm:left-8 -translate-x-1/2 @sm:translate-x-0 gap-x-2 @sm:gap-x-5"
        :id="tripDetailsId"
        :class="isFullScreen ? 'z-40' : 'z-20'"
      >
        <MonochromaticStatisticCard
          v-if="showDuration"
          text="Approx. Duration"
          :content="props.data.statistics?.tripDuration || tripDuration || 'TBD'"
        >
          <template #prefix>
            <SolidClock class="fill-white stroke-none h-6 w-6" />
          </template>
        </MonochromaticStatisticCard>
        <MonochromaticStatisticCard
          v-if="showDistance"
          text="Approx. Distance"
          :content="`${props.data.statistics?.distanceTravelled || distanceTravelled || 'TBD'} ${
            props.data.statistics?.distanceTravelledUnit || distanceTravelledUnit
          }`"
        >
          <template #prefix>
            <SolidLocationMarker class="fill-white stroke-none h-6 w-6" />
          </template>
        </MonochromaticStatisticCard>
        <MonochromaticStatisticCard
          v-if="showFuelConsumption"
          text="Approx. Fuel"
          :content="`${props.data.statistics?.fuelConsumption || fuelConsumption || 'TBD'} ${
            props.data.statistics?.fuelConsumptionUnit || fuelConsumptionUnit
          }`"
        >
          <template #prefix>
            <SolidFuel class="fill-white stroke-none h-6 w-6" />
          </template>
        </MonochromaticStatisticCard>
      </div>

      <!-- The coloured layout statistics -->
      <div
        v-else
        class="absolute flex bottom-8 right-1/2 @sm:right-8 translate-x-1/2 @sm:translate-x-0 gap-x-2 @sm:gap-x-5"
        :id="tripDetailsId"
        :class="isFullScreen ? 'z-40' : 'z-20'"
      >
        <ColouredStatisticCard
          v-if="showDuration"
          text="Approx. Duration"
          :content="props.data.statistics?.tripDuration || tripDuration || 'TBD'"
        >
          <template #prefix>
            <SolidClock class="fill-white stroke-none h-6 w-6" />
          </template>
        </ColouredStatisticCard>
        <ColouredStatisticCard
          v-if="showDistance"
          text="Approx. Distance"
          :content="`${props.data.statistics?.distanceTravelled || distanceTravelled || 'TBD'} ${
            props.data.statistics?.distanceTravelledUnit || distanceTravelledUnit
          }`"
        >
          <template #prefix>
            <SolidLocationMarker class="fill-white stroke-none h-6 w-6" />
          </template>
        </ColouredStatisticCard>
        <ColouredStatisticCard
          v-if="showFuelConsumption"
          text="Approx. Fuel"
          :content="`${props.data.statistics?.fuelConsumption || fuelConsumption || 'TBD'} ${
            props.data.statistics?.fuelConsumptionUnit || fuelConsumptionUnit
          }`"
        >
          <template #prefix>
            <SolidFuel class="fill-white stroke-none h-6 w-6" />
          </template>
        </ColouredStatisticCard>
      </div>
    </div>

    <template v-if="showLocationCards">
      <!-- The monochromatic layout location cards -->
      <div
        v-if="props.layout.type === 'monochromatic'"
        id="monochromatic-layout-cards-container"
        class="min-w-[45.625rem] bg-white p-10 hidden @sm:block"
        :class="$route.path === '/' ? 'w-fit' : 'w-min'"
      >
        <h1 class="text-5xl text-center mb-2">Your Route</h1>
        <h3 class="text-xl text-center">Learn about your route here</h3>

        <div class="flex justify-center">
          <OutlineDoubleWavy class="w-[87px] stroke-theme-primary" />
        </div>

        <div
          id="monochromatic-location-cards"
          ref="overlayContainer"
          class="flex flex-col gap-y-5 h-[calc(100%-8.5rem)] overflow-hidden overflow-y-auto pr-2"
          :class="isFullScreen ? 'z-40' : 'z-10'"
        >
          <MonochromaticLocationCard
            v-for="(stop, index) of itineraryStops"
            :id="`monochromatic-location-card-${index}`"
            :key="index"
            :name="stop.place.name"
            :description="stop.place.description"
            :selected="index === selectedMarker"
            :image="stop.place.images?.[0]"
            :label="stop.label"
          />
        </div>
      </div>

      <!-- The coloured layout Location cards -->
      <div
        v-else
        id="coloured-location-cards"
        ref="overlayContainer"
        class="absolute flex-col gap-y-5 h-[calc(100%-2rem)] overflow-hidden top-1/2 -translate-y-1/2 overflow-y-auto pl-3 pr-1 hidden @sm:flex"
        :class="isFullScreen ? 'z-40' : 'z-10'"
      >
        <ColouredLocationCard
          v-for="(stop, index) of itineraryStops"
          :id="`coloured-location-card-${index}`"
          :key="index"
          :name="stop.place.name"
          :description="stop.place.description"
          :selected="index === selectedMarker"
          :image="stop.place.images?.[0]"
          :label="stop.label"
        />
      </div>
    </template>
  </div>
</template>
