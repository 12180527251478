import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M20.7516 15.9516C18.9738 16.7557 16.9932 16.9991 15.0736 16.6491C13.154 16.2992 11.3867 15.3727 10.007 13.993C8.62728 12.6133 7.70076 10.8459 7.35084 8.92637C7.00093 7.00681 7.24424 5.0262 8.04837 3.24841C6.63304 3.88803 5.39401 4.86145 4.43757 6.08516C3.48114 7.30888 2.83584 8.74636 2.55706 10.2743C2.27828 11.8022 2.37432 13.3749 2.83696 14.8576C3.2996 16.3402 4.11501 17.6885 5.21325 18.7867C6.31149 19.885 7.65976 20.7004 9.1424 21.163C10.625 21.6257 12.1978 21.7217 13.7257 21.4429C15.2536 21.1641 16.6911 20.5188 17.9148 19.5624C19.1385 18.606 20.112 17.3669 20.7516 15.9516V15.9516Z" })
  ]))
}