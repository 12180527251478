import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M10.3532 11.3333C10.1978 11.3333 10.1018 11.1637 10.1817 11.0304L11.8285 8.28583C11.9062 8.15636 12.0938 8.15636 12.1715 8.28583L13.8183 11.0304C13.8982 11.1637 13.8022 11.3333 13.6468 11.3333L12.5464 11.3333C12.436 11.3333 12.3464 11.4229 12.3464 11.5333L12.3464 12.4667C12.3464 12.5771 12.436 12.6667 12.5464 12.6667L13.6468 12.6667C13.8022 12.6667 13.8982 12.8363 13.8183 12.9696L12.1715 15.7142C12.0938 15.8436 11.9062 15.8436 11.8285 15.7142L10.1817 12.9696C10.1018 12.8363 10.1978 12.6667 10.3532 12.6667L11.4536 12.6667C11.564 12.6667 11.6536 12.5771 11.6536 12.4667L11.6536 11.5333C11.6536 11.4229 11.564 11.3333 11.4536 11.3333L10.3532 11.3333Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M22.9 7.1H1.1V16.9H22.9V7.1ZM1 7V17H23V7H1Z",
      "clip-rule": "evenodd"
    })
  ]))
}