import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M3 10C2.44772 10 2 10.4477 2 11C2 11.5523 2.44772 12 3 12H8.77778C9.33006 12 9.77778 11.5523 9.77778 11C9.77778 10.4477 9.33006 10 8.77778 10H3Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2.5 13C2.22386 13 2 13.2239 2 13.5C2 13.7761 2.22386 14 2.5 14H21.5C21.7761 14 22 13.7761 22 13.5C22 13.2239 21.7761 13 21.5 13H2.5Z",
      "clip-rule": "evenodd"
    })
  ]))
}