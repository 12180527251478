import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.09091",
      d: "M19.6364 19.6364H4.36364M19.6364 12H4.36364M19.6364 4.36364H4.36364M19.6364 15.8182H4.36364M19.6364 8.18182H4.36364"
    })
  ]))
}