import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M8 9C7.44772 9 7 9.44772 7 10V13C7 13.5523 7.44772 14 8 14H16C16.5523 14 17 13.5523 17 13V10C17 9.44772 16.5523 9 16 9H8ZM9.3 11C9.13431 11 9 11.1343 9 11.3V11.7C9 11.8657 9.13431 12 9.3 12H14.7C14.8657 12 15 11.8657 15 11.7V11.3C15 11.1343 14.8657 11 14.7 11H9.3Z",
      "clip-rule": "evenodd"
    })
  ]))
}