import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    stroke: "currentColor",
    viewBox: "0 0 24 24",
    "aria-hidden": "true"
  }, [
    _createElementVNode("g", { id: "url(#clip0_65_60858)" }, [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M13.7545 21.4442C15.1738 19.0001 17.223 15.2908 18.6902 11.8719C21.1564 6.12549 17.0461 1.19995 12.1137 1.19995C7.18134 1.19995 3.07103 6.12549 5.53722 11.8719C7.00446 15.2908 9.05364 19.0001 10.473 21.4442C11.2147 22.7214 13.0128 22.7214 13.7545 21.4442ZM12.1134 12.6925C14.4215 12.6925 16.2926 10.8214 16.2926 8.51326C16.2926 6.20512 14.4215 4.33401 12.1134 4.33401C9.80522 4.33401 7.93411 6.20512 7.93411 8.51326C7.93411 10.8214 9.80522 12.6925 12.1134 12.6925Z"
      }),
    ]),
  ]))
}