import { affirmArgument } from '@ankor-io/common/lang/faults'

import { AuthenticationContext } from '@/iam/types'

/**
 * Link media within a proposal
 *
 * @param bindings
 * @param uriProposal the proposal using the media
 * @param uriMedia the media to link to this proposal
 * @returns the result of the fetch call
 */
export const linkMedia = async (
  bindings: { authenticationContext: AuthenticationContext },
  uriProposal: string,
  uriMedia: string[] | string,
): Promise<Response> => {
  //
  affirmArgument(() => !!bindings.authenticationContext, 'authentication context is required')
  affirmArgument(() => !!uriProposal, 'uriProposal is required')
  affirmArgument(() => !!uriMedia, 'urimedia is required')

  //
  const _uriMedia = Array.isArray(uriMedia) ? uriMedia : [uriMedia]

  //
  return fetch('/media/link', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await bindings.authenticationContext.getToken()}`,
    },
    body: JSON.stringify({
      uriEntity: uriProposal,
      uriMedia: _uriMedia,
    }),
  })
}

/**
 * Unlink media from a proposal
 *
 * @param bindings
 * @param uriProposal the proposal which was using the media
 * @param uriMedia the media to unlink from this proposal
 * @returns the result of the fetch call
 */
export const unlinkMedia = async (
  bindings: { authenticationContext: AuthenticationContext },
  uriProposal: string,
  uriMedia: string[] | string,
): Promise<Response> => {
  //
  affirmArgument(() => !!bindings.authenticationContext, 'authentication context is required')
  affirmArgument(() => !!uriProposal, 'uriProposal is required')
  affirmArgument(() => !!uriMedia, 'urimedia is required')

  //
  const _uriMedia = Array.isArray(uriMedia) ? uriMedia : [uriMedia]

  //
  return fetch('/media/unlink', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await bindings.authenticationContext.getToken()}`,
    },
    body: JSON.stringify({
      uriEntity: uriProposal,
      uriMedia: _uriMedia,
    }),
  })
}
