import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M3 4C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V5C22 4.44772 21.5523 4 21 4H3ZM3.34746 15.5233L8.59541 8.32321C8.7132 8.1616 8.95316 8.15831 9.07534 8.31663L13.1551 13.6031C13.2758 13.7594 13.5119 13.7585 13.6314 13.6014L16.1961 10.2293C16.3167 10.0707 16.5555 10.0716 16.6749 10.2309L20.6402 15.52C20.7885 15.7178 20.6473 16 20.4001 16H3.58989C3.34456 16 3.20295 15.7215 3.34746 15.5233ZM17 5.6C17 5.15817 17.3582 4.8 17.8 4.8H20.2C20.6418 4.8 21 5.15817 21 5.6C21 6.04183 20.6418 6.4 20.2 6.4H17.8C17.3582 6.4 17 6.04183 17 5.6ZM3.4 16.8C3.17909 16.8 3 16.9791 3 17.2C3 17.4209 3.17909 17.6 3.4 17.6H10.6C10.8209 17.6 11 17.4209 11 17.2C11 16.9791 10.8209 16.8 10.6 16.8H3.4ZM3 18.8C3 18.5791 3.17909 18.4 3.4 18.4H14.6C14.8209 18.4 15 18.5791 15 18.8C15 19.0209 14.8209 19.2 14.6 19.2H3.4C3.17909 19.2 3 19.0209 3 18.8ZM15 10C15.5523 10 16 9.55228 16 9C16 8.44772 15.5523 8 15 8C14.4477 8 14 8.44772 14 9C14 9.55228 14.4477 10 15 10Z",
      "clip-rule": "evenodd"
    })
  ]))
}