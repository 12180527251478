import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      d: "M19.5 19.5V3L10 2M19.5 19.5H22M19.5 19.5L10 21.5V19.5M10 2H6.5V19.5M10 2V19.5M6.5 19.5H10M6.5 19.5H2M12.5 9.5V11.5"
    })
  ]))
}