import { Component, ComputedOptions, MethodOptions } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { IUri, URIEntity } from '@ankor-io/common/uri/Uri'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'
import { formatLength } from '@ankor-io/common/vessel/Formatter'

import VesselHeroSectionVue from '@/sections/vessel/hero/VesselHeroSection.vue'
import { VesselHeroData } from '@/sections/vessel/hero/types'
import { AbstractSection } from '@/services/proposal/Section'

export class VesselHeroSection extends AbstractSection<VesselHeroData> {
  getType(): SectionType {
    return SectionType.VESSEL_HERO
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselHeroSectionVue
  }

  /**
   * Implement this method to allow this section to work in memory in the Lite Editor
   * @param _source
   */
  dataFrom<A extends { uri: string; [key: string]: any }>(_source: A): VesselHeroData {
    const _uri: IUri = new GenericUriParser().parse(_source.uri)
    if (_uri.getEntity() === URIEntity.VESSEL) {
      return {
        name: _source.blueprint?.name || '',
        type: _source.type?.join(', ') || '',
        length: formatLength(_source.blueprint?.length || 0),
        sleeps: (_source.blueprint?.sleeps || 0).toString(),
        cabins: (_source.blueprint?.cabins || 0).toString(),
        crew: (_source.blueprint?.crew || 0).toString(),
        builtYear: (_source.blueprint?.builtYear || 0).toString(),
        images: _source.blueprint?.images || [],
        model: _source.blueprint?.model || '',
        builder: _source.blueprint?.make || '',
      }
    }
    throw new Error(`Invalid source not supported for VesselHeroSection: ${_source.uri}`)
  }
}
