<script setup lang="ts">
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import TabsVue from '@/components/Tabs.vue'
import VesselGalleryTab from '@/sections/vessel-showcase/VesselShowcaseConfigurationTabs/VesselGalleryTab.vue'
import VesselSettingsTab from '@/sections/vessel-showcase/VesselShowcaseConfigurationTabs/VesselSettingsTab.vue'
import { VesselShowcaseLayoutTemplate, VesselShowcaseSectionData } from '@/sections/vessel-showcase/types/types'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The vessel showcase data
   */
  data: VesselShowcaseSectionData
  /**
   * The vessel layout
   */
  layout: VesselShowcaseLayoutTemplate
}

const props = defineProps<Props>()
const tabs: Ref<string[]> = ref(['Settings', 'Gallery'])
const activeTab: Ref<string> = ref('Settings')

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselShowcaseSectionData>): void
  (e: 'update:layout', value: { sectionId: string; layout: VesselShowcaseLayoutTemplate }): void
}>()

const deleteImage = (event: { value: string; index: number }): void => {
  // deep copy the data
  const data: VesselShowcaseSectionData = ObjectUtil.deepCopy(props.data)
  data.images.splice(event.index, 1)
  emit('update:value', { sectionId: props.id, data: data })
}

const addImage = (value: string): void => {
  // deep copy the data
  const data: VesselShowcaseSectionData = ObjectUtil.deepCopy(props.data)

  const images = data.images
  data.images = [...images, value]

  emit('update:value', { sectionId: props.id, data: data })
}

const updateByReorder = (event: { from: number; to: number; images: string[] }): void => {
  const data: any = ObjectUtil.deepCopy(props.data)
  if (data.images.length === 1 || event.from === event.to) {
    return
  }

  data.images = event.images
  emit('update:value', { sectionId: props.id, data: data })
}
</script>
<template>
  <TabsVue :tabs="tabs" @update:activeTab="activeTab = $event">
    <VesselSettingsTab
      v-show="activeTab === 'Settings'"
      :id="props.id"
      :data="props.data"
      :layout="props.layout"
      @update:value="
        emit('update:value', {
          sectionId: $event.sectionId,
          data: $event.data,
        })
      "
      @update:layout="
        emit('update:layout', {
          sectionId: $event.sectionId,
          layout: $event.layout,
        })
      "
    />
    <VesselGalleryTab
      v-show="activeTab === 'Gallery'"
      :id="props.id"
      :proposalUri="props.data.uri || props.proposalUri"
      :images="props.data.images"
      :layout="props.layout"
      @delete:value="deleteImage"
      @update:value="addImage"
      @update:reorder="updateByReorder"
      @update:layout="
        emit('update:layout', {
          sectionId: $event.sectionId,
          layout: $event.layout,
        })
      "
    />
  </TabsVue>
</template>
