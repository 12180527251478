<script setup lang="ts">
import { Ref, ref } from 'vue'

import { SolidPhotograph } from '@ankor-io/icons/solid'

import DropZone from '@/components/asset-uploader/DropZone.vue'
import { styleValidity } from '@/utils/dropzoneStyle'

type AssetUploaderProps = {
  imageIndex: number | null
  uploadState: string | null
  displayUploaderFirst?: boolean
}

withDefaults(defineProps<AssetUploaderProps>(), {
  imageIndex: null,
  uploadState: null,
  displayUploaderFirst: false,
})

const emit = defineEmits<{
  (e: 'file:dropped', value?: string): void
  (e: 'image:dragover', event: DragEvent): void
}>()

const isDraggedOver: Ref<boolean> = ref(false)
const dragOverImageSize: Ref<{ width: string | null; height: string | null }> = ref({ width: null, height: null })

const dragover = (event: DragEvent) => {
  if (isDraggedOver.value) {
    return
  }

  emit('image:dragover', event)
  let target = event.target as HTMLElement
  if (target.tagName === 'svg') {
    target = target.parentElement as HTMLElement
  }

  dragOverImageSize.value.width = target.clientWidth.toString() + 'px'
  dragOverImageSize.value.height = target.clientHeight.toString() + 'px'
  isDraggedOver.value = true
}

const resetDraggedImage = () => {
  isDraggedOver.value = false
  dragOverImageSize.value.width = null
  dragOverImageSize.value.height = null
}

const drop = (event: DragEvent) => {
  resetDraggedImage()
  const galleryImageId = event.dataTransfer?.getData('galleryImageId')
  if (galleryImageId) {
    emit('file:dropped', galleryImageId)
  } else {
    // Occurs if the drag happens from the hero itself - re ordering
    const target = event.target as HTMLElement
    emit('file:dropped', target.id.split(':')[1])
  }
}
</script>

<template>
  <div @dragover="dragover" @drop="drop">
    <div v-show="!isDraggedOver">
      <slot></slot>
    </div>
    <DropZone
      v-show="isDraggedOver"
      class="drop-area"
      :style="{ width: dragOverImageSize.width, height: dragOverImageSize.height }"
      #default="{ dropZoneActive }"
      @dragleave="resetDraggedImage"
    >
      <label
        for="dropzone-file"
        class="h-full flex grow items-center justify-center border-2 rounded-lg transition-all"
        :id="`dropzoneLabel-${imageIndex}`"
        :class="[dropZoneActive && 'bg-primary-50 border-primary-600 border-dashed', styleValidity(uploadState)]"
      >
        <div class="flex flex-col items-center justify-center pt-5 pb-6 pointer-events-none">
          <SolidPhotograph class="fill-primary-600 w-8 h-8" />
          <p class="text-primary-600 font-semibold text-sm">Drop image here</p>
        </div>
      </label>
    </DropZone>
  </div>
</template>
