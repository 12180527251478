<script setup lang="ts">
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Tabs from '@/components/Tabs.vue'
import GalleryTab from '@/sections/hero/GalleryTab.vue'
import { ImageSectionEditorData } from '@/sections/image/ImageSectionEditor'
import { LayoutTemplate } from '@/sections/types'

import ImageStyleTab from './ImageStyleTab.vue'

type Props = {
  /**
   * the section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The sections data
   */
  data: ImageSectionEditorData
  /**
   * The sections layout template
   */
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<ImageSectionEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const tabs: Ref<string[]> = ref(['Style', 'Gallery'])
const activeTab: Ref<string> = ref('Style')

const update = (event: { field: string; value: string }): void => {
  // deep copy the data
  const data: ImageSectionEditorData = ObjectUtil.deepCopy(props.data)
  if (event.field === 'images') {
    const images = data.images || []
    data.images = [...images, event.value]
  }

  if (event.field === 'heroImages') {
    data.image = event.value
  }

  emit('update:value', { sectionId: props.id, data: data })
}

const deleteImage = (event: { value: string; index: number }): void => {
  // deep copy the data
  const data: ImageSectionEditorData = ObjectUtil.deepCopy(props.data)
  data.images.splice(event.index, 1)
  emit('update:value', { sectionId: props.id, data: data })
}

const updateByReorder = (event: { from: number; to: number; images: string[] }): void => {
  const data: any = ObjectUtil.deepCopy(props.data)
  if (data.images.length === 1 || event.from === event.to) {
    return
  }

  data.images = event.images
  emit('update:value', { sectionId: props.id, data: data })
}
</script>
<template>
  <Tabs :tabs="tabs" @update:activeTab="activeTab = $event">
    <ImageStyleTab
      v-show="activeTab === 'Style'"
      :layout="layout"
      :image="props.data.image"
      @update:layout="emit('update:layout', { sectionId: props.id, layout: $event.layout })"
    />
    <GalleryTab
      v-show="activeTab === 'Gallery'"
      :proposalUri="props.proposalUri"
      :images="props.data.images || []"
      :heroImages="[props.data.image]"
      :layout="layout"
      @update:value="update"
      @delete:value="deleteImage"
      @update:reorder="updateByReorder"
    />
  </Tabs>
</template>
