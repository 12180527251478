<script setup lang="ts">
import { Ref, ref } from 'vue'

import { getMimeTypeFromArrayBuffer, isValidMimeType } from '@ankor-io/common/assets/valid'
import { OutlineCloudUpload } from '@ankor-io/icons/outline'
import { SolidCheckMark, SolidExclamationCircle, SolidPhotograph } from '@ankor-io/icons/solid'

import { styleValidity } from '@/utils/dropzoneStyle'

import DropZone from './DropZone.vue'

export interface AssetUploaderProps {
  id?: string
  uploadState?: string | null
  displayUploaderFirst?: boolean
  uploadProgress?: number // 0 - 100 as a percentage
}

const props = withDefaults(defineProps<AssetUploaderProps>(), {
  id: 'dropzone-file',
  uploadState: null,
  displayUploaderFirst: false,
  uploadProgress: 0,
})

// Additional validation if the file extension is valid or not
const isFileTypeSupported: Ref<boolean> = ref(true)

const emit = defineEmits<{
  (e: 'file:loaded', value: File): void
}>()

const addFileOnDrop = (files: File[]) => {
  const parser = new DOMParser()
  files.forEach(async (file) => {
    if (isValidMimeType(file.type) && isValidMimeType(getMimeTypeFromArrayBuffer(await file.arrayBuffer(), parser))) {
      isFileTypeSupported.value = true
      emit('file:loaded', file)
    } else {
      isFileTypeSupported.value = false
    }
  })
}

const addFileFromInput = async (event: Event, uploadState: string | null) => {
  if (!uploadState === null && uploadState !== 'Failed') {
    return
  }

  const parser = new DOMParser()
  const target = event.target as HTMLInputElement
  if (
    isValidMimeType(target.files!.item(0)!.type) &&
    isValidMimeType(getMimeTypeFromArrayBuffer(await target.files!.item(0)!.arrayBuffer(), parser))
  ) {
    isFileTypeSupported.value = true
    emit('file:loaded', target.files!.item(0)!)
  } else {
    isFileTypeSupported.value = false
  }
}
</script>

<template>
  <div class="flex" :class="displayUploaderFirst ? 'flex-col-reverse' : 'flex-col'">
    <slot></slot>
    <DropZone class="drop-area h-full" @file-dropped="addFileOnDrop($event)" #default="{ dropZoneActive }">
      <label
        class="flex grow items-center justify-center border-2 rounded-lg cursor-pointer transition-all h-full bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 hover:bg-gray-100 hover:dark:bg-gray-600"
        :for="props.id"
        :class="[
          dropZoneActive && 'bg-primary-50 dark:bg-gray-800 border-primary-600 border-dashed',
          styleValidity((!isFileTypeSupported && 'Failed') || props.uploadState),
        ]"
      >
        <div class="w-full flex flex-col items-center justify-center pt-5 pb-6 pointer-events-none">
          <template v-if="dropZoneActive">
            <SolidPhotograph class="fill-primary-600 w-8 h-8" />
            <p class="text-primary-600 font-semibold text-sm text-center">Drop image here</p>
          </template>
          <template v-else>
            <template v-if="isFileTypeSupported && props.uploadState === null">
              <OutlineCloudUpload class="w-8 h-8 stroke-gray-400 dark:stroke-gray-300 mb-3" />
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                <span class="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-400 font-semibold text-center">
                JPG, PNG, SVG and GIF supported.
              </p>
            </template>
            <template v-else-if="!isFileTypeSupported || props.uploadState === 'Failed'">
              <SolidExclamationCircle class="w-10 h-10 fill-red-600" />
              <p class="text-sm font-bold text-red-600 mb-2 text-center">File type or size error</p>
              <p class="text-xs font-semibold text-red-600 text-center">JPG, PNG, SVG and GIF supported.</p>
            </template>
            <template v-else-if="props.uploadState === 'Progress'">
              <OutlineCloudUpload class="w-8 h-8 stroke-gray-400 mb-3" />
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 font-semibold text-center">Upload in progress</p>
              <p class="text-xs text-gray-500 dark:text-gray-400 font-semibold mb-4 text-center">
                This may take a few moments...
              </p>
              <p class="text-xs text-right font-medium text-gray-500 dark:text-gray-400 w-2/3 mb-1">
                {{ uploadProgress }}%
              </p>
              <div class="w-2/3 bg-gray-200 rounded-sm h-1.5">
                <div class="bg-primary-600 h-1.5 rounded-sm" :style="{ width: `${uploadProgress}%` }"></div>
              </div>
            </template>
            <template v-else-if="props.uploadState === 'Success'">
              <SolidCheckMark class="w-10 h-10 fill-green-400 mb-3" />
              <p class="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-4 text-center">Upload complete!</p>
              <p class="text-xs text-right font-medium text-gray-500 dark:text-gray-400 w-2/3 mb-1">100%</p>
              <div class="w-2/3 h-1.5 bg-green-400 rounded-sm"></div>
            </template>
          </template>
        </div>
        <input :id="props.id" type="file" class="hidden" @change="addFileFromInput($event, props.uploadState)" />
      </label>
    </DropZone>
  </div>
</template>
