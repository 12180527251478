import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M12.3516 3.95162C12.5767 3.72666 12.8818 3.60028 13.2 3.60028C13.5182 3.60028 13.8234 3.72666 14.0484 3.95162L21.2484 11.1516C21.4734 11.3767 21.5998 11.6818 21.5998 12C21.5998 12.3182 21.4734 12.6234 21.2484 12.8484L14.0484 20.0484C13.8221 20.267 13.519 20.388 13.2043 20.3852C12.8897 20.3825 12.5887 20.2563 12.3662 20.0338C12.1438 19.8113 12.0175 19.5103 12.0148 19.1957C12.0121 18.8811 12.133 18.5779 12.3516 18.3516L17.5032 13.2H3.60002C3.28176 13.2 2.97654 13.0736 2.7515 12.8486C2.52645 12.6235 2.40002 12.3183 2.40002 12C2.40002 11.6818 2.52645 11.3765 2.7515 11.1515C2.97654 10.9265 3.28176 10.8 3.60002 10.8H17.5032L12.3516 5.64842C12.1267 5.42339 12.0003 5.11822 12.0003 4.80002C12.0003 4.48183 12.1267 4.17666 12.3516 3.95162V3.95162Z",
      "clip-rule": "evenodd"
    })
  ]))
}