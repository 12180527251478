import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-width": "1.09091",
      d: "M7.63636 5.45454H18.5455"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-width": "1.09091",
      d: "M7.63636 12H18.5455"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-width": "1.09091",
      d: "M7.63636 18.5455H18.5455"
    }),
    _createElementVNode("path", {
      fill: "#111928",
      d: "M5.33692 4.16528V6.54545H4.8337V4.64295H4.81974L4.27468 4.98463V4.53835L4.8639 4.16528H5.33692Z"
    }),
    _createElementVNode("path", {
      d: "M4.0713 13.0909V12.7283L4.91854 11.9438C4.9906 11.8741 5.05103 11.8114 5.09984 11.7555C5.14943 11.6998 5.18701 11.6451 5.21258 11.5917C5.23814 11.5374 5.25092 11.479 5.25092 11.4161C5.25092 11.3464 5.23504 11.2864 5.20327 11.236C5.17152 11.1849 5.12812 11.1458 5.07312 11.1186C5.0181 11.0907 4.95573 11.0769 4.886 11.0769C4.81316 11.0769 4.74964 11.0916 4.6954 11.1209C4.64116 11.1504 4.59932 11.1926 4.56988 11.2477C4.54044 11.3027 4.52572 11.3681 4.52572 11.4441H4.04807C4.04807 11.2884 4.08331 11.1531 4.15382 11.0385C4.22432 10.9238 4.32312 10.8351 4.45017 10.7723C4.57724 10.7096 4.72368 10.6782 4.88948 10.6782C5.05994 10.6782 5.20831 10.7084 5.33461 10.7688C5.46168 10.8285 5.56046 10.9114 5.63096 11.0175C5.70147 11.1237 5.73673 11.2453 5.73673 11.3824C5.73673 11.4723 5.7189 11.5611 5.68326 11.6486C5.6484 11.7362 5.58603 11.8334 5.49615 11.9403C5.40627 12.0465 5.27959 12.1739 5.11611 12.3227L4.76862 12.6633V12.6795H5.7681V13.0909H4.0713Z"
    }),
    _createElementVNode("path", {
      d: "M4.90314 19.6689C4.72958 19.6689 4.57501 19.6391 4.43942 19.5794C4.30461 19.519 4.19807 19.4361 4.11982 19.3307C4.04234 19.2246 4.00244 19.1022 4.00011 18.9635H4.50683C4.50993 19.0215 4.52891 19.0727 4.56378 19.1169C4.59942 19.1603 4.64667 19.194 4.70556 19.218C4.76445 19.242 4.83069 19.254 4.9043 19.254C4.98101 19.254 5.04879 19.2405 5.10768 19.2133C5.16657 19.1863 5.21267 19.1486 5.24599 19.1006C5.2793 19.0525 5.29596 18.9972 5.29596 18.9344C5.29596 18.8709 5.27814 18.8147 5.2425 18.7659C5.20763 18.7163 5.15726 18.6776 5.09142 18.6497C5.02633 18.6218 4.94885 18.6079 4.85898 18.6079H4.637V18.2383H4.85898C4.9349 18.2383 5.00192 18.2251 5.06003 18.1988C5.11892 18.1724 5.16463 18.1359 5.19717 18.0895C5.22971 18.0422 5.24599 17.9873 5.24599 17.9245C5.24599 17.8649 5.23165 17.8125 5.20298 17.7675C5.17509 17.7218 5.13558 17.6863 5.08443 17.6606C5.03408 17.6351 4.97519 17.6223 4.90778 17.6223C4.8396 17.6223 4.77723 17.6347 4.72067 17.6595C4.66412 17.6835 4.61879 17.718 4.5847 17.763C4.55061 17.8079 4.5324 17.8606 4.53008 17.921H4.04777C4.05009 17.7838 4.08922 17.663 4.16515 17.5584C4.24107 17.4538 4.34335 17.3721 4.47197 17.3132C4.60136 17.2535 4.7474 17.2237 4.91011 17.2237C5.07437 17.2237 5.21809 17.2535 5.34128 17.3132C5.46447 17.3729 5.56016 17.4534 5.62834 17.5549C5.6973 17.6556 5.73139 17.7687 5.73061 17.8943C5.73139 18.0275 5.68994 18.1387 5.60626 18.2278C5.52336 18.3169 5.41527 18.3734 5.28201 18.3974V18.4161C5.45711 18.4386 5.59038 18.4993 5.68181 18.5986C5.77401 18.6969 5.81972 18.8202 5.81894 18.9681C5.81972 19.1037 5.78059 19.2242 5.70156 19.3296C5.62331 19.4349 5.51522 19.5178 5.37731 19.5782C5.2394 19.6387 5.08134 19.6689 4.90314 19.6689Z"
    })
  ]))
}