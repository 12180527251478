<script setup lang="ts">
import { UUID } from '@ankor-io/common/lang/uuid'

import HelpTip from '@/components/HelpTip.vue'

import { Disclaimer } from './types/types'

const emit = defineEmits<{
  (e: 'add:disclaimer', value: Disclaimer): void
}>()

const disclaimers: Disclaimer[] = [
  {
    id: UUID.timeBased(),
    type: 'Changes to planned route',
    disclaimer:
      "Please note that any requests to change the planned yacht charter route are subject to the captain's discretion, availability, and prevailing weather conditions. The captain will do his/her best to accommodate requests within the constraints of safety and operational requirements. However, we cannot guarantee that all requests will be fulfilled. Changes may result in additional costs or changes to the overall charter experience. We appreciate your understanding and cooperation.",
  },
  {
    id: UUID.timeBased(),
    type: 'Risk of Injury or Death',
    disclaimer:
      "By participating in this yacht charter, guests acknowledge and accept the inherent risks associated with boating, water sports, and related activities. These risks may include, but are not limited to, personal injury, property damage, or even death. While we prioritize safety and adhere to industry-standard procedures, accidents and unforeseen events can occur. Guests are responsible for their own safety and are expected to follow all instructions provided by the captain and crew. The charter company, its employees, and agents shall not be held liable for any loss, injury, or death that may arise as a result of a guest's participation in the yacht charter.",
  },
  {
    id: UUID.timeBased(),
    type: 'Charter presentation information',
    disclaimer:
      "The content, information and the particulars included in this presentation, are displayed in good faith, and whilst believed to be accurate are not guaranteed. All information is subject to change, and we will do our best to inform you of any changes promptly.<br /><br />Please be aware that the prices quoted for the yacht charter may be subject to change without prior notice. The final price will depend on various factors, such as the yacht's availability, seasonal demand, and any applicable taxes or fees. The quotation provided is an estimate and may not reflect the exact costs of your charter.",
  },
  {
    id: UUID.timeBased(),
    type: 'Charter fees, inclusion, and exclusion',
    disclaimer:
      'The Charter Fee will cover the hire of the yacht, the yacht’s insurance, and the crew. All other expenses are in addition to the charter fee and are paid via the APA (Advance Provisioning Allowance). The APA covers all costs outside of the charter fee, including things like food and drinks, alcohol, fuel, wharf, and port fees, etc.<br /><br />Additional fees may be charged for relocation, and extra items or services not provided by the yacht or crew as standard.',
  },
  {
    id: UUID.timeBased(),
    type: 'Environmental damage',
    disclaimer:
      'The captain will comply with all reasonable requests given by the CHARTERER regarding the management, operation and movement of the vessel, wind, weather and other circumstances permitting. The captain may reuse to comply with any order that the Captain reasonably believes would result in the Vessel’s moving to any port or place that could be unsafe or improper for the Vessel.',
  },
  {
    id: UUID.timeBased(),
    type: 'Advanced provisioning allowance',
    disclaimer:
      'Please be aware that an Advanced Provisioning Allowance (APA) is required for this yacht charter. The APA is an additional cost, typically calculated as a percentage of the charter fee, to cover expenses such as fuel, port/marina fees, provisions, and other miscellaneous costs incurred during your charter. The exact percentage and amount will be specified in your charter agreement. The APA is collected prior to the start of the charter and will be held by the captain or management company. At the end of the charter, any unused portion of the APA will be refunded. If the APA is insufficient to cover all expenses, the guest is responsible for settling the outstanding balance before disembarking.',
  },
  {
    id: UUID.timeBased(),
    type: 'VAT (Value Added Tax)',
    disclaimer:
      'Please be aware that the prices quoted for the yacht charter may not include Value Added Tax (VAT). VAT rates and applicability vary depending on the jurisdiction in which the charter takes place, which may include multiple locations should your charter include different countries. If applicable, the VAT will be added to the charter fee and any other taxable services or goods provided during the charter. Details of the applicable VAT will be confirmed in the charter contract, however, may be subject to change without notice should your route change.',
  },
]
</script>
<template>
  <div class="flex flex-col gap-y-5">
    <HelpTip tip="Select a disclaimer sample" />

    <div class="h-[calc(100vh-16rem)] overflow-auto flex flex-col gap-y-5">
      <div v-for="(disclaimer, index) in disclaimers" :key="index" class="group relative">
        <div class="bg-white border-2 border-gray-200 h-44 flex justify-center items-center px-9">
          <span class="font-medium text-sm text-gray-500">{{ disclaimer.type }}</span>
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="emit('add:disclaimer', disclaimer)"
          >
            Add
          </button>
          <div
            class="z-10 absolute top-0 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
