import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import AdditionalNotesEditorVue from '@/sections/additionalNotes/AdditionalNotesEditor.vue'
import { AdditionalNotesEditorData } from '@/sections/additionalNotes/types'
import AdditionalNotesConfigurationVue from '@/sections/additionalNotes/AdditionalNotesConfiguration.vue'

export class AdditionalNotesEditor extends ProposalAbstractEditableSection<
  AdditionalNotesEditorData,
  EditableProposal
> {
  async fetchData(): Promise<AdditionalNotesEditorData> {
    return this.data
  }

  getType(): SectionType {
    return SectionType.ADDITIONAL_NOTES
  }

  getComponent(): Component {
    return AdditionalNotesEditorVue
  }

  getConfigurationComponent(): Component | null {
    return AdditionalNotesConfigurationVue
  }
}
