<script lang="ts" setup>
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Ref, onMounted, onUnmounted, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { OutlineChevronLeft, OutlineChevronRight } from '@ankor-io/icons/outline'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import YachtSummaryCardTitle from '@/sections/yacht-summary-card/title/YachtSummaryCardTitle.vue'
import { YachtSummaryCardLayoutTemplate, YachtSummaryCardSectionData } from '@/sections/yacht-summary-card/types/types'

type Props = {
  proposalUri: string
  data: YachtSummaryCardSectionData
  layout: YachtSummaryCardLayoutTemplate
  document: JsonProposal
}

const props = defineProps<Props>()
const modules = [Pagination]

const descriptionLines: Ref<number | null> = ref(null)
const isClamped: Ref<boolean> = ref(true)
const toggleClamp = () => {
  isClamped.value = !isClamped.value
}

const currentImg: Ref<number> = ref(0)
const updateImg = (to: number): void => {
  const element = document.getElementById(to.toString())
  element?.scrollIntoView({ inline: 'center', behavior: 'smooth' })
  currentImg.value = to
}

const handleResize = () => {
  const LINE_HEIGHT = 24 // The height of a single line in the description
  const description = document.getElementById('yacht-card-info-description')
  const descriptionRects = description?.getClientRects()
  if (descriptionRects?.item(0)?.height === 0) {
    descriptionLines.value = 0
  } else {
    descriptionLines.value = descriptionRects?.item(0)?.height! / LINE_HEIGHT || 0
  }
}

const showYachtTitle = (): boolean => {
  if (
    props.data.name ||
    props.data.apa ||
    props.data.vat ||
    (props.layout.options.length && props.data?.length) ||
    (props.layout.options.numPassengers && props.data.numPassengers) ||
    (props.layout.options.maxCrew && props.data.maxCrew) ||
    (props.layout.options.numCabins && props.data.numCabins) ||
    (props.layout.options.costAndFees && (props.data.price || props.data.priceSubtext))
  ) {
    return true
  }

  return false
}

const showYachtImageCarousel = (): boolean => {
  if (props.layout.options.galleryCarousel && props.data.images?.length) {
    return true
  }

  return false
}

const showYachtProfile = (): boolean => {
  if (
    (props.layout.options.yachtType && props.data.yachtType) ||
    (props.layout.options.length && props.data.length) ||
    (props.layout.options.buildYearRefitYear && props.data.buildYear) ||
    (props.layout.options.cruiseSpeed && props.data.cruiseSpeed) ||
    (props.layout.options.numDecks && props.data.numDecks) ||
    (props.layout.options.topSpeed && props.data.topSpeed) ||
    (props.layout.options.buildYearRefitYear && props.data.refitYear) ||
    (props.layout.options.flag && props.data.flag)
  ) {
    return true
  }

  return false
}

// Some styling requires only checking if the toggle is enabled
const isYachtProfileEnabled = (): boolean => {
  if (
    props.layout.options.yachtType ||
    props.layout.options.length ||
    props.layout.options.buildYearRefitYear ||
    props.layout.options.cruiseSpeed ||
    props.layout.options.numDecks ||
    props.layout.options.topSpeed ||
    props.layout.options.flag
  ) {
    return true
  }

  return false
}

const showYachtDetails = (): boolean => {
  if (
    showYachtProfile() ||
    (props.layout.options.descriptionAndNotes && props.data.description) ||
    (props.layout.options.yachtButton && props.data.yachtButton?.link)
  ) {
    return true
  }

  return false
}

onMounted(() => {
  setTimeout(() => {
    handleResize()
  }, 0)
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>
<template>
  <!-- The section can be added with no yacht in the itinerary - all empty data -->
  <div
    v-if="showYachtTitle() || showYachtImageCarousel() || showYachtProfile()"
    class="yacht-summary-section max-w-360 mx-auto p-4 sm:p-8"
    @keyup.left="updateImg(currentImg - 1)"
    @keyup.right="updateImg(currentImg + 1)"
  >
    <div v-if="showYachtTitle()" class="relative z-10 mx-4 sm:mx-8">
      <YachtSummaryCardTitle
        :name="props.data.name"
        :length="props.data.length"
        :numPassengers="props.data.numPassengers"
        :maxCrew="props.data.maxCrew"
        :numCabins="props.data.numCabins"
        :price="props.data.price"
        :price-subtext="props.data.priceSubtext"
        :apa="props.data.apa"
        :vat="props.data.vat"
        :enabled="props.layout.options"
      />
    </div>

    <div v-if="showYachtImageCarousel()" class="relative -mt-4 5xl:-mt-20">
      <!-- On desktop, the arrows show, and for mobile it changes to swiperjs swiping -->
      <div class="grow w-full hidden sm:block">
        <div
          v-for="(image, index) in props.data.images.filter((img) => !!img)"
          class="w-full h-full"
          :key="index"
          :class="{ hidden: currentImg !== index }"
        >
          <AssetViewer class="rounded-lg object-cover" :filename="image" :uri="props.proposalUri" />
        </div>
      </div>
      <!-- Arrows for desktop -->
      <div v-if="props.data.images?.length > 1" class="hidden sm:block">
        <!-- Previous -->
        <button
          v-if="currentImg > 0"
          class="absolute top-1/2 -translate-y-1/2 ml-12"
          @click="updateImg(currentImg - 1)"
        >
          <span
            class="inline-flex items-center justify-center rounded-full w-10 h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none"
          >
            <OutlineChevronLeft class="text-white w-5" />
            <span class="sr-only">Previous</span>
          </span>
        </button>
        <!-- Next -->
        <button
          v-if="currentImg < props.data.images.length - 1"
          class="absolute top-1/2 -translate-y-1/2 right-0 mr-12"
          @click="updateImg(currentImg + 1)"
        >
          <span
            class="inline-flex items-center justify-center rounded-full w-10 h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none"
          >
            <OutlineChevronRight class="text-white w-5" />
            <span class="sr-only">Previous</span>
          </span>
        </button>
      </div>
      <div class="block sm:hidden">
        <Swiper
          class="cursor-grab active:cursor-grabbing"
          :space-between="12"
          :modules="modules"
          :pagination="{
            dynamicBullets: true,
            dynamicMainBullets: 6,
          }"
          :style="['--swiper-pagination-color: white', '--swiper-pagination-bullet-inactive-color: #D1D5DB']"
        >
          <SwiperSlide class="!h-auto w-full" v-for="image in props.data.images.filter((img) => !!img)" :key="image">
            <AssetViewer class="rounded-lg h-full object-cover" :filename="image" :uri="props.proposalUri" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>

    <!-- Yacht profile and description -->
    <div
      v-if="showYachtDetails()"
      class="mt-8 flex gap-x-4 lg:gap-x-8 gap-y-5 flex-col sm:flex-row px-4 sm:px-0"
      :class="{
        'justify-center': !showYachtProfile() && (!props.layout.options.descriptionAndNotes || !props.data.description),
      }"
    >
      <!-- Left side - Yacht profile -->
      <div
        v-if="showYachtProfile()"
        class="sm:max-w-[30rem] w-full grid text-sm gap-x-4 lg:gap-x-16 h-min gap-y-2 grid-cols-1 sm:grid-cols-2"
      >
        <p v-if="props.layout.options.yachtType && props.data.yachtType" class="flex gap-x-1 items-center">
          <span class="font-bold">Yacht type:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.yachtType" />
        </p>
        <p v-if="props.layout.options.length && props.data.length" class="flex gap-x-1 items-center">
          <span class="font-bold">Length:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.length" />
        </p>
        <p v-if="props.layout.options.buildYearRefitYear && props.data.buildYear" class="flex gap-x-1 items-center">
          <span class="font-bold">Build year:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.buildYear" />
        </p>
        <p v-if="props.layout.options.cruiseSpeed && props.data.cruiseSpeed" class="flex gap-x-1 items-center">
          <span class="font-bold">Cruising speed:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.cruiseSpeed" />
        </p>
        <p v-if="props.layout.options.numDecks && props.data.numDecks" class="flex gap-x-1 items-center">
          <span class="font-bold">Decks:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.numDecks" />
        </p>
        <p v-if="props.layout.options.topSpeed && props.data.topSpeed" class="flex gap-x-1 items-center">
          <span class="font-bold">Top speed:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.topSpeed" />
        </p>
        <p v-if="props.layout.options.buildYearRefitYear && props.data.refitYear" class="flex gap-x-1 items-center">
          <span class="font-bold">Refit year:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.refitYear" />
        </p>
        <p v-if="props.layout.options.flag && props.data.flag" class="flex gap-x-1 items-center">
          <span class="font-bold">Flag:</span>
          <SingleLineTextEditor class="text-sm" :is-editable="false" :value="props.data.flag" />
        </p>
      </div>

      <!-- Middle - Yacht description -->
      <div
        v-if="props.layout.options.descriptionAndNotes && props.data.description"
        class="relative w-full flex-2"
        :class="{ 'border-gray-300 border-l-0 sm:border-l sm:pl-4 lg:pl-8': isYachtProfileEnabled() }"
      >
        <p
          id="yacht-card-info-description"
          :class="isClamped && descriptionLines! > 5 ? 'line-clamp-6' : 'line-clamp-none'"
        >
          <MultiLineTextEditor :is-editable="false" :value="props.data.description" />
        </p>
        <div
          v-if="descriptionLines! >= 5 && isClamped"
          class="crossfade-gradient pointer-events-none h-full w-full z-10 absolute top-0"
        ></div>
        <div
          v-if="descriptionLines! >= 5"
          class="absolute z-20 w-full text-center text-theme-primary cursor-pointer"
          @click="toggleClamp"
        >
          {{ isClamped ? 'Read more' : 'Read less' }}
        </div>
      </div>

      <!-- Right side - Yacht button -->
      <div
        v-if="props.layout.options.yachtButton && props.data.yachtButton?.link"
        class="yacht-button min-w-[12.938rem] w-min hidden @lg:flex justify-center h-min text-center text-white font-medium text-sm p-4 rounded-lg"
        :class="!props.data.yachtButton?.link ? 'pointer-events-none bg-gray-200' : 'bg-theme-primary'"
      >
        <a class="break-all" target="_blank" :href="props.data.yachtButton.link">
          {{ props.data.yachtButton?.title || 'Yacht Brochure' }}
        </a>
      </div>
    </div>

    <!-- Below profile and description - Yacht button -->
    <div
      v-if="props.layout.options.yachtButton && props.data.yachtButton?.link"
      class="yacht-button block lg:hidden min-w-[20rem] h-min w-fit mx-auto mt-8 text-sm text-center text-white font-medium p-4 rounded-lg"
      :class="!props.data.yachtButton?.link ? 'pointer-events-none bg-gray-200' : 'bg-theme-primary'"
    >
      <a class="break-all" target="_blank" :href="props.data.yachtButton.link">
        {{ props.data.yachtButton?.title || 'Yacht Brochure' }}
      </a>
    </div>
    <hr class="border-t-2 mx-auto mt-8 border-theme-shading w-28" />
  </div>

  <!-- TODO: Introduce layouts -->
  <!-- <div v-if="props.layout.type === 'layout1'">
    Put your layout 1 template in here
    <MultiLineTextEditor :value="props.data.value" :is-editable="false"></MultiLineTextEditor>
    <SingleLineTextEditor :value="props.data.value" :is-editable="false"></SingleLineTextEditor>
  </div>

  <div v-if="props.layout.type === 'layout2'">
    Put your layout 2 template in here
    <MultiLineTextEditor :value="props.data.value" :is-editable="false"></MultiLineTextEditor>
    <SingleLineTextEditor :value="props.data.value" :is-editable="false"></SingleLineTextEditor>
  </div> -->
</template>
<style scoped>
.crossfade-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 15.04%,
    v-bind('props.document.template.theme.color.background') 82.09%
  );
}
</style>
