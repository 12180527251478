export const isValidMimeType = (mimeType: string): boolean => {
  const supportedMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']
  return supportedMimeTypes.includes(mimeType)
}

// DOMParser interface cannot be found in this environment
export const getMimeTypeFromArrayBuffer = (arrayBuffer: ArrayBuffer, domParser: any): string => {
  const uint8arr = new Uint8Array(arrayBuffer)

  const len = 4
  if (uint8arr.length >= len) {
    let signatureArr = new Array(len)
    for (let i = 0; i < len; i++) {
      signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16)
    }

    const signature = signatureArr.join('').toUpperCase()
    switch (signature) {
      case '89504E47':
        return 'image/png'
      case '47494638':
        return 'image/gif'
      case 'FFD8FFDB':
      case 'FFD8FFE0':
      case 'FFD8FFEE':
      case 'FFD8FFE1':
        return 'image/jpeg'
      default:
        const decoder = new TextDecoder('utf-8')
        const svgString = decoder.decode(arrayBuffer)

        const doc = domParser.parseFromString(svgString, 'image/svg+xml')
        if (doc.documentElement.nodeName === 'svg') {
          return 'image/svg+xml'
        } else {
          return ''
        }
    }
  }

  return ''
}
