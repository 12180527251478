<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { computed, useAttrs } from 'vue'

defineOptions({ inheritAttrs: false })

type Tone = 'default' | 'red' | 'yellow' | 'green' | 'blue' | 'gray' | 'indigo' | 'purple' | 'pink' | 'cognac'
type Props = {
  tone?: Tone
  compact?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  tone: 'default',
  compact: false,
})

const emits = defineEmits<{
  (e: 'click'): void
}>()

//
//
//
const size = {
  default: 'text-sm px-3 py-0.5 rounded-md inline-flex items-center align-middle whitespace-nowrap',
  compact: 'text-xs px-2.5 py-0.5 rounded-md inline-flex items-center align-middle whitespace-nowrap',
}

const tone = {
  default: 'bg-primary-100 text-primary-800 dark:bg-gray-700 dark:text-primary-400 dark:border dark:border-primary-400',
  //
  red: 'bg-red-100 text-red-800 dark:bg-gray-700 dark:text-red-400 dark:border dark:border-red-400',
  yellow: 'bg-yellow-100 text-yellow-800 dark:bg-gray-700 dark:text-yellow-400 dark:border dark:border-yellow-400',
  green: 'bg-green-100 text-green-800 dark:bg-gray-700 dark:text-green-400 dark:border dark:border-green-400',
  blue: 'bg-blue-100 text-blue-800 dark:bg-gray-700 dark:text-blue-400 dark:border dark:border-blue-400',
  gray: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-400 dark:border dark:border-gray-400',
  indigo: 'bg-indigo-100 text-indigo-800 dark:bg-gray-700 dark:text-indigo-400 dark:border dark:border-indigo-400',
  purple: 'bg-purple-100 text-purple-800 dark:bg-gray-700 dark:text-purple-400 dark:border dark:border-purple-400',
  pink: 'bg-pink-100 text-pink-800 dark:bg-gray-700 dark:text-pink-400 dark:border dark:border-pink-400',
  cognac: 'bg-cognac-100 text-cognac-800 dark:bg-gray-700 dark:text-cognac-400 dark:border dark:border-cognac-400',
}

// determine the size of the badge
const classSize = computed(() => {
  return size[props.compact ? 'compact' : 'default']
})

// determine the color theme.
const classTone = computed(() => {
  return tone[props.tone]
})
</script>
<template>
  <span :class="twMerge(classSize, classTone, useAttrs().class as string)" @click="$emit('click')">
    <slot></slot>
  </span>
</template>
