import gql from 'graphql-tag'
import { Component, ComputedOptions, MethodOptions } from 'vue'

import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { Vessel } from '@ankor-io/common/vessel/types'

import VesselCrewOptions from '@/sections/vessel/crew/VesselCrewOptions.vue'
import VesselCrewSectionEditorVue from '@/sections/vessel/crew/VesselCrewSectionEditor.vue'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import { Crew, VesselCrewData } from './types'

const QUERY = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      crew {
        name
        avatar
        bio
        roles
        email
        phone
      }
    }
  }
`

const getAllCrewMembers = (vessel: Vessel, vesselUri: string | null): Crew[] => {
  if (!vesselUri) {
    return vessel.crew?.map((crewMember) => ({ ...crewMember, selected: true, avatar: '' })) || []
  }

  return (vessel.crew || []).map((crewMember) => {
    const avatarImage = crewMember.avatar ? replacePathToMediaUris(vesselUri, crewMember.avatar)[0] : ''
    return { ...crewMember, selected: true, avatar: avatarImage }
  })
}

export class VesselCrewSectionEditor extends ProposalAbstractEditableSection<VesselCrewData, EditableProposal> {
  async fetchData(): Promise<VesselCrewData> {
    const vesselUri = this.getSlideUri()
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: vesselUri },
      })
      .then(({ data }) => {
        const crewMembers: Crew[] = getAllCrewMembers(data.vessel, vesselUri)
        return Promise.resolve({
          heading: 'Crew',
          crewMembers,
        })
      })
      .catch(() => {
        console.error('Error fetching vessel crew members')
        return Promise.resolve({ heading: 'Crew', crewMembers: [] })
      })
  }

  getType(): SectionType {
    return SectionType.VESSEL_CREW
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselCrewSectionEditorVue
  }

  getConfigurationComponent(): Component<any, any, any, ComputedOptions, MethodOptions> | null {
    return VesselCrewOptions
  }
}
