<script setup lang="ts">
type Emits = {
  // emit when the visiblity changes and isVisible is true.
  (e: 'visible', entry: any): void
  // emit when visibility changes.
  (e: 'visibilityChanged', isVisible: boolean, entry: any): void
}

const emits = defineEmits<Emits>()

// handle the visibility changed event.
const emitEvents = (isVisible: boolean, entry: any) => {
  // emit this if it's visible.
  if (isVisible) {
    emits('visible', entry)
  }

  // always send this.
  emits('visibilityChanged', isVisible, entry)
}
</script>
<template>
  <div
    class="h-px w-full relative -top-[48rem]"
    v-bind="$attrs"
    v-observe-visibility="(isVisible: boolean, entry: any) => emitEvents(isVisible, entry)"
  ></div>
  <div class="flex justify-center py-4">
    <button
      type="button"
      id="next-results"
      class="outline-none leading-normal text-xs text-primary-600 dark:text-primary-500 hover:text-primary-800"
      name="loadMore"
      @click="(event) => emitEvents(true, event)"
    >
      Load more...
    </button>
  </div>
</template>
