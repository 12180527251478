import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-miterlimit": "10",
      d: "M1 11C2.22222 11 2.22222 10 3.44444 10C4.66667 10 4.66667 11 5.88889 11C7.11111 11 7.11111 10 8.33333 10C9.55556 10 9.55556 11 10.7778 11C12 11 12 10 13.2222 10C14.4444 10 14.4444 11 15.6667 11C16.8889 11 16.8889 10 18.1111 10C19.3333 10 19.3333 11 20.5556 11C21.7778 11 21.7778 10 23 10"
    }),
    _createElementVNode("path", {
      "stroke-miterlimit": "10",
      d: "M1 14C2.22222 14 2.22222 13 3.44444 13C4.66667 13 4.66667 14 5.88889 14C7.11111 14 7.11111 13 8.33333 13C9.55556 13 9.55556 14 10.7778 14C12 14 12 13 13.2222 13C14.4444 13 14.4444 14 15.6667 14C16.8889 14 16.8889 13 18.1111 13C19.3333 13 19.3333 14 20.5556 14C21.7778 14 21.7778 13 23 13"
    })
  ]))
}