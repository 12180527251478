import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import NavBarVue from './NavBar.vue'

export class NavBar extends AbstractSection<null> {
  getType(): SectionType {
    return SectionType.NAV_BAR
  }

  getComponent(): Component {
    return NavBarVue
  }
}
