import { inject } from 'vue'

import { FooterTemplate, HeaderTemplate, Identity, Template, ThemeTemplate } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { SlideType } from '@ankor-io/common/proposal/SlideType'
import { Trip } from '@ankor-io/common/trip/Trip'

import { AuthenticationContext } from '@/iam/types'
import { Crew } from '@/sections/vessel/crew/types'
import { VesselHeroData } from '@/sections/vessel/hero/types'
import { VesselOverviewData } from '@/sections/vessel/overview/types'
import { VesselSpecificationsSectionData } from '@/sections/vessel/specifications/types/types'
import { ToysAmenitiesSectionData } from '@/sections/vessel/toys-amenities/types/types'

// inject the config and the authentication context which are required
let authenticationContext: AuthenticationContext

/**
 * Stowage service definition
 */
export type ProposalService = {
  /**
   * Share the Proposal in a new Trip
   *
   * @param proposalUri the uri of the proposal to share
   * @returns a promise with the new Trip
   */
  share: (proposalUri: string) => Promise<Trip>
}

const share = async (proposalUri: string): Promise<Trip | null> => {
  const token = await authenticationContext.getToken()

  const res: Response = await fetch('/api/trip/new', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ proposalUri: proposalUri }),
  })

  if (res.status === 201) {
    return Promise.resolve(await res.json<Trip>())
  }
  // something went wrong, return null
  return Promise.resolve(null)
}

/**
 * This is required since inject only works in vue components
 */
export const useProposalService = (): ProposalService => {
  authenticationContext = inject('authenticationContext')!
  return {
    share: share,
  } as ProposalService
}

/**
 * @returns the global default template for a proposal
 */
export const getDefaultProposalTemplate = (): Template => {
  const itinerarySections = [
    {
      type: SectionType.ITINERARY_HERO,
      layout: {
        type: 'single',
      },
      data: {
        images: [],
      },
    },
    {
      type: SectionType.ITINERARY_SUMMARY,
      layout: {},
      data: {
        summaryTitle: '',
      },
    },
    {
      type: SectionType.ITINERARY_OVERVIEW,
      layout: {
        type: 'single_column_layout',
      },
      data: {
        name: '',
        description: '',
      },
    },
    {
      type: SectionType.ALL_DAY_CARDS,
      layout: {
        type: 'hero-two-column',
      },
      data: {
        segments: [],
      },
    },
    {
      type: SectionType.ITINERARY_MAP,
      layout: {
        type: 'coloured',
      },
      data: {
        segments: [],
      },
    },
    {
      type: SectionType.ITINERARY_CHECKLIST,
      layout: {
        type: 'single_column_layout',
      },
      data: {
        checklist: [],
      },
    },
    {
      type: SectionType.PROFILE_COMPANY,
      layout: {
        type: 'compact',
      },
      data: {
        givenName: null,
        surname: null,
        profileImage: null,
        role: null,
        personalPhone: null,
        email: null,
        website: null,
        companyImage: null,
        companyName: null,
        companyAddress: null,
        companyPhone: null,
      },
    },
  ]

  const vesselSections = [
    {
      type: SectionType.VESSEL_HERO,
      layout: {
        type: 'single',
      },
      data: {} as VesselHeroData,
    },
    {
      type: SectionType.VESSEL_OVERVIEW,
      layout: {
        type: 'single_column_layout',
      },
      data: {
        heading: '',
        description: '',
      } as VesselOverviewData,
    },
    {
      type: SectionType.VESSEL_SPECIFICATIONS,
      layout: {
        type: 'single',
      },
      data: {
        specifications: [],
      } as VesselSpecificationsSectionData,
    },
    {
      type: SectionType.VESSEL_TOYS_AMENITIES,
      layout: {
        type: 'single',
      },
      data: {
        heading: 'Toys & Amenities',
        toys: [],
        amenities: [],
        entertainment: [],
        tenders: [],
      } as ToysAmenitiesSectionData,
    },
    {
      type: SectionType.VESSEL_CREW,
      layout: {
        type: 'single',
      },
      data: {
        selected: true,
        name: '',
        avatar: '',
        bio: '',
        role: '',
        roles: [],
        email: '',
        phone: '',
      } as Crew,
    },
    {
      type: 'profile_company' as SectionType,
      layout: {
        type: 'compact',
      },
      data: {
        givenName: null,
        surname: null,
        profileImage: null,
        role: null,
        personalPhone: null,
        email: null,
        website: null,
        companyImage: null,
        companyName: null,
        companyAddress: null,
        companyPhone: null,
      },
    },
  ]

  const header = {
    section: {
      type: SectionType.NAV_BAR,
      // NOTE: if you change this to something else it is going to impact the lite editor as well
      layout: {
        type: 'logo',
        options: 'center',
      },
      data: {},
    },
  }

  return {
    theme: getDefaultTheme(),
    header: header as HeaderTemplate,
    footer: {} as FooterTemplate,
    identity: {} as Identity,
    yacht: {
      type: SlideType.YACHT,
      sections: [],
    },
    vessel: {
      type: SlideType.VESSEL,
      sections: vesselSections,
    },
    itinerary: {
      type: SlideType.ITINERARY,
      sections: itinerarySections,
    },
  }
}

export const getDefaultTheme = (): ThemeTemplate => {
  return {
    color: {
      primary: '#0055ff',
      background: '#f8f7f7',
      shading: '#d2e1f9',
      accent: '#00bfff',
    },
    font: {
      heading: {
        type: {
          family: 'Inria Serif',
          variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
          subsets: ['latin', 'latin-ext'],
          version: 'v14',
          lastModified: '2022-09-22',
          files: {
            regular: 'http://fonts.gstatic.com/s/inriaserif/v14/fC1lPYxPY3rXxEndZJAzN0SsfSzNr0Ck.ttf',
          },
          category: 'serif',
          kind: 'webfonts#webfont',
        },
        color: '#000000',
      },
      subheading: {
        type: {
          family: 'Inter',
          variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
          subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext', 'vietnamese'],
          version: 'v12',
          lastModified: '2022-09-22',
          files: {
            regular:
              'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
          },
          category: 'sans-serif',
          kind: 'webfonts#webfont',
        },
        color: '#000000',
      },
      primary: {
        type: {
          family: 'Istok Web',
          variants: ['regular', 'italic', '700', '700italic'],
          subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
          version: 'v20',
          lastModified: '2022-09-22',
          files: {
            regular: 'http://fonts.gstatic.com/s/istokweb/v20/3qTvojGmgSyUukBzKslZAWF-9kIIaQ.ttf',
          },
          category: 'sans-serif',
          kind: 'webfonts#webfont',
        },
        color: '#000000',
      },
    },
    density: '',
    borders: '',
  } as ThemeTemplate
}

/**
 * Get the default user template. If the template is incomplete, it returns a version of the template
 * that's merged with the system default template
 *
 * @param template the user template
 */
export const getUserTemplate = (template: Template): Template => {
  const _template = { ...template }
  // because we are introducing yachts just now,
  // the user could have a template with a well defined itinerary template but an
  // empty yacht template. If the yacht template is empty then make sure to fill it with the system default

  const proposalTemplate = getDefaultProposalTemplate()
  if (!_template.yacht || !Object.keys(_template.yacht).length) {
    _template.yacht = proposalTemplate.yacht
  }

  // same for vessel
  if (!_template.vessel || !Object.keys(_template.vessel).length) {
    _template.vessel = proposalTemplate.vessel
  }

  // same for theme
  if (!_template.theme || !Object.keys(_template.theme).length) {
    _template.theme = proposalTemplate.theme
  }

  // same for identity
  if (!_template.identity || !Object.keys(_template.identity).length) {
    _template.identity = proposalTemplate.identity
  }

  // same for header
  if (!_template.header || !Object.keys(_template.header).length) {
    _template.header = proposalTemplate.header
  }

  return _template
}
