import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { YachtSummaryCardSectionData } from '../yacht-summary-card/types/types'

import YachtSummaryCardSectionVue from './YachtSummaryCardSection.vue'

export class YachtSummaryCardSection extends AbstractSection<YachtSummaryCardSectionData> {
  getType(): SectionType {
    return SectionType.YACHT_SUMMARY_CARD
  }

  getComponent(): Component {
    return YachtSummaryCardSectionVue
  }
}
