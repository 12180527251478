<script setup lang="ts">
import { Ref, computed, onMounted, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import Tabs from '@/components/Tabs.vue'
import { AllDayCardsSectionEditorData } from '@/sections/allDayCards/AllDayCardsSectionEditor'
import GalleryTabForAllDayCards from '@/sections/allDayCards/GalleryTabForAllDayCards.vue'
import { AllDayCardsLayout, AllDayCardsOptions } from '@/sections/allDayCards/Type'
import HeroTwoColumnLayout from '@/sections/allDayCards/assets/HeroTwoColumnLayout.png'
import Toggle from '@/sections/hero/components/Toggle.vue'
import { LayoutTemplate } from '@/sections/types'
import { removeHtmlTagsFromString } from '@/utils/stringManipulations'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's data
   */
  data: AllDayCardsSectionEditorData
  /**
   * The section's layout
   */
  layout: AllDayCardsLayout
}

const props = defineProps<Props>()

const showDistances: Ref<boolean> = ref(true)

const selectedSegmentIndex: Ref<number> = ref(0)
const selectedSegmentImages = computed(() => props.data.segments[selectedSegmentIndex.value].images)

const updateSelectedSegment = (event: Event) => {
  const target = event.target as HTMLSelectElement
  const index = parseInt(target.value)
  selectedSegmentIndex.value = index
  document.getElementById(props.id)?.children[index].scrollIntoView({ behavior: 'smooth' })
}

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<AllDayCardsSectionEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const tabs: Ref<string[]> = ref(['Layout', 'Gallery', 'Settings'])
const activeTab: Ref<string> = ref('Layout')

onMounted(() => {
  showDistances.value = props.layout.options ? props.layout.options.showDistances : true
})

const updateLayout = (layoutType: string) => {
  emit('update:layout', { sectionId: props.id, layout: { type: layoutType } })
}

/**
 * Update layout options
 */
const updateLayoutOptions = () => {
  showDistances.value = !showDistances.value
  const options: AllDayCardsOptions = {
    showDistances: showDistances.value,
  }
  emit('update:layout', {
    sectionId: props.id,
    layout: {
      ...props.layout,
      options,
    },
  })
}

const updateImage = (event: { field: string; value: string; layout?: LayoutTemplate }): void => {
  const data: AllDayCardsSectionEditorData = ObjectUtil.deepCopy(props.data)

  if (event.field === 'images') {
    const images = data.segments[selectedSegmentIndex.value].images
    data.segments[selectedSegmentIndex.value].images = [...images, event.value]
  }

  emit('update:value', { sectionId: props.id, data: data })
}

/**
 * Update is happening when the user reorders the images in the gallery tab component.
 * Simply just fire the event to update the data.
 * @param event
 */
const updateByReorder = (event: { from: number; to: number; images: string[] }): void => {
  const data: AllDayCardsSectionEditorData = ObjectUtil.deepCopy(props.data)
  if (data.segments[selectedSegmentIndex.value].images.length === 1 || event.from === event.to) {
    return
  }

  data.segments[selectedSegmentIndex.value].images = event.images
  emit('update:value', { sectionId: props.id, data: data })
}
const deleteImage = (event: { value: string; index: number }): void => {
  const data: AllDayCardsSectionEditorData = ObjectUtil.deepCopy(props.data)
  if (event.value === data.segments[selectedSegmentIndex.value].heroImage) {
    data.segments[selectedSegmentIndex.value].heroImage = ''
  }

  data.segments[selectedSegmentIndex.value].stops.forEach((stop) => {
    if (event.value === stop.heroImage) {
      stop.heroImage = ''
    }
  })

  data.segments[selectedSegmentIndex.value].images.splice(event.index, 1)

  emit('update:value', { sectionId: props.id, data: data })
}
</script>
<template>
  <Tabs v-if="props.data && props.data.segments" :tabs="tabs" @update:activeTab="activeTab = $event">
    <div v-show="activeTab === 'Layout'">
      <HelpTip class="mb-5" tip="Select a style for the route day section" />
      <div id="hero-two-column-layout-container">
        <h1 class="text-sm font-semibold text-gray-900 dark:text-white">Hero Image with two column text</h1>
        <p class="text-xs mb-4 text-gray-500 dark:text-gray-400">An elegant showcase for excellent photography</p>
        <div class="hero-two-column-layout group relative">
          <img class="w-full border border-gray-200" :src="HeroTwoColumnLayout" />
          <!-- On hover apply -->
          <div v-if="layout.type !== 'hero-two-column'">
            <button
              class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
              type="button"
              @click="updateLayout('hero-two-column')"
            >
              Apply Style
            </button>
            <div
              class="z-10 absolute top-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
            ></div>
          </div>
          <Badge class="top-4 right-4" text="Added" :show="layout.type === 'hero-two-column'" />
        </div>
      </div>
    </div>

    <!-- Gallery Tab -->
    <div v-show="activeTab === 'Gallery'" class="flex flex-col gap-y-4">
      <HelpTip tip="Drag any image below into any position in the image layout" />
      <select
        id="days"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        @change.stop="updateSelectedSegment($event)"
      >
        <option
          v-for="(segment, $index) in props.data.segments"
          :key="$index"
          :value="$index"
          :selected="selectedSegmentIndex === $index"
        >
          {{ removeHtmlTagsFromString(segment.label) }}
        </option>
      </select>

      <GalleryTabForAllDayCards
        v-show="activeTab === 'Gallery'"
        :proposalUri="props.proposalUri"
        :images="selectedSegmentImages"
        :layout="layout"
        :allowsAddImage="false"
        @update:value="updateImage"
        @delete:value="deleteImage"
        @update:reorder="updateByReorder"
      />
    </div>

    <!--Settings Tab -->
    <div v-show="activeTab === 'Settings'" class="flex flex-col justify-center gap-y-5">
      <Toggle
        class="ml-1 mt-1"
        text="Show distance between places"
        :value="showDistances"
        :reversed="true"
        :is-bold="false"
        @click.prevent="updateLayoutOptions"
      />
    </div>
  </Tabs>
</template>
