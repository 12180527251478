<script setup lang="ts">
import { ComputedRef, Ref, computed, ref } from 'vue'

import HelpTip from '@/components/HelpTip.vue'
import InputComponent from '@/components/Input.vue'
import Toggle from '@/components/Toggle.vue'

import { YachtSummaryCardLayoutTemplate, YachtSummaryCardSectionData } from '../types/types'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The yacht summary card data
   */
  data: YachtSummaryCardSectionData
  /**
   * The yacht layout
   */
  layout: YachtSummaryCardLayoutTemplate
}

const props = defineProps<Props>()

const title: Ref<string> = ref(props.data.yachtButton?.title)
const link: Ref<string> = ref(props.data.yachtButton?.link)

const emit = defineEmits<{
  (e: 'update:value', value: { sectionId: string; data: YachtSummaryCardSectionData }): void
  (e: 'update:layout', value: { sectionId: string; layout: YachtSummaryCardLayoutTemplate }): void
}>()

const getFormattedTitle: { [key: string]: string } = {
  costAndFees: 'Cost & fees',
  yachtType: 'Yacht type/model',
  numCabins: 'Cabins',
  numPassengers: 'Cruising capacity',
  maxCrew: 'Crew',
  buildYearRefitYear: 'Build year; refit year',
  length: 'Length (m/ft)',
  numDecks: 'Decks',
  cruiseSpeed: 'Cruising speed',
  topSpeed: 'Top speed',
  flag: 'Flag',
  descriptionAndNotes: 'Description & notes',
  galleryCarousel: 'Gallery carousel',
  yachtButton: 'Yacht button',
}

const visibilityData: ComputedRef<{ title: string; value: any }[]> = computed(() =>
  Object.entries(props.layout.options || {})
    .filter(([title]) => title !== 'galleryCarousel' && title !== 'yachtButton')
    .map(([title, value]) => ({
      title,
      value,
    })),
)

const yachtButtonData: ComputedRef<{ title: string; value: any }[]> = computed(() =>
  Object.entries(props.layout.options || {})
    .filter(([title]) => title === 'yachtButton')
    .map(([title, value]) => ({
      title,
      value,
    })),
)

//The visibility and button toggle value update
const toggleEnableDisplay = (data: { title: string; value: boolean }) => {
  if (data.title !== 'yachtButton') {
    const updateIndex = visibilityData.value.findIndex((item) => item.title == data.title)
    visibilityData.value[updateIndex] = {
      title: data.title,
      value: !data.value,
    }
  } else {
    const updateIndex = yachtButtonData.value.findIndex((item) => item.title == data.title)
    yachtButtonData.value[updateIndex] = {
      title: data.title,
      value: !data.value,
    }
  }

  emit('update:layout', {
    sectionId: props.id,
    layout: {
      type: 'default',
      options: {
        ...props.layout.options,
        [data.title]: !data.value,
      },
    },
  })
}

// The button input field value update
const updateInputValue = (_title: string, _link: string) => {
  title.value = _title
  link.value = _link

  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      yachtButton: {
        title: _title,
        link: _link,
      },
    },
  })
}
</script>
<template>
  <div class="flex flex-col gap-y-5">
    <HelpTip tip="Toggle which fields are displayed. These settings will apply for all yachts in this presentation" />

    <div class="h-[calc(100vh-18.5rem)] overflow-y-auto gap-y-5">
      <!-- Yacht button settings - toggle and input value -->
      <div class="flex flex-col gap-x-1 gap-y-4">
        <Toggle
          v-for="(enabledData, index) in yachtButtonData"
          :key="`button-${index}`"
          :name="enabledData.title"
          :reversed="true"
          :is-bold="true"
          :title="getFormattedTitle[enabledData.title] || enabledData.title"
          :value="enabledData.value"
          @click.prevent="toggleEnableDisplay(enabledData)"
        />
        <InputComponent
          name="TitleInput"
          input-size="medium"
          title="Button title"
          background-color="bg-white"
          :value="title"
          @update:value="updateInputValue($event, link)"
        />
        <InputComponent
          name="LinkInput"
          input-size="medium"
          title="Paste link here"
          background-color="bg-white"
          :value="link"
          @update:value="updateInputValue(title, $event)"
        />
      </div>

      <!--Yacht visibility settings - toggles-->
      <div class="flex flex-col gap-x-1 gap-y-4 pt-6 border-t border-gray-200">
        <p class="text-sm font-semibold text-gray-900 dark:text-white">Visibility</p>
        <Toggle
          v-for="(enabledData, index) in visibilityData"
          :key="`visibility-${index}`"
          :name="enabledData.title"
          :reversed="true"
          :title="getFormattedTitle[enabledData.title] || enabledData.title"
          :value="enabledData.value"
          @click.prevent="toggleEnableDisplay(enabledData)"
        />
      </div>
    </div>
  </div>
</template>
