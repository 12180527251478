<script setup lang="ts">
import { Ref, ref } from 'vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { YachtSummaryCardLayoutTemplate, YachtSummaryCardSectionData } from './types/types'

import Tabs from '@/components/Tabs.vue'
import YachtGalleryTab from './YachtSummaryCardConfigurationTabs/YachtGalleryTab.vue'
import YachtSettingsTab from './YachtSummaryCardConfigurationTabs/YachtSettingsTab.vue'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The yacht summary card data
   */
  data: YachtSummaryCardSectionData
  /**
   * The yacht layout
   */
  layout: YachtSummaryCardLayoutTemplate
}

const props = defineProps<Props>()
const tabs: Ref<string[]> = ref(['Settings', 'Gallery'])
const activeTab: Ref<string> = ref('Settings')

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<YachtSummaryCardSectionData>): void
  (e: 'update:layout', value: { sectionId: string; layout: YachtSummaryCardLayoutTemplate }): void
}>()

// The visibility toggle and button input field value update
const updateSettings = (event: { sectionId: string; data: YachtSummaryCardSectionData }): void => {
  emit('update:value', {
    sectionId: event.sectionId,
    data: event.data,
  })
}

// The layout options update for the yacht summary card
const updateLayoutOptions = (event: { sectionId: string; layout: YachtSummaryCardLayoutTemplate }): void => {
  emit('update:layout', {
    sectionId: event.sectionId,
    layout: event.layout,
  })
}

const deleteImage = (index: number): void => {
  // deep copy the data
  const data: YachtSummaryCardSectionData = ObjectUtil.deepCopy(props.data)
  data.images.splice(index, 1)
  updateSettings({ sectionId: props.id, data })
}

const addImage = (value: string): void => {
  // deep copy the data
  const data: YachtSummaryCardSectionData = ObjectUtil.deepCopy(props.data)

  const images = data.images
  data.images = [...images, value]

  updateSettings({ sectionId: props.id, data })
}
</script>
<template>
  <Tabs :tabs="tabs" @update:activeTab="activeTab = $event">
    <YachtSettingsTab
      v-show="activeTab === 'Settings'"
      :id="props.id"
      :data="props.data"
      :layout="props.layout"
      @update:value="updateSettings"
      @update:layout="updateLayoutOptions"
    />
    <YachtGalleryTab
      v-show="activeTab === 'Gallery'"
      :id="props.id"
      :uri="props.proposalUri"
      :images="props.data.images"
      :layout="props.layout"
      @delete:value="deleteImage"
      @update:value="addImage"
      @update:layout="updateLayoutOptions"
    />
  </Tabs>
</template>
