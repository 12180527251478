import { Component } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { AbstractSection } from '@/services/proposal/Section'

import VesselSpecificationsSectionVue from './VesselSpecificationsSection.vue'
import { VesselSpecificationsSectionData } from './types/types'

export class VesselSpecificationsSection extends AbstractSection<VesselSpecificationsSectionData> {
  getType(): SectionType {
    return SectionType.VESSEL_SPECIFICATIONS
  }

  getComponent(): Component {
    return VesselSpecificationsSectionVue
  }
}
