<script setup lang="ts">
import { ref } from 'vue'

import { BiRunnable, Runnable } from '@ankor-io/common/lang/functional.types'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

/**
 * Marker props definition
 */
type MarkerProps = {
  /**
   * The string the marker will display
   */
  markerLabel: string
  placeName: string
  longitude: number
  latitude: number
  mapLayoutType: string
  markerIndex: number

  /**
   * A function to handle the number selection.
   * Normally we would use event emit and have the parent
   * handle it. However in this instance mapbox is injecting a bunch
   * of html so the emitted event kinda dies in those layers.
   * This handle select callback solves that issue.
   */
  handleSelect: Runnable<number>
  /**
   * The number of the selected marker
   */
  /**
   * Higher order function to handle zoom onto a marker
   * (see reason under the handleSelect comments)
   */
  zoomToMarkerRunnable: BiRunnable<number, number>
}

const props = defineProps<MarkerProps>()

/**
 * The label is absolute positioned on top of the svg.
 */
const active = ref(false)

/**
 * Update the active state and additionally add a greater z-index to the parent marker hidden behind mapbox
 */
const setActive = (event: MouseEvent) => {
  active.value = true
  const target = event.target as HTMLElement
  const mapboxMarker = target.parentElement?.parentElement
  mapboxMarker?.classList.add('z-10')
}

/**
 * Update the active state and additionally remove the greater z-index from the parent marker hidden behind mapbox
 */
const setInvactive = (event: MouseEvent) => {
  active.value = false
  const target = event.target as HTMLElement
  const mapboxMarker = target.parentElement?.parentElement
  mapboxMarker?.classList.remove('z-10')
}

/**
 * Selecting a marker produces a selected event
 * that emits the markerIndex props value to the parent
 */
const select = () => {
  props.handleSelect(props.markerIndex)
}

/**
 * Invoke the zoom to marker runnable provided via props.
 * a method responsible for zooming in to the marker
 */
const zoomMarker = () => {
  props.zoomToMarkerRunnable(props.longitude, props.latitude)
}
</script>
<template>
  <div class="relative" @click="zoomMarker">
    <div
      class="absolute text-white"
      @mouseenter.stop="setActive"
      @mouseleave.stop="setInvactive"
      @click="select"
      data-test="marker"
    >
      <div
        class="border border-gray-200 min-w-[2.25rem] h-9 flex justify-center items-center rounded-full overflow-hidden"
        :class="[
          active ? 'max-w-xs' : 'px-0 max-w-[2.25rem]',
          { 'monochromatic-marker-circle': mapLayoutType === 'monochromatic' },
        ]"
      >
        <div
          class="z-10 border-2 border-white bg-theme-primary hover:bg-theme-shading min-w-[2.25rem] h-9 flex justify-center items-center rounded-full text-sm text-white whitespace-nowrap transition-all duration-300 ease-in-out overflow-hidden"
          :class="[
            active ? 'px-1.5 max-w-xs' : 'px-0 max-w-[2.25rem]',
            { 'monochromatic-marker-circle': mapLayoutType === 'monochromatic' },
          ]"
          data-test="marker-label"
        >
          {{ markerLabel }}
          <div
            class="transition-all duration-300 ease-in-out"
            :class="active ? 'opacity-100 pl-1.5' : 'opacity-0 pl-0 w-0'"
          >
            <SingleLineTextEditor :is-editable="false" :value="placeName"></SingleLineTextEditor>
          </div>
        </div>
      </div>
      <div
        class="relative ml-2.5 -mt-0.5 h-0 w-0 border-l-8 border-r-8 border-t-8 border-solid border-l-transparent border-r-transparent border-t-gray-200"
        :class="{ 'monochromatic-marker-point': mapLayoutType === 'monochromatic' }"
      >
        <div
          class="absolute bottom-[1px] right-1/2 translate-x-1/2 border-l-[6px] border-r-[6px] border-t-[7px] border-solid border-l-transparent border-r-transparent border-t-white"
          :class="{ 'monochromatic-marker-point': mapLayoutType === 'monochromatic' }"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.monochromatic-marker-circle,
.monochromatic-marker-point {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
</style>
