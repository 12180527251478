import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M2 9.5C2 9.22386 2.22386 9 2.5 9H10.9118C11.1879 9 11.4118 9.22386 11.4118 9.5C11.4118 9.77614 11.1879 10 10.9118 10H2.5C2.22386 10 2 9.77614 2 9.5Z" }),
    _createElementVNode("path", { d: "M12.5883 9.5C12.5883 9.22386 12.8121 9 13.0883 9H21.5C21.7762 9 22 9.22386 22 9.5C22 9.77614 21.7762 10 21.5 10H13.0883C12.8121 10 12.5883 9.77614 12.5883 9.5Z" }),
    _createElementVNode("path", { d: "M2 11.5C2 11.2239 2.22386 11 2.5 11H10.9118C11.1879 11 11.4118 11.2239 11.4118 11.5C11.4118 11.7761 11.1879 12 10.9118 12H2.5C2.22386 12 2 11.7761 2 11.5Z" }),
    _createElementVNode("path", { d: "M12.5883 11.5C12.5883 11.2239 12.8121 11 13.0883 11H21.5C21.7762 11 22 11.2239 22 11.5C22 11.7761 21.7762 12 21.5 12H13.0883C12.8121 12 12.5883 11.7761 12.5883 11.5Z" }),
    _createElementVNode("path", { d: "M2 13.5C2 13.2239 2.22386 13 2.5 13H10.9118C11.1879 13 11.4118 13.2239 11.4118 13.5C11.4118 13.7761 11.1879 14 10.9118 14H2.5C2.22386 14 2 13.7761 2 13.5Z" }),
    _createElementVNode("path", { d: "M12.5883 13.5C12.5883 13.2239 12.8121 13 13.0883 13H21.5C21.7762 13 22 13.2239 22 13.5C22 13.7761 21.7762 14 21.5 14H13.0883C12.8121 14 12.5883 13.7761 12.5883 13.5Z" }),
    _createElementVNode("path", { d: "M2 15.5C2 15.2239 2.22386 15 2.5 15H10.9118C11.1879 15 11.4118 15.2239 11.4118 15.5C11.4118 15.7761 11.1879 16 10.9118 16H2.5C2.22386 16 2 15.7761 2 15.5Z" }),
    _createElementVNode("path", { d: "M12.5883 15.5C12.5883 15.2239 12.8121 15 13.0883 15H21.5C21.7762 15 22 15.2239 22 15.5C22 15.7761 21.7762 16 21.5 16H13.0883C12.8121 16 12.5883 15.7761 12.5883 15.5Z" })
  ]))
}