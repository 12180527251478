<script setup lang="ts">
import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import { LayoutTemplate } from '@/sections/types'

import ColouredMapLayout from './assets/ColouredMapLayout.png'
import MonochromaticMapLayout from './assets/MonochromaticMapLayout.png'

type Props = {
  uri: string
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:layout', layout: LayoutTemplate): void
}>()

const updateLayout = (layoutName: string) => {
  emit('update:layout', {
    type: layoutName,
    options: props.layout.options,
  })
}
</script>
<template>
  <div id="map-style-tab" class="flex flex-col gap-y-5">
    <HelpTip tip="Select a map style here." />

    <div class="h-[calc(100vh-16rem)] overflow-y-auto flex flex-col gap-y-5">
      <div id="coloured-layout-container">
        <div class="mb-5">
          <h1 class="text-sm font-semibold text-gray-900 dark:text-white">Blue ocean, overlaid day cards</h1>
          <p class="text-xs text-gray-500 dark:text-gray-400">Ideal if you want a bright, fun map.</p>
        </div>
        <div class="coloured-layout group relative">
          <img class="w-full border border-gray-200" :src="ColouredMapLayout" />
          <!-- On hover apply -->
          <div v-if="layout.type && layout.type !== 'coloured'">
            <button
              class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
              type="button"
              @click="updateLayout('coloured')"
            >
              Apply Style
            </button>
            <div
              class="z-10 absolute top-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
            ></div>
          </div>
          <Badge class="top-4 right-4" text="Added" :show="!layout.type || layout.type === 'coloured'" />
        </div>
      </div>

      <div id="monochromatic-layout-container">
        <div class="mb-5">
          <h1 class="text-sm font-semibold text-gray-900 dark:text-white">Monochromatic, sidebar cards</h1>
          <p class="text-xs text-gray-500 dark:text-gray-400">Ideal for understated elegance.</p>
        </div>
        <div class="monochromatic-layout group relative">
          <img class="w-full border border-gray-200" :src="MonochromaticMapLayout" />
          <!-- On hover apply -->
          <div v-if="layout.type !== 'monochromatic'">
            <button
              class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
              type="button"
              @click="updateLayout('monochromatic')"
            >
              Apply Style
            </button>
            <div
              class="z-10 absolute top-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
            ></div>
          </div>
          <Badge class="top-4 right-4" text="Added" :show="layout.type === 'monochromatic'" />
        </div>
      </div>
    </div>
  </div>
</template>
