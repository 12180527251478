/*
console.log(isValidURL('www.google.com')) // false
console.log(isValidURL('https://www.google.com')) // true
console.log(isValidURL('http://www.google.com')) // true
console.log(isValidURL('google')) // false
console.log(isValidURL('tel://444444444')) // true
console.log(isValidURL('mailto:xyz@xyz.com')) // true
*/

export const isValidURL = (string: string) => {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}
