<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import HelpTip from '@/components/HelpTip.vue'
import Input from '@/components/Input.vue'
import Toggle from '@/components/Toggle.vue'
import Dropdown from '@/sections/map/Dropdown.vue'
import { LayoutTemplate } from '@/sections/types'

import { MapStatisticData } from './types/types'

type Props = {
  layout: LayoutTemplate
  statistics: MapStatisticData
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:layout', layout: LayoutTemplate): void
  (e: 'update:value', value: MapStatisticData): void
}>()

const distanceTravelledUnitOptions = ['nm', 'km']
const cruisingSpeedUnitOptions = ['knots', 'km/hr']
const fuelConsumptionUnitOptions = ['US gal/hr', 'Imp. gal/hr', 'L/hr']

const enableLocationCards: Ref<boolean> = ref(true)
const enableDistance: Ref<boolean> = ref(true)
const enableDuration: Ref<boolean> = ref(true)
const enableFuelConsumption: Ref<boolean> = ref(true)

onMounted(() => {
  // Because value can be false, a simple props.layout.options.field || true will also fallback to true
  enableLocationCards.value =
    typeof props.layout?.options?.showLocationCards === 'boolean' ? props.layout?.options?.showLocationCards : true
  enableDistance.value =
    typeof props.layout?.options?.showDistance === 'boolean' ? props.layout?.options?.showDistance : true
  enableDuration.value =
    typeof props.layout?.options?.showDuration === 'boolean' ? props.layout?.options?.showDuration : true
  enableFuelConsumption.value =
    typeof props.layout?.options?.showFuel === 'boolean' ? props.layout?.options?.showFuel : true
})

// Hides/shows the data in the corner of the map
const toggleItem = (field: string) => {
  const options = ObjectUtil.deepCopy(props.layout.options)
  if (field === 'locationCards') {
    enableLocationCards.value = !enableLocationCards.value
    options.showLocationCards = enableLocationCards.value
  } else if (field === 'distance') {
    enableDistance.value = !enableDistance.value
    options.showDistance = enableDistance.value
  } else if (field === 'duration') {
    enableDuration.value = !enableDuration.value
    options.showDuration = enableDuration.value
  } else if (field === 'fuelConsumption') {
    enableFuelConsumption.value = !enableFuelConsumption.value
    options.showFuel = enableFuelConsumption.value
  }

  updateLayout({
    type: props.layout?.type,
    options,
  })
}

const updateInput = (field: string, event: Event) => {
  const target = event.target as HTMLInputElement
  updateInputValue(field, target.value)
}

const restrictKeyPress = (event: KeyboardEvent) => {
  // restrict to enter `e` character or up/down arrow keys
  if (event.code === 'KeyE') {
    event.preventDefault()
  }

  const target = event.target as HTMLInputElement

  // check if has decimal and prevent entering another decimal
  if (event.code === 'Period' && target.value.indexOf('.') != -1) {
    event.preventDefault()
  }

  // check if already has 2 decimal places
  if (target.value.indexOf('.') >= 0 && target.value.split('.')[1].length >= 2) {
    event.preventDefault()
  }

  // check if already has length of 12
  if (target.value.length >= 12) {
    event.preventDefault()
  }
}

// The input field value update
const updateInputValue = (field: string, value: string) => {
  const data = ObjectUtil.deepCopy(props.statistics)
  if (field === 'distanceTravelled') {
    data.distanceTravelled = value
  } else if (field === 'cruisingSpeed') {
    data.cruisingSpeed = value
  } else if (field === 'fuelConsumption') {
    data.fuelConsumption = value
  }

  const travelled = data.distanceTravelled
  const travelledUnit = data.distanceTravelledUnit
  const speed = data.cruisingSpeed
  const speedUnit = data.cruisingSpeedUnit
  data.tripDuration = calculateTripDuration({
    distanceTravelled: travelled,
    distanceTravelledUnit: travelledUnit,
    cruisingSpeed: speed,
    cruisingSpeedUnit: speedUnit,
  })

  updateValue(data)
}

// The dropdown field value update
const updateDropdownUnitValue = (field: string, value: string) => {
  const data = ObjectUtil.deepCopy(props.statistics)
  if (field === 'distanceTravelled') {
    data.distanceTravelledUnit = value
  } else if (field === 'cruisingSpeed') {
    data.cruisingSpeedUnit = value
  } else if (field === 'fuelConsumption') {
    data.fuelConsumptionUnit = value
  }

  const travelled = data.distanceTravelled
  const travelledUnit = data.distanceTravelledUnit
  const speed = data.cruisingSpeed
  const speedUnit = data.cruisingSpeedUnit
  data.tripDuration = calculateTripDuration({
    distanceTravelled: travelled,
    distanceTravelledUnit: travelledUnit,
    cruisingSpeed: speed,
    cruisingSpeedUnit: speedUnit,
  })

  updateValue(data)
}

const calculateTripDuration = (event: {
  distanceTravelled: string
  distanceTravelledUnit: string
  cruisingSpeed: string
  cruisingSpeedUnit: string
}): string => {
  if (!event?.cruisingSpeed || !event?.cruisingSpeedUnit || !event?.distanceTravelled) {
    return ''
  }

  // First convert all values into the same metric (kms)
  const NM_TO_KM = 1.852
  let distance = 0
  let speed = 0

  distance = Number(event?.distanceTravelled)
  if (distance === 0) {
    return ''
  }

  if (distance && event?.distanceTravelledUnit === 'nm') {
    distance *= NM_TO_KM
  }

  speed = Number(event?.cruisingSpeed)
  if (speed === 0) {
    return ''
  }

  if (speed && event?.cruisingSpeedUnit === 'knots') {
    speed *= NM_TO_KM
  }

  const timeInDecimals = distance / speed
  const hours = timeInDecimals.toString().split('.')[0]
  if (hours === '0') {
    return `${(timeInDecimals * 0.6).toFixed(2).toString()} min`
  } else {
    const minutesAsDecimal = timeInDecimals.toFixed(2).toString().split('.')[1]
    const minutes = Math.round(Number(minutesAsDecimal) * 0.6).toString()
    if (minutes === '0') {
      return `${hours} hr`
    } else {
      return `${hours} hr ${minutes} min`
    }
  }
}

const updateLayout = (layout: LayoutTemplate) => {
  emit('update:layout', layout)
}

const updateValue = (statistics: MapStatisticData) => {
  // Remove the old layout options and local variables
  if (props.layout.options) {
    const layout = ObjectUtil.deepCopy(props.layout)
    delete layout['options']
    emit('update:layout', layout)
  }

  emit('update:value', statistics)
}
</script>
<template>
  <!-- h-screen prevents overflow appearing when a dropdown is shown because it doesn't reserve any space -->
  <div id="map-settings-tab" class="flex flex-col gap-y-5">
    <HelpTip tip="Edit the value and visibility of the map statistics and map cards." />
    <div class="h-[calc(100vh-17rem)] overflow-y-auto flex flex-col gap-y-5">
      <Toggle
        title="Day cards"
        text="Show day cards with map"
        reversed
        :value="enableLocationCards"
        @click.prevent="toggleItem('locationCards')"
      />
      <Toggle
        title="Distance"
        text="Show approximate distance on map"
        reversed
        :value="enableDistance"
        @click.prevent="toggleItem('distance')"
      />
      <Toggle
        title="Time"
        text="Show the time spent travelling on the route"
        reversed
        :value="enableDuration"
        @click.prevent="toggleItem('duration')"
      />
      <Toggle
        title="Fuel"
        text="Show approximate petrol consumed"
        reversed
        :value="enableFuelConsumption"
        @click.prevent="toggleItem('fuelConsumption')"
      />

      <hr class="border-gray-200" />
      <div class="flex flex-col gap-y-4">
        <span class="text-sm font-semibold text-gray-900 dark:text-white">Change map statistics here</span>
        <div class="flex gap-x-2.5 pb-1">
          <div class="relative w-full">
            <!--  -->
            <input
              type="number"
              id="DistanceTravelled"
              class="peer block w-full h-full text-lg border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
              placeholder=" "
              :value="props.statistics!.distanceTravelled"
              @blur="updateInput('distanceTravelled', $event)"
              @keypress="restrictKeyPress($event)"
              @keydown.up.prevent
              @keydown.down.prevent
            />
            <label
              for="DistanceTravelled"
              class="absolute rounded-t text-xs font-medium cursor-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-700 peer-focus:text-primary-600 dark:peer-focus:text-primary-500"
            >
              Distance Travelled
            </label>
          </div>
          <!-- flex-none lets the dropdown box open up and take that w-28 space -->
          <div class="relative w-28 flex-none pr-1">
            <Dropdown
              id="distanceTravelled"
              :value="props.statistics!.distanceTravelledUnit"
              :options="distanceTravelledUnitOptions"
              @select:value="updateDropdownUnitValue('distanceTravelled', $event)"
            />
          </div>
        </div>
        <div class="flex gap-x-2.5 pb-1">
          <div class="relative w-full">
            <input
              type="number"
              id="CruisingSpeed"
              class="peer block w-full h-full text-lg border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
              maxlength="12"
              placeholder=" "
              :value="props.statistics!.cruisingSpeed"
              @blur="updateInput('cruisingSpeed', $event)"
              @keypress="restrictKeyPress($event)"
              @keydown.up.prevent
              @keydown.down.prevent
            />
            <label
              for="CruisingSpeed"
              class="absolute rounded-t text-xs font-medium cursor-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-700 peer-focus:text-primary-600 dark:peer-focus:text-primary-500"
            >
              Cruising speed
            </label>
          </div>
          <!-- flex-none lets the dropdown box open up and take that w-28 space -->
          <div class="relative w-28 flex-none pr-1">
            <Dropdown
              id="cruisingSpeed"
              :value="props.statistics!.cruisingSpeedUnit"
              :options="cruisingSpeedUnitOptions"
              @select:value="updateDropdownUnitValue('cruisingSpeed', $event)"
            />
          </div>
        </div>
        <div class="flex gap-x-2.5 pb-1">
          <div class="relative w-full">
            <input
              type="number"
              id="FuelConsumption"
              class="peer block w-full h-full text-lg border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
              maxlength="12"
              placeholder=" "
              :value="props.statistics!.fuelConsumption"
              @blur="updateInput('fuelConsumption', $event)"
              @keypress="restrictKeyPress($event)"
              @keydown.up.prevent
              @keydown.down.prevent
            />
            <label
              for="FuelConsumption"
              class="absolute rounded-t text-xs font-medium cursor-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-700 peer-focus:text-primary-600 dark:peer-focus:text-primary-500"
            >
              Fuel consumption
            </label>
          </div>
          <!-- flex-none lets the dropdown box open up and take that w-28 space -->
          <div class="relative w-28 flex-none pr-1">
            <Dropdown
              id="fuelConsumption"
              :value="props.statistics!.fuelConsumptionUnit"
              :options="fuelConsumptionUnitOptions"
              @select:value="updateDropdownUnitValue('fuelConsumption', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
