import { DateTime, Duration } from 'luxon'

/**
 * Convert an epoch time to a relative time string; i.e. "3 days ago"
 *
 * @param epochSec The epoch time to convert
 * @param maxRelativeAgoSec The maximum number of seconds to consider with a relative timestamp, default 13 days.
 * @param format the date format to use when the time is not relative
 */
export const epochAsLabel = (
  epochSec: number,
  { maxRelativeAgoSec, format } = { maxRelativeAgoSec: 86400 * 13, format: DateTime.DATE_SHORT },
): string | null => {
  if (!epochSec) {
    return ''
  }

  // Is the time outside of the relative range?
  if (DateTime.now().toSeconds() - epochSec > maxRelativeAgoSec) {
    return DateTime.fromSeconds(epochSec).toLocal().toLocaleString(format)
  }

  // see: https://moment.github.io/luxon/api-docs/index.html#datetimetorelativecalendar
  return DateTime.fromSeconds(epochSec).toLocal().toRelativeCalendar()
}

/**
 * Convert an ISO8601 duration to a number of days.
 *
 * @param pxd The input, e.g. 'P8D'
 * @returns The number of days in this ISO duration
 */
export const durationToDays = (pxd: string): number | undefined => {
  if (!pxd) {
    return undefined
  }

  return Duration.fromISO(pxd).days
}
