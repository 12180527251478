import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M6.99995 16C6.01497 16.0011 5.06421 15.6388 4.32982 14.9823C3.59544 14.3259 3.12907 13.4216 3.02006 12.4427C2.91106 11.4638 3.16708 10.479 3.73909 9.67714C4.31109 8.87526 5.15885 8.31263 6.11995 8.09703C5.84187 6.80012 6.09038 5.44586 6.8108 4.33218C7.53122 3.21849 8.66454 2.43661 9.96145 2.15853C11.2584 1.88045 12.6126 2.12895 13.7263 2.84937C14.84 3.56979 15.6219 4.70312 15.9 6.00003H16C17.2399 5.99878 18.4361 6.45831 19.3563 7.2894C20.2765 8.12049 20.8551 9.26386 20.9797 10.4975C21.1043 11.7312 20.766 12.9672 20.0306 13.9655C19.2952 14.9638 18.2151 15.6533 17 15.9M15 13L12 10M12 10L8.99995 13M12 10V22"
    })
  ]))
}