export const styleValidity = (uploadState: string | null): string => {
  if (uploadState === 'Failed') {
    return 'border-red-600 bg-red-200 hover:bg-red-300 border-dashed'
  }

  if (uploadState === 'Progress') {
    return 'border-gray-300 bg-white hover:bg-gray-50 border-dashed'
  }

  if (uploadState === 'Success') {
    return 'border-green-400 bg-white hover:bg-gray-50'
  }

  return 'border-gray-300 bg-gray-50 hover:bg-gray-100 border-dashed'
}
