import { ApolloClient } from '@apollo/client/core'
import { GrowthBook } from '@growthbook/growthbook'
import { Component, ComputedOptions, MethodOptions } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks, LifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { Callable, Indexable, Runnable } from '@ankor-io/common/lang/functional.types'
import { UUID } from '@ankor-io/common/lang/uuid'
import { Events } from '@ankor-io/common/proposal/Events'
import {
  EditableSection,
  EditableSectionState,
  GenericSection,
  HydrationType,
  JsonSection,
  Section,
  SectionInit,
  SectionLayout,
  SectionProperties,
  SectionTemplate,
} from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { ApolloClientProvider } from '@/apollo/ApolloClientProvider'
import { GrowthBookProvider } from '@/utils/growthbook/GrowthBookProvider'

/**
 * The abstract implementation of the generic section
 */
export abstract class AbstractGenericSection<T> implements GenericSection<T> {
  protected properties: SectionProperties
  private proxy: ProxyConstructor
  // the section's data
  data: T
  // references to the source of the data
  refs?: { [key: string]: any }
  // the section's layout
  layout: SectionLayout
  // the uri of the slide this section belongs to
  // it's ok for this not to be a ref since this value
  // must never change for a section instance
  private slideUri: string | null
  // instance of apollo client
  client: () => Promise<ApolloClient<any>>
  // instance of growthbook
  getGrowthBook: () => GrowthBook

  /**
   * Create a section setting data and layout from a template.
   *
   * @param sectionTemplate the section template to initialize the data from
   */
  constructor(init: SectionInit) {
    const deepCopy: SectionTemplate = JSON.parse(JSON.stringify(init.template))
    this.data = deepCopy.data
    this.layout = deepCopy.layout
    this.slideUri = init.slideUri
    this.client = ApolloClientProvider.getInstance()
    this.getGrowthBook = GrowthBookProvider.getInstance()
    this.proxy = this._getProxy(init.source)
    // default by value for now which currently is the only supported behaviour
    this.properties = deepCopy.properties || { hydration: HydrationType.BY_VALUE }
    this.refs = deepCopy.refs
  }

  /**
   * Get a new proxy for the given source that allows for set and get
   *
   * @param source the proxy target
   * @returns the proxy
   */
  protected _getProxy<E extends Object>(source?: E): E {
    const handler: ProxyHandler<E> = {
      get: (target: E, prop: string | symbol): any => {
        const value = (target as Indexable)[prop as string]
        if (value) {
          if (typeof value === 'object') {
            return new Proxy(value, handler)
          }
        }
        return value
      },
      set: (target: E, prop: string | symbol, newValue: any): boolean => {
        ;(target as Indexable)[prop as string] = newValue
        return true
      },
    }
    return new Proxy(source !== null && source !== undefined ? source : ({} as E), handler)
  }

  /**
   * Updates the proxy value
   *
   * @param source the proxy target
   */
  protected _setProxy<E extends Object>(source: E): void {
    this.proxy = this._getProxy(source) as unknown as ProxyConstructor
  }

  /**
   *
   * @returns a proxy that allows accessing section data from the object source
   */
  getProxy<E>(): E {
    return this.proxy as unknown as E
  }

  getSlideUri(): string | null {
    return this.slideUri
  }

  getProperties(): SectionProperties {
    return this.properties
  }

  abstract getType(): SectionType

  abstract getComponent(): Component

  /**
   * Generate a template for this section doing a deep copy of all the values.
   * This method can be overloaded for sections that requires to pass
   * default static data to the template
   *
   * @returns a template version of this section
   */
  asTemplate(): SectionTemplate {
    return {
      type: JSON.parse(JSON.stringify(this.getType())),
      layout: JSON.parse(JSON.stringify(this.layout)),
      properties: this.properties,
    }
  }

  /**
   * Deserialize this section into a json object
   *
   * @returns a json object representing the current state of this section
   */
  toJson(): JsonSection<T> {
    return {
      id: '',
      type: JSON.parse(JSON.stringify(this.getType())),
      layout: JSON.parse(JSON.stringify(this.layout)),
      data: JSON.parse(JSON.stringify(this.data)),
      properties: this.properties,
      refs: this.refs,
    }
  }
}

/**
 * The abstract section
 */
export abstract class AbstractSection<T> extends AbstractGenericSection<T> implements Section<T> {
  /**
   * Allows the section developer to set lifecycle hooks from within the vue component
   *
   * @param hooks the editable lifecycle hooks set by the section developer
   */
  setLifecycleHooks(hooks: LifecycleHooks): void {
    this.onBeforeAttach = decorate(this, 'onBeforeAttach', hooks.onBeforeAttach)
    this.onAttached = decorate(this, 'onAttached', hooks.onAttached)
    this.onBeforeRemove = decorate(this, 'onBeforeRemove', hooks.onBeforeRemove)
    this.onRemoved = decorate(this, 'onRemoved', hooks.onRemoved)
  }

  public onBeforeAttach(): void {}

  public onAttached(): void {}

  public onBeforeRemove(): void {}

  public onRemoved(): void {}

  public dataFrom<A extends { uri: string; [key: string]: any }>(_source: A): T {
    throw new Error(`dataFrom not implemented for section ${this.getType()}`)
  }

  public setData(data: T): void {
    this.data = data
  }

  public setRef(refs?: { [key: string]: any }): void {
    this.refs = refs
  }
}

/**
 * The abstract editable section
 */
export abstract class AbstractEditableSection<T, D> extends AbstractGenericSection<T> implements EditableSection<T> {
  readonly id: string
  private state: EditableSectionState
  private hydrating: boolean
  private observers: Runnable<any>[]

  constructor(sectionInit: SectionInit) {
    super(sectionInit)
    this.id = sectionInit.id || UUID.timeBased()
    // set the default state to initialized unless specified
    this.state = sectionInit.state || EditableSectionState.INITIALIZED
    this.hydrating = false
    this.observers = []
    this.reHydrate()
  }

  subscribe(runnable: Runnable<any>): void {
    this.observers.push(runnable)
  }

  unsubscribe(runnable: Runnable<any>): void {
    this.observers = this.observers.filter((_runnable: Runnable<any>) => _runnable !== runnable)
  }

  notifyAll(args: any): void {
    this.observers.forEach((runnable: Runnable<any>) => runnable(args))
  }

  isHydrating(): boolean {
    return this.hydrating
  }

  reHydrate(): void {
    if ((this.data as any)?.uri && (this.data as any)?.shouldRehydrate) {
      // let's get the dispatcher, so we can dispatch some events
      const dispatcher = useAppDispatcher().get()
      // dispatch a pause event that is going to pause synchronization
      dispatcher.dispatchEvent(Events.PAUSE, this.id)
      this.onBeforeHydrate()
      this.hydrating = true
      this.hydrate()
        .then(() => {
          this.setState(EditableSectionState.INITIALIZED)
          // now that we have data, let's unpause synchronization
          dispatcher.dispatchEvent(Events.UNPAUSE, this.id)
          this.onHydrated()
        })
        .finally(() => (this.hydrating = false))
    }
  }

  beforeMount(): void {
    if (this.getState() === EditableSectionState.NEEDS_INIT) {
      // let's get the dispatcher, so we can dispatch some events
      const dispatcher = useAppDispatcher().get()
      // dispatch a pause event that is going to pause synchronization
      dispatcher.dispatchEvent(Events.PAUSE, this.id)
      this.onBeforeHydrate()
      this.hydrating = true
      this.hydrate()
        .then(() => {
          this.setState(EditableSectionState.INITIALIZED)
          // now that we have data, let's unpause synchronization
          dispatcher.dispatchEvent(Events.UNPAUSE, this.id)
          this.onHydrated()
        })
        .finally(() => (this.hydrating = false))
    }
  }

  beforeUnmount(): void {
    if (this.getState() === EditableSectionState.NEEDS_TO_GO) {
      this.onBeforeRemove()
      this.onRemoved()
    }
  }

  getState(): EditableSectionState {
    return this.state
  }

  setState(state: EditableSectionState): void {
    this.state = state
  }

  /**
   * Allows the section developer to set lifecycle hooks from within the vue component
   *
   * @param hooks the editable lifecycle hooks set by the section developer
   */
  setLifecycleHooks(hooks: EditableLifecycleHooks): void {
    this.onBeforeAttach = decorate(this, 'onBeforeAttach', hooks.onBeforeAttach)
    this.onAttached = decorate(this, 'onAttached', hooks.onAttached)
    this.onBeforeRemove = decorate(this, 'onBeforeRemove', hooks.onBeforeRemove)
    this.onRemoved = decorate(this, 'onRemoved', hooks.onRemoved)
    this.onBeforeHydrate = decorate(this, 'onBeforeHydrate', hooks.onBeforeHydrate)
    this.onHydrated = decorate(this, 'onHydrated', hooks.onHydrated)
    this.onBeforeEdit = decorate(this, 'onBeforeEdit', hooks.onBeforeEdit)
    this.onEdited = decorate(this, 'onEdited', hooks.onEdited)
  }

  setData(data: T) {
    this.data = data
  }

  setLayout(layout: SectionLayout) {
    this.layout = layout
  }

  onChanges(event: ChangeEvent<any>): void {
    const proxy = this.getProxy<any>()
    for (const key of Object.keys(event.data)) {
      proxy[key] = event.data[key]
    }
    this.onBeforeEdit()
  }

  /**
   * Override this method to return a configuration component
   *
   * @returns returns null by defult
   */
  getConfigurationComponent(): Component<any, any, any, ComputedOptions, MethodOptions> | null {
    return null
  }

  fetchData(): Promise<T> {
    throw new Error('Not implemented')
  }

  referenceData<R>(): Promise<R> {
    throw new Error('Not implemented')
  }

  /**
   * Fetches the data for this section and sets it
   *
   * @returns a Promise of void when done
   */
  async hydrate(): Promise<void> {
    // default by value for now until all section support by reference
    const hydrationType: HydrationType = this.getProperties().hydration || HydrationType.BY_VALUE
    if (hydrationType === HydrationType.BY_VALUE) {
      return this.fetchData()
        .then((val) => {
          try {
            this.data = JSON.parse(JSON.stringify(val))
          } catch (error: any) {
            console.debug('error while hydrating by value', error)
            this.data = {} as T
          }
        })
        .catch((err) => console.error(err))
    }
    return this.referenceData<{[key: string]: any}>()
      .then((refs) => {
        try {
          this.refs = JSON.parse(JSON.stringify(refs))
        } catch (error: any) {
          console.debug('error while hydrating by reference', error)
        }
      })
      .catch((err) => console.error(err))
  }

  onBeforeHydrate(): void {}

  onHydrated(): void {}

  onBeforeEdit(): void {}

  onEdited(): void {}

  onBeforeAttach(): void {}

  onAttached(): void {}

  onBeforeRemove(): void {}

  onRemoved(): void {}

  abstract deserialize(): D

  /**
   * Update the section data when there are changes coming from above (going down)
   *
   * @param d the updated D instance
   */
  updateDataFrom(d: D): void {
    super._setProxy(d!)
    this.onEdited()
  }

  /**
   * Deserialize this section into a json object. This overrides the parent method
   *
   * @returns a json object representing the current state of this section
   */
  toJson(): JsonSection<T> {
    return {
      id: this.id,
      type: JSON.parse(JSON.stringify(this.getType())),
      layout: JSON.parse(JSON.stringify(this.layout)),
      data: JSON.parse(JSON.stringify(this.data || {}))!,
      state: this.getState(),
      properties: this.getProperties(),
      refs: this.refs,
    }
  }
}

export abstract class ProposalAbstractEditableSection<T, D> extends AbstractEditableSection<T, D> {
  abstract fetchData(): Promise<T>

  deserialize(): D {
    throw new Error('Not implemented')
  }
}

/**
 * Allows to decorate any section method, this is currently used to allow decoration of lifecycle methods.
 * This function should never be exposed.
 *
 * @param section the section to decorate the lifecycle method for
 * @param method the name of the lifecycle method to decorate
 * @param target the callable providing the decoration behaviour
 * @returns a callable wrapping the original method and the target invokations
 */
const decorate = (section: Section<any> | EditableSection<any>, method: string, target?: Callable): Callable => {
  // get the original method
  const originalMethod: Callable = (section as Indexable)[method]

  // if the original method is not found then throw an error!
  if (!originalMethod) {
    throw new Error(`Cannot override non existing method '${method}' on a section`)
  }

  // if the target is not found then return the original method
  if (!target) {
    return originalMethod
  }

  // we have a target! return a wrapper that calls the original and the target methods
  // make sure the wrapper is NOT an anonymous function otherwise `this` will be undefined
  function decorated() {
    originalMethod.call(section)
    if (target) {
      target.call(section)
    }
  }

  return decorated.bind(section)
}
