<script setup lang="ts">
type Position = 'prefix' | 'suffix' | 'none'

interface ButtonProps {
  id?: string
  name: string
  isPrimary: boolean
  onClick?: () => void
  position?: Position
  hasBorder?: boolean
  disabled?: boolean
}

withDefaults(defineProps<ButtonProps>(), {
  isPrimary: true,
  position: 'none',
  hasBorder: false,
  disabled: false,
})

// dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700
const setIsPrimaryStyles = (isPrimary: boolean, hasBorder: boolean, disabled: boolean): string => {
  if (isPrimary) {
    return disabled
      ? 'text-white bg-gray-200 cursor-not-allowed dark:bg-gray-400'
      : 'text-white bg-primary-600 hover:bg-primary-800 active:bg-primary-600 focus:bg-primary-600'
  }

  const defaultClasses = disabled
    ? 'text-gray-200 bg-white cursor-not-allowed dark:bg-gray-400'
    : 'text-primary-600 bg-transparent hover:bg-primary-300 focus:bg-white'
  if (hasBorder) {
    return `${defaultClasses} border border-primary-600 hover:border-primary-600 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-white dark:hover:bg-gray-600`
  }

  return defaultClasses
}
</script>

<template>
  <button
    type="button"
    class="group transition-all flex items-center outline-none font-medium rounded-lg text-sm px-4 py-3 text-center focus:ring-2 focus:ring-primary-300 whitespace-nowrap"
    :id="id"
    :class="setIsPrimaryStyles(isPrimary, hasBorder, disabled)"
    :disabled="disabled"
    @click="onClick"
  >
    <slot v-if="position === 'prefix'"></slot>
    <span class="text-sm font-medium w-full">{{ name }}</span>
    <slot v-if="position === 'suffix'"></slot>
  </button>
</template>
