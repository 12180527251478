<script setup lang="ts">
/**
 * ###############################################
 * ################### Input #####################
 * id - Proposal ID
 * uri - URI of the vessel entity
 * data - {@VesselOverviewData} - Vessel overview data
 * layout - {@LayoutTemplate} - Layout template
 * ################### Output ####################
 * Renders vessel overview section (non-editable)
 * Heading
 * Vessel description
 * ###############################################
 */
import { Ref, onMounted, onUnmounted, onUpdated, ref } from 'vue'

import { LifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { Vessel } from '@ankor-io/common/vessel/types'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { VesselOverviewData } from '@/sections/vessel/overview/types'

type Props = {
  id: string
  uri: string
  data: VesselOverviewData
  layout: LayoutTemplate
  lifecycle: Runnable<LifecycleHooks>
  source?: { uri: string; [key: string]: any }
}

const props = defineProps<Props>()

props.lifecycle({
  onBeforeAttach() {
    // if the source is provided and the data is null, use that to hydrate. This is necessary for the lite editor
    // to work in memory
    if (props.source && !props.data) {
      //@ts-ignore this works because of the lifecycle bound to the section class
      this.setData(this.dataFrom<Vessel>(props.source))
    }
  },
} as LifecycleHooks)

const NUM_LINES_TO_CLAMP = 4
const descriptionRef: Ref<any> = ref(null)
const isClamped: Ref<boolean> = ref(true)
const showClampButton: Ref<boolean> = ref(true)

onMounted(() => {
  checkDescriptionClampable()
  window.addEventListener('resize', checkDescriptionClampable)
})

onUpdated(() => {
  setTimeout(() => {
    checkDescriptionClampable()
  }, 0)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkDescriptionClampable)
})

const checkDescriptionClampable = () => {
  if (!props.data?.description) {
    showClampButton.value = false
    return
  }

  const height = descriptionRef.value?.scrollHeight!
  const lineHeight = Number(descriptionRef.value?.style.lineHeight.replace('px', ''))!
  const lines = height / lineHeight
  if (lines > NUM_LINES_TO_CLAMP) {
    showClampButton.value = true
  } else {
    showClampButton.value = false
  }
}
</script>

<template>
  <div
    v-if="props.data && (props.data.heading || props.data.description)"
    class="mt-5 w-full max-w-7xl mx-auto p-4 sm:p-8"
  >
    <!-- Heading -->
    <h1 v-if="props.data.heading" class="overview-heading font-bold text-center text-3xl mb-4">
      <SingleLineTextEditor :value="props.data.heading" :is-editable="false" />
    </h1>
    <!-- Narrative -->
    <p
      v-if="props.data.description"
      class="mb-4"
      ref="descriptionRef"
      style="line-height: 24px"
      :class="isClamped ? 'line-clamp-4' : 'line-clamp-none'"
    >
      <MultiLineTextEditor class="gap-10 font-light" :value="props.data.description" :is-editable="false" />
    </p>
    <span v-if="showClampButton" class="cursor-pointer text-theme-primary" @click="isClamped = !isClamped">
      {{ isClamped ? 'Show more' : 'Show less' }}
    </span>
  </div>
</template>
<style>
.overview-heading .ProseMirror p {
  line-height: 46px; /* TailwindCSS would not override the text editors line height */
}
</style>
