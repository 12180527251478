<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { useAttrs } from 'vue'

const props = defineProps<{
  keyField: string
  items: any[]
}>()

// Turn off the inheritAttrs as we apply them to the class using twMerge
defineOptions({
  inheritAttrs: false,
})
const withClassAttrs = (classes: any) => {
  const inAttrs = useAttrs().class as string
  return twMerge(classes, inAttrs)
}
</script>

<template>
  <slot name="before"></slot>
  <div :class="withClassAttrs(`max-w-screen-2xl mx-auto`)" data-test="list-grid-container">
    <ul
      role="list"
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-y-8 sm:gap-x-6"
    >
      <template v-for="(item, index) in props.items" :key="item[props.keyField]">
        <li class="w-full">
          <slot name="item" :item="item" :index="index"></slot>
        </li>
      </template>
    </ul>
  </div>
  <slot name="after"></slot>
</template>
