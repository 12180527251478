import { Component } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { AbstractSection } from '@/services/proposal/Section'

import NavBarLiteSectionVue from './NavBarLiteSection.vue'

export class NavBarLiteSection extends AbstractSection<null> {
  getType(): SectionType {
    return SectionType.NAV_BAR
  }

  getComponent(): Component {
    return NavBarLiteSectionVue
  }

  dataFrom<A extends { uri: string; [p: string]: any }>(_source: A): null {
    throw new Error('Method not implemented.')
  }
}
