import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 95 95",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M61.3542 57.3952V69.666C61.3542 70.5404 62.0632 71.2493 62.9376 71.2493H78.7939V57.3952H61.3542ZM61.3542 55.416H78.7939V23.4794L70.0741 10.8848L61.3542 23.4794V55.416Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M11.8751 87.084H84.3324C86.7485 87.084 87.9867 84.1877 86.3171 82.4413L77.1876 73.2298H62.3438C60.7043 73.2298 59.3751 71.9006 59.3751 70.2611V55.4173L14.6833 10.3309C12.1935 7.82454 7.91675 9.58774 7.91675 13.1206V25.9593V27.7087H13.8542C14.9473 27.7087 15.8334 28.5948 15.8334 29.6878C15.8334 30.7809 14.9473 31.667 13.8542 31.667H7.91675V47.5003H13.8542C14.9473 47.5003 15.8334 48.3865 15.8334 49.4794C15.8334 50.5727 14.9473 51.4586 13.8542 51.4586H7.91675V67.2923H13.8542C14.9473 67.2923 15.8334 68.1786 15.8334 69.2715C15.8334 70.3648 14.9473 71.2507 13.8542 71.2507H7.91675V71.6128V83.1257C7.91675 85.3114 9.68893 87.084 11.8751 87.084ZM27.7084 46.5111C28.2549 46.5111 28.698 46.954 28.698 47.5007V62.3444H43.5417C44.0884 62.3444 44.5313 62.7873 44.5313 63.334C44.5313 63.8806 44.0884 64.3236 43.5417 64.3236H27.7084H26.7188V63.334V47.5007C26.7188 46.954 27.1619 46.5111 27.7084 46.5111Z",
      "clip-rule": "evenodd"
    })
  ]))
}