import { SectionLayout } from '@ankor-io/common/proposal/Section'
import { LineItem, Pricing } from '@ankor-io/common/vessel/types'

/**
 * The value for different types of InputAmountsTaxed
 */
export enum InputAmountsTaxedValues {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
  NONE = 'NONE',
}

export type KeyValueFeatured = {
  key: string
  value: string
  featured: boolean
}

export type VesselShowcaseSectionData = {
  uri: string | null
  type: string
  name: string
  description: string
  images: string[]
  offering: string
  features: KeyValueFeatured[]
  pricing: VesselPricing
  shouldCopyAssets: boolean
  shouldRehydrate: boolean
  buttonLink: string
  buttonText: string
}

export type Size = 'small' | 'medium' | 'large'
export type Alignment = 'left' | 'center' | 'right'
export type HighlightsPlacement =
  | 'topLeft'
  | 'top'
  | 'topRight'
  | 'left'
  | '-'
  | 'right'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight'

export type VesselShowcaseOptions = {
  size: Size
  imageHeight: Size
  alignment: Alignment
  highlightsBackground: string
  showHighlightsBorder: boolean
  highlightsPlacement: HighlightsPlacement
  featuresBackground: string
  showDescription: boolean
  showFeatures: boolean
  showPricing: boolean
  showButton: boolean
  enabledPricingColumns: string[]
  pricingLabel: string
  pricingHeaderBackground: string
  pricingBodyBackground: string
}

export type CurrencyOption = {
  currency: string
  code: string
  leftSided: boolean
}

// Vessel Showcase Layout Template
export type VesselShowcaseLayoutTemplate = SectionLayout & {
  /**
   * The layout type
   */
  type: string

  /**
   * The options for a layout
   */
  options: VesselShowcaseOptions
}

/**
 * Extended pricing for line items to display quantity as percent
 */
export type LineItemPricing = LineItem & {
  displayQuantityAsPercent: boolean
}

/**
 * Extended pricing for vessels with extended line items
 */
export type VesselPricing = Pricing & {
  lineItems: LineItemPricing[]
}
