import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { Component, ComputedOptions, MethodOptions } from 'vue'
import VesselCrewSectionVue from '@/sections/vessel/crew/VesselCrewSection.vue'
import { VesselCrewData } from './types'

export class VesselCrewSection extends AbstractSection<VesselCrewData> {
  getType(): SectionType {
    return SectionType.VESSEL_CREW
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselCrewSectionVue
  }
}
