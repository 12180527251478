import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M9.85716 10.8571V17.7143M14.1429 10.8571V17.7143M3.42859 6.28571H20.5714M19.5 6.28571L18.5711 20.1623C18.5326 20.739 18.2907 21.2786 17.8941 21.6727C17.4974 22.0667 16.9756 22.2857 16.4336 22.2857H7.56645C7.02445 22.2857 6.50259 22.0667 6.10597 21.6727C5.70934 21.2786 5.46743 20.739 5.42895 20.1623L4.50002 6.28571H19.5ZM15.2143 6.28571V2.85714C15.2143 2.55404 15.1014 2.26335 14.9005 2.04902C14.6996 1.83469 14.427 1.71428 14.1429 1.71428H9.85716C9.573 1.71428 9.30048 1.83469 9.09955 2.04902C8.89861 2.26335 8.78573 2.55404 8.78573 2.85714V6.28571H15.2143Z"
    })
  ]))
}