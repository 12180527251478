import { Component } from 'vue'

import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import HeadingSectionVue from './HeadingSectionEditor.vue'
import ConfigurationComponent from './HeadingConfiguration.vue'

export interface HeadingSectionEditorData {
  heading: string
  subheading: string
}

export class HeadingSectionEditor extends ProposalAbstractEditableSection<HeadingSectionEditorData, EditableProposal> {
  async fetchData(): Promise<HeadingSectionEditorData> {
    return Promise.resolve({
      heading: '',
      subheading: '',
    })
  }

  getType(): SectionType {
    return SectionType.HEADING
  }

  getComponent(): Component {
    return HeadingSectionVue
  }

  getConfigurationComponent(): Component | null {
    return ConfigurationComponent
  }
}
