import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M16.6133 3C16.8711 3 17 3.07069 17 3.21208C17 3.2651 16.9908 3.32695 16.9724 3.39764L16.7514 4.77614C16.7145 4.9352 16.6593 5.04124 16.5856 5.09426C16.5304 5.14728 16.4107 5.16495 16.2265 5.14727H14.7901L12.2762 18.9057H13.7127C13.8969 18.8881 14.0074 18.9057 14.0442 18.9588C14.0994 19.0118 14.1087 19.1178 14.0718 19.2769L13.8508 20.6024C13.814 20.7614 13.7587 20.8675 13.6851 20.9205C13.6298 20.9735 13.5101 21 13.326 21H7.38674C7.12891 21 7 20.9293 7 20.7879C7 20.7349 7.00921 20.673 7.02762 20.6024L7.24862 19.2769C7.28545 19.1178 7.33149 19.0118 7.38674 18.9588C7.46041 18.9057 7.58932 18.8881 7.77348 18.9057H9.18232L11.6685 5.14727H10.2597C10.0755 5.16495 9.9558 5.14728 9.90055 5.09426C9.86372 5.04124 9.86372 4.9352 9.90055 4.77614L10.1215 3.39764C10.1584 3.23859 10.2044 3.13255 10.2597 3.07953C10.3333 3.02651 10.4622 3 10.6464 3H16.6133Z"
    })
  ]))
}