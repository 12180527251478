import { StowageService } from './StowageService' // imported for linking
import { AuthenticationProvider } from '@/iam/authentication.provider'

const getDocument = async <T>(uri: string): Promise<T | null> => {
  const token = await AuthenticationProvider.getInstance()().getToken()
  const res: Response = await fetch(`/stowage/document/${encodeURIComponent(uri)}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status === 200) {
    const json: any = await res.json()
    return Promise.resolve(json.data as T)
  }

  return Promise.resolve(null)
}

const setDocument = async <T>(uri: string, document: T): Promise<T | null> => {
  const token = await AuthenticationProvider.getInstance()().getToken()

  const res: Response = await fetch('/stowage/document', {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ uri: uri.toString(), document: document }),
  })

  if (res.status === 200) {
    return Promise.resolve(document as T)
  }

  return Promise.resolve(null)
}

const deleteDocument = async (uri: string): Promise<boolean> => {
  const token = await AuthenticationProvider.getInstance()().getToken()
  const uriEncoded = encodeURIComponent(uri)
  const res: Response = await fetch(`/stowage/document/${uriEncoded}`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status === 200) {
    return Promise.resolve(true)
  }

  return Promise.resolve(false)
}

/**
 * This service is required because it works everywhere in ts files as well as in vue components.
 * The existing {@link StowageService} only works in vue components.
 * @returns a stowage service that allows to get and set documents
 */
export const useStowageService = () => {
  return {
    getDocument: getDocument,
    setDocument: setDocument,
    deleteDocument: deleteDocument,
  }
}
