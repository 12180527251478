<script setup lang="ts">
import { Ref, inject, ref, watch } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { OutlineDelete } from '@ankor-io/icons/outline'
import { SolidPlus } from '@ankor-io/icons/solid'
import { _requestSignedUrl, uploadFile } from '@ankor-io/wheelhouse/src/services/assets/upload'

import AssetUploader from '@/components/asset-uploader/AssetUploader.vue'
import DeleteConfirmation, { DeleteConfirmationDataProps } from '@/components/modal-content/DeleteConfirmation.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { AuthenticationContext } from '@/iam/types'
import { useModal } from '@/modal/useModal'
import { LayoutTemplate } from '@/sections/types'
import { applyDragCursor, removeDragCursor } from '@/utils/dragcursor'

type Props = {
  proposalUri: string
  images: string[]
  layout: LayoutTemplate
  allowsAddImage?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  allowsAddImage: true,
})

const emit = defineEmits<{
  (e: 'update:reorder', value: { from: number; to: number; images: string[] }): void
  (e: 'update:value', value: { field: string; value: string; layout?: LayoutTemplate }): void
  (e: 'delete:value', value: { value: string; index: number; layout?: LayoutTemplate }): void
}>()

const uploadProgress: Ref<number> = ref(0)
const uploadState: Ref<string | null> = ref(null)
const images: Ref<string[]> = ref(props.images || [])
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const { isOpen, updateModalState } = useModal()
const modalValue: Ref<DeleteConfirmationDataProps | null> = ref(null)
const imageToDelete: Ref<{ index: number; image: string } | null> = ref(null)

// ----- Drag Items ----- //

const moveTo: Ref<number | null> = ref(null)
const moveFrom: Ref<number | null> = ref(null)
const hasStartedDrag: Ref<boolean> = ref(false)
const clonedRefs: Ref<any> = ref([])

/**
 * Check if item needs to be shifted down
 */
const shiftDown = (index: number): boolean => {
  if (typeof moveTo.value !== 'number' || typeof moveFrom.value !== 'number') return false
  return moveFrom.value > moveTo.value && moveFrom.value > index && index >= moveTo.value
}

/**
 * Check if item needs to be shifted up
 */
const shiftUp = (index: number): boolean => {
  if (typeof moveTo.value !== 'number' || typeof moveFrom.value !== 'number') return false
  return moveFrom.value < moveTo.value && moveFrom.value < index && index <= moveTo.value
}

const dragstart = (event: DragEvent, index: number) => {
  const imageId = index.toString()
  moveFrom.value = index

  const target = event.target as HTMLElement
  target.classList.remove('shadow-[0_2px_20px_rgba(0,0,0,0.25)]')

  const dataTransfer = event.dataTransfer as DataTransfer
  const image = clonedRefs.value[index].imageRef as HTMLImageElement
  const offsetX = event.offsetX

  dataTransfer.setDragImage(image, offsetX, 64)
  dataTransfer.setData('galleryImageId', `galleryIndex-${imageId}`)

  setTimeout(() => {
    target.classList.add('opacity-0')
  }, 100)
}

const drag = () => {
  if (hasStartedDrag.value) {
    return
  }

  hasStartedDrag.value = true // event happens after dragstart, so moveFrom value cannot be used here
  applyDragCursor()
}

/**
 * Drag end event
 */
const dragend = (event: DragEvent) => {
  removeDragCursor()
  moveTo.value = null
  moveFrom.value = null

  const target = event.target as HTMLElement
  target.classList.add('shadow-[0_2px_20px_rgba(0,0,0,0.25)]')
  setTimeout(() => {
    target.classList.remove('opacity-0')
  }, 100)
}

const dragItemsLeave = (index: number) => {
  applyDragCursor()
  const lastItem = images.value?.length - 1
  if (index === lastItem && moveFrom.value! > lastItem) moveTo.value = null
}

const moveItem = (event: DragEvent, to: number): void => {
  removeDragCursor()
  hasStartedDrag.value = false
  if (moveFrom.value === to) {
    moveTo.value = null
    moveFrom.value = null

    return
  }

  // Get drag item id for lookup
  const dragItemId: string | undefined = event.dataTransfer?.getData('galleryImageId')
  if (dragItemId) {
    const item = images.value.splice(moveFrom.value!, 1)[0]
    reorderList(item, to)
  }
}

/**
 * Add item to designated position
 */
const reorderList = (item: string, to: number): void => {
  images.value.splice(to, 0, item)
  emit('update:reorder', { from: moveFrom.value!, to, images: images.value })

  moveTo.value = null
  moveFrom.value = null
}

const handleUploadFile = async (file: File | File[]): Promise<boolean> => {
  const setProgress = setInterval(() => {
    if (uploadProgress.value >= 99) {
      clearInterval(setProgress)
      uploadProgress.value = 0
    } else {
      uploadProgress.value += 1
    }
  }, 100)

  const token: string | undefined = await authenticationContext.getToken()
  const filesToUpload = Array.isArray(file) ? file : [file]

  const results = filesToUpload.map(async (fileToUpload: File) => {
    const { mediaUri, signedUrl }: { mediaUri: string; signedUrl: string } = await _requestSignedUrl(
      props.proposalUri,
      token!,
    )
    const uploaded: boolean = await uploadFile(signedUrl, fileToUpload)

    const fileReader = new FileReader()
    fileReader.readAsDataURL(fileToUpload)
    if (uploaded) {
      clearInterval(setProgress)
      uploadProgress.value = 0
      uploadState.value = 'Success'
      update({
        field: 'images',
        value: mediaUri,
      })

      setTimeout(() => {
        uploadState.value = null
      }, 3000)

      return Promise.resolve(true)
    } else {
      clearInterval(setProgress)
      uploadProgress.value = 0
      uploadState.value = 'Failed'

      return Promise.resolve(false)
    }
  })

  return results.reduce(
    async (a: Promise<boolean>, b: Promise<boolean>) => (await a) && (await b),
    Promise.resolve(true),
  )
}

const update = (event: { field: string; value: string; layout?: LayoutTemplate }): void => {
  emit('update:value', { field: event.field, value: event.value, layout: event.layout })
}

const deleteImage = (event: { value: string; index: number; layout?: LayoutTemplate }): void => {
  emit('delete:value', {
    value: event.value,
    index: event.index,
    layout: event.layout || props.layout,
  })
}

const openDeleteConfirmation = (index: number, image: string) => {
  modalValue.value = {
    message: 'You are about to remove this image from this presentation. Are you sure you want to continue?',
    labelCancel: 'No, keep the image',
    labelConfirm: 'Yes, delete it',
  }

  imageToDelete.value = { index, image }
  updateModalState(true)
}

const confirmDelete = () => {
  deleteImage({
    value: imageToDelete.value?.image!,
    index: imageToDelete.value?.index!,
  })

  closeModal()
}

const closeModal = () => {
  modalValue.value = null
  imageToDelete.value = null
  updateModalState(false)
}

watch(
  () => props.images,
  (newValue: string[], oldValue: string[]) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      images.value = newValue
    }
  },
)

watch(isOpen, (value) => {
  if (!value) {
    modalValue.value = null
    imageToDelete.value = null
  }
})
</script>
<template>
  <div class="h-full flex flex-col gap-4" id="Gallery">
    <AssetUploader
      class="h-48"
      :uploadProgress="uploadProgress"
      :uploadState="uploadState"
      @file:loaded="handleUploadFile"
    />
    <div class="h-[calc(100vh-33.5rem)] overflow-auto">
      <div class="flex flex-col gap-5">
        <div
          v-for="(image, imageIndex) of images"
          class="gallery-image group relative"
          draggable="true"
          :key="imageIndex"
          @dragstart="dragstart($event, imageIndex)"
          @drag="drag"
          @dragenter.stop="applyDragCursor"
          @dragover.stop.prevent="moveTo = imageIndex"
          @dragleave.stop="dragItemsLeave(imageIndex)"
          @drop.stop.prevent="moveItem($event, imageIndex)"
          @dragend="dragend"
        >
          <div
            class="flex flex-col rounded transition-[background-color] ease-in-out duration-500"
            :class="{ 'bg-gray-300': moveTo === imageIndex && moveFrom !== moveTo }"
          >
            <div
              class="shifted-element relative transition-[transform] ease-in-out duration-500 z-10"
              :class="{
                'pointer-events-none': typeof moveFrom === 'number' && moveFrom !== imageIndex,
                'translate-y-[11.25rem]': shiftDown(imageIndex),
                'translate-y-[-11.25rem]': shiftUp(imageIndex),
              }"
            >
              <!-- On hover add -->
              <div class="absolute top-0 w-full h-full transition-all duration-300 invisible group-hover:visible">
                <div
                  class="z-10 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
                ></div>
              </div>
              <button
                v-if="allowsAddImage"
                class="z-20 absolute flex justify-around items-center top-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:top-1/4 group-hover:-translate-y-1/4 border border-white bg-white w-20 px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
                type="button"
              >
                <SolidPlus class="w-5 h-5 fill-white" />
                <span class="mr-1 text-white font-medium text-xs">Add</span>
              </button>
              <button
                type="button"
                class="z-20 absolute flex gap-x-1 justify-around items-center bottom-0 left-1/2 -translate-x-1/2 cursor-pointer border border-red-600 bg-red-300 w-20 px-3 py-2 rounded-md transition-all duration-300 opacity-0 group-hover:opacity-100"
                :class="
                  allowsAddImage
                    ? 'group-hover:bottom-1/4 group-hover:translate-y-1/4'
                    : 'group-hover:bottom-1/2 group-hover:translate-y-1/2'
                "
                @click="openDeleteConfirmation(imageIndex, image)"
              >
                <OutlineDelete class="w-4 h-4 shrink-0 stroke-red-600" />
                <span class="text-red-600 font-medium text-xs">Delete</span>
              </button>

              <div class="w-full h-40" draggable="false">
                <AssetViewerPlaceholder
                  class="object-cover"
                  :id="`galleryIndex-${imageIndex}`"
                  :url="`/media/${image}`"
                />
              </div>
              <div class="z-[-1] w-full absolute inset-0 h-40">
                <AssetViewerPlaceholder
                  :id="`cloneIndex-${imageIndex}`"
                  :url="`/media/${image}`"
                  @ref:element="(el) => (clonedRefs[imageIndex] = el)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalContentWrapper v-if="modalValue">
      <DeleteConfirmation
        :message="modalValue.message"
        :label-cancel="modalValue.labelCancel"
        :label-confirm="modalValue.labelConfirm"
        @close:modal="closeModal()"
        @confirm:modal="confirmDelete()"
      />
    </ModalContentWrapper>
  </div>
</template>
