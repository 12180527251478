import gql from 'graphql-tag';
import { Component } from 'vue';



import { Segment, Stop } from '@ankor-io/common/itinerary/Itinerary';
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace';
import { EditableProposal } from '@ankor-io/common/proposal/Proposal';
import { SectionType } from '@ankor-io/common/proposal/SectionType';



import MapConfiguration from '@/sections/map/MapConfiguration.vue';
import MapSectionEditorVue from '@/sections/map/MapSectionEditor.vue';
import { MapSectionData } from '@/sections/map/types/types';
import { ProposalAbstractEditableSection } from '@/services/proposal/Section';


const QUERY = gql`
  query Itinerary($URI: String!) {
    itinerary(uri: $URI) {
      segments {
        label
        stops {
          place {
            uri
            name
            location {
              coordinates {
                longitude
                latitude
              }
            }
            images
            description
          }
          pathFromPrevious {
            distance {
              value
              unit
            }
          }
        }
      }
    }
  }
`

const updateSegmentImagesToMediaUri = (segments: Segment[]): Segment[] => {
  return segments.map((segment: Segment) => ({
    ...segment,
    stops: segment.stops.map((stop: Stop) => ({
      ...stop,
      place: { ...stop.place, images: replacePathToMediaUris(stop.place.uri, ...stop.place.images) },
    })),
  }))
}

export class MapSectionEditor extends ProposalAbstractEditableSection<MapSectionData, EditableProposal> {
  async fetchData(): Promise<MapSectionData> {
    const client = await this.client()

    return client
      .query({
        query: QUERY,
        variables: { URI: this.getSlideUri() },
        errorPolicy: 'all',
      })
      .then(({ data, errors }) => {
        console.debug('MapSectionEditor errors: ', errors)
        let distance = 0
        data.itinerary.segments.forEach((segment: Segment) => {
          segment.stops.forEach((stop) => {
            // Calculate the total distance from each spot
            distance += Number(stop.pathFromPrevious?.distance?.value) || 0
          })
        })

        return Promise.resolve({
          segments: updateSegmentImagesToMediaUri(data.itinerary.segments),
          statistics: {
            distanceTravelled: distance === 0 ? '' : distance.toString(),
            distanceTravelledUnit: 'nm',
            cruisingSpeed: '',
            cruisingSpeedUnit: '',
            fuelConsumption: '',
            fuelConsumptionUnit: '',
            tripDuration: '',
          },
        })
      })
  }

  getType(): SectionType {
    return SectionType.ITINERARY_MAP
  }

  getComponent(): Component {
    return MapSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return MapConfiguration
  }
}
