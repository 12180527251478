<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import SingleHeroImage from '@/sections/hero/assets/single.png'
import TripleStackHeroImage from '@/sections/hero/assets/triple-stacked.png'
import Toggle from '@/sections/hero/components/Toggle.vue'
import { LayoutTemplate } from '@/sections/types'

type LayoutTabProps = {
  layout: LayoutTemplate
}

const props = defineProps<LayoutTabProps>()

const emit = defineEmits<{
  (e: 'update:layout', value: { layout: LayoutTemplate }): void
}>()

const enableGalleryRef: Ref<boolean> = ref(false)
const addCycle: Ref<number> = ref(0)

const updateLayout = (layoutName: string) => {
  emit('update:layout', {
    layout: { type: layoutName, options: { ...props.layout.options, enableGallery: enableGalleryRef.value } },
  })
}

/**
 * Shows or hides the Show all items button on the hero
 */
const toggleEnableGallery = () => {
  enableGalleryRef.value = !enableGalleryRef.value
  emit('update:layout', {
    layout: { type: props.layout.type, options: { ...props.layout.options, enableGallery: enableGalleryRef.value } },
  })
}

onMounted(() => {
  enableGalleryRef.value = props.layout?.options?.enableGallery || false
  addCycle.value = props.layout?.options?.addCycle || 0
})
</script>
<template>
  <div id="hero-layout-tab" class="flex flex-col gap-y-5">
    <!-- ml-1, mt-1 added because active/focus ring is cut off -->
    <Toggle
      class="ml-1 mt-1"
      text="Include ‘Show all Photos’ gallery button. NB: All images in this section’s gallery will be included and shown to your client."
      :value="enableGalleryRef"
      @click.prevent="toggleEnableGallery"
    />

    <HelpTip tip="Select a contents style to apply to this section." />
    <hr class="border-gray-200" />

    <div class="h-[calc(100vh-22rem)] overflow-y-auto flex flex-col gap-y-5">
      <div>
        <h1 class="text-sm font-semibold text-gray-900 dark:text-white">Single full size</h1>
        <p class="text-xs text-gray-500 dark:text-gray-400">One hero image that stands alone.</p>
      </div>
      <div class="single-layout group relative">
        <img class="w-full border border-gray-200" :src="SingleHeroImage" />
        <!-- On hover apply -->
        <div v-if="layout.type !== 'single'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout('single')"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute top-0 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </div>
        <Badge text="Added" :show="layout.type === 'single' || !layout.type" />
      </div>

      <div>
        <h1 class="text-sm font-semibold mt-5 text-gray-900 dark:text-white">Triple stacked</h1>
        <p class="text-xs text-gray-500 dark:text-gray-400">
          A hero image sits across the page, with two smaller images below it.
        </p>
      </div>
      <div class="triple-stacked-layout group relative bg-gray-200">
        <img class="mx-auto" :src="TripleStackHeroImage" />
        <!-- On hover apply -->
        <div v-if="layout.type !== 'triple_stacked'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout('triple_stacked')"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute top-0 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </div>
        <Badge text="Added" :show="layout.type === 'triple_stacked'" />
      </div>
    </div>
  </div>
</template>
