
/**
 * Function that formats the length of a yacht in meters and feet.
 *
 * @param lengthInMeters a string holding the length in meters
 * @returns a formatted string that shows length in meters and feet
 */
export const formatLength = (lengthInMeters: string | number): string => {
  const meters = Number(lengthInMeters)
  const feet = meters * 3.281

  if (isNaN(meters) || isNaN(feet)) {
    return ''
  }

  return `${Math.floor(meters)}m/${Math.floor(feet)}ft`
}

/**
 * Function that formats the length of a yacht in meters and feet.
 *
 * @param lengthInMeters a string holding the length in meters
 * @returns a formatted string that shows length in meters and feet separated by a space and feet within parenthesis
 */
export const formatLengthWithSpaceSeparator = (lengthInMeters: string | number): string => {
  if (!lengthInMeters) {
    return ''
  }

  const meters = Number(lengthInMeters)
  const feet = meters * 3.281

  if (isNaN(meters) || isNaN(feet)) {
    return ''
  }

  return `${Math.floor(meters)}m (${Math.floor(feet)}ft)`
}
