import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2 11C2 10.4477 2.44772 10 3 10H21C21.5523 10 22 10.4477 22 11V13C22 13.5523 21.5523 14 21 14H3C2.44772 14 2 13.5523 2 13V11ZM5 11.3333H6V12.6667H5V11.3333ZM19 11.3333H7V12.6667H19V11.3333Z",
      "clip-rule": "evenodd"
    })
  ]))
}