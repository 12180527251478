import { Component } from 'vue'
import { AbstractSection } from '@/services/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import HeadingSectionVue from '@/sections/heading/HeadingSection.vue'

export type HeadingSectionData = {
  heading: string | null
  subheading: string | null
}

export class HeadingSection extends AbstractSection<HeadingSectionData> {
  public onAttached(): void {
    const proxy = this.getProxy<HeadingSectionData>()
    this.data.heading = proxy?.heading || null
    this.data.subheading = proxy?.subheading || null
  }

  getType(): SectionType {
    return SectionType.HEADING
  }

  getComponent(): Component {
    return HeadingSectionVue
  }
}
