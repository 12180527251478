import { Component } from 'vue'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { AbstractSection } from '@/services/proposal/Section'
import ToysAmenitiesSectionVue from '@/sections/vessel/toys-amenities/ToysAmenitiesSection.vue'
import { ToysAmenitiesSectionData } from '@/sections/vessel/toys-amenities/types/types'

export class ToysAmenitiesSection extends AbstractSection<ToysAmenitiesSectionData> {
  getType(): SectionType {
    return SectionType.VESSEL_TOYS_AMENITIES
  }

  getComponent(): Component {
    return ToysAmenitiesSectionVue
  }
}
