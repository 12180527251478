import { Component } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks, LifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Deserializable, Serializable } from '@ankor-io/common/lang/Serializable'

import { Runnable } from '../lang/functional.types'
import { SectionType } from './SectionType'
import { Templating } from './Templating'

/**
 * The generic section interface
 */
export interface GenericSection<T> extends Templating<SectionTemplate> {
  /**
   * The data for this section
   */
  readonly data: T
  /**
   * The layout selected for this section
   */
  readonly layout: SectionLayout
  /**
   * References to the source of the data
   */
  readonly refs?: { [key: string]: any }
  /**
   * Get this section type
   */
  getType(): SectionType
  /**
   * The vue component representing this section
   */
  getComponent(): Component
  /**
   * Get the uri of the slide this section belongs to.
   * Special sections like Header and Footer do not belong
   * to a single slide, therefore they will have a null uri
   */
  getSlideUri(): string | null
  /**
   * Get a proxy object that's the source for the section data
   */
  getProxy<D extends Object>(): D
  /**
   * Get the section properties
   */
  getProperties(): SectionProperties
}

/**
 * Describe the Section interface
 * @param T the type of data
 * @param E the type of object the data is coming from
 */
export interface Section<T> extends GenericSection<T>, LifecycleHooks, Serializable<JsonSection<T>> {
  /**
   * Allows the section developer to set the lifecycle hooks from within the
   * vue component
   *
   * @param hooks the lifecycle hooks the developer wants to consume
   */
  setLifecycleHooks(hooks: LifecycleHooks): void
}

/**
 * Describes the different state a section can be in
 */
export enum EditableSectionState {
  // The section needs initialization
  NEEDS_INIT = 'needs_init',
  // The section has been initialized
  INITIALIZED = 'initialized',
  // The section is about to be removed
  NEEDS_TO_GO = 'needs_to_go',
}

/**
 * Editable section interface definition.
 */
export interface EditableSection<T>
  extends GenericSection<T>,
    EditableLifecycleHooks,
    Serializable<JsonSection<T>>,
    Deserializable<any> {
  /**
   * The section non-persisted unique identifier. Useful for in memory operations
   */
  readonly id: string
  /**
   * Change the state of a section
   * @param state the state to set this section to
   */
  setState(state: EditableSectionState): void
  /**
   * Get the current section state
   */
  getState(): EditableSectionState
  /**
   * Update the section data from the object
   *
   * @param d the updated object
   */
  updateDataFrom(d: any): void
  /**
   * Fetches the data from the original source and hydrates it to the section
   */
  hydrate(): Promise<void>
  /**
   * Trigge
   */
  onChanges(event: ChangeEvent<any>): void
  /**
   * The vue component representing the configuration for this section
   * Returns null when not implemented
   */
  getConfigurationComponent(): Component | null
  /**
   * Allows the section developer to set the lifecycle hooks from within the
   * vue component
   *
   * @param hooks the lifecycle hooks the developer wants to consume
   */
  setLifecycleHooks(hooks: EditableLifecycleHooks): void

  /**
   * Ability to set data
   * @param data
   */
  setData(data: T): void

  /**
   * Ability to set layout
   * @param layout
   */
  setLayout(layout: SectionLayout): void

  /**
   * Hook Section lifecycles into the beforeMount hook
   */
  beforeMount(): void

  /**
   * Hook Section lifecycles into the beforeUnmount hook
   */
  beforeUnmount(): void
  /**
   * Returns true when the section is its hydrate lifecycle, false when the section
   * is not in the hydrate lifecycle
   */
  isHydrating(): boolean
  /**
   * Notify all the section observers about a particular change.
   * This allows sections to communicate sideways with the configuration component
   * and vice-versa without having to go through a diff sync cycle
   */
  notifyAll(args: any): void
  /**
   * Allows to subscribe an observer that listens to sections notifications
   *
   * @param runnable the observer to trigger on notify all
   */
  subscribe(runnable: Runnable<any>): void
  /**
   * Removes this runnable from listining to sections notifications
   *
   * @param runnable the observer to remove
   */
  unsubscribe(runnable: Runnable<any>): void
}

/**
 * Represents the section template
 */
export type SectionTemplate = {
  /**
   * The section's type
   */
  type: SectionType
  /**
   * The sections choosen layout
   */
  layout: SectionLayout
  /**
   * Any default static data that should be saved in the template
   */
  data?: any
  /**
   * Section properties
   */
  properties?: SectionProperties
  /**
   * Holds reference to where the data is coming from
   */
  refs?: { [key: string]: any }
}

export type JsonSection<T> = {
  /**
   * The section's type
   */
  type: SectionType
  /**
   * The sections layout
   */
  layout: SectionLayout
  /**
   * the section data
   */
  data: T
  /**
   * The section unique identifier
   */
  id: string
  /**
   * The state for this section. This can be null for non editable sections
   * since they are in a permanent non changing state
   */
  state?: EditableSectionState
  /**
   * Section properties
   */
  properties?: SectionProperties
  /**
   * Holds reference to where the data is coming from
   */
  refs?: { [key: string]: any }
}

/**
 * Marker type to represent the layout template
 */
export type SectionLayout = {}

/**
 * Used as object initializer in the section constructor
 */
export type SectionInit = {
  /**
   * The slide id this section belongs to.
   * Special sections like Header or Footer do not belong
   * to a slide therefore they will have a null slideId
   */
  slideUri: string | null
  /**
   * The section template
   */
  template: SectionTemplate
  /**
   * The object acting as the source for the section data
   */
  source: any | null
  /**
   * A nullable id that is the section unique identifier
   */
  id?: string
  /**
   * A nullable section state
   */
  state?: EditableSectionState
}

export type SectionProperties = {
  hydration: HydrationType
}

export enum HydrationType {
  /**
   * Hydrated data is stored in the section object
   */
  BY_VALUE = 'by_value',
  /**
   * Hydrated data is a referenced from the source subdocument
   */
  BY_REFERENCE = 'by_reference',
}
